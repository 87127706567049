/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage, Spacings } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import { getErrorMessage, hasError } from './utils';
import { useStoreField } from './DataStore';

export type StoreErrorProps = {
	name: string;
};

export default function StoreError({ name }: StoreErrorProps): ReactElement | null {
	const field = useStoreField(name);

	return hasError(field) ? (
		<ErrorMessage type="small" style={{ marginTop: Spacings.xxSmall }}>
			{getErrorMessage(field.errors)}
		</ErrorMessage>
	) : null;
}
