/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import VerifyEmailError from 'pages/login-failure/VerifyEmailError';
import DefaultError from 'pages/login-failure/DefaultError';
import { ReactElement, useEffect } from 'react';
import { useLocation } from 'url/hooks';
import { track } from 'tracking/sentry';

export default function LoginFailure(): ReactElement {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const error = searchParams.get('error');

	if (error && error.toLowerCase().indexOf('steadybit-verify-email') >= 0) {
		return <VerifyEmailError />;
	}
	useEffect(() => {
		track('LoginFailure', {
			error: error,
		});
	}, [error]);
	return <DefaultError error={error} />;
}
