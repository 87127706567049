/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Flex, Grid, presets, SingleChoiceListItem } from '@steadybit/ui-components-lib';
import { PreflightActionIntegrationScope } from 'services/preflightActionIntegrationApi';
import { FormikTextField, Label, OptionTypeBase, Stack, Text } from 'components';
import TeamSelection from 'pages/settings/teams/components/TeamSelection';
import { CreatePreflightActionIntegrationRequest } from 'ui-api';
import { ReactElement, useEffect, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { useFormikContext } from 'formik';
import { isUserAdmin } from 'utils/user';

import { PreflightActionIcon } from '../../../../../hocs/PreflightActionIcon';
import { useAsyncState } from '../../../../../utils/hooks/useAsyncState';
import { Services } from '../../../../../services/services';

export type PreflightActionIntegrationFormValues = CreatePreflightActionIntegrationRequest;

interface PreflightActionIntegrationFormProps {
	disabled?: string[] | boolean;
}

export function PreflightActionIntegrationForm({ disabled }: PreflightActionIntegrationFormProps): ReactElement {
	const isDisabled = (name: string): boolean => (Array.isArray(disabled) ? disabled.includes(name) : Boolean(disabled));

	return (
		<Stack size={'medium'}>
			<FormPreflightActionIntegrationScope disabled={isDisabled('scope')} />
			<FormikTextField
				label={'Name'}
				name={'name'}
				maxLength={255}
				autoFocus
				autoComplete={'off'}
				disabled={isDisabled('name')}
			/>
			<FormPreflightActionIntegrationPreflightAction disabled={isDisabled('preflightActionId')} />
		</Stack>
	);
}

function FormPreflightActionIntegrationScope({ disabled }: { disabled?: boolean }): ReactElement {
	const formik = useFormikContext<PreflightActionIntegrationFormValues>();
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const handleScopeChange = (option?: OptionTypeBase | null): void => {
		if (option && 'value' in option && option.value !== formik.values.scope) {
			formik.setFieldValue('scope', option?.value);
			formik.setFieldTouched('scope');
		}
		if (option?.value !== PreflightActionIntegrationScope.TEAM.value) {
			formik.setFieldValue('team', '');
			formik.setFieldTouched('team');
		}
	};

	const hasError = !!formik.errors.team;

	return (
		<Grid cols="1fr 2fr" spacing="small">
			<Flex spacing="xSmall">
				<Label>Scope</Label>
				<presets.dropdown.SingleChoiceButton
					selectedId={formik.values.scope}
					withKeyboardArrowSupport
					maxContentHeight="250px"
					items={(isAdmin
						? [PreflightActionIntegrationScope.GLOBAL, PreflightActionIntegrationScope.TEAM]
						: [PreflightActionIntegrationScope.TEAM]
					).map((v) => ({
						...v,
						id: v.value,
					}))}
					disabled={disabled}
					onSelect={(id) => handleScopeChange(PreflightActionIntegrationScope[id])}
					style={{ width: '100%' }}
				>
					{PreflightActionIntegrationScope[formik.values.scope].label}
				</presets.dropdown.SingleChoiceButton>
			</Flex>

			{formik.values.scope === 'TEAM' && (
				<Flex spacing="xSmall">
					<Label>Team</Label>

					<TeamSelection
						value={formik.values.team}
						myTeamsOnly={!isAdmin}
						includeAny={isAdmin}
						disabled={disabled}
						onChange={(teamId) => {
							formik.setFieldValue('team', teamId);
							formik.setFieldTouched('team');
						}}
					/>
					{hasError ? (
						<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
							{formik.errors.team}
						</Text>
					) : null}
				</Flex>
			)}
		</Grid>
	);
}

type PrefixContentRenderer = (p: { item: SingleChoiceListItem }) => ReactElement | null;
const PrefixContent: PrefixContentRenderer = ({ item }): ReactElement | null => {
	return <PreflightActionIcon id={item.id} />;
};

function FormPreflightActionIntegrationPreflightAction({ disabled }: { disabled?: boolean }): ReactElement {
	const formik = useFormikContext<PreflightActionIntegrationFormValues>();
	const [preflightActions] = useAsyncState(
		() => Services.preflightActionDefinitionsApi.fetchPreflightActionSummaries(),
		[],
	);
	const [items, setItems] = useState<SingleChoiceListItem[]>([]);
	useEffect(() => {
		if (preflightActions.value && preflightActions.value.content) {
			setItems(
				preflightActions.value.content.map((action) => ({
					id: action.id,
					label: action.label + ' (' + action.id + ')',
					hasError: formik.errors.preflightActionId && formik.values.preflightActionId === action.id,
				})),
			);
		}
	}, [preflightActions.value]);
	const selectedPreflightAction = items.find((item) => item.id === formik.values.preflightActionId);

	const handlePreflightActionChange = (preflightActionId?: string | null): void => {
		if (preflightActionId && preflightActionId !== formik.values.preflightActionId) {
			formik.setFieldValue('preflightActionId', preflightActionId);
			formik.setFieldTouched('preflightActionId');
		}
	};

	const hasError = !!formik.errors.preflightActionId;

	return (
		<Stack size={'xSmall'}>
			<Flex spacing="xSmall">
				<Label>Preflight Action</Label>
				<presets.dropdown.SingleChoiceButton
					selectedId={formik.values.preflightActionId}
					withKeyboardArrowSupport
					items={items}
					disabled={disabled}
					PrefixContent={PrefixContent}
					onSelect={(id) => handlePreflightActionChange(id)}
					style={{ width: '100%' }}
				>
					{selectedPreflightAction ? selectedPreflightAction.label : 'Select preflight action'}
				</presets.dropdown.SingleChoiceButton>
				{hasError ? (
					<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
						{formik.errors.preflightActionId}
					</Text>
				) : null}
			</Flex>
		</Stack>
	);
}
