/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { OccuranceVO, TemplateMetadataVO, VariableVO } from 'ui-api';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { usePromise } from 'utils/hooks/usePromise';
import { useStore } from 'DataStore/DataStore';
import { Services } from 'services/services';
import { useEffect } from 'react';

import { UseTemplateFormData } from './UseTemplateFormLoadingHandler';

interface EnrichedMetadata extends TemplateMetadataVO {
	environmentVariables: VariableVO[];
}

interface MetadataSyncJobProps {
	withExperimentHyphotesisExtraction?: boolean;
	withExperimentNameExtraction?: boolean;
}

export default function MetadataSyncJob({
	withExperimentHyphotesisExtraction,
	withExperimentNameExtraction,
}: MetadataSyncJobProps): null {
	const { data, setValue } = useStore<UseTemplateFormData>();
	const { id, environmentId } = data;

	const environmentVariablesResult = Services.environments.useEnvironmentVariables$(environmentId);
	const isEnvVarLoading = environmentVariablesResult.loading;
	const environmentVariables = environmentVariablesResult.value?.content || [];
	const enrichtedMetadataResult = usePromise(async () => {
		const metadata = await Services.templatesApi.getTemplateMetadata(
			{
				...data,
				lanes: data.__originalLanes,
				experimentName: withExperimentNameExtraction ? data.__originalExperimentName : '',
				hypothesis: withExperimentHyphotesisExtraction ? data.__originalHypothesis : '',
			},
			environmentId,
		);

		return {
			...metadata,
			environmentVariables: [],
		} as EnrichedMetadata;
	}, [id, environmentId]);
	if (enrichtedMetadataResult.value) {
		enrichtedMetadataResult.value.environmentVariables = environmentVariables;
	}
	const [stableId, stableMetadata] = useStableInstance(enrichtedMetadataResult.value);
	useEffect(() => {
		if (stableMetadata === undefined || isEnvVarLoading) {
			return;
		}

		const variablesMap: Record<string, OccuranceVO[]> = {};
		Object.keys(stableMetadata.variables).forEach((key) => {
			variablesMap[key] = stableMetadata.variables[key];
		});

		const placeholdersMap: Record<string, OccuranceVO[]> = {};
		Object.keys(stableMetadata.placeholders).forEach((key) => {
			placeholdersMap[key] = stableMetadata.placeholders[key];
		});

		const placeholderValues: Record<string, string> = {};
		Object.keys(placeholdersMap).forEach((key) => {
			placeholderValues[key] = '';
		});

		const variableValuesMap: Record<string, string> = {};
		Object.keys(variablesMap).forEach((key) => {
			variableValuesMap[key] = '';
		});

		stableMetadata.environmentVariables.forEach(({ key, value }) => {
			variableValuesMap[key] = value;
		});

		setValue('initialMetadataLoaded', true);
		setValue('variables', stableMetadata.environmentVariables);
		setValue('variablesMap', variablesMap);
		setValue('placeholdersMap', placeholdersMap);
		setValue('placeholderValuesMap', placeholderValues);
		setValue('variableValuesMap', variableValuesMap);
		setValue(
			'placeholders',
			data.placeholders.filter((p) => placeholdersMap[p.key]),
		);
	}, [stableId, isEnvVarLoading]);

	return null;
}
