/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Icon, IconButton, Text, Tooltip } from '@steadybit/ui-components-lib';
import { RouterLink, TableDataCell, TableRow } from 'components';
import { ExperimentScheduleSummaryVO } from 'ui-api';
import { ReactElement } from 'react';

import { ActivateToggle, CronOrStart, NextExecution, ScheduledBy } from './ScheduleTableComponents';
import { ConfigToEdit } from './types';

interface ScheduleRowProps {
	schedule: ExperimentScheduleSummaryVO;
	setConfigToEdit: (config: ConfigToEdit) => void;
	setScheduleIdToDelete: (id: string) => void;
}

export default function ScheduleRow({
	schedule,
	setScheduleIdToDelete,
	setConfigToEdit,
}: ScheduleRowProps): ReactElement {
	const isDeletePermitted: boolean = schedule._actions?.includes('delete-schedule') ?? false;
	const isEditPermitted: boolean = schedule._actions?.includes('edit') ?? false;

	return (
		<TableRow>
			<TableDataCell maxWidth={450}>
				<Flex direction="horizontal" spacing="small" align="center">
					<Icon
						type={!schedule.enabled ? 'calendar-paused' : schedule.cron ? 'calendar-recurrent' : 'calendar-once'}
						style={{ color: Colors.neutral700 }}
					/>

					<Flex>
						<RouterLink
							to={`/experiments/edit/${schedule.experimentKey}`}
							sx={{ color: Colors.neutral800, ':hover': { color: Colors.slate } }}
						>
							<Flex direction="horizontal" align="center" spacing="xxSmall">
								<Text type="mediumStrong">{schedule.experimentKey}</Text>
								<Text type="medium" textEllipsis>
									{schedule.experimentName}
								</Text>
							</Flex>
						</RouterLink>

						<Flex direction="horizontal" align="center" spacing="xxSmall" data-private>
							<ScheduledBy schedule={schedule} setConfigToEdit={setConfigToEdit} />
						</Flex>
					</Flex>
				</Flex>
			</TableDataCell>

			<TableDataCell>
				<NextExecution schedule={schedule} />
			</TableDataCell>

			<TableDataCell>
				<CronOrStart schedule={schedule} />
			</TableDataCell>

			<TableDataCell>
				<ActivateToggle schedule={schedule} />
			</TableDataCell>

			<TableDataCell justifyContent="flex-end">
				<Tooltip content={isEditPermitted ? 'Edit schedule' : 'View schedule'}>
					{({ setRefElement }) => (
						<IconButton
							ref={setRefElement}
							icon={isEditPermitted ? 'edit' : 'view'}
							type="chromelessWithBorderOnHover"
							size="small"
							onClick={() => setConfigToEdit({ experimentKey: schedule.experimentKey })}
						/>
					)}
				</Tooltip>
			</TableDataCell>

			<TableDataCell justifyContent="flex-end">
				<Tooltip
					content={isDeletePermitted ? 'Delete schedule' : `You don't have permissions to delete this schedule${''}`}
				>
					{({ setRefElement }) => (
						<IconButton
							ref={setRefElement}
							type="chromelessWithBorderOnHover"
							disabled={!isDeletePermitted}
							size="small"
							icon="delete"
							onClick={() => setScheduleIdToDelete(schedule.id)}
						/>
					)}
				</Tooltip>
			</TableDataCell>

			<TableDataCell></TableDataCell>
		</TableRow>
	);
}
