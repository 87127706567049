/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull platform_frontend'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 126
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/steadybit/Steadybit/implementation/platform_frontend)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development' | 'onpremtest' | 'onpremtest20240729';

export const ApiKey: Record<Environment, string> = {
  production: '',
  development: '',
  onpremtest: '',
  onpremtest20240729: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '126',
    branch: 'main',
    source: 'platform_frontend',
    versionId: '11dc752d-92c3-4946-9da0-e32f5cf19caf'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * Available height of the client to render the product
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  client_height?: number;
  /**
   * Ratio between width/height of the available space to render the product
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  client_ratio?: number;
  /**
   * The resolution for rendering the product (width x height)
   */
  client_resolution?: string;
  /**
   * Ratio between height of the available space to render the product / height of the screen.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  client_screen_height_ratio?: number;
  /**
   * Ratio between width of the available space to render the product / width of the screen
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  client_screen_width_ratio?: number;
  /**
   * Available width of the client to render the product
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  client_width?: number;
  fbclid?: string;
  gbraid?: string;
  gclid?: string;
  hubspot_url?: string;
  initial_dclid?: any;
  initial_fbclid?: any;
  initial_gbraid?: any;
  initial_gclid?: any;
  initial_ko_click_id?: any;
  initial_li_fat_id?: any;
  initial_msclkid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_rtd_cid?: any;
  initial_ttclid?: any;
  initial_twclid?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_id?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  initial_wbraid?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | do_ce_improve_reliability, roll_out_ce, train_teams, learn_ce, unsure, compare_ce_solutions |
   */
  intention?:
    | "do_ce_improve_reliability"
    | "roll_out_ce"
    | "train_teams"
    | "learn_ce"
    | "unsure"
    | "compare_ce_solutions";
  invited_by?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | developer, sre, performance_qa_engineer, manager, other, prefer_not_to_say |
   */
  job_role?: "developer" | "sre" | "performance_qa_engineer" | "manager" | "other" | "prefer_not_to_say";
  logrocket_url?: string;
  referrer?: string;
  referring_domain?: string;
  /**
   * Screen height of the entire display
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  screen_height?: number;
  /**
   * Screen ratio (width/height) of the entire display
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  screen_ratio?: number;
  /**
   * Screen resolution of the entire display
   */
  screen_resolution?: string;
  /**
   * Screen width of the entire display
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  screen_width?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  technology_focus?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user, admin |
   */
  user_role?: "user" | "admin";
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  wbraid?: string;
}

export interface AdviceTargetListFilteredProperties {
  filter_text: string;
}

export interface AgentAddVisitedProperties {
  url?: string;
}

export interface AgentSetupCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_container_discovered: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_deployments_discovered: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_hosts_discovered: number;
}

export interface AgentSetupDiscoveryViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_container_discovered: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_deployments_discovered: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_hosts_discovered: number;
}

export interface AgentSetupInstallationContainerRuntimeChosenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | containerd, docker, crio |
   */
  agent_container_runtime: "containerd" | "docker" | "crio";
}

export interface AgentSetupInstallationScriptCopiedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | containerd, docker, crio |
   */
  agent_container_runtime: "containerd" | "docker" | "crio";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | kubernetes_helm, docker, linux |
   */
  agent_installation_technology: "kubernetes_helm" | "docker" | "linux";
  agent_kubernets_cluster_name?: string;
}

export interface AgentSetupInstallationSystemLoadingProperties {
  extensions_connected: boolean;
  system_discovered: boolean;
}

export interface AgentSetupInstallationTechnologyChosenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | kubernetes_helm, docker, linux |
   */
  agent_installation_technology: "kubernetes_helm" | "docker" | "linux";
}

export interface AgentSetupInstallationTroubleshootingProperties {
  extensions_connected: boolean;
  system_discovered: boolean;
}

export interface AgentSetupSkippedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | start, installation, discovery, extension |
   */
  agent_setup_step: "start" | "installation" | "discovery" | "extension";
}

export interface AgentSetupSuggestedExtensionsViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  agent_setup_suggested_extensions: string[];
}

export interface AgentsListFilteredProperties {
  filter_text: string;
}

export interface AgentsListViewedProperties {
  agents_ever_reported: boolean;
  url?: string;
}

export interface ApiAccessTokensListFilteredProperties {
  filter_text: string;
}

export interface ApiAccessTokensListSortedProperties {
  sorted_by: string;
}

export interface ApiAccessTokensListViewedProperties {
  url?: string;
}

export interface DashboardHeroTriggeredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | create_experiment, install_extensions, create_incident_badge |
   */
  dashboard_hero_action: "create_experiment" | "install_extensions" | "create_incident_badge";
}

export interface DashboardSystemDiscoveryEnlargedProperties {
  environment_id?: string;
}

export interface DashboardSystemDiscoveryEnvironmentChangedProperties {
  environment_id?: string;
}

export interface DashboardSystemDiscoveryRefreshedProperties {
  environment_id?: string;
}

export interface DashboardSystemDiscoveryViewedProperties {
  environment_id?: string;
}

export interface DashboardTeamActivityExportedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 7_days, 30_days, all_time |
   */
  team_activity_time_range: "7_days" | "30_days" | "all_time";
}

export interface DashboardTeamActivityFilteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 7_days, 30_days, all_time |
   */
  team_activity_time_range: "7_days" | "30_days" | "all_time";
}

export interface DashboardViewedProperties {
  resume_agent_setup_shown: boolean;
}

export interface EnvironmentListSortedProperties {
  sorted_by: string;
}

export interface EnvironmentListViewedProperties {
  url?: string;
}

export interface EnvironmentVariableListViewedProperties {
  environment_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  environment_variables?: string[];
}

export interface ExperimentExecutionFilteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | failed, errored, completed, canceled |
   */
  experiment_execution_state?: "failed" | "errored" | "completed" | "canceled";
  experiment_execution_user_filtered?: boolean;
}

export interface ExperimentExecutionLogDownloadedProperties {
  environment_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  experiment_execution_id: number;
  experiment_execution_log_type: string;
  experiment_key?: string;
}

export interface ExperimentExecutionMarkerSetProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  experiment_execution_id: number;
  experiment_execution_running: boolean;
  experiment_key?: string;
}

export interface ExperimentExecutionRunModalViewedProperties {
  environment_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  experiment_execution_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | failed, succeeded, aborted, errored |
   */
  experiment_execution_result?: "failed" | "succeeded" | "aborted" | "errored";
  experiment_execution_running: boolean;
  experiment_key?: string;
  experiment_run_modal_step?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | failed, succeeded, aborted, errored |
   */
  experiment_run_modal_step_state?: "failed" | "succeeded" | "aborted" | "errored";
}

export interface ExperimentExecutionViewedProperties {
  environment_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  experiment_execution_id: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | failed, succeeded, aborted, errored |
   */
  experiment_execution_result?: "failed" | "succeeded" | "aborted" | "errored";
  experiment_execution_running: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | attack_monitor, agent_log, tracing |
   */
  experiment_execution_view?: "attack_monitor" | "agent_log" | "tracing";
  experiment_key?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  experiment_number_of_steps: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  experiment_steps?: string[];
}

export interface ExperimentListFilteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_actions?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_environments?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_tags?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_target_types?: string[];
  filter_text?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_texts?: string[];
}

export interface ExperimentListSortedProperties {
  sorted_by: string;
}

export interface ExperimentListViewedProperties {
  url?: string;
}

export interface ExperimentSidebarFilteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  elements_count?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | action, template |
   */
  experiment_sidebar_category: "action" | "template";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_actions?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_kind?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_tags?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_target_types?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_texts?: string[];
}

export interface ExperimentSidebarGroupedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | technology, legacy |
   */
  group_by: "technology" | "legacy";
}

export interface ExperimentSidebarStepDocumentationOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | check, attack, load_test, other, basic |
   */
  experiment_step_action_kind?: "check" | "attack" | "load_test" | "other" | "basic";
  experiment_step_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | action, template |
   */
  experiment_step_kind: "action" | "template";
}

export interface ExperimentSidebarViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  elements_count?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | action, template |
   */
  experiment_sidebar_category: "action" | "template";
}

export interface ExperimentStepTargetQueryKeyAddedProperties {
  action: string;
  environment_id?: string;
  experiment_key?: string;
  experiment_step_target_key_changed?: string;
}

export interface ExperimentStepTargetQueryKeyRemovedProperties {
  action: string;
  environment_id?: string;
  experiment_key?: string;
  experiment_step_target_key_changed?: string;
}

export interface ExperimentStepTargetQueryKeyReplacedProperties {
  action: string;
  environment_id?: string;
  experiment_key?: string;
  experiment_step_target_key_changed?: string;
}

export interface ExperimentStepTargetSelectionTemplateAppliedProperties {
  action: string;
  environment_id?: string;
  experiment_key?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | default, ui |
   */
  experiment_step_target_template_method: "default" | "ui";
  experiment_step_target_template_name?: string;
}

export interface ExperimentTargetsAllViewedProperties {
  target_type?: string;
}

export interface ExperimentTargetsAttackedViewedProperties {
  attack_query_defined: boolean;
  target_type?: string;
}

export interface ExperimentTemplateDetailsViewedProperties {
  experiment_template_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | new_experiment, settings |
   */
  experiment_template_view_context: "new_experiment" | "settings";
}

export interface ExperimentTemplateUsedProperties {
  experiment_template_name?: string;
}

export interface ExperimentTemplateUsedWizardStepCompletedProperties {
  experiment_template_last_step: boolean;
  experiment_template_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  experiment_template_step: number;
  experiment_template_step_placeholder?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  experiment_template_total_steps: number;
}

export interface ExperimentTemplateUsedWizardStepStartedProperties {
  experiment_template_last_step: boolean;
  experiment_template_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  experiment_template_step: number;
  experiment_template_step_placeholder?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  experiment_template_total_steps: number;
}

export interface ExperimentVariableDeletedProperties {
  experiment_key?: string;
  experiment_variable: string;
  variable_shadowing: boolean;
}

export interface ExperimentVariableListViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  environment_variables?: string[];
  experiment_key?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  experiment_variables?: string[];
}

export interface ExperimentViewedProperties {
  environment_id?: string;
  experiment_key?: string;
  experiment_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  experiment_number_of_steps: number;
  /**
   * Is the experiment that the user currently views already saved or unsaved?
   */
  experiment_saved: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  experiment_steps?: string[];
}

export interface ExplorerAdviceListViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | explorer_advice, explorer_target |
   */
  advice_context: "explorer_advice" | "explorer_target";
  advice_id_shown?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  advice_ids_listed: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filter_tag?: string[];
}

export interface ExplorerAdviceViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | explorer_advice, explorer_target |
   */
  advice_context: "explorer_advice" | "explorer_target";
  advice_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | validation_needed, implemented, action_needed |
   */
  advice_status: "validation_needed" | "implemented" | "action_needed";
  target: string;
}

export interface ExtensionTypeDeletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | actions, target_types, target_attributes, target_enrichment_rules, advice_definitions, preflight_actions_definitions |
   */
  extension_type:
    | "actions"
    | "target_types"
    | "target_attributes"
    | "target_enrichment_rules"
    | "advice_definitions"
    | "preflight_actions_definitions";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  extension_type_ids: string[];
  url?: string;
}

export interface ExtensionsListViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  current_page?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | actions, target_types, target_attributes, target_enrichment_rules, advice_definitions, preflight_actions_definitions |
   */
  extension_type:
    | "actions"
    | "target_types"
    | "target_attributes"
    | "target_enrichment_rules"
    | "advice_definitions"
    | "preflight_actions_definitions";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  extension_warnings: ("unavailable" | "multiple_versions" | "unknown_versions")[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_elements?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_pages?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  type_ids_multiple_versions?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  type_ids_unavailable?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  type_ids_unknown_versions?: string[];
  url?: string;
}

export interface LandscapeExplorerAttributesSearchedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | target_attributes, target_group_shared_attributes, group_shared_attributes |
   */
  landscape_explorer_target_attribute_type:
    | "target_attributes"
    | "target_group_shared_attributes"
    | "group_shared_attributes";
  search_term: string;
}

export interface LandscapeExplorerColorBySetAttributeProperties {
  environment_id?: string;
  landscape_explorer_colored_by?: string;
  landscape_explorer_filter_query?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  landscape_explorer_grouped_by?: string[];
  landscape_explorer_set_attribute: string;
  landscape_explorer_set_attribute_values_length: string;
  landscape_explorer_sized_by?: string;
  url?: string;
}

export interface LandscapeExplorerColorReusedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_color_assignments: number;
  url?: string;
}

export interface LandscapeExplorerDeletedViewViewedProperties {
  url?: string;
}

export interface LandscapeExplorerGroupBySetAttributeProperties {
  environment_id?: string;
  landscape_explorer_colored_by?: string;
  landscape_explorer_filter_query?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  landscape_explorer_grouped_by?: string[];
  landscape_explorer_set_attribute: string;
  landscape_explorer_set_attribute_values_length: string;
  landscape_explorer_sized_by?: string;
  url?: string;
}

export interface LandscapeExplorerGroupDetailsViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shared_attributes, targets |
   */
  landscape_explorer_subview: "shared_attributes" | "targets";
}

export interface LandscapeExplorerHelpNeededProperties {
  url?: string;
}

export interface LandscapeExplorerHelpTutorialDismissedProperties {
  url?: string;
}

export interface LandscapeExplorerHelpTutorialDismissedPermanentlyProperties {
  url?: string;
}

export interface LandscapeExplorerHelpTutorialOpenedProperties {
  url?: string;
}

export interface LandscapeExplorerNewViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | view_switcher, saved_views |
   */
  landscape_explorer_new_view_context: "view_switcher" | "saved_views";
  url?: string;
}

export interface LandscapeExplorerRenderingLimitReachedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_groups: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_targets: number;
  url?: string;
}

export interface LandscapeExplorerResettedProperties {
  environment_id?: string;
  landscape_explorer_colored_by?: string;
  landscape_explorer_filter_query?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  landscape_explorer_grouped_by?: string[];
  landscape_explorer_sized_by?: string;
  url?: string;
}

export interface LandscapeExplorerTargetAttributesViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | target_attributes, target_group_shared_attributes, group_shared_attributes |
   */
  landscape_explorer_target_attribute_type:
    | "target_attributes"
    | "target_group_shared_attributes"
    | "group_shared_attributes";
}

export interface LandscapeExplorerTargetCopiedForExperimentProperties {
  environment_id?: string;
  target_query: string;
}

export interface LandscapeExplorerTargetDuplicationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  explore_method: ("color_by" | "group_by")[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_targets: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_visible_targets: number;
  url?: string;
}

export interface LandscapeExplorerTargetSelectedForExperimentProperties {
  environment_id?: string;
  target_query: string;
}

export interface LandscapeExplorerViewConfiguredProperties {
  environment_id?: string;
  landscape_explorer_colored_by?: string;
  landscape_explorer_filter_query?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  landscape_explorer_grouped_by?: string[];
  landscape_explorer_show_advice?: boolean;
  landscape_explorer_sized_by?: string;
  url?: string;
}

export interface LandscapeExplorerViewLoadedProperties {
  environment_id?: string;
  landscape_explorer_colored_by?: string;
  landscape_explorer_filter_query?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  landscape_explorer_grouped_by?: string[];
  landscape_explorer_show_advice?: boolean;
  landscape_explorer_sized_by?: string;
  landscape_explorer_view_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | navigation, hub, shared_link |
   */
  landscape_explorer_view_source: "navigation" | "hub" | "shared_link";
  url?: string;
}

export interface LandscapeExplorerViewSavedProperties {
  environment_id?: string;
  landscape_explorer_colored_by?: string;
  landscape_explorer_filter_query?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  landscape_explorer_grouped_by?: string[];
  landscape_explorer_save_new?: boolean;
  landscape_explorer_show_advice?: boolean;
  landscape_explorer_sized_by?: string;
  landscape_explorer_view_name?: string;
  url?: string;
}

export interface LandscapeExplorerViewedProperties {
  environment_id?: string;
  url?: string;
}

export interface LandscapeTableViewedProperties {
  landscape_table_show_advice?: boolean;
  target_type?: string;
  url?: string;
}

export interface LicenseExpiredViewedProperties {
  url?: string;
}

export interface LicenseOverviewExportedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ui, api |
   */
  license_export_method: "ui" | "api";
}

export interface LicenseOverviewViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  agents_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  agents_limit?: number;
  agents_limit_reached: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  environment_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  environment_limit?: number;
  environment_limit_reached: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  experiment_runs_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  experiment_runs_limit?: number;
  experiment_runs_limit_reached: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ui, api |
   */
  license_view_method: "ui" | "api";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  team_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  team_limit?: number;
  team_limit_reached: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  users_amount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  users_limit?: number;
  users_limit_reached: boolean;
}

export interface LoginFailureProperties {
  login_error?: string;
}

export interface OnboardingQuestionnaireViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | job_role, intention, technology_focus |
   */
  onboarding_question: "job_role" | "intention" | "technology_focus";
}

export interface PlatformDataExportedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | database, metrics, user_analytics |
   */
  platform_export_type: "database" | "metrics" | "user_analytics";
}

export interface PreflightActionIntegrationListFilteredProperties {
  filter_team?: string;
  filter_text?: string;
}

export interface PreflightActionIntegrationListSortedProperties {
  sorted_by: string;
}

export interface PreflightActionIntegrationListViewedProperties {
  url?: string;
}

export interface RunExperimentLongClickReleasedProperties {
  fired: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  progress: number;
}

export interface SteadyinvadersEndedProperties {
  onboarding: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  steadyinvaders_score: number;
  tenant_prepared: boolean;
}

export interface SteadyinvadersStartedProperties {
  onboarding: boolean;
  tenant_prepared: boolean;
}

export interface TeamListSortedProperties {
  sorted_by: string;
}

export interface TeamListViewedProperties {
  url?: string;
}

export interface TeamSwitchedProperties {
  team_id_switched_to: string;
}

export interface TenantPreparationViewedProperties {
  url?: string;
}

export interface UserListFilteredProperties {
  filter_text: string;
}

export interface UserListSortedProperties {
  sorted_by: string;
}

export interface UserListViewedProperties {
  url?: string;
}

export interface WebhookListFilteredProperties {
  filter_team?: string;
  filter_text?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | slack, custom, preflight |
   */
  webhook_type?: "slack" | "custom" | "preflight";
}

export interface WebhookListSortedProperties {
  sorted_by: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | slack, custom, preflight |
   */
  webhook_type?: "slack" | "custom" | "preflight";
}

export interface WebhookListViewedProperties {
  url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | slack, custom, preflight |
   */
  webhook_type?: "slack" | "custom" | "preflight";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdviceTargetListFiltered implements BaseEvent {
  event_type = 'Advice Target List Filtered';

  constructor(
    public event_properties: AdviceTargetListFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentAddVisited implements BaseEvent {
  event_type = 'Agent Add Visited';

  constructor(
    public event_properties?: AgentAddVisitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupCompleted implements BaseEvent {
  event_type = 'Agent Setup Completed';

  constructor(
    public event_properties: AgentSetupCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupDiscoveryViewed implements BaseEvent {
  event_type = 'Agent Setup Discovery Viewed';

  constructor(
    public event_properties: AgentSetupDiscoveryViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupInstallationContainerRuntimeChosen implements BaseEvent {
  event_type = 'Agent Setup Installation Container Runtime Chosen';

  constructor(
    public event_properties: AgentSetupInstallationContainerRuntimeChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupInstallationScriptCopied implements BaseEvent {
  event_type = 'Agent Setup Installation Script Copied';

  constructor(
    public event_properties: AgentSetupInstallationScriptCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupInstallationSystemLoading implements BaseEvent {
  event_type = 'Agent Setup Installation System Loading';

  constructor(
    public event_properties: AgentSetupInstallationSystemLoadingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupInstallationTechnologyChosen implements BaseEvent {
  event_type = 'Agent Setup Installation Technology Chosen';

  constructor(
    public event_properties: AgentSetupInstallationTechnologyChosenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupInstallationTroubleshooting implements BaseEvent {
  event_type = 'Agent Setup Installation Troubleshooting';

  constructor(
    public event_properties: AgentSetupInstallationTroubleshootingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupInstallationViewed implements BaseEvent {
  event_type = 'Agent Setup Installation Viewed';
}

export class AgentSetupResumed implements BaseEvent {
  event_type = 'Agent Setup Resumed';
}

export class AgentSetupSkipped implements BaseEvent {
  event_type = 'Agent Setup Skipped';

  constructor(
    public event_properties: AgentSetupSkippedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentSetupStartViewed implements BaseEvent {
  event_type = 'Agent Setup Start Viewed';
}

export class AgentSetupSuggestedExtensionsViewed implements BaseEvent {
  event_type = 'Agent Setup Suggested Extensions Viewed';

  constructor(
    public event_properties: AgentSetupSuggestedExtensionsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentsListFiltered implements BaseEvent {
  event_type = 'Agents List Filtered';

  constructor(
    public event_properties: AgentsListFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AgentsListViewed implements BaseEvent {
  event_type = 'Agents List Viewed';

  constructor(
    public event_properties: AgentsListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApiAccessTokensListFiltered implements BaseEvent {
  event_type = 'API Access Tokens List Filtered';

  constructor(
    public event_properties: ApiAccessTokensListFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApiAccessTokensListSorted implements BaseEvent {
  event_type = 'API Access Tokens List Sorted';

  constructor(
    public event_properties: ApiAccessTokensListSortedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApiAccessTokensListViewed implements BaseEvent {
  event_type = 'API Access Tokens List Viewed';

  constructor(
    public event_properties?: ApiAccessTokensListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangelogViewed implements BaseEvent {
  event_type = 'Changelog Viewed';
}

export class DashboardHeroTriggered implements BaseEvent {
  event_type = 'Dashboard Hero Triggered';

  constructor(
    public event_properties: DashboardHeroTriggeredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardSystemDiscoveryEnlarged implements BaseEvent {
  event_type = 'Dashboard System Discovery Enlarged';

  constructor(
    public event_properties?: DashboardSystemDiscoveryEnlargedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardSystemDiscoveryEnvironmentChanged implements BaseEvent {
  event_type = 'Dashboard System Discovery Environment Changed';

  constructor(
    public event_properties?: DashboardSystemDiscoveryEnvironmentChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardSystemDiscoveryRefreshed implements BaseEvent {
  event_type = 'Dashboard System Discovery Refreshed';

  constructor(
    public event_properties?: DashboardSystemDiscoveryRefreshedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardSystemDiscoveryViewed implements BaseEvent {
  event_type = 'Dashboard System Discovery Viewed';

  constructor(
    public event_properties?: DashboardSystemDiscoveryViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardTeamActivityExported implements BaseEvent {
  event_type = 'Dashboard Team Activity Exported';

  constructor(
    public event_properties: DashboardTeamActivityExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardTeamActivityFiltered implements BaseEvent {
  event_type = 'Dashboard Team Activity Filtered';

  constructor(
    public event_properties: DashboardTeamActivityFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardViewed implements BaseEvent {
  event_type = 'Dashboard Viewed';

  constructor(
    public event_properties: DashboardViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EnvironmentListSorted implements BaseEvent {
  event_type = 'Environment List Sorted';

  constructor(
    public event_properties: EnvironmentListSortedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EnvironmentListViewed implements BaseEvent {
  event_type = 'Environment List Viewed';

  constructor(
    public event_properties?: EnvironmentListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EnvironmentVariableListViewed implements BaseEvent {
  event_type = 'Environment Variable List Viewed';

  constructor(
    public event_properties?: EnvironmentVariableListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentExecutionFiltered implements BaseEvent {
  event_type = 'Experiment Execution Filtered';

  constructor(
    public event_properties?: ExperimentExecutionFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentExecutionLogDownloaded implements BaseEvent {
  event_type = 'Experiment Execution Log Downloaded';

  constructor(
    public event_properties: ExperimentExecutionLogDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentExecutionMarkerSet implements BaseEvent {
  event_type = 'Experiment Execution Marker Set';

  constructor(
    public event_properties: ExperimentExecutionMarkerSetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentExecutionRunModalViewed implements BaseEvent {
  event_type = 'Experiment Execution Run Modal Viewed';

  constructor(
    public event_properties: ExperimentExecutionRunModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentExecutionViewed implements BaseEvent {
  event_type = 'Experiment Execution Viewed';

  constructor(
    public event_properties: ExperimentExecutionViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentListFiltered implements BaseEvent {
  event_type = 'Experiment List Filtered';

  constructor(
    public event_properties?: ExperimentListFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentListSorted implements BaseEvent {
  event_type = 'Experiment List Sorted';

  constructor(
    public event_properties: ExperimentListSortedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentListViewed implements BaseEvent {
  event_type = 'Experiment List Viewed';

  constructor(
    public event_properties?: ExperimentListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentSidebarFiltered implements BaseEvent {
  event_type = 'Experiment Sidebar Filtered';

  constructor(
    public event_properties: ExperimentSidebarFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentSidebarGrouped implements BaseEvent {
  event_type = 'Experiment Sidebar Grouped';

  constructor(
    public event_properties: ExperimentSidebarGroupedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentSidebarStepDocumentationOpened implements BaseEvent {
  event_type = 'Experiment Sidebar Step Documentation Opened';

  constructor(
    public event_properties: ExperimentSidebarStepDocumentationOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentSidebarViewed implements BaseEvent {
  event_type = 'Experiment Sidebar Viewed';

  constructor(
    public event_properties: ExperimentSidebarViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentStepTargetQueryKeyAdded implements BaseEvent {
  event_type = 'Experiment Step Target Query Key Added';

  constructor(
    public event_properties: ExperimentStepTargetQueryKeyAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentStepTargetQueryKeyRemoved implements BaseEvent {
  event_type = 'Experiment Step Target Query Key Removed';

  constructor(
    public event_properties: ExperimentStepTargetQueryKeyRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentStepTargetQueryKeyReplaced implements BaseEvent {
  event_type = 'Experiment Step Target Query Key Replaced';

  constructor(
    public event_properties: ExperimentStepTargetQueryKeyReplacedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentStepTargetSelectionTemplateApplied implements BaseEvent {
  event_type = 'Experiment Step Target Selection Template Applied';

  constructor(
    public event_properties: ExperimentStepTargetSelectionTemplateAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentTargetsAllViewed implements BaseEvent {
  event_type = 'Experiment Targets - All Viewed';

  constructor(
    public event_properties?: ExperimentTargetsAllViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentTargetsAttackedViewed implements BaseEvent {
  event_type = 'Experiment Targets - Attacked Viewed';

  constructor(
    public event_properties: ExperimentTargetsAttackedViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentTemplateDetailsViewed implements BaseEvent {
  event_type = 'Experiment Template Details Viewed';

  constructor(
    public event_properties: ExperimentTemplateDetailsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentTemplateUsed implements BaseEvent {
  event_type = 'Experiment Template Used';

  constructor(
    public event_properties?: ExperimentTemplateUsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentTemplateUsedWizardStepCompleted implements BaseEvent {
  event_type = 'Experiment Template Used / Wizard Step Completed';

  constructor(
    public event_properties: ExperimentTemplateUsedWizardStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentTemplateUsedWizardStepStarted implements BaseEvent {
  event_type = 'Experiment Template Used / Wizard Step Started';

  constructor(
    public event_properties: ExperimentTemplateUsedWizardStepStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentVariableDeleted implements BaseEvent {
  event_type = 'Experiment Variable Deleted';

  constructor(
    public event_properties: ExperimentVariableDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentVariableListViewed implements BaseEvent {
  event_type = 'Experiment Variable List Viewed';

  constructor(
    public event_properties?: ExperimentVariableListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperimentViewed implements BaseEvent {
  event_type = 'Experiment Viewed';

  constructor(
    public event_properties: ExperimentViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExplorerAdviceListViewed implements BaseEvent {
  event_type = 'Explorer Advice List Viewed';

  constructor(
    public event_properties: ExplorerAdviceListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExplorerAdviceViewed implements BaseEvent {
  event_type = 'Explorer Advice Viewed';

  constructor(
    public event_properties: ExplorerAdviceViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExtensionTypeDeleted implements BaseEvent {
  event_type = 'Extension Type Deleted';

  constructor(
    public event_properties: ExtensionTypeDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExtensionsListViewed implements BaseEvent {
  event_type = 'Extensions List Viewed';

  constructor(
    public event_properties: ExtensionsListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerAttributesSearched implements BaseEvent {
  event_type = 'Landscape Explorer Attributes Searched';

  constructor(
    public event_properties: LandscapeExplorerAttributesSearchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerColorBySetAttribute implements BaseEvent {
  event_type = 'Landscape Explorer Color By Set Attribute';

  constructor(
    public event_properties: LandscapeExplorerColorBySetAttributeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerColorReused implements BaseEvent {
  event_type = 'Landscape Explorer Color Reused';

  constructor(
    public event_properties: LandscapeExplorerColorReusedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerDeletedViewViewed implements BaseEvent {
  event_type = 'Landscape Explorer Deleted View Viewed';

  constructor(
    public event_properties?: LandscapeExplorerDeletedViewViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerGroupBySetAttribute implements BaseEvent {
  event_type = 'Landscape Explorer Group By Set Attribute';

  constructor(
    public event_properties: LandscapeExplorerGroupBySetAttributeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerGroupDetailsViewed implements BaseEvent {
  event_type = 'Landscape Explorer Group Details Viewed';

  constructor(
    public event_properties: LandscapeExplorerGroupDetailsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerHelpNeeded implements BaseEvent {
  event_type = 'Landscape Explorer Help Needed';

  constructor(
    public event_properties?: LandscapeExplorerHelpNeededProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerHelpTutorialDismissed implements BaseEvent {
  event_type = 'Landscape Explorer Help Tutorial Dismissed';

  constructor(
    public event_properties?: LandscapeExplorerHelpTutorialDismissedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerHelpTutorialDismissedPermanently implements BaseEvent {
  event_type = 'Landscape Explorer Help Tutorial Dismissed Permanently';

  constructor(
    public event_properties?: LandscapeExplorerHelpTutorialDismissedPermanentlyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerHelpTutorialOpened implements BaseEvent {
  event_type = 'Landscape Explorer Help Tutorial Opened';

  constructor(
    public event_properties?: LandscapeExplorerHelpTutorialOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerNewView implements BaseEvent {
  event_type = 'Landscape Explorer New View';

  constructor(
    public event_properties: LandscapeExplorerNewViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerRenderingLimitReached implements BaseEvent {
  event_type = 'Landscape Explorer Rendering Limit Reached';

  constructor(
    public event_properties: LandscapeExplorerRenderingLimitReachedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerResetted implements BaseEvent {
  event_type = 'Landscape Explorer Resetted';

  constructor(
    public event_properties?: LandscapeExplorerResettedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerTargetAttributesViewed implements BaseEvent {
  event_type = 'Landscape Explorer Target Attributes Viewed';

  constructor(
    public event_properties: LandscapeExplorerTargetAttributesViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerTargetCopiedForExperiment implements BaseEvent {
  event_type = 'Landscape Explorer Target Copied For Experiment';

  constructor(
    public event_properties: LandscapeExplorerTargetCopiedForExperimentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerTargetDetailsViewed implements BaseEvent {
  event_type = 'Landscape Explorer Target Details Viewed';
}

export class LandscapeExplorerTargetDuplication implements BaseEvent {
  event_type = 'Landscape Explorer Target Duplication';

  constructor(
    public event_properties: LandscapeExplorerTargetDuplicationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerTargetSelectedForExperiment implements BaseEvent {
  event_type = 'Landscape Explorer Target Selected For Experiment';

  constructor(
    public event_properties: LandscapeExplorerTargetSelectedForExperimentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerViewConfigured implements BaseEvent {
  event_type = 'Landscape Explorer View Configured';

  constructor(
    public event_properties?: LandscapeExplorerViewConfiguredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerViewLoaded implements BaseEvent {
  event_type = 'Landscape Explorer View Loaded';

  constructor(
    public event_properties: LandscapeExplorerViewLoadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerViewSaved implements BaseEvent {
  event_type = 'Landscape Explorer View Saved';

  constructor(
    public event_properties?: LandscapeExplorerViewSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeExplorerViewed implements BaseEvent {
  event_type = 'Landscape Explorer Viewed';

  constructor(
    public event_properties?: LandscapeExplorerViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandscapeTableViewed implements BaseEvent {
  event_type = 'Landscape Table Viewed';

  constructor(
    public event_properties?: LandscapeTableViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LicenseExpiredViewed implements BaseEvent {
  event_type = 'License Expired Viewed';

  constructor(
    public event_properties?: LicenseExpiredViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LicenseOverviewExported implements BaseEvent {
  event_type = 'License Overview Exported';

  constructor(
    public event_properties: LicenseOverviewExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LicenseOverviewViewed implements BaseEvent {
  event_type = 'License Overview Viewed';

  constructor(
    public event_properties: LicenseOverviewViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginFailure implements BaseEvent {
  event_type = 'Login Failure';

  constructor(
    public event_properties?: LoginFailureProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingQuestionnaireAnswered implements BaseEvent {
  event_type = 'Onboarding Questionnaire Answered';
}

export class OnboardingQuestionnaireCompleted implements BaseEvent {
  event_type = 'Onboarding Questionnaire Completed';
}

export class OnboardingQuestionnaireSkipped implements BaseEvent {
  event_type = 'Onboarding Questionnaire Skipped';
}

export class OnboardingQuestionnaireViewed implements BaseEvent {
  event_type = 'Onboarding Questionnaire Viewed';

  constructor(
    public event_properties: OnboardingQuestionnaireViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingSkipped implements BaseEvent {
  event_type = 'Onboarding Skipped';
}

export class PlatformDataExported implements BaseEvent {
  event_type = 'Platform Data Exported';

  constructor(
    public event_properties: PlatformDataExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreflightActionIntegrationListFiltered implements BaseEvent {
  event_type = 'Preflight Action Integration List Filtered';

  constructor(
    public event_properties?: PreflightActionIntegrationListFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreflightActionIntegrationListSorted implements BaseEvent {
  event_type = 'Preflight Action Integration List Sorted';

  constructor(
    public event_properties: PreflightActionIntegrationListSortedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreflightActionIntegrationListViewed implements BaseEvent {
  event_type = 'Preflight Action Integration List Viewed';

  constructor(
    public event_properties?: PreflightActionIntegrationListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RunExperimentLongClickReleased implements BaseEvent {
  event_type = 'Run Experiment Long Click Released';

  constructor(
    public event_properties: RunExperimentLongClickReleasedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SteadyinvadersEnded implements BaseEvent {
  event_type = 'Steadyinvaders Ended';

  constructor(
    public event_properties: SteadyinvadersEndedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SteadyinvadersStarted implements BaseEvent {
  event_type = 'Steadyinvaders Started';

  constructor(
    public event_properties: SteadyinvadersStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TeamListSorted implements BaseEvent {
  event_type = 'Team List Sorted';

  constructor(
    public event_properties: TeamListSortedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TeamListViewed implements BaseEvent {
  event_type = 'Team List Viewed';

  constructor(
    public event_properties?: TeamListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TeamSwitched implements BaseEvent {
  event_type = 'Team Switched';

  constructor(
    public event_properties: TeamSwitchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TenantPreparationViewed implements BaseEvent {
  event_type = 'Tenant Preparation Viewed';

  constructor(
    public event_properties?: TenantPreparationViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserListFiltered implements BaseEvent {
  event_type = 'User List Filtered';

  constructor(
    public event_properties: UserListFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserListSorted implements BaseEvent {
  event_type = 'User List Sorted';

  constructor(
    public event_properties: UserListSortedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserListViewed implements BaseEvent {
  event_type = 'User List Viewed';

  constructor(
    public event_properties?: UserListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebhookListFiltered implements BaseEvent {
  event_type = 'Webhook List Filtered';

  constructor(
    public event_properties?: WebhookListFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebhookListSorted implements BaseEvent {
  event_type = 'Webhook List Sorted';

  constructor(
    public event_properties: WebhookListSortedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebhookListViewed implements BaseEvent {
  event_type = 'Webhook List Viewed';

  constructor(
    public event_properties?: WebhookListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Advice Target List Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Advice%20Target%20List%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filter_text)
   * @param options Amplitude event options.
   */
  adviceTargetListFiltered(
    properties: AdviceTargetListFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdviceTargetListFiltered(properties), options);
  }

  /**
   * Agent Add Visited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Add%20Visited)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  agentAddVisited(
    properties?: AgentAddVisitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentAddVisited(properties), options);
  }

  /**
   * Agent Setup Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_of_container_discovered)
   * @param options Amplitude event options.
   */
  agentSetupCompleted(
    properties: AgentSetupCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupCompleted(properties), options);
  }

  /**
   * Agent Setup Discovery Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Discovery%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_of_container_discovered)
   * @param options Amplitude event options.
   */
  agentSetupDiscoveryViewed(
    properties: AgentSetupDiscoveryViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupDiscoveryViewed(properties), options);
  }

  /**
   * Agent Setup Installation Container Runtime Chosen
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Installation%20Container%20Runtime%20Chosen)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. agent_container_runtime)
   * @param options Amplitude event options.
   */
  agentSetupInstallationContainerRuntimeChosen(
    properties: AgentSetupInstallationContainerRuntimeChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupInstallationContainerRuntimeChosen(properties), options);
  }

  /**
   * Agent Setup Installation Script Copied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Installation%20Script%20Copied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. agent_container_runtime)
   * @param options Amplitude event options.
   */
  agentSetupInstallationScriptCopied(
    properties: AgentSetupInstallationScriptCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupInstallationScriptCopied(properties), options);
  }

  /**
   * Agent Setup Installation System Loading
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Installation%20System%20Loading)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. extensions_connected)
   * @param options Amplitude event options.
   */
  agentSetupInstallationSystemLoading(
    properties: AgentSetupInstallationSystemLoadingProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupInstallationSystemLoading(properties), options);
  }

  /**
   * Agent Setup Installation Technology Chosen
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Installation%20Technology%20Chosen)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. agent_installation_technology)
   * @param options Amplitude event options.
   */
  agentSetupInstallationTechnologyChosen(
    properties: AgentSetupInstallationTechnologyChosenProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupInstallationTechnologyChosen(properties), options);
  }

  /**
   * Agent Setup Installation Troubleshooting
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Installation%20Troubleshooting)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. extensions_connected)
   * @param options Amplitude event options.
   */
  agentSetupInstallationTroubleshooting(
    properties: AgentSetupInstallationTroubleshootingProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupInstallationTroubleshooting(properties), options);
  }

  /**
   * Agent Setup Installation Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Installation%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  agentSetupInstallationViewed(
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupInstallationViewed(), options);
  }

  /**
   * Agent Setup Resumed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Resumed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  agentSetupResumed(
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupResumed(), options);
  }

  /**
   * Agent Setup Skipped
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Skipped)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. agent_setup_step)
   * @param options Amplitude event options.
   */
  agentSetupSkipped(
    properties: AgentSetupSkippedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupSkipped(properties), options);
  }

  /**
   * Agent Setup Start Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Start%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  agentSetupStartViewed(
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupStartViewed(), options);
  }

  /**
   * Agent Setup Suggested Extensions Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agent%20Setup%20Suggested%20Extensions%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. agent_setup_suggested_extensions)
   * @param options Amplitude event options.
   */
  agentSetupSuggestedExtensionsViewed(
    properties: AgentSetupSuggestedExtensionsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentSetupSuggestedExtensionsViewed(properties), options);
  }

  /**
   * Agents List Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agents%20List%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filter_text)
   * @param options Amplitude event options.
   */
  agentsListFiltered(
    properties: AgentsListFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentsListFiltered(properties), options);
  }

  /**
   * Agents List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Agents%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. agents_ever_reported)
   * @param options Amplitude event options.
   */
  agentsListViewed(
    properties: AgentsListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AgentsListViewed(properties), options);
  }

  /**
   * API Access Tokens List Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/API%20Access%20Tokens%20List%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filter_text)
   * @param options Amplitude event options.
   */
  apiAccessTokensListFiltered(
    properties: ApiAccessTokensListFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApiAccessTokensListFiltered(properties), options);
  }

  /**
   * API Access Tokens List Sorted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/API%20Access%20Tokens%20List%20Sorted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. sorted_by)
   * @param options Amplitude event options.
   */
  apiAccessTokensListSorted(
    properties: ApiAccessTokensListSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApiAccessTokensListSorted(properties), options);
  }

  /**
   * API Access Tokens List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/API%20Access%20Tokens%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  apiAccessTokensListViewed(
    properties?: ApiAccessTokensListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApiAccessTokensListViewed(properties), options);
  }

  /**
   * Changelog Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Changelog%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param options Amplitude event options.
   */
  changelogViewed(
    options?: EventOptions,
  ) {
    return this.track(new ChangelogViewed(), options);
  }

  /**
   * Dashboard Hero Triggered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20Hero%20Triggered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. dashboard_hero_action)
   * @param options Amplitude event options.
   */
  dashboardHeroTriggered(
    properties: DashboardHeroTriggeredProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardHeroTriggered(properties), options);
  }

  /**
   * Dashboard System Discovery Enlarged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20System%20Discovery%20Enlarged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  dashboardSystemDiscoveryEnlarged(
    properties?: DashboardSystemDiscoveryEnlargedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardSystemDiscoveryEnlarged(properties), options);
  }

  /**
   * Dashboard System Discovery Environment Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20System%20Discovery%20Environment%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  dashboardSystemDiscoveryEnvironmentChanged(
    properties?: DashboardSystemDiscoveryEnvironmentChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardSystemDiscoveryEnvironmentChanged(properties), options);
  }

  /**
   * Dashboard System Discovery Refreshed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20System%20Discovery%20Refreshed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  dashboardSystemDiscoveryRefreshed(
    properties?: DashboardSystemDiscoveryRefreshedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardSystemDiscoveryRefreshed(properties), options);
  }

  /**
   * Dashboard System Discovery Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20System%20Discovery%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  dashboardSystemDiscoveryViewed(
    properties?: DashboardSystemDiscoveryViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardSystemDiscoveryViewed(properties), options);
  }

  /**
   * Dashboard Team Activity Exported
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20Team%20Activity%20Exported)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. team_activity_time_range)
   * @param options Amplitude event options.
   */
  dashboardTeamActivityExported(
    properties: DashboardTeamActivityExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardTeamActivityExported(properties), options);
  }

  /**
   * Dashboard Team Activity Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20Team%20Activity%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. team_activity_time_range)
   * @param options Amplitude event options.
   */
  dashboardTeamActivityFiltered(
    properties: DashboardTeamActivityFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardTeamActivityFiltered(properties), options);
  }

  /**
   * Dashboard Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Dashboard%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. resume_agent_setup_shown)
   * @param options Amplitude event options.
   */
  dashboardViewed(
    properties: DashboardViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardViewed(properties), options);
  }

  /**
   * Environment List Sorted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Environment%20List%20Sorted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. sorted_by)
   * @param options Amplitude event options.
   */
  environmentListSorted(
    properties: EnvironmentListSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EnvironmentListSorted(properties), options);
  }

  /**
   * Environment List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Environment%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  environmentListViewed(
    properties?: EnvironmentListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EnvironmentListViewed(properties), options);
  }

  /**
   * Environment Variable List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Environment%20Variable%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  environmentVariableListViewed(
    properties?: EnvironmentVariableListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EnvironmentVariableListViewed(properties), options);
  }

  /**
   * Experiment Execution Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Execution%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_execution_state)
   * @param options Amplitude event options.
   */
  experimentExecutionFiltered(
    properties?: ExperimentExecutionFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentExecutionFiltered(properties), options);
  }

  /**
   * Experiment Execution Log Downloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Execution%20Log%20Downloaded)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  experimentExecutionLogDownloaded(
    properties: ExperimentExecutionLogDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentExecutionLogDownloaded(properties), options);
  }

  /**
   * Experiment Execution Marker Set
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Execution%20Marker%20Set)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_execution_id)
   * @param options Amplitude event options.
   */
  experimentExecutionMarkerSet(
    properties: ExperimentExecutionMarkerSetProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentExecutionMarkerSet(properties), options);
  }

  /**
   * Experiment Execution Run Modal Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Execution%20Run%20Modal%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  experimentExecutionRunModalViewed(
    properties: ExperimentExecutionRunModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentExecutionRunModalViewed(properties), options);
  }

  /**
   * Experiment Execution Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Execution%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  experimentExecutionViewed(
    properties: ExperimentExecutionViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentExecutionViewed(properties), options);
  }

  /**
   * Experiment List Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20List%20Filtered)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. filter_actions)
   * @param options Amplitude event options.
   */
  experimentListFiltered(
    properties?: ExperimentListFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentListFiltered(properties), options);
  }

  /**
   * Experiment List Sorted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20List%20Sorted)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. sorted_by)
   * @param options Amplitude event options.
   */
  experimentListSorted(
    properties: ExperimentListSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentListSorted(properties), options);
  }

  /**
   * Experiment List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20List%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  experimentListViewed(
    properties?: ExperimentListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentListViewed(properties), options);
  }

  /**
   * Experiment Sidebar Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Sidebar%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. elements_count)
   * @param options Amplitude event options.
   */
  experimentSidebarFiltered(
    properties: ExperimentSidebarFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentSidebarFiltered(properties), options);
  }

  /**
   * Experiment Sidebar Grouped
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Sidebar%20Grouped)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. group_by)
   * @param options Amplitude event options.
   */
  experimentSidebarGrouped(
    properties: ExperimentSidebarGroupedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentSidebarGrouped(properties), options);
  }

  /**
   * Experiment Sidebar Step Documentation Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Sidebar%20Step%20Documentation%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_step_action_kind)
   * @param options Amplitude event options.
   */
  experimentSidebarStepDocumentationOpened(
    properties: ExperimentSidebarStepDocumentationOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentSidebarStepDocumentationOpened(properties), options);
  }

  /**
   * Experiment Sidebar Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Sidebar%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. elements_count)
   * @param options Amplitude event options.
   */
  experimentSidebarViewed(
    properties: ExperimentSidebarViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentSidebarViewed(properties), options);
  }

  /**
   * Experiment Step Target Query Key Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Step%20Target%20Query%20Key%20Added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  experimentStepTargetQueryKeyAdded(
    properties: ExperimentStepTargetQueryKeyAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentStepTargetQueryKeyAdded(properties), options);
  }

  /**
   * Experiment Step Target Query Key Removed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Step%20Target%20Query%20Key%20Removed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  experimentStepTargetQueryKeyRemoved(
    properties: ExperimentStepTargetQueryKeyRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentStepTargetQueryKeyRemoved(properties), options);
  }

  /**
   * Experiment Step Target Query Key Replaced
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Step%20Target%20Query%20Key%20Replaced)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  experimentStepTargetQueryKeyReplaced(
    properties: ExperimentStepTargetQueryKeyReplacedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentStepTargetQueryKeyReplaced(properties), options);
  }

  /**
   * Experiment Step Target Selection Template Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Step%20Target%20Selection%20Template%20Applied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  experimentStepTargetSelectionTemplateApplied(
    properties: ExperimentStepTargetSelectionTemplateAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentStepTargetSelectionTemplateApplied(properties), options);
  }

  /**
   * Experiment Targets - All Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Targets%20-%20All%20Viewed)
   *
   * User has decided to see all targets in the landscape as he seems to be unsure which are the right ones.
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. target_type)
   * @param options Amplitude event options.
   */
  experimentTargetsAllViewed(
    properties?: ExperimentTargetsAllViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentTargetsAllViewed(properties), options);
  }

  /**
   * Experiment Targets - Attacked Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Targets%20-%20Attacked%20Viewed)
   *
   * User has clicked on "Show targets" within the experiment's attack configuration
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. attack_query_defined)
   * @param options Amplitude event options.
   */
  experimentTargetsAttackedViewed(
    properties: ExperimentTargetsAttackedViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentTargetsAttackedViewed(properties), options);
  }

  /**
   * Experiment Template Details Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Template%20Details%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_template_name)
   * @param options Amplitude event options.
   */
  experimentTemplateDetailsViewed(
    properties: ExperimentTemplateDetailsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentTemplateDetailsViewed(properties), options);
  }

  /**
   * Experiment Template Used
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Template%20Used)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_template_name)
   * @param options Amplitude event options.
   */
  experimentTemplateUsed(
    properties?: ExperimentTemplateUsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentTemplateUsed(properties), options);
  }

  /**
   * Experiment Template Used / Wizard Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Template%20Used%20%2F%20Wizard%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_template_last_step)
   * @param options Amplitude event options.
   */
  experimentTemplateUsedWizardStepCompleted(
    properties: ExperimentTemplateUsedWizardStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentTemplateUsedWizardStepCompleted(properties), options);
  }

  /**
   * Experiment Template Used / Wizard Step Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Template%20Used%20%2F%20Wizard%20Step%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_template_last_step)
   * @param options Amplitude event options.
   */
  experimentTemplateUsedWizardStepStarted(
    properties: ExperimentTemplateUsedWizardStepStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentTemplateUsedWizardStepStarted(properties), options);
  }

  /**
   * Experiment Variable Deleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Variable%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experiment_key)
   * @param options Amplitude event options.
   */
  experimentVariableDeleted(
    properties: ExperimentVariableDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentVariableDeleted(properties), options);
  }

  /**
   * Experiment Variable List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Variable%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment_variables)
   * @param options Amplitude event options.
   */
  experimentVariableListViewed(
    properties?: ExperimentVariableListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentVariableListViewed(properties), options);
  }

  /**
   * Experiment Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Experiment%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  experimentViewed(
    properties: ExperimentViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentViewed(properties), options);
  }

  /**
   * Explorer Advice List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Explorer%20Advice%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. advice_context)
   * @param options Amplitude event options.
   */
  explorerAdviceListViewed(
    properties: ExplorerAdviceListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExplorerAdviceListViewed(properties), options);
  }

  /**
   * Explorer Advice Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Explorer%20Advice%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. advice_context)
   * @param options Amplitude event options.
   */
  explorerAdviceViewed(
    properties: ExplorerAdviceViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExplorerAdviceViewed(properties), options);
  }

  /**
   * Extension Type Deleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Extension%20Type%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. extension_type)
   * @param options Amplitude event options.
   */
  extensionTypeDeleted(
    properties: ExtensionTypeDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExtensionTypeDeleted(properties), options);
  }

  /**
   * Extensions List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Extensions%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  extensionsListViewed(
    properties: ExtensionsListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExtensionsListViewed(properties), options);
  }

  /**
   * Landscape Explorer Attributes Searched
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Attributes%20Searched)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. landscape_explorer_target_attribute_type)
   * @param options Amplitude event options.
   */
  landscapeExplorerAttributesSearched(
    properties: LandscapeExplorerAttributesSearchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerAttributesSearched(properties), options);
  }

  /**
   * Landscape Explorer Color By Set Attribute
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Color%20By%20Set%20Attribute)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerColorBySetAttribute(
    properties: LandscapeExplorerColorBySetAttributeProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerColorBySetAttribute(properties), options);
  }

  /**
   * Landscape Explorer Color Reused
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Color%20Reused)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_color_assignments)
   * @param options Amplitude event options.
   */
  landscapeExplorerColorReused(
    properties: LandscapeExplorerColorReusedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerColorReused(properties), options);
  }

  /**
   * Landscape Explorer Deleted View Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Deleted%20View%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  landscapeExplorerDeletedViewViewed(
    properties?: LandscapeExplorerDeletedViewViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerDeletedViewViewed(properties), options);
  }

  /**
   * Landscape Explorer Group By Set Attribute
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Group%20By%20Set%20Attribute)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerGroupBySetAttribute(
    properties: LandscapeExplorerGroupBySetAttributeProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerGroupBySetAttribute(properties), options);
  }

  /**
   * Landscape Explorer Group Details Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Group%20Details%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. landscape_explorer_subview)
   * @param options Amplitude event options.
   */
  landscapeExplorerGroupDetailsViewed(
    properties: LandscapeExplorerGroupDetailsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerGroupDetailsViewed(properties), options);
  }

  /**
   * Landscape Explorer Help Needed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Help%20Needed)
   *
   * Button clicked to show the tutorial again ("Need help?")
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  landscapeExplorerHelpNeeded(
    properties?: LandscapeExplorerHelpNeededProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerHelpNeeded(properties), options);
  }

  /**
   * Landscape Explorer Help Tutorial Dismissed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Help%20Tutorial%20Dismissed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  landscapeExplorerHelpTutorialDismissed(
    properties?: LandscapeExplorerHelpTutorialDismissedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerHelpTutorialDismissed(properties), options);
  }

  /**
   * Landscape Explorer Help Tutorial Dismissed Permanently
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Help%20Tutorial%20Dismissed%20Permanently)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  landscapeExplorerHelpTutorialDismissedPermanently(
    properties?: LandscapeExplorerHelpTutorialDismissedPermanentlyProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerHelpTutorialDismissedPermanently(properties), options);
  }

  /**
   * Landscape Explorer Help Tutorial Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Help%20Tutorial%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  landscapeExplorerHelpTutorialOpened(
    properties?: LandscapeExplorerHelpTutorialOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerHelpTutorialOpened(properties), options);
  }

  /**
   * Landscape Explorer New View
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20New%20View)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. landscape_explorer_new_view_context)
   * @param options Amplitude event options.
   */
  landscapeExplorerNewView(
    properties: LandscapeExplorerNewViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerNewView(properties), options);
  }

  /**
   * Landscape Explorer Rendering Limit Reached
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Rendering%20Limit%20Reached)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_of_groups)
   * @param options Amplitude event options.
   */
  landscapeExplorerRenderingLimitReached(
    properties: LandscapeExplorerRenderingLimitReachedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerRenderingLimitReached(properties), options);
  }

  /**
   * Landscape Explorer Resetted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Resetted)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerResetted(
    properties?: LandscapeExplorerResettedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerResetted(properties), options);
  }

  /**
   * Landscape Explorer Target Attributes Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Target%20Attributes%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. landscape_explorer_target_attribute_type)
   * @param options Amplitude event options.
   */
  landscapeExplorerTargetAttributesViewed(
    properties: LandscapeExplorerTargetAttributesViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerTargetAttributesViewed(properties), options);
  }

  /**
   * Landscape Explorer Target Copied For Experiment
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Target%20Copied%20For%20Experiment)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerTargetCopiedForExperiment(
    properties: LandscapeExplorerTargetCopiedForExperimentProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerTargetCopiedForExperiment(properties), options);
  }

  /**
   * Landscape Explorer Target Details Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Target%20Details%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param options Amplitude event options.
   */
  landscapeExplorerTargetDetailsViewed(
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerTargetDetailsViewed(), options);
  }

  /**
   * Landscape Explorer Target Duplication
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Target%20Duplication)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. explore_method)
   * @param options Amplitude event options.
   */
  landscapeExplorerTargetDuplication(
    properties: LandscapeExplorerTargetDuplicationProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerTargetDuplication(properties), options);
  }

  /**
   * Landscape Explorer Target Selected For Experiment
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Target%20Selected%20For%20Experiment)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerTargetSelectedForExperiment(
    properties: LandscapeExplorerTargetSelectedForExperimentProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerTargetSelectedForExperiment(properties), options);
  }

  /**
   * Landscape Explorer View Configured
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20View%20Configured)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerViewConfigured(
    properties?: LandscapeExplorerViewConfiguredProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerViewConfigured(properties), options);
  }

  /**
   * Landscape Explorer View Loaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20View%20Loaded)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerViewLoaded(
    properties: LandscapeExplorerViewLoadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerViewLoaded(properties), options);
  }

  /**
   * Landscape Explorer View Saved
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20View%20Saved)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerViewSaved(
    properties?: LandscapeExplorerViewSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerViewSaved(properties), options);
  }

  /**
   * Landscape Explorer Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Explorer%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. environment_id)
   * @param options Amplitude event options.
   */
  landscapeExplorerViewed(
    properties?: LandscapeExplorerViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeExplorerViewed(properties), options);
  }

  /**
   * Landscape Table Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Landscape%20Table%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. landscape_table_show_advice)
   * @param options Amplitude event options.
   */
  landscapeTableViewed(
    properties?: LandscapeTableViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandscapeTableViewed(properties), options);
  }

  /**
   * License Expired Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/License%20Expired%20Viewed)
   *
   * When somebody sees the license expired banner
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  licenseExpiredViewed(
    properties?: LicenseExpiredViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LicenseExpiredViewed(properties), options);
  }

  /**
   * License Overview Exported
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/License%20Overview%20Exported)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. license_export_method)
   * @param options Amplitude event options.
   */
  licenseOverviewExported(
    properties: LicenseOverviewExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LicenseOverviewExported(properties), options);
  }

  /**
   * License Overview Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/License%20Overview%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. agents_amount)
   * @param options Amplitude event options.
   */
  licenseOverviewViewed(
    properties: LicenseOverviewViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LicenseOverviewViewed(properties), options);
  }

  /**
   * Login Failure
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Login%20Failure)
   *
   * For tracking authentication issues in /login-failure
   *
   * @param properties The event's properties (e.g. login_error)
   * @param options Amplitude event options.
   */
  loginFailure(
    properties?: LoginFailureProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginFailure(properties), options);
  }

  /**
   * Onboarding Questionnaire Answered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Onboarding%20Questionnaire%20Answered)
   *
   * Owner: Manuel Gerding
   *
   * @param options Amplitude event options.
   */
  onboardingQuestionnaireAnswered(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuestionnaireAnswered(), options);
  }

  /**
   * Onboarding Questionnaire Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Onboarding%20Questionnaire%20Completed)
   *
   * Owner: Manuel Gerding
   *
   * @param options Amplitude event options.
   */
  onboardingQuestionnaireCompleted(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuestionnaireCompleted(), options);
  }

  /**
   * Onboarding Questionnaire Skipped
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Onboarding%20Questionnaire%20Skipped)
   *
   * Owner: Manuel Gerding
   *
   * @param options Amplitude event options.
   */
  onboardingQuestionnaireSkipped(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuestionnaireSkipped(), options);
  }

  /**
   * Onboarding Questionnaire Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Onboarding%20Questionnaire%20Viewed)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. onboarding_question)
   * @param options Amplitude event options.
   */
  onboardingQuestionnaireViewed(
    properties: OnboardingQuestionnaireViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuestionnaireViewed(properties), options);
  }

  /**
   * Onboarding Skipped
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Onboarding%20Skipped)
   *
   * Owner: Manuel Gerding
   *
   * @param options Amplitude event options.
   */
  onboardingSkipped(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSkipped(), options);
  }

  /**
   * Platform Data Exported
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Platform%20Data%20Exported)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. platform_export_type)
   * @param options Amplitude event options.
   */
  platformDataExported(
    properties: PlatformDataExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlatformDataExported(properties), options);
  }

  /**
   * Preflight Action Integration List Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Preflight%20Action%20Integration%20List%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filter_team)
   * @param options Amplitude event options.
   */
  preflightActionIntegrationListFiltered(
    properties?: PreflightActionIntegrationListFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreflightActionIntegrationListFiltered(properties), options);
  }

  /**
   * Preflight Action Integration List Sorted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Preflight%20Action%20Integration%20List%20Sorted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. sorted_by)
   * @param options Amplitude event options.
   */
  preflightActionIntegrationListSorted(
    properties: PreflightActionIntegrationListSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreflightActionIntegrationListSorted(properties), options);
  }

  /**
   * Preflight Action Integration List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Preflight%20Action%20Integration%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  preflightActionIntegrationListViewed(
    properties?: PreflightActionIntegrationListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreflightActionIntegrationListViewed(properties), options);
  }

  /**
   * Run Experiment Long Click Released
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Run%20Experiment%20Long%20Click%20Released)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fired)
   * @param options Amplitude event options.
   */
  runExperimentLongClickReleased(
    properties: RunExperimentLongClickReleasedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RunExperimentLongClickReleased(properties), options);
  }

  /**
   * Steadyinvaders Ended
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Steadyinvaders%20Ended)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. onboarding)
   * @param options Amplitude event options.
   */
  steadyinvadersEnded(
    properties: SteadyinvadersEndedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SteadyinvadersEnded(properties), options);
  }

  /**
   * Steadyinvaders Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Steadyinvaders%20Started)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. onboarding)
   * @param options Amplitude event options.
   */
  steadyinvadersStarted(
    properties: SteadyinvadersStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SteadyinvadersStarted(properties), options);
  }

  /**
   * Team List Sorted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Team%20List%20Sorted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. sorted_by)
   * @param options Amplitude event options.
   */
  teamListSorted(
    properties: TeamListSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TeamListSorted(properties), options);
  }

  /**
   * Team List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Team%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  teamListViewed(
    properties?: TeamListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TeamListViewed(properties), options);
  }

  /**
   * Team Switched
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Team%20Switched)
   *
   * Owner: Manuel Gerding
   *
   * @param properties The event's properties (e.g. team_id_switched_to)
   * @param options Amplitude event options.
   */
  teamSwitched(
    properties: TeamSwitchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TeamSwitched(properties), options);
  }

  /**
   * Tenant Preparation Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Tenant%20Preparation%20Viewed)
   *
   * Owner: Ansgar Schulte
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  tenantPreparationViewed(
    properties?: TenantPreparationViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TenantPreparationViewed(properties), options);
  }

  /**
   * User List Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/User%20List%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filter_text)
   * @param options Amplitude event options.
   */
  userListFiltered(
    properties: UserListFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserListFiltered(properties), options);
  }

  /**
   * User List Sorted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/User%20List%20Sorted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. sorted_by)
   * @param options Amplitude event options.
   */
  userListSorted(
    properties: UserListSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserListSorted(properties), options);
  }

  /**
   * User List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/User%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  userListViewed(
    properties?: UserListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserListViewed(properties), options);
  }

  /**
   * Webhook List Filtered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Webhook%20List%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filter_team)
   * @param options Amplitude event options.
   */
  webhookListFiltered(
    properties?: WebhookListFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebhookListFiltered(properties), options);
  }

  /**
   * Webhook List Sorted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Webhook%20List%20Sorted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. sorted_by)
   * @param options Amplitude event options.
   */
  webhookListSorted(
    properties: WebhookListSortedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebhookListSorted(properties), options);
  }

  /**
   * Webhook List Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/steadybit/Steadybit/events/main/latest/Webhook%20List%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  webhookListViewed(
    properties?: WebhookListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebhookListViewed(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
