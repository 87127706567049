/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { cssTransition, Id, toast, ToastContainer, ToastContent, ToastOptions, TypeOptions } from 'react-toastify';
import { ReactElement } from 'react';

import './Snackbar.css';

const FadeIn = cssTransition({
	enter: 'Toastify--animate Toastify__fade-enter',
	exit: 'Toastify--animate Toastify__fade-exit',
});

const SnackbarContainerId = 'snackbar';
interface SnackbarContainerProps {
	containerId?: string;
}
export function SnackbarContainer({ containerId = SnackbarContainerId }: SnackbarContainerProps): ReactElement {
	return (
		<ToastContainer
			containerId={containerId}
			position="bottom-center"
			enableMultiContainer
			draggable={false}
			hideProgressBar
			newestOnTop
			closeButton
			transition={FadeIn}
		/>
	);
}

function showSnackbar(type: TypeOptions, autoClose: number) {
	return (content: ToastContent, options: ToastOptions = {}): Id => {
		const opts = { autoClose, type, containerId: SnackbarContainerId, ...options };
		if (options.toastId && toast.isActive(options.toastId)) {
			toast.dismiss(options.toastId);
		}
		return toast(content, opts);
	};
}

export const Snackbar = {
	dark: showSnackbar('dark', 3000),
	darkForever: showSnackbar('dark', Number.MAX_VALUE),
	error: showSnackbar('error', 5000),
	errorForever: showSnackbar('error', Number.MAX_VALUE),
	dismiss: toast.dismiss,
};
