/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { isOccuranceStepLabelVO, isOccuranceStepParameterVO } from 'pages/templates/UseTemplateModal/types';
import FieldValueHandler from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Field';
import { ExperimentStepWaitVO, OccuranceStepParameterVO, OccuranceVO } from 'ui-api';
import ChunksWithPlaceholders from 'pages/templates/ChunksWithPlaceholders';
import { ReactElement } from 'react';
import { Stack } from 'components';

interface WaitParametersProps {
	waitStep: ExperimentStepWaitVO;
	stepOccurances: OccuranceVO[];
	environmentVariable?: string;
	stepPath: string;
}

export default function WaitParameters({
	environmentVariable,
	stepOccurances,
	stepPath,
	waitStep,
}: WaitParametersProps): ReactElement {
	// env and exp variables are not supported in the attacks custom label
	const hasOccuranceInCustomLabel = !environmentVariable && stepOccurances.some(isOccuranceStepLabelVO);

	const occurancesInParameters: OccuranceStepParameterVO[] = stepOccurances.filter(isOccuranceStepParameterVO);

	return (
		<Stack>
			{hasOccuranceInCustomLabel && <ChunksWithPlaceholders value={waitStep.customLabel || ''} />}
			{occurancesInParameters.map((occurance: OccuranceStepParameterVO) => {
				const resolvedField = occurance.field;
				return (
					<FieldValueHandler
						key={resolvedField.name}
						path={`${stepPath}.parameters['${resolvedField.name}']`}
						field={resolvedField}
						stepPath={stepPath}
						disabled
					/>
				);
			})}
		</Stack>
	);
}
