/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import TeamSelection from 'pages/settings/teams/components/TeamSelection';
import { Flex, presets, Text } from '@steadybit/ui-components-lib';
import { FormikTextField, Stack } from 'components';
import { useTenant } from 'tenancy/useTenant';
import { isUserAdmin } from 'utils/user';
import { ReactElement } from 'react';
import { useField } from 'formik';

interface AccessTokenFormProps {
	accessToken?: string;
	isAdminToken: boolean;
}

export default function AccessTokenForm({ accessToken, isAdminToken }: AccessTokenFormProps): ReactElement {
	const hasToken = Boolean(accessToken);
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const [teamField, , teamHelper] = useField('teamId');
	const [typeField, , typeHelper] = useField('type');

	const typeOtions = [
		{
			id: 'ADMIN',
			label: 'Admin – allows usage of the management APIs, e.g., environments and teams',
			isSelected: typeField.value === 'ADMIN',
		},
		{
			id: 'TEAM',
			label: 'Team – allows usage of the team specific APIs, e.g., experiments',
			isSelected: typeField.value === 'TEAM',
		},
	];

	return (
		<>
			{!hasToken && (
				<Stack size={'medium'} pb="2px">
					<FormikTextField label={'Token name'} name={'name'} maxLength={255} autoFocus autoComplete={'off'} />
					{isAdmin && (
						<Flex spacing="xSmall">
							<Text type="mediumStrong">Type</Text>
							<presets.dropdown.SingleChoiceButton
								withKeyboardArrowSupport
								items={typeOtions}
								onSelect={typeHelper.setValue}
								style={{ width: '100%' }}
							>
								{typeOtions.find((o) => o.isSelected)?.label || typeField.value}
							</presets.dropdown.SingleChoiceButton>
						</Flex>
					)}
					{!isAdminToken && (
						<Flex spacing="xSmall">
							<Text type="mediumStrong">Team</Text>
							<TeamSelection value={teamField.value} onChange={teamHelper.setValue} />
						</Flex>
					)}
				</Stack>
			)}
		</>
	);
}
