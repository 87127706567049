/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepVOUnion, ExperimentLaneVO, ExperimentStepActionVO, ExperimentStepWaitVO } from 'ui-api';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ReactElement, useEffect, useState } from 'react';
import { useStoreField } from 'DataStore/DataStore';
import { useUrlState } from 'url/useUrlState';
import { Container } from 'components';

import { duplicateStep, filterEmpty, findStep, removeStep } from '../utils';
import { UrlState, selectedStepIdParam } from '../urlParams';
import { SidebarShrinker } from './SidebarShrinker';
import useSignals$ from '../useSignals$';
import Action from './Action';
import Wait from './Wait';

interface StepConfigurationSidebarProps {
	disabled: boolean;
	top?: string;
}

export default function StepConfigurationSidebar({
	top,
	disabled,
}: StepConfigurationSidebarProps): ReactElement | null {
	const [{ selectedStepId }] = useUrlState<UrlState>([selectedStepIdParam]);
	const { value: lanes } = useStoreField<ExperimentLaneVO[]>('lanes');

	if (!selectedStepId) {
		return null;
	}

	const [step, stepPath] = findStep(lanes, selectedStepId);
	if (!step) {
		return null;
	}

	return <StepConfigurationSidebarContent step={step} path={stepPath} disabled={disabled} top={top} />;
}

interface StepConfigurationSidebarContentProps extends StepConfigurationSidebarProps {
	step: BaseExperimentStepVOUnion;
	path: string;
}

function StepConfigurationSidebarContent({
	disabled,
	step,
	path,
	top,
}: StepConfigurationSidebarContentProps): ReactElement | null {
	const [expanded, setExpanded] = useState<boolean>(false);
	const [{ selectedStepId }, , updateUrlState] = useUrlState<UrlState>([selectedStepIdParam]);
	const { value: lanes, setValue } = useStoreField<ExperimentLaneVO[]>('lanes');

	const { validationSignal$ } = useSignals$();
	useEffect(() => {
		validationSignal$.next(0);
	}, [expanded, validationSignal$]);

	const onDelete: () => void = () => {
		setValue(filterEmpty(removeStep(lanes, step.id)));
		updateUrlState({ selectedStepId: undefined });
	};

	const onDuplicate: () => void = () => {
		const newLanes = duplicateStep(lanes, step);
		if (newLanes) {
			setValue(newLanes);
		}
	};

	const onClose: () => void = () => updateUrlState({ selectedStepId: undefined });

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent): void => {
			if (e.key === 'Delete') {
				const isBody = e.target === document.body;
				if (isBody) {
					onDelete();
				}
			}
		};
		window.addEventListener('keydown', onKeyDown);
		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [step.id]);

	return (
		<Container
			key={selectedStepId}
			sx={{
				position: 'absolute',
				top: top || '127px',
				bottom: 16,
				right: 0,

				width: expanded ? '1300px' : '544px',
				transition: 'width 0.2s ease-out',

				backgroundColor: 'neutral000',

				boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
				overflowY: 'hidden',

				// to allow the header shadow to be visible on this sidebar
				zIndex: 2,
			}}
		>
			<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
				{step.type === 'wait' ? (
					<>
						<Wait
							waitStep={step as ExperimentStepWaitVO}
							disabled={disabled}
							stepPath={path}
							withErrors
							onDuplicate={onDuplicate}
							onDelete={onDelete}
							onClose={onClose}
						/>
						<SidebarShrinker expanded={expanded} setExpanded={setExpanded} />
					</>
				) : (
					<Action
						actionStep={step as ExperimentStepActionVO}
						expanded={expanded}
						disabled={disabled}
						stepPath={path}
						setExpanded={setExpanded}
						onDuplicate={onDuplicate}
						onDelete={onDelete}
						onClose={onClose}
					/>
				)}
			</ErrorBoundary>
		</Container>
	);
}
