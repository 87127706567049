/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useStore } from 'DataStore/DataStore';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { UseTemplateFormData } from './UseTemplateFormLoadingHandler';
import { replaceMarkers } from './utils';

export default function PlaceholderSyncJob(): null {
	const store = useStore<UseTemplateFormData>();
	const { placeholderValuesMap, variableValuesMap } = store.data;

	const [debounceSync] = useState(() =>
		debounce(
			async (v) => {
				store.replaceValues(
					replaceMarkers({ formData: v, replaceEmptyValues: false, replacePlaceholders: true, replaceVariables: true }),
				);
			},
			250,
			{ leading: true },
		),
	);

	const [stableTpId] = useStableInstance(placeholderValuesMap);
	const [stableHvId] = useStableInstance(variableValuesMap);

	useEffect(() => {
		debounceSync(store.data);
	}, [stableTpId, stableHvId]);

	return null;
}
