/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { ExperimentScheduleSummaryVO } from 'ui-api';
import { ReactElement, ReactNode } from 'react';

import SchedulesFilter, { FilterType } from './SchedulesFilter';

interface SchedulesSidebarProps {
	schedules?: ExperimentScheduleSummaryVO[];
	activeFilter?: FilterType;
	children?: ReactNode;
	setActiveFilter?: (v: FilterType) => void;
}

export default function SchedulesSidebar({
	activeFilter,
	schedules,
	children,
	setActiveFilter,
}: SchedulesSidebarProps): ReactElement {
	return (
		<Flex
			style={{
				height: '100%',
				border: '1px solid',
				borderColor: Colors.neutral300,
				overflow: 'hidden',
			}}
			spacing="xSmall"
		>
			<Flex
				spacing="xSmall"
				style={{
					width: '100%',
					backgroundColor: Colors.neutral100,
					p: 'small',
				}}
			>
				<Text type="smallStrong">Filter by:</Text>
				<SchedulesFilter
					numberOfRecurrentSchedules={schedules?.filter((s) => !!s.cron).length}
					numberOfOnceSchedules={schedules?.filter((s) => !s.cron).length}
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
				/>
			</Flex>

			{children}
		</Flex>
	);
}
