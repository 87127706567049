/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, RouterLink, Link, Text, Tooltip } from 'components';
import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { IconComponent, IconNewTab } from 'components/icons';

type RenderIcon = () => ReactElement;

interface NavigationItemProps {
	icon?: IconComponent;
	tooltip?: ReactNode;
	'data-cy'?: string;
	disabled?: boolean;
	external?: boolean;
	expanded: boolean;
	label: string;
	href?: string;
	onClick?: MouseEventHandler;
	renderIcon?: RenderIcon;
	/**
	 * @deprecated This is only for the storybook
	 */
	isShowcaseMode?: boolean;
	isActive?: boolean;
}

export default function NavigationItem({
	isShowcaseMode,
	external,
	disabled,
	isActive,
	expanded,
	tooltip,
	label,
	icon,
	href,
	renderIcon,
	onClick,
	...props
}: NavigationItemProps): ReactElement {
	const Icon = icon;

	let item = (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '8px',

				padding: external ? '4px 12px' : '12px 12px',

				backgroundColor: isActive ? 'purple600' : 'transparent',
				borderRadius: '4px',
				overflow: 'hidden',

				color: expanded ? (disabled ? 'purple600' : 'neutral000') : 'transparent',
				cursor: disabled ? 'default' : 'pointer',
				'&:hover': external
					? {
							textDecoration: 'underline',
							color: 'purple300',
						}
					: {
							backgroundColor: disabled ? undefined : 'purple600',
						},
			}}
		>
			{Icon && (
				<Icon
					color={disabled ? 'purple600' : 'purple300'}
					sx={{
						minWidth: '24px',
						minHeight: '24px',
						width: '24px',
						height: '24px',
					}}
				/>
			)}
			{renderIcon && renderIcon()}
			<Text
				as="span"
				variant="smallStrong"
				sx={{
					transition: 'color 0.2s',
					whiteSpace: 'nowrap',
				}}
			>
				{label}
			</Text>
			{external && expanded && (
				<IconNewTab
					color={disabled ? 'purple600' : 'purple300'}
					sx={{
						minWidth: '16px',
						minHeight: '16px',
						width: '16px',
						height: '16px',
					}}
				/>
			)}
		</Container>
	);

	if (tooltip) {
		item = <Tooltip content={tooltip}>{item}</Tooltip>;
	}

	if (disabled || isShowcaseMode || (!href && !onClick)) {
		return item;
	}

	if (external) {
		return (
			<Link
				to={href}
				external
				sx={{
					'&:hover': {
						textDecoration: 'none',
					},
				}}
				data-cy={props['data-cy']}
			>
				{item}
			</Link>
		);
	}

	return (
		<RouterLink
			to={href}
			sx={{
				'&:hover': {
					textDecoration: 'none',
				},
			}}
			data-cy={props['data-cy']}
			onClick={onClick}
		>
			{item}
		</RouterLink>
	);
}
