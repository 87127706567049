/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TemplateFormValues } from 'pages/templates/TemplateEditor/types';
import { Stack, userConfirm, Text, Snackbar } from 'components';
import { userInput } from 'components/Dialog/UserInput';
import { Services } from 'services/services';
import { toMillis } from 'utils/dateFns';

import { TemplateDeletionMethodVO } from '../../ui-api';

export async function deleteTemplate(templateId: string, deletionMethod: TemplateDeletionMethodVO): Promise<boolean> {
	const userChoice = await userConfirm({
		width: '776px',
		title: 'Delete this template',
		message: (
			<>
				<Stack size="none" mb="medium">
					<Text as="span">Are you sure you want to delete this template?</Text>
					<Text as="span">Once you delete it, it won’t be available anymore for future uses.</Text>
				</Stack>
				<Text as="span" variant="mediumStrong">
					The experiments created from this template won’t be deleted.
				</Text>
			</>
		),
		actions: [
			{ value: 'cancel', label: 'Cancel' },
			{
				value: 'delete',
				label: 'Delete template',
				variant: 'primary',
				dataCyTag: 'confirmDelete',
			},
		],
	});
	if (userChoice === 'delete') {
		await Services.templatesApi.deleteTemplate(templateId, deletionMethod);
		Snackbar.dark('Template deleted');
		return true;
	}
	return false;
}

export async function saveTemplate(template: TemplateFormValues): Promise<TemplateFormValues | null> {
	if (!template.templateTitle) {
		const inputResult = await userInput({
			title: 'Name your template',
			placeholder: 'Untitled template',
			buttonOkCaption: 'Save template',
		});
		if (inputResult.action !== 'ok') {
			return null;
		}
		template = {
			...template,
			templateTitle: inputResult.input || '',
		};
	}

	let savedTemplate;
	if (template.id) {
		savedTemplate = await Services.templatesApi.updateTemplate(template.id, {
			...template,
			version: template.version || 0,
		});
	} else {
		savedTemplate = await Services.templatesApi.createTemplate({
			...template,
		});
	}
	Snackbar.dark('Template saved.', { toastId: 'template-saved' });
	return {
		...template,
		...savedTemplate,
		placeholders: template.placeholders,
		created: toMillis(template.created),
		edited: toMillis(template.edited),
	};
}
