/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Errors, StateField } from './DataStore';

export function getErrorMessage(error: Errors | undefined): string {
	return typeof error === 'object' && error !== null && 'message' in error ? String(error.message) : 'Error';
}

export function hasError<T>(field: StateField<T>): boolean {
	return Boolean(field.touched && field.errors);
}
