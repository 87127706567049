/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DescriptionEditor from 'pages/templates/components/DescriptionEditor/DescriptionEditor';
import { Button, ErrorMessage, TextInput } from '@steadybit/ui-components-lib';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import { CollapsibleV2, Stack, Text, userConfirmV2 } from 'components';
import { ReactElement, useEffect, useState } from 'react';
import { TemplatePlaceholderVO } from 'ui-api';
import { theme } from 'styles.v2/theme';

interface PlaceholdersProps {
	existingPlaceholders: TemplatePlaceholderVO[];
	onPlaceholderSelected: (key: string, name: string, desc: string) => void;
}

export default function Placeholders({ existingPlaceholders, onPlaceholderSelected }: PlaceholdersProps): ReactElement {
	return (
		<Button
			withLeftIcon="plus"
			type="chromeless"
			size="small"
			onClick={async () => {
				await userConfirmV2({
					title: 'New placeholder',
					message: ({ setDisabled }) => (
						<AddPlaceholderContent setDisabled={setDisabled} placeholders={existingPlaceholders} />
					),
					actions: [
						{
							value: 'confirm',
							label: 'Create placeholder',
							variant: 'primary',
							dataCy: 'create-placeholder',
							action: async () => {
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								const placeholderKey = document.getElementById('new-placeholder-key')?.value.trim();
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								const placeholderName = document.getElementById('new-placeholder-name')?.value.trim();
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								const placeholderDescription = document.getElementById('new-placeholder-description')?.value.trim();

								if (placeholderKey) {
									onPlaceholderSelected(placeholderKey, placeholderName || '', placeholderDescription || '');
								}
							},
						},
					],
					secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
					width: '1080px',
				});
			}}
			data-cy="add-placeholder"
		>
			Add placeholder
		</Button>
	);
}

function AddPlaceholderContent({
	placeholders,
	setDisabled,
}: {
	placeholders: TemplatePlaceholderVO[];
	setDisabled: (disabled: boolean) => void;
}): ReactElement {
	const [value, setValue] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const isUnique = !placeholders.find((placeholder) => placeholder.key === value);
	useEffect(() => {
		setDisabled(!value || !isUnique);
	}, [value, isUnique]);

	return (
		<>
			<Stack size="large">
				<Stack size="xxSmall">
					<Text as="span" variant="mediumStrong" color="neutral800">
						Placeholder Key*
					</Text>
					<Text as="span" variant="small" color="neutral600">
						Provide the technical identifier by which you want to reference this placeholder in the Experiment tab of
						the template editor
					</Text>
					<Stack direction="horizontal" size="none" maxWidth="500px">
						<PlaceholderMarker marker="[[" left />
						<TextInput
							id="new-placeholder-key"
							placeholder="PLACEHOLDER"
							value={value}
							onChange={setValue}
							style={{
								borderRadius: 'none',
								zIndex: 1,
							}}
						/>
						<PlaceholderMarker marker="]]" />
					</Stack>
					{!isUnique && (
						<ErrorMessage type="small" withIcon>
							The placeholder with that name already exists
						</ErrorMessage>
					)}
				</Stack>

				<CollapsibleV2
					title={
						<Stack size="xxSmall">
							<Text as="span" variant="mediumStrong" color="neutral800">
								Complete placeholder definition
							</Text>
							<Text as="span" variant="small" color="neutral600">
								You can specify this information later on.
							</Text>
						</Stack>
					}
					backgroundColor={theme.colors.neutral100}
				>
					<Stack size="large" mx="-xSmall">
						<Stack size="xxSmall">
							<Text as="span" variant="mediumStrong" color="neutral800">
								Displayed name
							</Text>
							<Text as="span" variant="small" color="neutral600">
								Provide a human-readable name for this placeholder
							</Text>
							<TextInput placeholder="Name" value={name} onChange={setName} />
						</Stack>

						<Stack size="xxSmall">
							<Text as="span" variant="mediumStrong" color="neutral800">
								Description
							</Text>
							<Text as="span" variant="small" color="neutral600">
								Describe the placeholder to make the intention clear for a user of your template. It helps to pose this
								as a question, e.g., &apos;What is the URL of the load-balanced HTTP endpoint served by the system under
								test?&apos;
							</Text>
							<DescriptionEditor value={description} onChange={setDescription} />
						</Stack>
					</Stack>
				</CollapsibleV2>
			</Stack>
			<TextInput
				id="new-placeholder-description"
				value={description}
				style={{
					display: 'none',
				}}
			/>
			<TextInput
				id="new-placeholder-name"
				value={name}
				style={{
					display: 'none',
				}}
			/>
		</>
	);
}
