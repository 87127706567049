/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ActionVO, ExperimentStepActionVO, MetricCheckVO, MetricQueryVO } from 'ui-api';
import useIsExperimentDisabled from 'pages/experimentsV2/useIsExperimentDisabled';
import { usePromise } from 'utils/hooks/usePromise';
import { useStoreField } from 'DataStore/DataStore';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from 'components';

import MetricQueries from './MetricQueries';
import MetricChecks from './MetricChecks';

interface MetricQuerySectionProps {
	step: ExperimentStepActionVO;
	stepPath: string;
	action: ActionVO;
}

export default function MetricQuerySection({ stepPath, action, step }: MetricQuerySectionProps): ReactElement | null {
	const disabled = useIsExperimentDisabled();

	const { value: metricQueries, setValue: setMetricQueries } = useStoreField<MetricQueryVO[]>(
		`${stepPath}.metricQueries`,
	);
	const { value: metricChecks, setValue: setMetricChecks } = useStoreField<MetricCheckVO[]>(`${stepPath}.metricChecks`);

	const saveQuery = (query: MetricQueryVO): void => {
		const currentQueries = step.metricQueries.slice();
		const match = currentQueries.find((q) => q.id === query.id);
		if (match) {
			currentQueries[currentQueries.indexOf(match)] = query;
		} else {
			currentQueries.push(query);
		}
		setMetricQueries(currentQueries);
	};

	const deleteQuery = (query: MetricQueryVO): void => {
		setMetricQueries(metricQueries.filter((q) => q.id !== query.id));
	};

	const saveCheck = (check: MetricCheckVO): void => {
		const currentChecks = metricChecks.slice();
		const match = currentChecks.find((c) => c.id === check.id);
		if (match) {
			currentChecks[currentChecks.indexOf(match)] = check;
		} else {
			currentChecks.push(check);
		}
		setMetricChecks(currentChecks);
	};

	const deleteCheck = (check: MetricCheckVO): void => {
		setMetricChecks(metricChecks.filter((c) => c.id !== check.id));
	};

	const metricQueryParameterDefinitionsResult = usePromise(
		() => Services.experiments.fetchMetricQueryParameters(action.id, step.parameters),
		[step.actionId],
	);

	const metricQueryParameterDefinitions = metricQueryParameterDefinitionsResult.value;
	if (!metricQueryParameterDefinitions) {
		return null;
	}

	return (
		<Stack size="medium" mt="small" mb="xxxLarge" px="medium">
			<MetricQueries
				metricQueryParameterDefinitions={metricQueryParameterDefinitions}
				queries={metricQueries}
				checks={metricChecks}
				disabled={disabled}
				deleteQuery={deleteQuery}
				saveQuery={saveQuery}
			/>
			<MetricChecks
				queries={metricQueries}
				checks={metricChecks}
				stepPath={stepPath}
				disabled={disabled}
				addNewCheck={() => saveCheck(createNewCheck(step.metricQueries))}
				deleteCheck={deleteCheck}
				saveCheck={saveCheck}
			/>
		</Stack>
	);
}

function createNewCheck(queries: MetricQueryVO[]): MetricCheckVO {
	return {
		id: uuidv4(),
		a: {
			type: 'metric',
			metric: {
				steadybit_metric_query_id: queries[0].id,
			},
		},
		condition: 'GTE',
		b: { type: 'scalar', value: 0 },
	};
}
