/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconCalendar, IconComponent, IconExperiment, IconRuns } from 'components/icons';
import { Container, Pill, RouterLink, Stack, Text, Tooltip } from 'components';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import Skeletons from 'components/Skeleton/Skeletons';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { setParamValue } from 'url/utils';
import { useHistory } from 'url/hooks';
import Sidebar from 'targets/Sidebar';
import { ReactElement } from 'react';

import useRefreshingExperimentRuns from './hooks/useRefreshingExperimentRuns';
import useRefreshingExperiments from './hooks/useRefreshingExperiments';
import Schedules from './SchedulesList/SchedulesList';
import ExperimentRuns from './runs/ExperimentRuns';
import { teamIdsParam } from './runs/urlParams';
import ExperimentList from './experimentList';

interface ExperimentsWorkspaceProps {
	activeView: 'experiments' | 'experimentruns' | 'schedules';
}

export default function ExperimentsWorkspace({ activeView }: ExperimentsWorkspaceProps): ReactElement {
	const { createHref } = useHistory();

	const team = useTeam();
	const schedulesResult = Services.schedulesApi.useSchedules$();
	const schedules = schedulesResult.value?.content || [];

	const targetDefinitionsResult = useTargetDefinitions();
	const targetDefinitions = targetDefinitionsResult.value || [];

	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const actions = actionsResult.value || [];

	const experimentsResult = useRefreshingExperiments();
	const experimentRunsResult = useRefreshingExperimentRuns();

	return (
		<Stack direction="horizontal" size="none" height="100%">
			<Sidebar title="Experiments" widthExpanded={340} backgroundColor="neutral100">
				{(collapsed) => {
					return (
						<Stack size="xSmall" mt="xxLarge">
							<Text variant="small" color="neutral600">
								{collapsed ? <>&nbsp;</> : 'GENERAL'}
							</Text>
							<SidebarItem
								label="Experiments"
								Icon={IconExperiment}
								count={experimentsResult.value?.totalElements}
								href={createHref((location) => {
									location.pathname = '/experiments';
								})}
								isActive={activeView === 'experiments'}
								squashed={collapsed}
							/>
							<SidebarItem
								label="Experiment Runs"
								Icon={IconRuns}
								count={experimentRunsResult.value?.totalElements}
								href={createHref((location) => {
									location.pathname = '/experimentruns';
									setParamValue(location, teamIdsParam, [team.id]);
								})}
								isActive={activeView === 'experimentruns'}
								squashed={collapsed}
							/>
							<SidebarItem
								label="Schedules"
								Icon={IconCalendar}
								count={schedulesResult.value ? schedules.length : undefined}
								href={createHref((location) => {
									location.pathname = '/experiments/schedules';
								})}
								isActive={activeView === 'schedules'}
								squashed={collapsed}
							/>
						</Stack>
					);
				}}
			</Sidebar>

			<Container display="flex" justifyContent="center" width="100%" minWidth="930px">
				{activeView === 'schedules' ? (
					<Schedules schedulesResult={schedulesResult} />
				) : activeView === 'experimentruns' ? (
					<ExperimentRuns experimentExecutionsResult={experimentRunsResult} />
				) : (
					<ExperimentList
						targetDefinitions={targetDefinitions}
						experiments={experimentsResult}
						schedules={schedules}
						actions={actions}
					/>
				)}
			</Container>
		</Stack>
	);
}

interface SidebarItemProps {
	Icon: IconComponent;
	squashed: boolean;
	isActive: boolean;
	count?: number;
	label: string;
	href: string;
}

function SidebarItem({ label, Icon, count, isActive, href, squashed }: SidebarItemProps): ReactElement {
	return (
		<RouterLink to={href} variant="secondary">
			<Tooltip content={squashed ? label : undefined}>
				<Stack
					direction="horizontal"
					size="xxSmall"
					sx={{
						px: 'small',
						py: 'xSmall',
						mx: '-xSmall',
						borderRadius: 6,
						color: 'neutral600',

						cursor: 'pointer',
						'&:hover': {
							bg: 'neutral200',
							color: 'neutral800',
						},

						...(isActive && {
							bg: 'neutral200',
							color: 'neutral800',
						}),
					}}
				>
					<Icon
						sx={{
							minWidth: '20px',
							minHeight: '20px',
							maxWidth: '20px',
							maxHeight: '20px',
						}}
					/>
					{!squashed && (
						<>
							<Text variant="mediumStrong">{label}</Text>
							{count === undefined ? (
								<Skeletons height={20} widths={[32]} />
							) : (
								<Pill backgroundColor="cyan200" backgroundColorOnHover="cyan800" color="cyan800">
									{count}
								</Pill>
							)}
						</>
					)}
				</Stack>
			</Tooltip>
		</RouterLink>
	);
}
