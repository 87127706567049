/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentExecutionMetricStoreContext, useExecutionMetricStore } from 'services/executionMetricsStreams';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { ReactElement, ReactNode } from 'react';
import { ExperimentExecutionVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';

interface ExperimentExecutionLoaderProps {
	experimentExecutionId: number;
	children: ({
		experimentExecution,
	}: {
		experimentExecution: DataStreamResult<ExperimentExecutionVO | undefined>;
	}) => ReactNode;
}

export default function ExperimentExecutionLoader({
	experimentExecutionId,
	children,
}: ExperimentExecutionLoaderProps): ReactElement {
	const tenant = useTenant();
	const experimentRunResult = Services.experiments.useExecution$(experimentExecutionId);
	const metricStore = useExecutionMetricStore(tenant.key, experimentExecutionId);

	return (
		<ExperimentExecutionMetricStoreContext value={{ metricStore }}>
			{children({ experimentExecution: experimentRunResult })}
		</ExperimentExecutionMetricStoreContext>
	);
}
