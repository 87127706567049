/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { wrapWithEnvironmentVariableMarkers, wrapWithPlaceholderMarkers } from 'pages/templates/utils';
import { Mode, useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { Chunk, ChunkLUT } from '@steadybit/ui-components-lib';
import { TemplatePlaceholderVO, VariableVO } from 'ui-api';
import { useStoreField } from 'DataStore/DataStore';
import { mergeAndSort } from 'utils/envVars';
import { cloneDeep } from 'lodash';

export default function useChunkLUT(): ChunkLUT {
	const { mode: editorMode } = useEditorSettings();

	const { value: additionalPlaceholders = [] } = useStoreField<TemplatePlaceholderVO[]>('additionalPlaceholders');
	const { value: placeholderValuesMap = {} } = useStoreField<Record<string, string>>('placeholderValuesMap');
	const { value: variableValuesMap = {} } = useStoreField<Record<string, string>>('variableValuesMap');
	const { value: experimentVariables = [] } = useStoreField<VariableVO[]>('experimentVariables');
	const { value: placeholders = [] } = useStoreField<TemplatePlaceholderVO[]>('placeholders');
	const { value: environmentVariables = [] } = useStoreField<VariableVO[]>('variables');
	const chunkLUT: ChunkLUT = getChunkLUT({
		environmentAndExperimentVariables: mergeAndSort(environmentVariables, experimentVariables),
		placeholders: [...placeholders, ...additionalPlaceholders],
		placeholderValuesMap,
		variableValuesMap,
		editorMode,
	});

	return chunkLUT;
}

function getChunkLUT({
	environmentAndExperimentVariables,
	placeholderValuesMap,
	variableValuesMap,
	placeholders,
	editorMode,
}: {
	environmentAndExperimentVariables: VariableVO[];
	placeholderValuesMap: Record<string, string>;
	variableValuesMap: Record<string, string>;
	placeholders: TemplatePlaceholderVO[];
	editorMode: Mode | undefined;
}): ChunkLUT {
	const chunkLUT: ChunkLUT = {};

	// merge variables and value map so we have all used and available variables
	variableValuesMap = cloneDeep(variableValuesMap);
	for (let i = 0; i < environmentAndExperimentVariables.length; i++) {
		const variable = environmentAndExperimentVariables[i];
		variableValuesMap[variable.key] = variableValuesMap[variable.key] || variable.value || '';
	}

	chunkLUT.variables = Object.entries(variableValuesMap).reduce(
		(acc, [key, value]) => {
			if (value) {
				acc[wrapWithEnvironmentVariableMarkers(key)] = { value };
			} else {
				acc[wrapWithEnvironmentVariableMarkers(key)] = { tooltip: 'Value is not yet assigned' };
			}
			return acc;
		},
		{} as Record<string, Chunk>,
	);

	if (editorMode !== 'experiment') {
		// merge placeholders and placeholder map so we have all used and available placeholders
		placeholderValuesMap = cloneDeep(placeholderValuesMap);
		for (let i = 0; i < placeholders.length; i++) {
			const placeholder = placeholders[i];
			placeholderValuesMap[placeholder.key] = placeholderValuesMap[placeholder.key] || '';
		}

		chunkLUT.placeholders = Object.entries(placeholderValuesMap).reduce(
			(acc, [key, value]) => {
				if (value) {
					acc[wrapWithPlaceholderMarkers(key)] = { value };
				} else {
					const placeholder = placeholders.find((p) => p.key === key);
					if (placeholder) {
						if (editorMode === 'templateUsage') {
							acc[wrapWithPlaceholderMarkers(key)] = {
								placeholder: placeholder.name,
								tooltip: 'Value is not yet assigned',
							};
						} else {
							acc[wrapWithPlaceholderMarkers(key)] = {
								value: placeholder.name,
							};
						}
					}
				}
				return acc;
			},
			{} as Record<string, Chunk>,
		);
	}

	return chunkLUT;
}
