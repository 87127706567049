/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Heading,
	Link,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Tooltip,
} from 'components';
import { Button, Colors, ErrorMessage, Flex, Grid, hooks, Text, TextInput } from '@steadybit/ui-components-lib';
import { IconDelete, IconEnvironment, IconExperiment, IconInformation } from 'components/icons';
import { replaceLaneMarker, wrapWithEnvironmentVariableMarkers } from 'pages/templates/utils';
import { ExperimentError, ExperimentFormValues } from 'pages/experimentsV2/types';
import { KeyValuePair } from 'components/KeyValueListInput/KeyValueListInput';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import useLastExecutionId from 'pages/experimentsV2/useLastExecutionId';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ReactElement, useEffect, useState } from 'react';
import { useStore } from 'DataStore/DataStore';
import { localeCompare } from 'utils/string';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { VariableVO } from 'ui-api';
import { ampli } from 'ampli';

interface ExperimentAndEnvironmentVariablesProps {
	environmentVariables: VariableVO[];
	setIsDeletingVariable: (isDeleting: boolean) => void;
	close: () => void;
}

interface VariableError {
	key: ExperimentError | undefined;
	value: ExperimentError | undefined;
}

interface NewVariable extends VariableVO {
	__new: boolean;
}

export default function ExperimentAndEnvironmentVariables({
	environmentVariables,
	setIsDeletingVariable,
	close,
}: ExperimentAndEnvironmentVariablesProps): ReactElement {
	const { data, errors, setValue } = useStore<ExperimentFormValues>();

	const lastExecutionId = useLastExecutionId(data.key);
	const experimentRunResult = Services.experiments.useExecution$(lastExecutionId);
	const experimentExecution = experimentRunResult.value;

	const isCurrentlyExecuting = experimentExecution && !experimentExecution.ended;

	const experimentVariables = data.experimentVariables;
	const experimentVariablesErrors = errors.experimentVariables as unknown as Array<VariableError> | undefined;
	const experimentVariablesError = errors.overallExperimentVariables as unknown as ExperimentError | undefined;

	useEffect(() => {
		ampli.experimentVariableListViewed({
			experiment_key: data.experimentKey,
			experiment_variables: experimentVariables.map((v) => v.key),
			environment_variables: environmentVariables.map((v) => v.key),
		});
	}, []);

	// if only the name is invalid, we can still run the experiment
	const hasErrors = Object.keys(errors).length > 1 || (Object.keys(errors).length === 1 && !('name' in errors));

	const hasAnyVariable = Object.keys(data.metadata?.variables ?? {}).length > 0;

	const variablesToRender: Array<VariableVO | NewVariable> = [
		...experimentVariables,
		{ key: '', value: '', __new: true },
	];

	return (
		<Flex
			spacing="xSmall"
			style={{ p: 'small', minWidth: '600px', maxHeight: 'calc(100vh - 180px)', overflowY: 'auto' }}
		>
			<Flex direction="horizontal" align="center" spacing="xSmall" justify="spread" style={{ width: '100%' }}>
				<Heading variant="small">Variables</Heading>

				<Tooltip
					content={
						isCurrentlyExecuting
							? 'The experiment is currently running.'
							: hasErrors
								? 'There are validation errors in your experiment.'
								: !hasAnyVariable
									? 'There are no variables used in this experiment'
									: undefined
					}
				>
					<Button
						withLeftIcon="function"
						disabled={hasErrors || !hasAnyVariable || isCurrentlyExecuting || experimentRunResult.loading}
						type="chromeless"
						size="small"
						onClick={async () => {
							close();
							document.getElementById('execute-experiment-shadow-button')?.click();
						}}
						style={{ color: Colors.neutral600, onHover: { color: Colors.slate } }}
					>
						Override variables for a run
					</Button>
				</Tooltip>
			</Flex>

			<Flex direction="horizontal" align="center" spacing="xSmall" style={{ paddingTop: '12px' }}>
				<IconExperiment variant="small" />
				<Text type="small">Experiment variables</Text>
			</Flex>

			<Flex spacing="xSmall">
				{variablesToRender.map((variable, index) => {
					const isNew = isNewVariable(variable);
					return (
						<EnvironmentVariable
							key={index}
							error={experimentVariablesErrors?.[index]}
							experimentVariables={experimentVariables.filter((_, i) => i !== index)}
							environmentVariables={environmentVariables}
							variable={variable}
							setVariable={(_variable) => {
								if (isNew) {
									if (_variable.key === '' && _variable.value === '') {
										return;
									}

									const copied = experimentVariables.slice();
									copied.push(_variable);
									setValue('experimentVariables', copied);
									return;
								}

								const copied = [...experimentVariables];
								const oldVariable = copied[index];
								copied[index] = _variable;

								setValue('experimentVariables', copied);
								if (oldVariable.key !== _variable.key) {
									const oldKey = wrapWithEnvironmentVariableMarkers(oldVariable.key);
									const newKey = wrapWithEnvironmentVariableMarkers(_variable.key);
									const newLanes = replaceLaneMarker(data.lanes, oldKey, newKey);
									setValue('lanes', newLanes);
								}
							}}
							onDelete={
								isNew
									? undefined
									: ({ shadowsEnvironmentVariable }) => {
											setValue(
												'experimentVariables',
												experimentVariables.filter((v) => v.key !== variable.key),
											);

											if (!shadowsEnvironmentVariable) {
												const key = wrapWithEnvironmentVariableMarkers(variable.key);
												const newLanes = replaceLaneMarker(data.lanes, key, variable.value);
												setValue('lanes', newLanes);
											}

											ampli.experimentVariableDeleted({
												experiment_key: data.experimentKey,
												experiment_variable: variable.key,
												variable_shadowing: shadowsEnvironmentVariable,
											});
										}
							}
							setIsDeletingVariable={setIsDeletingVariable}
						/>
					);
				})}

				{experimentVariablesError && (
					<ErrorMessage withIcon type="small" level={experimentVariablesError.level}>
						{experimentVariablesError.message}
					</ErrorMessage>
				)}
			</Flex>

			<Flex
				direction="horizontal"
				align="center"
				spacing="xSmall"
				justify="spread"
				style={{ width: '100%', paddingTop: '24px' }}
			>
				<Flex direction="horizontal" align="center" spacing="xSmall">
					<IconEnvironment variant="small" />
					<Text type="small">Environment variables</Text>
				</Flex>
				<Link
					href={`/settings/environments/${data.environmentId}/variables`}
					external
					style={{ color: Colors.neutral500 }}
				>
					<Button
						type="chromeless"
						size="small"
						withLeftIcon="gear"
						style={{ color: Colors.neutral600 }}
						onClick={() => {}}
					>
						Manage variables for this environment
					</Button>
				</Link>
			</Flex>

			<Table width="100%">
				<TableHead>
					<TableRow>
						<TableHeadCell width="auto">Key</TableHeadCell>
						<TableHeadCell width="auto">Value</TableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{environmentVariables
						.slice()
						.sort((v1, v2) => localeCompare(v1.key, v2.key))
						.map((variable) => (
							<TableRow key={variable.key}>
								<TableDataCell sx={{ minHeight: '32px !important' }}>
									<Text textEllipsis type="small">
										{variable.key}
									</Text>
								</TableDataCell>
								<TableDataCell sx={{ minHeight: '32px !important' }}>
									<Text textEllipsis type="small">
										{variable.value}
									</Text>
								</TableDataCell>
							</TableRow>
						))}
					{environmentVariables.length === 0 && (
						<TableRow>
							<TableDataCell colSpan={3} sx={{ minHeight: '42px !important' }}>
								<Text style={{ color: theme.colors.neutral600 }}>No variables found.</Text>
							</TableDataCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Flex>
	);
}

interface EnvironmentVariableProps {
	environmentVariables: VariableVO[];
	experimentVariables: VariableVO[];
	variable: KeyValuePair;
	error?: VariableError;
	onDelete?: (p: { shadowsEnvironmentVariable: boolean }) => void;
	setIsDeletingVariable: (isDeleting: boolean) => void;
	setVariable: (v: KeyValuePair) => void;
}

function EnvironmentVariable({
	environmentVariables,
	experimentVariables,
	variable,
	error,
	setIsDeletingVariable,
	setVariable,
	onDelete,
}: EnvironmentVariableProps): ReactElement {
	const [isKeyEditing, setIsKeyEditing] = useState(false);
	const [isValueEditing, setIsValueEditing] = useState(false);

	const [tempKey, setTempKey] = useState(variable.key);
	useEffect(() => setTempKey(variable.key), [variable.key]);
	hooks.useUnmountEffect(() => {
		if (tempKey !== variable.key) {
			setVariable({ key: tempKey, value: variable.value });
		}
	});

	const isKeyErrorneous: boolean = !!error?.key;
	const isValueErrorneous: boolean = !!error?.value;

	const shadowsEnvironmentVariable = environmentVariables.find((v) => v.key === variable.key);
	const shadowsExperimentVariable = !!experimentVariables.find((v) => v.key === variable.key);

	const [isDeleting, setIsDeleting] = useState(false);

	const hasError = isKeyErrorneous || shadowsExperimentVariable;

	useEffect(() => {
		return () => {
			setIsDeletingVariable(false);
		};
	}, []);

	return (
		<Flex spacing="xSmall">
			{isDeleting && onDelete && (
				<DeleteShadowingVariableMessage
					variable={variable}
					evironmentVariableValue={shadowsEnvironmentVariable?.value || ''}
					onClose={() => {
						setIsDeleting(false);
						setIsDeletingVariable(false);
					}}
					onDelete={() => onDelete({ shadowsEnvironmentVariable: !!shadowsEnvironmentVariable })}
				/>
			)}

			<Grid cols="240px 400px 40px" spacing="xSmall" align="center" style={{ width: '100%' }}>
				{/* Key */}
				<Flex direction="horizontal" style={{ width: '100%' }}>
					<PlaceholderMarker marker="{{" left small />
					<TextInput
						value={tempKey}
						placeholder="Key"
						size="small"
						withRightIcon={shadowsEnvironmentVariable ? 'info-circle' : undefined}
						withRightIconTooltip={
							shadowsEnvironmentVariable
								? 'This experiment variable will shadow an environment variable with the same key.'
								: undefined
						}
						errored={hasError}
						onFocus={() => {
							setIsKeyEditing(true);
						}}
						onBlur={() => {
							setIsKeyEditing(false);
							setVariable({ key: tempKey, value: variable.value });
						}}
						onChange={(_key) => {
							setTempKey(_key);
						}}
						style={{
							borderRadius: 'none',
							zIndex: 1,
						}}
					/>
					<PlaceholderMarker marker="}}" small />
				</Flex>

				{/* Value */}
				<TextInput
					withRightIcon={isValueErrorneous ? 'warning' : undefined}
					withRightIconTooltip="The value cannot be empty"
					errored={isValueErrorneous}
					placeholder="Value"
					value={variable.value}
					size="small"
					onFocus={() => setIsValueEditing(true)}
					onBlur={() => setIsValueEditing(false)}
					onChange={(_value) => {
						if (variable.key) {
							setVariable({ key: variable.key, value: _value });
						}
					}}
				/>

				{onDelete && (
					<ButtonIcon
						variant="small"
						muted
						color="neutral600"
						onClick={() => {
							if (shadowsEnvironmentVariable) {
								setIsDeleting(true);
								setIsDeletingVariable(true);
							} else {
								onDelete({ shadowsEnvironmentVariable: false });
							}
						}}
						tooltip="Delete experiment variable"
					>
						<IconDelete />
					</ButtonIcon>
				)}
			</Grid>
			{shadowsExperimentVariable && isKeyEditing && (
				<ErrorMessage type="small" withIcon>
					This key is already used.
				</ErrorMessage>
			)}
			{isValueErrorneous && isValueEditing && (
				<ErrorMessage type="small" withIcon>
					Enter a value for the variable.
				</ErrorMessage>
			)}
			{error && !isKeyEditing && !isValueEditing && (
				<ErrorBoundary>
					{error.key && (
						<ErrorMessage type="small" withIcon>
							{error.key.message}
						</ErrorMessage>
					)}
					{error.value && (
						<ErrorMessage type="small" withIcon>
							{error.value.message}
						</ErrorMessage>
					)}
				</ErrorBoundary>
			)}
			{shadowsEnvironmentVariable && (isKeyEditing || isValueEditing) && (
				<Flex
					direction="horizontal"
					spacing="xSmall"
					align="center"
					style={{
						p: 'xSmall',
						color: theme.colors.slate,
						backgroundColor: theme.colors.purple100,
						borderRadius: 'xxSmall',
						width: 'calc(100% - 48px)',
					}}
				>
					<IconInformation />
					<Text type="small">This experiment variable will shadow an environment variable with the same key.</Text>
				</Flex>
			)}
		</Flex>
	);
}

interface AddMessageProps {
	evironmentVariableValue: string;
	variable: KeyValuePair;
	onClose: () => void;
	onDelete: () => void;
}

function DeleteShadowingVariableMessage({
	evironmentVariableValue,
	variable,
	onDelete,
	onClose,
}: AddMessageProps): ReactElement {
	return (
		<ModalOverlay open onClose={onClose} zIndex={42}>
			{({ close }) => {
				return (
					<ModalV2 width="100%" maxWidth="864px" slick centered withFooter>
						<ModalHeaderV2 title="Delete experiment variable and use environment variable?" onClose={close} />
						<ModalContentV2>
							<Flex spacing="small" style={{ color: theme.colors.neutral600 }}>
								<Text type="medium">
									There is an <Text type="mediumStrong">environment</Text> variable with the same key as the experiment
									variable that you are about to delete.
								</Text>

								<Text type="medium">
									After deleting the experiment variable, any reference to the key{' '}
									<Text type="mediumStrong">{variable.key}</Text> will resolve to the value defined for the selected
									environment (<Text type="mediumStrong">{evironmentVariableValue}</Text>).
								</Text>

								<Text type="medium">Do you want to continue?</Text>
							</Flex>
						</ModalContentV2>
						<ModalFooterV2>
							<Flex direction="horizontal" justify="spread" style={{ width: '100%', px: 'small', pb: 'small' }}>
								<Button onClick={onClose} type="secondary">
									Cancel
								</Button>
								<Button onClick={onDelete}>Delete experiment variable</Button>
							</Flex>
						</ModalFooterV2>
					</ModalV2>
				);
			}}
		</ModalOverlay>
	);
}

function isNewVariable(variable: KeyValuePair): variable is NewVariable {
	return (variable as NewVariable).__new;
}
