/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BreadcrumbItem, Breadcrumbs, Button, ButtonIcon, HeaderBar, Stack, Tooltip, userConfirmV2 } from 'components';
import { deleteTemplate, saveTemplate } from 'templates/components/formUtils';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { useStore, useValidations } from 'DataStore/DataStore';
import { IconTemplate, IconTrash } from 'components/icons';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'url/hooks';

import { TemplateFormValues } from './types';

export default function TemplateHeader(): ReactElement {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { data, setValue, resetValues } = useStore<TemplateFormValues>();
	const { errors, isValidating } = useValidations();
	const { templateTitle, id } = data;

	const hasErrors = Object.keys(errors).length > 0;
	const isNewTemplate = !id;

	const history = useHistory();

	// Use an effect to handle navigating back to the templates list after save,
	// as formic fields are still touched and need another react update cycle,
	// so that the unsaved changes prompt will not intervene.
	const [shouldRedirect, setShouldRedirect] = useState(false);
	useEffect(() => {
		if (shouldRedirect) {
			setShouldRedirect(false);
			history.replace('/settings/templates');
		}
	}, [shouldRedirect]);

	return (
		<HeaderBar
			theme="template"
			icon={IconTemplate}
			tagline={
				<Breadcrumbs>
					<BreadcrumbItem variant="small" label="Settings" to="/settings" sx={{ color: 'purple200', mr: 'xxxSmall' }} />
					<BreadcrumbItem variant="small" label="Templates" to="/settings/templates" sx={{ color: 'purple200' }} />
				</Breadcrumbs>
			}
			title={
				<EditableLabel
					value={templateTitle}
					placeholder="Untitled Template"
					theme="light"
					// Template title validation errors are displayed in a form below,
					// hence no need to restrict them in the input here.
					maxLength={null}
					onChange={(value) => setValue('templateTitle', value)}
				/>
			}
			sx={{ height: '66px' }}
		>
			<Stack direction="horizontal" alignItems="center" size="small">
				<ButtonIcon
					onClick={async () => {
						if (!isNewTemplate) {
							await deleteTemplate(id || '', 'UI_EDITOR');
							history.replace('/settings/templates');
							return;
						}

						if (
							(await userConfirmV2({
								width: '480px',
								title: 'Discard new template',
								message: `Do you really want to discard your new template: ${templateTitle || 'Untitled'}?`,
								actions: [
									{ value: 'cancel', label: 'Cancel' },
									{
										value: 'confirm',
										label: 'Discard',
										variant: 'primary',
									},
								],
							})) === 'confirm'
						) {
							await resetValues(data);
							history.replace('/settings/templates');
						}
					}}
					tooltip={isNewTemplate ? 'Discard new template' : 'Delete template'}
				>
					<IconTrash color="purple200" />
				</ButtonIcon>

				<Tooltip content={hasErrors ? 'Fix all validation errors to save this template' : ''}>
					<Button
						variant="secondary"
						minWidth="140px"
						onClick={async () => {
							setIsSubmitting(true);
							const savedTemplate = await saveTemplate(data);
							if (savedTemplate) {
								resetValues({ ...savedTemplate, isNewTemplateFromFile: false });
								setShouldRedirect(true);
							}
							setIsSubmitting(false);
						}}
						disabled={hasErrors || isValidating}
						data-cy="save-template"
					>
						{isSubmitting ? 'Saving…' : isValidating ? 'Validating…' : 'Save template'}
					</Button>
				</Tooltip>
			</Stack>
		</HeaderBar>
	);
}
