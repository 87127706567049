/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Flex, Grid, Text } from '@steadybit/ui-components-lib';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { ReactElement, ReactNode, useState } from 'react';
import { Toggle, Tooltip } from 'components';
import { useUser } from 'services/usersApi';
import { timeZones } from 'utils/dateFns';

import { saveSchedule, Schedule, ScheduleContentBlock } from './EditSchedulesModal';
import DeleteScheduleExperimentModal from '../DeleteScheduleModal';
import ScheduleCronTab from '../ScheduleCronTab';
import { OverrideVariables } from '../Common';
import ScheduleOnce from '../ScheduleOnce';
import { ScheduleType } from './types';

interface EditScheduleProps {
	experimentName?: string;
	experimentKey: string;
	schedule: Schedule;
	setScheduleToEdit: (v: Schedule | null) => void;
	onScheduleDeleted: () => void;
	onClose: () => void;
}

export default function EditSchedule({
	experimentName,
	experimentKey,
	schedule,
	setScheduleToEdit,
	onScheduleDeleted,
	onClose,
}: EditScheduleProps): ReactElement {
	const permissions = useGlobalPermissions();
	const disabled = !permissions.schedules.canEdit.allowed;

	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

	const user = useUser();
	const browserDefaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const usersTimeZone = timeZones.find((t) => t.timeZone === (user.timeZone || browserDefaultTimezone)) || timeZones[0];

	const setScheduleUpdate = (_schedule: Schedule): void => setScheduleToEdit({ ..._schedule, __isTouched: true });

	const switchScheduleType = (type: ScheduleType): void => {
		if (type === 'once' && !schedule.startAt) {
			const now = new Date();
			now.setMinutes(now.getMinutes() + 60);
			schedule.startAt = now;
		} else if (type === 'cron' && !schedule.cron) {
			schedule.cron = '';
			schedule.timezone = usersTimeZone.timeZone;
		}
		setScheduleUpdate({ ...schedule, __type: type });
	};

	const onSave = !schedule.__isTouched
		? undefined
		: async () => {
				const savedSchedule = await saveSchedule(schedule);
				if (savedSchedule) {
					setScheduleToEdit({ ...schedule, ...savedSchedule, __isTouched: false });
				}
			};

	const ConfigPage = schedule.__type === 'once' ? ScheduleOnce : ScheduleCronTab;

	return (
		<Flex spacing="small" style={{ position: 'relative', overflowY: 'auto', height: '100%' }}>
			{showDeleteModal && (
				<DeleteScheduleExperimentModal
					id={schedule.id}
					onClose={() => setShowDeleteModal(false)}
					onScheduleDeleted={onScheduleDeleted}
				/>
			)}
			<Grid cols="1fr 190px" align="center" style={{ width: '100%' }}>
				<Text type="largeStrong" textEllipsis>
					{experimentKey}
					<Text type="large"> {experimentName}</Text>
				</Text>
				<Tooltip content={permissions.schedules.canDelete.tooltipContent}>
					<Button
						type="chromeless"
						withLeftIcon="delete"
						disabled={!permissions.schedules.canDelete.allowed}
						onClick={() => setShowDeleteModal(true)}
					>
						{`${schedule.id === '<new>' ? 'Discard' : 'Delete'} schedule`}
					</Button>
				</Tooltip>
			</Grid>

			<ScheduleContentBlock
				title="Activate Schedule"
				description="Decide if you want to have this schedule active or not."
				setting={
					<Toggle
						type="radio"
						checked={schedule.enabled}
						disabled={disabled}
						onChange={(e) => {
							setScheduleUpdate({ ...schedule, enabled: e.target.checked });
						}}
					/>
				}
			/>

			<ConfigPage
				schedule={schedule}
				disabled={disabled}
				switchScheduleType={switchScheduleType}
				setScheduleUpdate={setScheduleUpdate}
			>
				{(invaidMessage) => (
					<>
						<OverrideVariables
							variables={schedule.variables}
							experimentKey={experimentKey}
							disabled={disabled}
							setVariables={(variables) => {
								setScheduleUpdate({ ...schedule, variables });
							}}
						/>

						<ScheduleContentBlock
							title="Run Experiments in parallel"
							description="At the configured time, should this experiment run even when another experiment runs in parallel?"
							setting={
								<Toggle
									type="radio"
									checked={schedule.allowParallel}
									disabled={disabled}
									style={{ minWidth: '36px' }}
									onChange={(e) => {
										setScheduleUpdate({ ...schedule, allowParallel: e.target.checked });
									}}
								/>
							}
						/>

						{onSave && !disabled && (
							<Flex
								direction="horizontal"
								spacing="small"
								style={{
									position: 'sticky',
									bottom: 0,
									p: 'xSmall',
									width: '100%',
									backgroundColor: Colors.neutral000,
								}}
								justify="end"
							>
								<Button type="secondary" onClick={onClose}>
									Cancel
								</Button>
								<Tooltip content={invaidMessage}>
									<Button disabled={!!invaidMessage} onClick={onSave}>
										Save Schedule
									</Button>
								</Tooltip>
							</Flex>
						)}
					</>
				)}
			</ConfigPage>
		</Flex>
	);
}

export interface ConfigureScheduleProps {
	schedule: Schedule;
	disabled: boolean;
	switchScheduleType: (options: ScheduleType) => void;
	setScheduleUpdate: (v: Schedule) => void;

	children: (invaidMessage: string | undefined) => ReactNode;
}
