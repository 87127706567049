/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { IconAdviceUnknown } from 'components/icons';
import { ReactElement } from 'react';
import { Link } from 'components';

export default function NoAdviceContent(): ReactElement {
	return (
		<AstroidScreen
			title={
				<Text type="xLargeStrong" slate>
					No advice found
				</Text>
			}
			icon={<IconAdviceUnknown variant="xxLarge" color="slate" />}
			description={
				<Flex align="center" spacing="small">
					<Text type="medium" neutral600>
						To get advice on your reliability, please install an extension that provides some.
					</Text>
					<Text type="medium" neutral600>
						You can view all extensions that offer advice in our
						<Link href="https://hub.steadybit.com/extensions?tags=Advice" external ml="4px">
							Reliability Hub
						</Link>
						.
					</Text>
				</Flex>
			}
		/>
	);
}
