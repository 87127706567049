/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Code,
	Container,
	ContainerProps,
	Link,
	Message,
	ShortenedText,
	Spinner,
	Stack,
	Tab,
	TabList,
	Tabs,
	Text,
} from 'components';
import { IconAdviceGeneral, IconClose, IconNewTab, IconTags } from 'components/icons';
import { getAttributeKeyValue, getTargetLabel, TargetId } from 'targets/util';
import { useAttributeDefinitions } from 'attributes/useAttributeDefinitions';
import AttackedTargetIndicator from 'targets/AttackedTargetIndicator';
import { NotFound } from 'pages/advice/AdviceDetails/AdviceContent';
import { useTargetDefinition } from 'targets/useTargetDefinition';
import { sharedAdviceIdParam } from 'targets/Explore/urlParams';
import AdviceBadges from 'components/advice/AdviceBadges';
import { getLabel as getI18nLabel } from 'i18n/label';
import { usePromise } from 'utils/hooks/usePromise';
import { isBlank, toTitleCase } from 'utils/string';
import { ReactElement, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { fetchTarget } from 'targets/fetch';
import useAdvice from 'targets/useAdvice';
import { theme } from 'styles.v2/theme';
import { AdviceStatusVO } from 'ui-api';
import { getHash } from 'utils/hash';
import { groupBy } from 'lodash';

import TargetAdvice, { TargetAdviceState } from './TargetAdvice';
import TargetAdviceDetails from './TargetAdviceDetails';
import TargetAttributeList from './TargetAttributeList';

export interface TargetDetailsProps extends ContainerProps {
	defaultToAdvicePage?: boolean;
	environmentId: string | null;
	onClose?: () => void;
	adviceType?: string;
	withAdvice: boolean;
	targetId: TargetId;
	renderInModal?: boolean;
}
interface AdviceIdUrlState {
	sharedAdviceId: string | null;
}

export default function TargetDetails({
	defaultToAdvicePage,
	environmentId,
	adviceType,
	withAdvice,
	targetId,
	onClose,
	renderInModal = false,
	...containerProps
}: TargetDetailsProps): ReactElement {
	const [{ sharedAdviceId }] = useUrlState<AdviceIdUrlState>([sharedAdviceIdParam]);
	const targetDefinition = useTargetDefinition(targetId.type);
	const attributeDefinitions = useAttributeDefinitions();
	const fetchResult = usePromise(() => fetchTarget(targetId, true), [getHash(targetId)]);
	const adviceEnabled = withAdvice;
	const adviceResult = useAdvice(targetId.type, targetId.name);

	const selectedAdviceIdState = useState<string | undefined>(undefined);
	const activeBadgesState = useState<Array<AdviceStatusVO>>([]);
	const selectedTagsState = useState<string[]>([]);

	const targetAdviceState: TargetAdviceState = {
		selectedAdviceIdState: selectedAdviceIdState,
		activeBadgesState: activeBadgesState,
		selectedTagsState: selectedTagsState,
	};

	const [attributesOrAdvice, setAttributesOrAdvice] = useState<'attributes' | 'advice'>(
		sharedAdviceId || defaultToAdvicePage ? 'advice' : 'attributes',
	);

	if (
		targetDefinition.loading ||
		!fetchResult.value ||
		!attributeDefinitions.value ||
		(adviceResult.loading && attributesOrAdvice === 'advice')
	) {
		return (
			<Container
				{...containerProps}
				sx={{ borderRadius: 8, ...(containerProps.sx || {}) }}
				bg="white"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Container display="flex" justifyContent="center">
					<Spinner variant="xLarge" color="neutral300" />
				</Container>
			</Container>
		);
	}

	if (fetchResult.value.length === 0) {
		return (
			<Container
				{...containerProps}
				sx={{ borderRadius: 8, ...(containerProps.sx || {}) }}
				bg="white"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Message variant="danger">
					<Stack size="medium" alignItems="flex-start">
						<span>Target not found while querying for the following ID</span>
						<Code lang="json" width="100%" withCopyToClipboard>
							{JSON.stringify(targetId, undefined, 2)}
						</Code>
					</Stack>
				</Message>
			</Container>
		);
	}

	const target = fetchResult.value[0];
	const attributesGroupedByKey = groupBy(target.attributes, (a) => a.key);
	const label = getTargetLabel(target, targetDefinition.value);

	const steadybitUrl = target.attributes.filter((a) => a.key === 'steadybit.url')[0]?.value;
	const selectedAdvice = adviceResult.value?.find((a) => a.adviceDefinitionId === adviceType);

	return (
		<Stack size="none" {...containerProps}>
			{onClose && (
				<ButtonIcon
					ml="auto"
					mb="-40px"
					minWidth="40px"
					minHeight="40px"
					onClick={(e) => {
						e.stopPropagation();
						onClose();
					}}
				>
					<IconClose />
				</ButtonIcon>
			)}
			{/* Header */}
			<Container sx={{ backgroundColor: 'neutral100', px: 'small', py: 'medium' }}>
				<Stack size="small">
					<Container display="flex" justifyContent="space-between" alignItems="center" mr="xLarge">
						<Stack direction="horizontal" alignItems="center" size="xSmall">
							{targetDefinition.value ? <AttackedTargetIndicator targetType={targetDefinition.value.id} /> : null}
							<ShortenedText tooltip={label} tx="heading" variant="large" as="h1" color="purple700" noWrap>
								{label}
							</ShortenedText>
						</Stack>

						{/* don't show advice list if the modal is focused on a single advice */}
						{adviceEnabled && !adviceType && adviceResult.value && <AdviceBadges result={adviceResult.value} />}
						{steadybitUrl && (
							<Stack direction="horizontal" alignItems="center">
								<Link href={steadybitUrl} target="_blank">
									<ButtonIcon variant="medium">
										<IconNewTab />
									</ButtonIcon>
								</Link>
							</Stack>
						)}
					</Container>

					{/* Columns */}
					<Container display="flex" flexDirection="row" sx={{ columnGap: 'xxLarge', rowGap: 'small' }} flexWrap="wrap">
						{targetDefinition.value &&
							targetDefinition.value.table.columns
								.map((column, i) => {
									const keyValue = getAttributeKeyValue(column, target.attributes);
									if (isBlank(keyValue?.value) || keyValue?.value === label) {
										return null;
									}

									const attributeDefinition = attributeDefinitions.value.find(
										(attr) => attr.attribute === keyValue?.key,
									);
									const header = attributeDefinition
										? getI18nLabel(attributeDefinition.label, 1)
										: (keyValue?.key ?? column.attribute);

									return (
										<Stack key={i} size="xxxSmall" direction="vertical">
											<Text variant="xSmall" color="neutral600">
												{toTitleCase(header)}
											</Text>
											<ShortenedText
												tooltip={keyValue?.value}
												variant="smallStrong"
												color="neutral700"
												noWrap
												sx={{ maxWidth: 200 }}
											>
												{keyValue?.value}
											</ShortenedText>
										</Stack>
									);
								})
								.filter(Boolean)}
					</Container>
				</Stack>
			</Container>

			{/* Body */}
			{!adviceEnabled || (adviceResult.value && adviceResult.value.length === 0) ? (
				<Container as="dl" overflowY="auto">
					<TargetAttributeList attributesGroupedByKey={attributesGroupedByKey} />
				</Container>
			) : (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						margin: `12px ${renderInModal ? '12px' : '0px'}`,
						overflowY: 'hidden',
					}}
				>
					<Tabs
						value={attributesOrAdvice}
						onChange={(t) => {
							setAttributesOrAdvice(t === 'attributes' ? 'attributes' : 'advice');
						}}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							borderBottom: 'none',
						}}
					>
						<TabList>
							<Tab value="advice">
								<IconAdviceGeneral size="small" mr="xSmall" /> Advice
							</Tab>
							<Tab value="attributes">
								<IconTags size="small" mr="xSmall" /> Attributes
							</Tab>
						</TabList>
					</Tabs>
					{attributesOrAdvice === 'attributes' && (
						<Container
							as="dl"
							overflowY="auto"
							display="flex"
							flexDirection="column"
							sx={{
								border: '1px solid ' + theme.colors.neutral300,
								borderTop: 'none',
							}}
						>
							<TargetAttributeList attributesGroupedByKey={attributesGroupedByKey} />
						</Container>
					)}
					{attributesOrAdvice === 'advice' && (
						<>
							{adviceType ? (
								<div
									style={{
										border: '1px solid ' + theme.colors.neutral300,
										borderTop: 'none',
									}}
								>
									{selectedAdvice ? (
										<TargetAdviceDetails
											environmentId={environmentId}
											advice={selectedAdvice}
											targetId={targetId}
											context={'explorer_advice'}
										/>
									) : (
										<NotFound adviceIsNotAvailableForTarget />
									)}
								</div>
							) : (
								<TargetAdvice
									advice={adviceResult.value || []}
									targetId={targetId}
									environmentId={environmentId}
									targetAdviceState={targetAdviceState}
								/>
							)}
						</>
					)}
				</div>
			)}
		</Stack>
	);
}
