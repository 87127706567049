/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	RouterLink,
	ShortenedText,
	Spinner,
	StateBadge,
	Table,
	TableBody,
	TableDataCell,
	TableRow,
	Text,
	Tooltip,
} from 'components';
import DashboardWidget from 'components/DashboardWidget/DashboardWidget';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { ExperimentExecutionSummaryVO } from 'ui-api';
import { formatDateWithTime } from 'utils/dateFns';
import { PageParams } from 'utils/hooks/usePage';
import { ReactElement, ReactNode } from 'react';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';

export function LastExecutionsWidget(): ReactElement {
	const team = useTeam();

	const [executions] = useAsyncState(async () => {
		const criteria = new URLSearchParams();
		criteria.append('teamId', team.id);
		const experimentExecutions = (await Services.experiments.fetchExperimentRuns(criteria, new PageParams(0, 5)))
			.content;
		return experimentExecutions.sort((a, b) => (a.requested > b.requested ? -1 : 1));
	}, [team]);

	return (
		<DashboardWidget title="Latest Runs" icon="experiment">
			<ExecutionsTable>
				{executions.loading ? (
					<TableRow>
						<TableDataCell colSpan={7}>
							<Spinner variant="large" color={'neutral500'} />
						</TableDataCell>
					</TableRow>
				) : executions.value ? (
					executions.value.map((execution, i) => (
						<ExecutionRow key={execution.id} value={execution} hideBorder={i === (executions.value?.length || 0) - 1} />
					))
				) : null}
				{executions.value && !executions.loading && executions.value.length === 0 && (
					<TableRow sx={{ td: { borderBottomColor: 'neutral000' } }}>
						<TableDataCell colSpan={7}>
							<Text muted>No runs found.</Text>
						</TableDataCell>
					</TableRow>
				)}
			</ExecutionsTable>
		</DashboardWidget>
	);
}

function ExecutionsTable({ children }: { children: ReactNode }): ReactElement {
	return (
		<Table width={'100%'} sx={{ '& td > div': { minHeight: 43 } }}>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

function ExecutionRow({
	hideBorder,
	value,
}: {
	value: ExperimentExecutionSummaryVO;
	hideBorder?: boolean;
}): ReactElement {
	return (
		<TableRow hoverable={true} sx={{ ...(hideBorder ? { td: { borderBottomColor: 'neutral000' } } : {}) }} height={52}>
			<TableDataCell width={'100%'} maxWidth={1}>
				<Tooltip onlyShowOnEllipsis content={`${value.experimentKey} ${value.name}`}>
					<RouterLink
						to={`/experiments/${value.teamKey}/edit/${value.experimentKey}/executions/${value.id}`}
						sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
					>
						<ShortenedText noWrap>
							<Text variant={'smallMedium'} as={'span'} mr={'xxSmall'}>
								{value.experimentKey}
							</Text>
							<Text variant={'small'} color={'neutral700'} as={'span'}>
								{value.name}
							</Text>
						</ShortenedText>
						<ShortenedText noWrap sx={{ marginTop: -4 }}>
							<Text variant={'xSmall'} color={'neutral600'} as={'span'} data-private>
								#{value.id} ({formatDateWithTime(value.requested)} by {value?.createdBy?.name})
							</Text>
						</ShortenedText>
					</RouterLink>
				</Tooltip>
			</TableDataCell>
			<TableDataCell width={90} sx={{ whiteSpace: 'nowrap', justifyContent: 'flex-end' }}>
				<StateBadge as="state" value={value.state} />
			</TableDataCell>
		</TableRow>
	);
}
