/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Spinner,
	Stack,
	Text,
} from 'components';
import {CreatePreflightActionIntegrationRequest} from 'ui-api';
import {useAsyncState} from 'utils/hooks/useAsyncState';
import {Form, Formik, FormikHelpers} from 'formik';
import {useTenant} from 'tenancy/useTenant';
import {ValidationError} from 'utils/error';
import {Services} from 'services/services';
import React, {ReactElement} from 'react';
import {isUserAdmin} from 'utils/user';

import {
	PreflightActionIntegrationForm,
	PreflightActionIntegrationFormValues
} from './components/preflightActionIntegrationForm';

const INITIAL_VALUES: PreflightActionIntegrationFormValues = {
	name: '',
	team: '',
	scope: 'GLOBAL',
	preflightActionId: '',
};

const getInitialValues = async (isAdmin: boolean): Promise<PreflightActionIntegrationFormValues> => {
	if (isAdmin) {
		return { ...INITIAL_VALUES };
	} else {
		const team = await Services.teams.findInitialTeam();
		return { ...INITIAL_VALUES, team: team?.id ?? '', scope: 'TEAM' };
	}
};

interface AddPreflightActionIntegrationProps {
	labelTitle?: string;
	onClose?: () => void;
}

function AddPreflightActionIntegration({ labelTitle, onClose }: AddPreflightActionIntegrationProps): ReactElement {
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);
	const [submitError, setSubmitError] = React.useState<Error | null>();
	const [initialValues] = useAsyncState(() => getInitialValues(isAdmin), [isAdmin]);

	const handleSubmit = async (
		values: PreflightActionIntegrationFormValues,
		form: FormikHelpers<CreatePreflightActionIntegrationRequest>,
	): Promise<void> => {
		try {
			setSubmitError(null);
			await Services.preflightActionIntegrationsApi.createPreflightActionIntegration({ ...values });
			onClose?.();
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	return (
		<>
			{initialValues.loading ? (
				<Stack>
					<Spinner variant="large" color={'neutral500'} mr={'auto'} />
				</Stack>
			) : null}
			{!initialValues.loading && initialValues.value ? (
				<Formik initialValues={{ ...initialValues.value }} onSubmit={handleSubmit} validateOnChange>
					{({ isSubmitting }) => (
						<Form>
							<ModalV2 withFooter>
								<ModalHeaderV2 title={labelTitle || 'Add Preflight Action Integration'} onClose={onClose} />
								<ModalContentV2>
									<PreflightActionIntegrationForm />
								</ModalContentV2>
								<ModalFooterV2>
									<>
										{submitError && <Container width="100%">{submitError.toString()}</Container>}
										{submitError && (
											<Container width="100%">
												<Text variant="mediumStrong" color="coral">
													{submitError.toString()}
												</Text>
											</Container>
										)}
										<Button id={'save'} type={'submit'} loading={isSubmitting}>
											Save
										</Button>
									</>
								</ModalFooterV2>
							</ModalV2>
						</Form>
					)}
				</Formik>
			) : null}
		</>
	);
}

export default AddPreflightActionIntegration;
