/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Dropdown, Pill, presets, TextArea } from '@steadybit/ui-components-lib';
import { useStoreField } from 'DataStore/DataStore';
import { ReactElement, useState } from 'react';
import { hasError } from 'DataStore/utils';

export default function ExperimentSubHeaderHypothesis({ disabled }: { disabled: boolean }): ReactElement {
	const hypothesisField = useStoreField<string>('hypothesis');
	const hypothesis = hypothesisField.value;
	const [localHypothesis, setLocalHypothesis] = useState<string>(hypothesis);

	return (
		<Dropdown
			placement="bottom-start"
			renderDropdownContent={({ close }) => (
				<presets.dropdown.DropdownContentFrame
					maxHeight="500px"
					style={{ padding: '1px', borderRadius: 'xxSmall', border: 'none' }}
				>
					<TextArea
						placeholder="No description"
						value={localHypothesis}
						maxLength={20_000}
						autoFocus
						onChange={setLocalHypothesis}
						style={{
							minWidth: '400px',
							minHeight: '150px',
						}}
						onKeyDown={(e) => {
							if (e.key === 'Escape' || e.key === 'Esc') {
								close();
							}
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
			onOpen={() => setLocalHypothesis(hypothesis)}
			onClose={() => {
				if (hypothesis !== localHypothesis) {
					hypothesisField.setValue(localHypothesis);
				}
			}}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Pill
						ref={setRefElement}
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						type={hasError(hypothesisField) ? 'error' : 'default'}
						withLeftIcon="description"
						disabled={disabled}
						style={{ maxWidth: '400px' }}
						onClick={disabled ? undefined : () => setOpen(!isOpen)}
					>
						{hypothesis || 'No Hypothesis'}
					</Pill>
				);
			}}
		</Dropdown>
	);
}
