/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ButtonRunIconStop, RouterLink, RouterPagination, Spinner, Stack, StateBadge, Tooltip } from 'components';
import { Colors, ErrorMessage, Flex, Grid, Icon, presets, Spacings, Text } from '@steadybit/ui-components-lib';
import React, { CSSProperties, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { ExperimentExecutionSummaryVO, ExperimentExecutionVO } from 'ui-api';
import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { PageLocation, usePage } from 'utils/hooks/usePage';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import Skeletons from 'components/Skeleton/Skeletons';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { IconDot } from 'components/icons';
import StateSelect from 'hocs/StateSelect';
import UserSelect from 'hocs/UserSelect';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'url/hooks';
import Sidebar from 'targets/Sidebar';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { ampli } from 'ampli';

import ExperimentVariableOverrideIndicator from './ExperimentVariableOverrideIndicator';
import { ExperimentExecutionSubHeader } from './header/experimentExecutionSubHeader';
import { getColorForState, getIconForState } from './utils';
import './experimentExecutionWithSideList.css';

export const ExperimentExecutionWithSideList: React.FC<{
	experimentExecution?: DataStreamResult<ExperimentExecutionVO | undefined>;
	experimentExecutionSection?: string;
	experimentExecutionId: number;
	experimentKey: string;
	children: ReactNode;
}> = ({ experimentKey, experimentExecutionId, experimentExecution, experimentExecutionSection, children }) => {
	const isLoading = !experimentExecution || experimentExecutionId !== experimentExecution.value?.id;
	const hasErrors = Boolean(experimentExecution?.error);
	const notFound = Boolean((experimentExecution?.error as AxiosError)?.response?.status === 404);

	return (
		<Flex
			direction="horizontal"
			style={{
				overflow: 'hidden',
				width: '100%',
				height: '100%',
			}}
		>
			<Sidebar
				className="exec-sidebar"
				title="Recent Executions"
				widthExpanded={300}
				animated={false}
				px="small"
				sx={{
					position: 'relative',
					overflow: 'hidden',
					borderColor: Colors.neutral400,
				}}
			>
				{(collapsed) => (
					<RecentExecutionsPageResolver
						experimentExecutionId={experimentExecutionId}
						experimentKey={experimentKey}
						collapsed={collapsed}
					/>
				)}
			</Sidebar>

			{hasErrors ? (
				<ErrorScreen notFound={notFound} experimentExecutionId={experimentExecutionId} />
			) : !isLoading ? (
				<Flex style={{ width: '100%', height: '100%' }}>
					<ExperimentExecutionSubHeader
						experimentExecution={experimentExecution.value}
						experimentExecutionSection={experimentExecutionSection}
					/>
					<Flex style={{ width: '100%', height: '100%', overflowY: 'auto' }}>{children}</Flex>
				</Flex>
			) : (
				<LoadingScreen />
			)}
		</Flex>
	);
};

function LoadingScreen(): ReactElement {
	const applicationHeaderHeight = useApplicationHeaderHeight();
	const height = `calc(100vh - ${applicationHeaderHeight + 80}px)`;

	return (
		<Stack justifyContent="center" alignItems="center" height={height} width="100%">
			<Spinner variant="xLarge" color="neutral300" />
		</Stack>
	);
}

interface ErrorScreenProps {
	experimentExecutionId: number;
	notFound: boolean;
}
function ErrorScreen({ experimentExecutionId, notFound }: ErrorScreenProps): ReactElement {
	const applicationHeaderHeight = useApplicationHeaderHeight();
	const height = `calc(100vh - ${applicationHeaderHeight + 80}px)`;

	return (
		<Stack justifyContent="center" alignItems="center" height={height} width="100%">
			<Text type="large" neutral600>
				{notFound
					? `Experiment run #${experimentExecutionId} was not found.`
					: `Could not load the experiment run #${experimentExecutionId}.`}
			</Text>
		</Stack>
	);
}

interface RecentExecutionsProps {
	experimentExecutionId: number;
	experimentKey: string;
	collapsed: boolean;
}

function RecentExecutionsPageResolver({
	experimentExecutionId,
	experimentKey,
	collapsed,
}: RecentExecutionsProps): ReactElement {
	const page = usePage('/executions', { page: 0, size: 15 });

	const [{ page: pageFromUrl }, , updateUrlState] = useUrlState([
		{
			pathSegment: '/executions',
			name: 'page',
			defaultValue: undefined,
		},
	]);
	const [experimentExecutionIds] = useAsyncState(() => {
		const newPage = page.withPage(0).withSize(10_000_000);
		return Services.experiments.fetchExperimentRunIds(experimentKey, newPage.pageParams, newPage.criteria);
	}, [page.criteria.toString(), experimentKey]);
	useEffect(() => {
		if (!experimentExecutionIds.value) {
			return;
		}

		const indexOfCurrentExecution = experimentExecutionIds.value.indexOf(experimentExecutionId);
		const pageOfCurrentExecution = Math.floor(indexOfCurrentExecution / page.pageParams.size);

		if (pageOfCurrentExecution !== pageFromUrl) {
			updateUrlState({ page: pageOfCurrentExecution });
		}
	}, [experimentExecutionId, experimentExecutionIds.value?.length]);

	if (pageFromUrl === undefined) {
		return (
			<Flex justify="center" style={{ marginTop: Spacings.small }}>
				<Skeletons height={24} widths={['130px', '130px']} />
			</Flex>
		);
	}

	return (
		<Flex
			style={{
				width: '100%',
				height: '100%',
				maxHeight: '100%',
				paddingTop: collapsed ? '12px' : '0',
			}}
		>
			{!collapsed && <ExperimentRunFilters page={page} />}
			<RecentExecutions
				experimentExecutionId={experimentExecutionId}
				experimentKey={experimentKey}
				collapsed={collapsed}
			/>
		</Flex>
	);
}

function RecentExecutions({ experimentExecutionId, experimentKey, collapsed }: RecentExecutionsProps): ReactElement {
	const page = usePage('/executions', { page: 0, size: 15 });

	const [experimentExecutions, fetch] = useAsyncState(
		() => Services.experiments.fetchExperimentRuns(page.criteria, page.pageParams, experimentKey),
		[page, experimentKey],
	);
	const environmentsResult = usePromise(async () => {
		const usedEnvironmentIds =
			experimentExecutions.value?.content.map((run) => run.environmentId).filter(Boolean) || [];
		if (usedEnvironmentIds.length === 0) {
			return new Map();
		}
		return await Services.environments.fetchEnvironmentsById(usedEnvironmentIds);
	}, [experimentExecutions]);
	const environments = environmentsResult.value;

	const [stop, handleStopButtonClick] = useAsyncFn((executionId) =>
		Services.experiments.cancelExperimentRun(executionId),
	);

	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(debouncedFetch, ['experiment.execution.requested'], debouncedFetch.cancel, [experimentKey]);

	const isLoading = experimentExecutions.loading && !experimentExecutions.value;
	if (isLoading) {
		const width = collapsed ? '59px' : '268px';
		return (
			<Flex spacing="xSmall">
				<Skeletons height={42} widths={[width]} />
				<Skeletons height={42} widths={[width]} />
				<Skeletons height={42} widths={[width]} />
			</Flex>
		);
	}

	const errorMessage = experimentExecutions.error ? experimentExecutions.error.message : null;
	if (errorMessage) {
		if (collapsed) {
			return (
				<Flex align="center" justify="center" style={{ width: '100%' }}>
					<Icon type="error" style={{ color: Colors.coral }} />
				</Flex>
			);
		}
		return <ErrorMessage type="small" withIcon>{`Error loading Executions: ${errorMessage}`}</ErrorMessage>;
	}

	const executions: ExperimentExecutionSummaryVO[] = experimentExecutions.value?.content || [];

	return (
		<Flex
			style={{
				width: 'calc(100% + 32px)',
				marginLeft: Spacings['-small'],
				height: '100%',
				maxHeight: '100%',
				overflow: 'hidden',
				pl: 'small',
			}}
		>
			<Flex
				spacing="xxSmall"
				style={{
					width: '100%',
					height: '100%',
					maxHeight: '100%',
					overflowX: 'hidden',
					overflowY: 'scroll',
				}}
			>
				{executions.map((execution, index) => (
					<ExperimentRunRow
						key={execution.id}
						experimentChanged={
							index !== 0 &&
							execution.experimentVersion !== experimentExecutions?.value?.content?.[index - 1].experimentVersion
						}
						stopExecution={
							execution.state === 'CREATED' && !stop.loading ? () => handleStopButtonClick(execution.id) : undefined
						}
						environmentName={environments ? environments.get(execution.environmentId)?.name || '' : ''}
						last={index + 1 === experimentExecutions?.value?.content.length}
						selected={execution.id === experimentExecutionId}
						experimentKey={experimentKey}
						collapsed={collapsed}
						execution={execution}
						first={index === 0}
					/>
				))}
			</Flex>

			{experimentExecutions.value?.totalPages && experimentExecutions.value?.totalPages > 1 && !collapsed && (
				<Flex
					align="center"
					style={{
						width: 'calc(100% + 16px)',
						borderTop: '1px solid ' + Colors.neutral200,
						marginLeft: Spacings['-small'],
						py: 'xSmall',
						backgroundColor: Colors.neutral000,
						marginTop: 'auto',
					}}
				>
					<RouterPagination
						size="small"
						activePage={page.pageParams.page}
						totalPages={experimentExecutions.value.totalPages}
						to={(i) => page.withPage(i).toString()}
					/>
				</Flex>
			)}
		</Flex>
	);
}

function ExperimentRunFilters({ page }: { page: PageLocation }): ReactElement {
	const history = useHistory();
	useEffect(() => {
		if (page.criteria.get('state') || page.criteria.get('createdBy')) {
			ampli.experimentExecutionFiltered({
				experiment_execution_user_filtered: page.criteria.get('createdBy') !== null,
				experiment_execution_state:
					page.criteria.get('state') === 'COMPLETED'
						? 'completed'
						: page.criteria.get('state') === 'FAILED'
							? 'failed'
							: page.criteria.get('state') === 'ERRORED'
								? 'errored'
								: page.criteria.get('state') === 'CANCELED'
									? 'canceled'
									: undefined,
			});
		}
	}, [page.criteria.get('state'), page.criteria.get('createdBy')]);

	return (
		<Grid cols="130px 130px" spacing="xSmall" style={{ py: 'small' }}>
			<Flex>
				<Text type="smallMedium">Filter by state</Text>
				<StateSelect
					value={page.criteria.get('state')}
					onChange={(state) => history.replace(page.withCriterion('state', state).toString())}
				/>
			</Flex>
			<Flex>
				<Text type="smallMedium">Filter by user</Text>
				<UserSelect
					value={page.criteria.get('createdBy')}
					onChange={(createdBy) => history.replace(page.withCriterion('createdBy', createdBy).toString())}
				/>
			</Flex>
		</Grid>
	);
}

interface ExperimentRunRowProps {
	execution: ExperimentExecutionSummaryVO;
	experimentChanged: boolean;
	environmentName: string;
	experimentKey: string;
	collapsed: boolean;
	selected: boolean;
	first: boolean;
	last: boolean;
	stopExecution?: () => void;
}
function ExperimentRunRow({
	experimentChanged,
	environmentName,
	experimentKey,
	collapsed,
	execution,
	selected,
	first,
	last,
	stopExecution,
}: ExperimentRunRowProps): ReactElement {
	const experimentRunResult = Services.experiments.useExecution$(execution.id, execution.state !== 'RUNNING');
	const experimentRun = experimentRunResult.value || execution;

	const isAnyOverrideDefined =
		execution.variables &&
		Object.values(execution.variables).some((v) => v.origin === 'EXECUTION' || v.origin === 'SCHEDULE');

	const IconComponent = getIconForState(execution.state);
	const indicator =
		experimentRun.state === 'RUNNING' ? (
			<StopStateBadge execution={experimentRunResult.value} />
		) : (
			<Tooltip
				color="light"
				content={
					<Flex spacing="xSmall">
						<Flex
							direction="horizontal"
							align="center"
							spacing="xxSmall"
							style={{ color: getColorForState(execution.state) }}
						>
							<IconComponent type="small" />
							<Text type="smallStrong" style={{ textTransform: 'capitalize' }}>
								{execution.state.toLocaleLowerCase()}
							</Text>
						</Flex>
						<presets.pill.Tag small>{environmentName}</presets.pill.Tag>
						<Text type="xSmallMedium">{formatDateWithTime(experimentRun.requested)}</Text>
					</Flex>
				}
			>
				<div>
					<StateBadge
						as="iconMedium"
						value={experimentRun.state}
						onClick={experimentRun.state === 'CREATED' ? stopExecution : undefined}
					/>
				</div>
			</Tooltip>
		);

	if (collapsed) {
		return (
			<RouterLink
				to={`/experiments/edit/${experimentKey}/executions/${experimentRun.id}/attack`}
				sx={{ width: '100%' }}
			>
				<ListItemWrapper
					collapsed
					selected={selected}
					isFirst={first}
					isLast={last}
					onClick={experimentChanged ? undefined : () => {}}
				>
					{indicator}
				</ListItemWrapper>
			</RouterLink>
		);
	}

	return (
		<>
			{experimentChanged && (
				<ListItemWrapper selected={false} isFirst={first}>
					<IconDot color="neutral400" height={6} width={20} />
					<Text type="small" neutral600>
						Experiment design changed
					</Text>
				</ListItemWrapper>
			)}
			<RouterLink
				to={`/experiments/edit/${experimentKey}/executions/${experimentRun.id}/attack`}
				sx={{
					color: 'inherit',
					width: '100%',
					':hover': {
						textDecoration: 'none',
					},
				}}
			>
				<ListItemWrapper selected={selected} isFirst={first && !experimentChanged} isLast={last} onClick={() => {}}>
					{indicator}
					<Flex>
						<Flex direction="horizontal" align="center" spacing="xSmall">
							<Text type="smallStrong" neutral700>
								#{experimentRun.id}
							</Text>
							<Tooltip content={environmentName} onlyShowOnEllipsis>
								<div>
									<presets.pill.Tag small>{environmentName}</presets.pill.Tag>
								</div>
							</Tooltip>
							{isAnyOverrideDefined && <ExperimentVariableOverrideIndicator />}
						</Flex>
						<Text type="small">{formatDateWithTime(experimentRun.requested)}</Text>
					</Flex>
				</ListItemWrapper>
			</RouterLink>
		</>
	);
}

interface ButtonRunIconStopProps {
	execution: ExperimentExecutionVO | undefined;
}

function StopStateBadge({ execution }: ButtonRunIconStopProps): ReactElement {
	return (
		<Flex
			style={{
				color: Colors.slate,
				borderRadius: 'large',
				padding: '3px',
				margin: '-3px',
				onHover: {
					backgroundColor: Colors.coral,
					color: Colors.neutral000,
				},
			}}
			onClick={execution ? async () => Services.experiments.cancelExperimentRun(execution.id) : () => {}}
		>
			<ButtonRunIconStop started={execution?.started} estimatedEnd={execution?.estimatedEnd} />
		</Flex>
	);
}

interface ListItemWrapperProps {
	collapsed?: boolean;
	selected: boolean;
	isFirst?: boolean;
	isLast?: boolean;

	children: ReactNode;
	onClick?: () => void;
}

function ListItemWrapper({
	collapsed = false,
	isFirst,
	isLast,
	selected,
	children,
	onClick,
}: ListItemWrapperProps): ReactElement {
	const commonIndicatorStyle: CSSProperties = {
		position: 'absolute',
		left: collapsed ? '29px' : '17px',
		width: '2px',
		backgroundColor: Colors.neutral200,
	};
	return (
		<Flex
			direction="horizontal"
			align="center"
			justify={collapsed ? 'center' : 'start'}
			spacing="small"
			style={{
				position: 'relative',
				width: '100%',
				height: '64px',
				px: 'xSmall',
				py: 'small',
				borderRadius: 'xSmall',
				backgroundColor: selected ? Colors.purple100 : Colors.neutral000,
				onHover: onClick ? { backgroundColor: Colors.purple100 } : undefined,
			}}
			onClick={onClick}
		>
			{children}

			{!isFirst && (
				<div
					style={{
						...commonIndicatorStyle,
						top: 0,
						height: '20px',
					}}
				/>
			)}
			{!isLast && (
				<div
					style={{
						...commonIndicatorStyle,
						bottom: '-4px',
						height: '20px',
					}}
				/>
			)}
		</Flex>
	);
}
