/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { getGlobal } from 'utils/localStorage';
const debuggingLines: string[] = [];

declare global {
	interface Window {
		getSteadybitDebugInfo: () => string;
	}
}

if (getGlobal('steadybitDebugEnabled') === 'true') {
	window.getSteadybitDebugInfo = () => {
		return debuggingLines.join('\n');
	};
}

let logRendered = false;
export function addDebuggingLine(line: string): void {
	if (getGlobal('steadybitDebugEnabled') !== 'true') {
		return;
	}

	if (debuggingLines.length < 200) {
		debuggingLines.push(`${Date.now()};${line}`);
	} else if (!logRendered) {
		console.debug('Debugging lines limit reached');
		logRendered = true;
	}
}
