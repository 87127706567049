/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Box, Colors, Dropdown, presets, Spacings } from '@steadybit/ui-components-lib';
import Calendar from 'components/Calendar/Calendar';
import { formatDate } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { days } from 'utils/time';

interface TimesProps {
	selectedTime: string | [number, number] | undefined;
	selectItem: (time: string | [number, number] | undefined) => void;
}

export default function Times({ selectedTime, selectItem }: TimesProps): ReactElement {
	const items = [
		{ id: 'lastDay', label: 'Past 1 Day', isSelected: selectedTime === 'lastDay' },
		{ id: 'lastWeek', label: 'Past 1 Week', isSelected: selectedTime === 'lastWeek' },
		{ id: 'lastMonth', label: 'Past 1 Month', isSelected: selectedTime === 'lastMonth' },
		{ id: 'allTime', label: 'All Time', isSelected: selectedTime === undefined },
		{ id: 'custom', label: 'Custom', isSelected: Array.isArray(selectedTime) },
	];
	return (
		<Dropdown
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame minWidth={width}>
					<presets.dropdown.SingleChoiceList
						withKeyboardArrowSupport
						items={items}
						onSelect={(id) => {
							if (id === 'custom') {
								const now = new Date().getTime();
								selectItem([now - days(1).getMillis(), now]);
							} else {
								selectItem(id === 'allTime' ? undefined : id);
								close();
							}
						}}
					/>
					{Array.isArray(selectedTime) && (
						<CustomTimeRange time={Array.isArray(selectedTime) ? selectedTime : undefined} onSelect={selectItem} />
					)}
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => (
				<presets.dropdown.ChoiceButton
					ref={setRefElement}
					isOpen={isOpen}
					placeholder=""
					style={{ width: '100%' }}
					onClick={() => setOpen(!isOpen)}
				>
					{getTimeLabel(selectedTime)}
				</presets.dropdown.ChoiceButton>
			)}
		</Dropdown>
	);
}
interface CustomTimeRangeProps {
	time: [number, number] | undefined;
	onSelect: (timeRange: [number, number]) => void;
}
function CustomTimeRange({ time, onSelect }: CustomTimeRangeProps): ReactElement {
	const expanded = !!time;

	return (
		<Box
			style={{
				p: 'xxSmall',
				margin: Spacings.xxSmall,
				marginTop: Spacings['-xxSmall'],
				backgroundColor: Colors.slateMidLight20p,
			}}
		>
			{expanded && (
				<Box
					style={{
						py: 'small',
						px: 'medium',
						borderRadius: 'xSmall',
						border: '1px solid ' + theme.colors.neutral300,
						backgroundColor: theme.colors.neutral000,
					}}
				>
					<Calendar
						value={time ? [new Date(time[0]), new Date(time[1])] : undefined}
						range
						onChange={(e) => {
							if (Array.isArray(e) && e.length === 2) {
								const from = e[0] as Date;
								const to = e[1] as Date;
								onSelect([from.getTime(), to.getTime()]);
							}
						}}
					/>
				</Box>
			)}
		</Box>
	);
}

function getTimeLabel(time: string | [number, number] | undefined): string {
	if (!time) {
		return 'All Time';
	}
	if (Array.isArray(time)) {
		return `${formatDate(new Date(time[0]))} - ${formatDate(new Date(time[1]))}`;
	}

	if (time === 'lastMonth') {
		return 'Past 1 Month';
	}
	if (time === 'lastWeek') {
		return 'Past 1 Week';
	}
	if (time === 'lastDay') {
		return 'Past 1 Day';
	}
	return 'Custom';
}
