/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DidNotSavePrompt } from 'pages/experiments/wizard/components/DidNotSavePrompt';
import { saveTemplate } from 'templates/components/formUtils';
import { useStore, useTouched } from 'DataStore/DataStore';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';

import { TemplateFormValues } from './types';

export default function UnsavedChangesPrompt(): ReactElement {
	const { touched } = useTouched();
	const { data, resetValues, errors } = useStore<TemplateFormValues>();
	const history = useHistory();
	const dirty = Object.keys(touched).length > 0;
	const hasErrors = Object.keys(errors).length > 0;
	const isNewTemplateFromFile = !!data.isNewTemplateFromFile;

	return (
		<DidNotSavePrompt
			saveDisabledBecauseOf={hasErrors ? 'Fix all validation errors to save this template' : undefined}
			when={dirty || isNewTemplateFromFile}
			entityTitle="template"
			block={({ pathname }) => {
				return !pathname.startsWith('/settings/templates/design');
			}}
			onSaveCallback={async () => {
				const savedTemplate = await saveTemplate(data);
				if (savedTemplate) {
					resetValues({ ...savedTemplate, isNewTemplateFromFile: false });
					history.replace('/settings/templates');
				}
			}}
		/>
	);
}
