/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { Link } from 'components';

export function NoActionsNotification(): ReactElement {
	return (
		<Wrapper>
			<Text type="mediumStrong" neutral700>
				No actions found
			</Text>
			<Text type="small" neutral700 style={{ textAlign: 'center' }}>
				Please{' '}
				<span style={{ fontWeight: '600' }}>
					<Link href="https://docs.steadybit.com/quick-start/set-up-agents" external>
						install an agent
					</Link>
				</span>{' '}
				and an extension to have actions available for your experiment.
			</Text>
		</Wrapper>
	);
}

interface NoTemplatesNotificationProps {
	isSearchDefined: boolean;
	hadNeverAnAgent: boolean;
}

export function NoTemplatesNotification({
	isSearchDefined,
	hadNeverAnAgent,
}: NoTemplatesNotificationProps): ReactElement {
	if (isSearchDefined) {
		return (
			<Wrapper>
				<Text type="mediumStrong" neutral700>
					No templates matched your query
				</Text>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<Text type="mediumStrong" neutral700>
				No templates found
			</Text>
			<>
				{hadNeverAnAgent && (
					<Text type="small" neutral700 style={{ textAlign: 'center' }}>
						Please{' '}
						<span style={{ fontWeight: '600' }}>
							<Link href="https://docs.steadybit.com/quick-start/set-up-agents" external>
								install an agent
							</Link>
						</span>{' '}
						and matching extensions to have templates available for your experiment.
					</Text>
				)}
			</>
		</Wrapper>
	);
}

function Wrapper({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
	return (
		<Flex align="center" spacing="xSmall" style={{ width: '100%', p: 'medium' }}>
			{children}
		</Flex>
	);
}
