/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps, Heading, Help } from 'components';
import { Flex, Spacings } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface ExperimentRunCardProps extends Omit<ContainerProps, 'title'> {
	additionalHeaderItems?: React.ReactNode;
	title: string | React.ReactNode;
	help?: React.ReactNode;
}

export default function ExperimentRunCard({
	additionalHeaderItems,
	children,
	sx = {},
	title,
	help,
	...props
}: ExperimentRunCardProps): ReactElement {
	return (
		<Container
			display="flex"
			sx={{
				flexDirection: 'column',
				bg: 'neutral000',
				maxHeight: 352,
				border: '1px solid',
				borderColor: 'neutral400',
				borderRadius: '2px 2px 0px 0px',

				...sx,
			}}
			{...props}
		>
			<Container
				display="flex"
				alignItems="center"
				bg="neutral600"
				color="neutral000"
				sx={{
					minHeight: '36px',
					flexDirection: 'row',
				}}
			>
				<Heading variant={'xSmall'} sx={{ py: 'xSmall', px: 'small', mr: 'auto' }}>
					{title}
				</Heading>

				{additionalHeaderItems && (
					<Flex direction="horizontal" align="center" style={{ marginRight: Spacings.small }}>
						{additionalHeaderItems}
					</Flex>
				)}
				{help && (
					<Flex
						direction="horizontal"
						align="center"
						style={{
							marginTop: Spacings.xxSmall,
							marginRight: Spacings.small,
						}}
					>
						<Help variant={'medium'}>{help}</Help>
					</Flex>
				)}
			</Container>

			{children}
		</Container>
	);
}
