/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Spinner,
	Stack,
	Text,
} from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { PreflightActionIntegrationVO } from 'ui-api';
import { Form, Formik, FormikHelpers } from 'formik';
import { ValidationError } from 'utils/error';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import React, { ReactElement } from 'react';
import { isUserAdmin } from 'utils/user';
import { ensure } from 'utils/ensure';

import {
	PreflightActionIntegrationForm,
	PreflightActionIntegrationFormValues,
} from './components/preflightActionIntegrationForm';

interface EditPreflightActionIntegrationProps {
	id: string;
	labelTitle?: string;
	onClose?: () => void;
}

function EditPreflightActionIntegration({
	id,
	onClose,
	labelTitle,
}: EditPreflightActionIntegrationProps): ReactElement {
	const [submitError, setSubmitError] = React.useState<Error | null>();
	const [preflightActionIntegration] = useAsyncState(
		() => Services.preflightActionIntegrationsApi.fetchPreflightActionIntegration(id),
		[id],
	);

	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const handleSubmit = async (
		values: PreflightActionIntegrationFormValues,
		form: FormikHelpers<PreflightActionIntegrationFormValues>,
	): Promise<void> => {
		try {
			setSubmitError(null);
			await Services.preflightActionIntegrationsApi.updatePreflightActionIntegration(id, {
				...values,
				version: ensure(preflightActionIntegration.value).version,
			});
			Snackbar.dark(`'${values.name}' saved.`, { toastId: 'preflightActionIntegration-saved' });
			onClose?.();
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	const toPreflightActionIntegrationFormValue = (
		preflightActionIntegration: PreflightActionIntegrationVO,
	): PreflightActionIntegrationFormValues => {
		const formValues: PreflightActionIntegrationFormValues = {
			...preflightActionIntegration,
			team: preflightActionIntegration.teamId,
		};
		return formValues;
	};

	return (
		<>
			{preflightActionIntegration.loading ? (
				<Stack>
					<Spinner variant="large" color={'neutral500'} mr={'auto'} />
				</Stack>
			) : null}
			{!preflightActionIntegration.loading && preflightActionIntegration.value ? (
				<Formik
					initialValues={toPreflightActionIntegrationFormValue(preflightActionIntegration.value)}
					onSubmit={handleSubmit}
				>
					{({ isSubmitting }) => (
						<Form noValidate>
							<ModalV2 withFooter>
								<ModalHeaderV2
									title={
										labelTitle
											? labelTitle
											: `Edit PreflightActionIntegration ${preflightActionIntegration.value?.name}`
									}
									onClose={onClose}
								/>
								<ModalContentV2>
									<PreflightActionIntegrationForm
										disabled={
											!preflightActionIntegration.value?._actions.includes('edit') ||
											isSubmitting ||
											(isAdmin ? [] : ['type', 'scope'])
										}
									/>
								</ModalContentV2>
								<ModalFooterV2>
									<>
										{submitError && <Container width="100%">{submitError.toString()}</Container>}
										{submitError && (
											<Container width="100%">
												<Text variant="mediumStrong" color="coral">
													{submitError.toString()}
												</Text>
											</Container>
										)}
										<Button id={'save'} type={'submit'} loading={isSubmitting}>
											Save
										</Button>
									</>
								</ModalFooterV2>
							</ModalV2>
						</Form>
					)}
				</Formik>
			) : null}

			{preflightActionIntegration.error ? (
				<Text color={'coral'}>{preflightActionIntegration.error.toString()}</Text>
			) : null}
			{submitError ? <Text color={'coral'}>{submitError.toString()}</Text> : null}
		</>
	);
}

export default EditPreflightActionIntegration;
