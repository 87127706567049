/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useContext, createContext } from 'react';
import { ActionVO } from 'ui-api';

type ActionsContextType = {
	allActions: ActionVO[];
	actions: ActionVO[];
	loading: boolean;
};

export const ActionsContext = createContext<ActionsContextType>({ actions: [], allActions: [], loading: true });

const useActions = (): ActionsContextType => {
	return useContext(ActionsContext);
};
export default useActions;
