/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Banner, Heading, ModalContentV2, ModalHeaderV2, ModalV2, Stack, Text } from 'components';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { lazy, ReactElement, Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';

import { newExperimentTagsParam, UrlState } from './urlParams';
import { useUrlState } from '../../../url/useUrlState';
import FromTemplate from './FromTemplate';
import FromScratch from './FromScratch';
import FromUpload from './FromUpload';

const TeamSwitch = lazy(() => import('./images/TeamSwitch'));

interface CreateExperimentModalProps {
	onClose: () => void;
}

export default function CreateExperimentModal({ onClose }: CreateExperimentModalProps): ReactElement {
	const [uploadError, setUploadError] = useState<string | null>(null);
	const [{ newExperimentTags }] = useUrlState<UrlState>([newExperimentTagsParam]);
	const permissions = useGlobalPermissions();

	if (!permissions.experiments.canCreate.allowed) {
		return (
			<ModalV2 width="1000px" slick centered>
				<ModalHeaderV2 title="" onClose={onClose} />
				<ModalContentV2>
					<Stack size="medium" alignItems="center" mt="small">
						<Heading variant="xLarge">Sorry, you’re not part of this Team</Heading>

						<Stack alignItems="center" size="none">
							<Text as="span" variant="large" color="neutral600">
								When you are not part of a Team, you can’t create an Experiment or perform any other action.
							</Text>
							<Text as="span" variant="large" color="neutral600">
								If you want to create a new Experiment, switch to a Team you’re a member of using the Team switch menu.
							</Text>
						</Stack>
						<div style={{ marginTop: '48px' }}>
							<Suspense fallback={<div style={{ width: '389px', height: '340px' }} />}>
								<TeamSwitch />
							</Suspense>
						</div>
					</Stack>
				</ModalContentV2>
			</ModalV2>
		);
	}

	return (
		<>
			<Helmet>
				<title>Experiments / Create</title>
			</Helmet>
			<ModalV2 width="800px" slick centered>
				<ModalHeaderV2 title="Create New Experiment" onClose={onClose} />
				<ModalContentV2>
					<Stack>
						<Stack direction="horizontal" size="large" justifyContent="center" mt="large">
							<FromScratch newExperimentTags={newExperimentTags} />
							<FromTemplate newExperimentTags={newExperimentTags} />
							<FromUpload newExperimentTags={newExperimentTags} setUploadError={setUploadError} />
						</Stack>
						{uploadError && (
							<Banner
								variant="danger"
								title=""
								description={<span style={{ whiteSpace: 'normal' }}>{uploadError}</span>}
							/>
						)}
					</Stack>
				</ModalContentV2>
			</ModalV2>
		</>
	);
}
