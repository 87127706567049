/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { IconChevronDoubleLeft, IconChevronDoubleRight } from 'components/icons';
import { Colors, Flex, Spacings } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Button, Container, Heading, StyleProp } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';

interface SidebarProps {
	backgroundColor?: keyof typeof Colors;
	title: string | ReactNode;
	px?: 'small' | 'medium';
	widthCollapsed?: number;
	widthExpanded?: number;
	className?: string;
	animated?: boolean;
	sx?: StyleProp;
	children: (collapsed: boolean, setCollapsed: (ex: boolean) => void) => ReactNode | ReactNode[];
}

export default function Sidebar({
	backgroundColor = 'neutral000',
	widthCollapsed = 92,
	widthExpanded = 380,
	animated = true,
	px = 'medium',
	className,
	title,
	sx = {},
	children,
}: SidebarProps): ReactElement {
	const [collapsed, setCollapsed] = useState(window.document.body.clientWidth < 1440);
	const [debouncedCollapsed, setDebouncedCollapsed] = useState(collapsed);

	useEffect(() => {
		if (collapsed || !animated) {
			setDebouncedCollapsed(collapsed);
			return;
		}

		const timeout = setTimeout(() => {
			setDebouncedCollapsed(collapsed);
		}, 200);
		return () => clearTimeout(timeout);
	}, [collapsed, animated]);

	return (
		<Container
			className={className}
			as="aside"
			sx={{
				width: collapsed ? widthCollapsed : widthExpanded,
				height: '100%',
				px,
				flexShrink: 0,
				overflow: 'hidden',
				transition: animated ? 'width 0.2s ease-in-out, max-width 0.2s ease-in-out' : 'none',
				borderRight: '1px solid',
				borderColor: 'neutral200',
				backgroundColor: Colors[backgroundColor],
				...sx,
			}}
		>
			<Flex
				direction="horizontal"
				justify="spread"
				align="center"
				style={{ height: '52px', paddingTop: Spacings.medium }}
			>
				<Heading as="h1" variant="large" m="0" sx={{ ...textEllipsis }}>
					{collapsed ? <>&nbsp;</> : title}
				</Heading>
				<Button
					variant={'chromeless'}
					onClick={(e) => {
						e.stopPropagation();
						setCollapsed(!collapsed);
					}}
				>
					{collapsed ? (
						<IconChevronDoubleRight style={{ minWidth: 24, minHeight: 24 }} />
					) : (
						<IconChevronDoubleLeft style={{ minWidth: 24, minHeight: 24 }} />
					)}
				</Button>
			</Flex>

			<div style={{ height: 'calc(100% - 52px)' }}>
				{children(animated ? debouncedCollapsed : collapsed, setCollapsed)}
			</div>
		</Container>
	);
}
