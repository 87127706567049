/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ExperimentScheduleSummaryVO, ExperimentScheduleVO } from 'ui-api';
import { IconType } from '@steadybit/ui-components-lib';
import { compare } from 'utils/dateFns';

export function getSchedulesIcon(schedules: ExperimentScheduleVO[]): IconType {
	return schedules.every((s) => !s.cron)
		? 'calendar-once'
		: schedules.every((s) => !!s.cron)
			? 'calendar-recurrent'
			: 'calendar';
}

export function compareNextExecution(
	a: ExperimentScheduleSummaryVO,
	b: ExperimentScheduleSummaryVO,
	direction: 'asc' | 'desc',
): number {
	// disabled schedules should be at the end
	if (a.enabled !== b.enabled) {
		return compareEnabled(a.enabled, b.enabled);
	}

	if (direction === 'asc') {
		const c = a;
		a = b;
		b = c;
	}

	if (a.nextExecution && b.nextExecution) {
		return compare(new Date(a.nextExecution), new Date(b.nextExecution));
	}
	if (a.nextExecution) {
		return -1;
	}
	return 1;
}

export function compareEnabled(enabledA: boolean, enabledB: boolean): number {
	return enabledA ? -1 : enabledB ? 1 : 0;
}

export function sortByExperimentKey(experimentKeyA: string, experimentKeyB: string): number {
	let numberPartA = Number(experimentKeyA.split('-')[1]);
	numberPartA = Number.isNaN(numberPartA) ? 0 : numberPartA;
	let numberPartB = Number(experimentKeyB.split('-')[1]);
	numberPartB = Number.isNaN(numberPartB) ? 0 : numberPartB;
	return numberPartA - numberPartB;
}
