/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	getBGColorForState,
	getColorForState,
	getDarkerColorForState,
	getIconForState,
} from 'pages/experiments/components/utils';
import { Container, Text } from 'components';
import { startCase, toLower } from 'lodash';
import { ReactElement } from 'react';

type StateBadgeLightProps = {
	as: 'iconMedium' | 'tag' | 'state';
	value: string;
	onClick?: () => void;
};

export default function StateBadge({ as, value, onClick }: StateBadgeLightProps): ReactElement {
	const Icon = getIconForState(value);

	if (as === 'iconMedium') {
		return (
			<Icon
				variant="small"
				minWidth={20}
				minHeight={20}
				maxWidth={20}
				maxHeight={20}
				sx={{ color: getColorForState(value) }}
			/>
		);
	}

	if (as === 'state') {
		return (
			<Container
				onClick={onClick}
				sx={{
					display: 'flex',
					alignItems: 'center',

					color: getColorForState(value),
					cursor: onClick ? 'pointer' : 'default',
				}}
			>
				<Icon
					variant="small"
					minWidth={16}
					minHeight={16}
					maxWidth={16}
					maxHeight={16}
					sx={{ color: getColorForState(value), mr: 'xxSmall' }}
				/>
				<Text data-cy="state-badge" variant="smallMedium">
					{startCase(toLower(value))}
				</Text>
			</Container>
		);
	}

	return (
		<Container
			onClick={onClick}
			sx={{
				display: 'flex',
				alignItems: 'center',
				p: '4px 12px',

				borderRadius: '15px',
				backgroundColor: getBGColorForState(value),
				color: getDarkerColorForState(value),
				cursor: onClick ? 'pointer' : 'default',
			}}
		>
			<Icon
				variant="small"
				minWidth={16}
				minHeight={16}
				maxWidth={16}
				maxHeight={16}
				sx={{ color: getDarkerColorForState(value), mr: 'xxSmall' }}
			/>
			<Text data-cy="state-badge" variant="smallMedium">
				{startCase(toLower(value))}
			</Text>
		</Container>
	);
}
