/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Grid, Icon, IconType, Pill, Spacings, Text } from '@steadybit/ui-components-lib';
import { Skeletons } from 'components';
import { ReactElement } from 'react';

export type FilterType = 'once' | 'recurrent' | null;

interface SchedulesFilterProps {
	numberOfRecurrentSchedules: number | undefined;
	numberOfOnceSchedules: number | undefined;
	activeFilter?: FilterType;
	setActiveFilter?: (filter: FilterType) => void;
}

export default function SchedulesFilter({
	numberOfRecurrentSchedules,
	numberOfOnceSchedules,
	activeFilter,
	setActiveFilter,
}: SchedulesFilterProps): ReactElement {
	return (
		<Grid cols="1fr 1fr" spacing="xSmall" style={{ width: '100%' }}>
			<Filter
				numItems={numberOfOnceSchedules}
				isActive={activeFilter === 'once'}
				icon="calendar-once"
				title="Once"
				onClick={() => setActiveFilter?.(activeFilter === 'once' ? null : 'once')}
			/>
			<Filter
				numItems={numberOfRecurrentSchedules}
				isActive={activeFilter === 'recurrent'}
				icon="calendar-recurrent"
				title="Recurrent"
				onClick={() => setActiveFilter?.(activeFilter === 'recurrent' ? null : 'recurrent')}
			/>
		</Grid>
	);
}

interface FilterProps {
	numItems: number | undefined;
	isActive: boolean;
	icon: IconType;
	title: string;
	onClick: () => void;
}

function Filter({ isActive, numItems, title, icon, onClick }: FilterProps): ReactElement {
	const isLoading = numItems === undefined;
	return (
		<Flex
			direction="horizontal"
			spacing="xxSmall"
			justify="center"
			align="center"
			style={{
				height: '40px',
				px: 'small',
				color: isActive ? Colors.slate : Colors.neutral700,
				backgroundColor: Colors.neutral200,
				borderRadius: 'xxSmall',

				outline: isActive ? '2px solid ' + Colors.slate : 'none',
				border: '1px solid',
				borderColor: isActive ? Colors.neutral000 : Colors.neutral200,

				onHover: {
					backgroundColor: Colors.neutral300,
					color: Colors.slate,
				},
			}}
			onClick={onClick}
		>
			<Icon type={icon} />
			<Text type="mediumStrong">{title}</Text>
			{isLoading ? (
				<Skeletons height={20} widths={[20]} />
			) : (
				<Pill
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						minWidth: '20px',
						height: '20px',
						paddingRight: Spacings.xSmall,
						paddingLeft: Spacings.xSmall,
						backgroundColor: Colors.cyan200,
						color: Colors.cyan800,
					}}
				>
					<Text type="smallStrong">{numItems}</Text>
				</Pill>
			)}
		</Flex>
	);
}
