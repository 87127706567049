/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ExperimentPreview from 'pages/templates/components/ExperimentPreview';
import ChunksWithPlaceholders from 'pages/templates/ChunksWithPlaceholders';
import { useStableErrorMap } from 'pages/experimentsV2/useFieldErrors';
import { TemplateError } from 'pages/templates/TemplateEditor/types';
import { ErrorMessage, Flex } from '@steadybit/ui-components-lib';
import { ActionVO, ExperimentLaneVO, OccuranceVO } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import Markdown from 'components/Markdown/Markdown';
import { useStoreField } from 'DataStore/DataStore';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { cloneDeep } from 'lodash';

import {
	isOccuranceActionPredicateVO,
	isOccuranceStepLabelVO,
	isOccuranceStepParameterVO,
	isOccuranceTemplateFieldVO,
} from '../types';
import { getUniqueTargetTypeIfPossible } from '../utils';
import OccurrenceValue from './OccurrenceValue';

interface TemplatePlaceholderProps {
	actionsResult: DataStreamResult<ActionVO[]>;
	placeholder: Placeholder;
}

export interface Placeholder {
	description: string;
	value: string;
	name: string;
	key: string;
}

export default function TemplatePlaceholder({ placeholder, actionsResult }: TemplatePlaceholderProps): ReactElement {
	const { value: placeholderValuesMap, setValue: setPlaceholderValuesMap } =
		useStoreField<Record<string, string>>('placeholderValuesMap');
	const { value: placeholdersMap } = useStoreField<Record<string, OccuranceVO[]>>('placeholdersMap');
	const { value: environmentId } = useStoreField<string>('environmentId');
	const { value: lanes } = useStoreField<ExperimentLaneVO[]>('lanes');

	const selectedPlaceholder = placeholdersMap[placeholder.key];

	const selectedStepIds = collectStepIds(selectedPlaceholder || []);

	const uniqueTargetType = getUniqueTargetTypeIfPossible(selectedStepIds, lanes);

	const stepIdToError = useStableErrorMap();

	const appearsInHypothesis = (selectedPlaceholder || []).some(
		(occurance) => isOccuranceTemplateFieldVO(occurance) && occurance.templateField === 'HYPOTHESIS',
	);

	const appearsInExperimentName = (selectedPlaceholder || []).some(
		(occurance) => isOccuranceTemplateFieldVO(occurance) && occurance.templateField === 'EXPERIMENT_NAME',
	);

	return (
		<Stack size="large" pb="xLarge">
			<Stack size="xSmall">
				<Markdown content={placeholder.description} />
				<OccurrenceValue
					occurances={selectedPlaceholder || []}
					environmentId={environmentId}
					targetType={uniqueTargetType}
					value={placeholder.value}
					setValue={(_value) => {
						const clone = cloneDeep(placeholderValuesMap);
						clone[placeholder.key] = _value;
						setPlaceholderValuesMap(clone);
					}}
				/>
			</Stack>
			{appearsInExperimentName && (
				<VariableTextfieldUsage
					originalFieldname="__originalExperimentName"
					placeholderName={placeholder.name}
					usageName="Experiment name"
					fieldName="experimentName"
					errorFieldName="name"
					maxChars={255}
				/>
			)}
			{appearsInHypothesis && (
				<VariableTextfieldUsage
					originalFieldname="__originalHypothesis"
					placeholderName={placeholder.name}
					errorFieldName="hypothesis"
					fieldName="hypothesis"
					usageName="hypothesis"
					maxChars={20_000}
				/>
			)}
			{selectedStepIds.size > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral800">
						<Text variant="smallStrong" color="neutral800" as={'span'}>
							{placeholder.name}
						</Text>
						&nbsp;is used in the following steps:
					</Text>

					<ExperimentPreview
						key={placeholder.key}
						actions={actionsResult.value || []}
						selectedStepIds={selectedStepIds}
						stepIdToError={stepIdToError}
						lanes={lanes}
					/>
				</Stack>
			)}
		</Stack>
	);
}

export function collectStepIds(occurances: OccuranceVO[]): Set<string> {
	const ids = new Set<string>();
	occurances.forEach((occurance) => {
		if (isOccuranceActionPredicateVO(occurance)) {
			ids.add(occurance.stepId);
		} else if (isOccuranceStepLabelVO(occurance)) {
			ids.add(occurance.stepId);
		} else if (isOccuranceStepParameterVO(occurance)) {
			ids.add(occurance.stepId);
		}
	});
	return ids;
}

interface VariableTextfieldUsageProps {
	originalFieldname: string;
	placeholderName: string;
	errorFieldName: string;
	fieldName: string;
	usageName: string;
	maxChars: number;
}

export function VariableTextfieldUsage({
	originalFieldname,
	placeholderName,
	errorFieldName,
	fieldName,
	usageName,
	maxChars,
}: VariableTextfieldUsageProps): ReactElement {
	const { value: originalValue } = useStoreField<string>(originalFieldname);
	const { errors } = useStoreField<string>(errorFieldName);
	const { value } = useStoreField<string>(fieldName);

	const error = errors as TemplateError | undefined;

	return (
		<Flex spacing="xSmall">
			<Flex direction="horizontal" wrap spacing="xxSmall">
				<Text as="span" variant="smallStrong" sx={{ minWidth: 'min-content' }}>
					{placeholderName}
				</Text>
				<Text as="span" variant="smallMedium" sx={{ minWidth: 'min-content' }}>
					is used in the {usageName}:
				</Text>{' '}
			</Flex>

			<Flex
				style={{
					background: theme.colors.neutral150,
					gap: '8px',
					px: 'small',
					py: 'xSmall',
					borderRadius: 'xxSmall',
					width: '100%',
					overflow: 'auto',
					minHeight: '20px',
				}}
			>
				<ChunksWithPlaceholders value={originalValue || value} />
			</Flex>

			{error && value.length >= maxChars && (
				<ErrorMessage
					type="small"
					level="warning"
					withIcon
				>{`The ${usageName} is too long (${value.length}/${maxChars}) and will be truncated.`}</ErrorMessage>
			)}
			{error && value.length === 0 && (
				<ErrorMessage type="small" level={error.level} withIcon>
					{error.message}
				</ErrorMessage>
			)}
		</Flex>
	);
}
