/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ServerExecutionMetricEvent, useExecutionMetrics } from 'services/executionMetricsStreams';
import { metrics as m, presets, LineChartTimeMetric } from '@steadybit/ui-components-lib';
import { ExperimentExecutionVO, MetricIdentityVO, MetricQueryVO } from 'ui-api';
import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import { ExperimentPlayerTimeStamp } from 'components/ExperimentPlayer/types';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { Container, Li, Stack, Text, Tooltip, Ul } from 'components';
import { DataStreamResult } from 'utils/hooks/stream/result';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { schemeCategory10 } from 'd3';
import { getHash } from 'utils/hash';
import { Heading } from 'theme-ui';

interface ExecutionMetricsProps {
	experimentExecution: ExperimentExecutionVO;
	position: ExperimentPlayerTimeStamp | null;
	metricQueries: MetricQueryVO[];
	duration: number;
	start: number;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
}

const MAX_METRICS_TO_RENDER = 5;

export default function ExecutionMetrics({
	experimentExecution,
	metricQueries,
	position,
	duration,
	start,
	onPositionSelect,
}: ExecutionMetricsProps): ReactElement {
	const [selectedMetricId, setSelectedMetricId] = useState<string | null>(null);

	const selecteMetricDefinition = metricQueries.find((q) => q.id === selectedMetricId);
	const metricsResult = useMetrics(experimentExecution.id, metricQueries);
	const allMetricValues = metricsResult.value || [];

	// Set the first metric as selected if none is selected
	useEffect(() => {
		if (selectedMetricId) {
			return;
		}

		const firstMetric = allMetricValues.find((m) => m.labels.steadybit_metric_query_id);
		if (firstMetric) {
			setSelectedMetricId(firstMetric.labels.steadybit_metric_query_id);
		}
	}, [selectedMetricId, metricsResult]);

	if (metricsResult.loading) {
		return (
			<Frame>
				<Container display="flex" justifyContent="center" py="xLarge">
					<LoadingIndicator variant="xxLarge" color="slate" />
				</Container>
			</Frame>
		);
	}
	if (allMetricValues.length === 0) {
		return (
			<Frame>
				<Container display="flex" justifyContent="center" py="xLarge" color="neutral500">
					There were no metrics collected
				</Container>
			</Frame>
		);
	}
	if (!selecteMetricDefinition) {
		return (
			<Frame>
				<Container display="flex" justifyContent="center" py="xLarge" color="neutral500">
					There is no metric selected
				</Container>
			</Frame>
		);
	}

	const options: Map<string, string> = new Map();
	allMetricValues.map((metricValue) => {
		const id = metricValue.labels.steadybit_metric_query_id;
		if (!options.has(id)) {
			const definition = metricQueries.find((q) => q.id === id);
			options.set(id, definition ? definition.label : metricValue.name || '');
		}
	});

	return (
		<Frame
			headerContent={
				<presets.dropdown.SingleChoiceButton
					items={Array.from(options.entries()).map(([id, label]) => ({ id, label }))}
					selectedId={selectedMetricId || undefined}
					placement="bottom-start"
					maxContentHeight="250px"
					size="small"
					onSelect={setSelectedMetricId}
					style={{ minWidth: '200px' }}
				>
					{selecteMetricDefinition.label}
				</presets.dropdown.SingleChoiceButton>
			}
		>
			<MetricPresenter
				selectedMetricId={selectedMetricId}
				metrics={allMetricValues.filter(({ labels }) => labels.steadybit_metric_query_id === selectedMetricId)}
				position={position}
				duration={duration}
				start={start}
				onPositionSelect={onPositionSelect}
			/>
		</Frame>
	);
}

interface DataPoint {
	parent: CombinedMetricData;
	value: number;
	id: string;
	ts: number;
}

interface CombinedMetricData {
	data: DataPoint[];
	color: string;
	id: string;
	renderDescription: () => ReactElement;
}

interface MetricPresenterProps {
	selectedMetricId: string | null;
	position: number | null;
	metrics: MetricGroup[];
	duration: number;
	start: number;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
}

function MetricPresenter({
	selectedMetricId,
	position,
	duration,
	metrics,
	start,
	onPositionSelect,
}: MetricPresenterProps): ReactElement {
	const [selectedMetricIds, setSelectedMetricIds] = useState<string[]>([]);

	useEffect(() => setSelectedMetricIds(metrics.slice(0, MAX_METRICS_TO_RENDER).map((m) => m.id)), [selectedMetricId]);

	if (metrics.length === 0) {
		return <MessageContainer>Please select metrics to show their values</MessageContainer>;
	}

	return (
		<Container>
			{selectedMetricIds.length === 0 ? (
				<MessageContainer>Please select metrics to show their values</MessageContainer>
			) : (
				<m.LineChart
					height={450}
					data={metrics
						.filter((m) => selectedMetricIds.includes(m.id))
						.map(({ color, values }) => {
							return values.map((d) => ({
								ts: d.ts,
								value: d.value,
								color,
								data: {
									renderDescription: () => getMetricDescription(d.parent),
								},
							}));
						})}
					start={start}
					end={start + duration}
					timeMarkers={position ? [position] : []}
					onPositionSelect={onPositionSelect}
					formatX={(v) => formatTime(new Date(v))}
					formatY={(v) => String(v)}
					renderTooltip={renderTooltip}
				/>
			)}
			<Stack size="xSmall" pl="xLarge" pr="small">
				{metrics.map((metric) => (
					<LegendItem
						key={metric.id}
						color={metric.color}
						deactivated={!selectedMetricIds.includes(metric.id)}
						disabled={!selectedMetricIds.includes(metric.id) && selectedMetricIds.length >= MAX_METRICS_TO_RENDER}
						onClick={() => {
							if (selectedMetricIds.includes(metric.id)) {
								setSelectedMetricIds(selectedMetricIds.filter((_id) => metric.id !== _id));
							} else {
								setSelectedMetricIds([...selectedMetricIds, metric.id]);
							}
						}}
					>
						{getMetricDescription(metric)}
					</LegendItem>
				))}
			</Stack>
		</Container>
	);
}

function getMetricDescription(metric: MetricIdentityVO): ReactElement {
	const keys = Object.keys(metric.labels);
	return (
		<>
			{'{ '}
			{keys.map((key, i) => (
				<Fragment key={key}>
					<Text variant="smallStrong" minWidth="fit-content">
						{key}
					</Text>
					<Text variant="small" mr="xxSmall" minWidth="fit-content">
						=&quot;{metric.labels[key]}&quot;{i < keys.length - 1 ? ',' : ''}
					</Text>
				</Fragment>
			))}
			{' }'}
		</>
	);
}

interface TooltipData extends LineChartTimeMetric {
	y: string;
	color: string;
	data: {
		renderDescription: () => ReactElement;
	};
}

function renderTooltip(dataPoints: TooltipData[]): ReactElement {
	return (
		<Ul
			sx={{
				px: 'xSmall',
				py: 'xSmall',
				backgroundColor: 'neutral000',
				border: '1px solid ' + theme.colors.neutral200,
			}}
		>
			{dataPoints.map(({ value, color, data }, i) => {
				return (
					<Li key={i} display="flex" alignItems="center" maxWidth={400}>
						<LegendItem color={color} shorten>
							{data.renderDescription()}
						</LegendItem>
						<Text variant="smallStrong" style={{ width: 'fit-content', marginRight: '12px' }}>
							{value}
						</Text>
					</Li>
				);
			})}
		</Ul>
	);
}

interface FrameProps {
	headerContent?: ReactNode;
	children: ReactNode;
}

function Frame({ headerContent, children }: FrameProps): ReactElement {
	return (
		<Stack
			size="none"
			sx={{
				borderRadius: '2px 2px 0px 0px',
				overflow: 'hidden',
				border: '1px solid',
				borderColor: 'neutral400',
			}}
		>
			<Stack direction="horizontal" alignItems="center" px="small" py="2px" backgroundColor="neutral600" minHeight={36}>
				<Heading variant="mediumStrong" color="neutral000">
					Metrics
				</Heading>
				{headerContent}
			</Stack>
			<Container p="small" backgroundColor="neutral000">
				{children}
			</Container>
		</Stack>
	);
}

interface LegendItemProps {
	deactivated?: boolean;
	children: ReactNode;
	disabled?: boolean;
	shorten?: boolean;
	color: string;
	onClick?: () => void;
}

function LegendItem({ color, children, disabled, deactivated, shorten, onClick }: LegendItemProps): ReactElement {
	return (
		<Tooltip content={disabled ? `You can compare only up to ${MAX_METRICS_TO_RENDER} metrics` : ''}>
			<Container
				onClick={disabled ? undefined : onClick}
				sx={{
					display: 'flex',
					alignItems: 'center',
					px: 'xSmall',
					cursor: disabled ? undefined : 'pointer',
					'&:hover': {
						backgroundColor: disabled ? undefined : 'neutral100',
					},
				}}
			>
				<Container
					sx={{
						minWidth: 12,
						minHeight: 12,
						borderRadius: '50%',
						backgroundColor: deactivated ? 'neutral300' : color,
						mr: 'xSmall',
					}}
				/>
				<Container
					display="flex"
					alignItems="center"
					sx={shorten ? { ...textEllipsis } : { flexWrap: 'wrap', opacity: disabled ? 0.5 : 1 }}
				>
					{children}
				</Container>
			</Container>
		</Tooltip>
	);
}

function MessageContainer({ children }: { children: ReactNode }): ReactElement {
	return (
		<Container display="flex" justifyContent="center" alignItems="center" py="xLarge" color="neutral500" height={450}>
			{children}
		</Container>
	);
}

interface MetricValue {
	parent: MetricGroup;
	value: number;
	ts: number;
}

interface MetricGroup extends MetricIdentityVO {
	values: MetricValue[];
	color: string;
	id: string;
}

function useMetrics(executionId: number, metricQueries: MetricQueryVO[]): DataStreamResult<MetricGroup[]> {
	const ids = new Set(metricQueries.map((q) => q.id));
	return useExecutionMetrics({
		dependencies: [executionId, ...Array.from(ids)],
		filterMetric: (_metric: ServerExecutionMetricEvent) => ids.has(_metric.labels.steadybit_metric_query_id),
		transformResponse: (_metrics) => {
			const groupedMetrics = new Map<string, MetricGroup>();
			_metrics.forEach((m, i) => {
				// the ID is the unique combination of all labels
				const id = getHash(m.labels);
				const existing = groupedMetrics.get(id);
				if (existing) {
					existing.values.push({ value: m.value, ts: m.ts, parent: existing });
				} else {
					const group: MetricGroup = {
						id,
						color: schemeCategory10[i % 10],
						values: [],
						labels: m.labels,
					};
					group.values.push({ value: m.value, ts: m.ts, parent: group });
					groupedMetrics.set(id, group);
				}
			});

			return Array.from(groupedMetrics.values());
		},
	});
}
