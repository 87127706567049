/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { smellsLikeEnvVar, smellsLikeTemplatePlaceholder } from 'components';
import { UnitFieldUnit, utils } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import { Options } from './Common';
import { Input } from './Integer';

const SECONDS: UnitFieldUnit = { label: 'Seconds', id: 's' };

interface DurationProps {
	hasErrors?: boolean;
	disabled: boolean;
	units?: string[];
	value: string | null;
	setValue: (value: string | null) => void;
}

export default function Duration({ value, hasErrors, disabled, units, setValue }: DurationProps): ReactElement {
	let unit = SECONDS;
	let duration: number | string | null = null;
	if (value != null) {
		try {
			const result = utils.parseDuration(value);
			duration = result.value;
			unit = result.unit;
		} catch {
			// ignore
		}
	}

	let v: string | null = null;
	if (value == null) {
		v = null;
	} else if (smellsLikeEnvVar(value) || smellsLikeTemplatePlaceholder(value)) {
		v = value;
	} else {
		v = String(duration);
	}

	const defaultOptions = [
		{ id: 'ms', label: 'MilliSec.' },
		{ id: 's', label: 'Seconds' },
		{ id: 'm', label: 'Minutes' },
	];
	let options = defaultOptions;
	if (units && units.length > 0) {
		options = [];
		units.forEach((u) => {
			switch (u) {
				case 'milliseconds':
					options.push({ id: 'ms', label: 'MilliSec.' });
					break;
				case 'seconds':
					options.push({ id: 's', label: 'Seconds' });
					break;
				case 'minutes':
					options.push({ id: 'm', label: 'Minutes' });
					break;
				case 'hours':
					options.push({ id: 'h', label: 'Hours' });
					break;
				case 'days':
					options.push({ id: 'd', label: 'Days' });
					break;
				case 'weeks':
					options.push({ id: 'w', label: 'Weeks' });
					break;
				case 'months':
					options.push({ id: 'M', label: 'Months' });
					break;
			}
		});
		if (options.length === 0) {
			options = defaultOptions;
		}
		// sort options from milliseconds to months
		options.sort((a, b) => {
			const order = ['ms', 's', 'm', 'h', 'd', 'w', 'M'];
			return order.indexOf(a.id) - order.indexOf(b.id);
		});
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Input
				hasErrors={hasErrors}
				disabled={disabled}
				value={v}
				withUnit
				onChange={(newDuration) => {
					if (newDuration == null) {
						return setValue(null);
					}
					if (smellsLikeEnvVar(newDuration)) {
						return setValue(String(newDuration));
					}
					if (smellsLikeTemplatePlaceholder(newDuration)) {
						return setValue(String(newDuration));
					}
					setValue(newDuration + unit.id);
				}}
			/>
			<Options
				disabled={disabled}
				options={options}
				value={unit.id}
				onChange={(_v) => setValue((v == null ? 0 : duration) + _v)}
			/>
		</div>
	);
}
