/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Icon, IconType, Text } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';

export interface DashboardWidgetProps {
	actionsRight?: ReactNode;
	actionsLeft?: ReactNode;
	icon?: IconType;
	title: string;
	children: ReactNode;
}

export default function DashboardWidget({
	actionsRight,
	actionsLeft,
	title,
	icon,
	children,
}: DashboardWidgetProps): ReactElement {
	return (
		<Flex
			spacing="xSmall"
			style={{
				width: '100%',
				px: 'medium',
				py: 'small',
				borderRadius: 'xSmall',
				backgroundColor: Colors.neutral000,
				boxShadow: '2px 2px 10px rgba(21, 31, 45, 0.10), 0px 4px 8px rgba(21, 31, 45, 0.05)',
			}}
		>
			<Flex direction="horizontal" align="center" spacing="xSmall" justify="spread" style={{ width: '100%' }}>
				<Flex direction="horizontal" align="center" spacing="xSmall" style={{ flexGrow: 1 }}>
					{icon && <Icon type={icon} />}
					<Text type="mediumStrong" textEllipsis>
						{title}
					</Text>
					{actionsLeft}
				</Flex>
				{actionsRight}
			</Flex>
			{children}
		</Flex>
	);
}
