/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { lazy, ReactElement, Suspense } from 'react';

const MarkdownLazyEditor = lazy(() => import('./MarkdownLazyEditor'));

interface DescriptionEditorProps {
	hasError?: boolean;
	value: string;
	onChange: (value: string) => void;
}

export default function DescriptionEditor(props: DescriptionEditorProps): ReactElement {
	return (
		<Suspense fallback={<div />}>
			<MarkdownLazyEditor {...props} />
		</Suspense>
	);
}
