/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

export const scaleOptions = [
	{ id: '1', label: '1x' },
	{ id: '2', label: '2x' },
	{ id: '3', label: '3x' },
];

interface ScaleSelectionProps {
	selectedScale: string;
	setSelectScale: (scale: string) => void;
}

export default function ScaleSelection({ selectedScale, setSelectScale }: ScaleSelectionProps): ReactElement {
	return (
		<presets.dropdown.SingleChoiceButton
			selectedId={selectedScale}
			placement="bottom-start"
			maxContentHeight="250px"
			withKeyboardArrowSupport
			items={scaleOptions}
			size="small"
			onSelect={setSelectScale}
		>
			{scaleOptions.find((_scale) => _scale.id === selectedScale)?.label || 'Scale'}
		</presets.dropdown.SingleChoiceButton>
	);
}
