/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { metrics } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';
import { Container, Stack } from 'components';
import { theme } from 'styles.v2/theme';

import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import { formatTime } from '../../../../utils/dateFns';

interface ChartWithTimeAxisProps extends Pick<ExperimentRunLogsAndMetricsProps, 'position' | 'start' | 'duration'> {
	children: ReactNode;
}

export default function ChartWithTimeAxis({
	children,
	position,
	duration,
	start,
}: ChartWithTimeAxisProps): ReactElement {
	return (
		<Stack size="none" sx={{ position: 'relative' }}>
			{children}

			{position && (
				<Container sx={{ position: 'absolute', top: 0, left: 168, right: 0, bottom: 30, pointerEvents: 'none' }}>
					<Container
						sx={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: `${Math.max(0, 100 * ((position - start) / duration))}%`,
							width: 2,
							backgroundColor: theme.colors.slate,
						}}
					/>
				</Container>
			)}

			<div style={{ paddingLeft: 168 }}>
				<metrics.TimeAxis start={start} end={start + duration} formatTime={(v) => formatTime(new Date(v))} />
			</div>
		</Stack>
	);
}
