/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { asDate, compare, formatDateWithTime } from 'utils/dateFns';
import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import { aggregateErrorInformation } from 'utils/error';
import { Snackbar, Toggle, Tooltip } from 'components';
import { ExperimentScheduleSummaryVO } from 'ui-api';
import { Services } from 'services/services';

import { ConfigToEdit } from './types';

export function ScheduledBy({
	schedule,
	setConfigToEdit,
}: {
	schedule: ExperimentScheduleSummaryVO;
	setConfigToEdit: (config: ConfigToEdit) => void;
}): ReactElement | null {
	return (
		<Text
			type="small"
			style={{
				color: Colors.neutral600,
				textDecoration: 'none',
				cursor: 'pointer',
				onHover: { color: Colors.slate },
			}}
			onClick={() => setConfigToEdit({ experimentKey: schedule.experimentKey, scheduleId: schedule.id })}
		>
			Scheduled by{' '}
			<Text type="smallStrong" style={{ overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}>
				{schedule.editedBy.name}
			</Text>
		</Text>
	);
}

export function CronOrStart({ schedule }: { schedule: ExperimentScheduleSummaryVO }): ReactElement | null {
	if (!schedule.cron && !schedule.startAt) {
		return null;
	}
	if (schedule.startAt) {
		return (
			<Text type="small" neutral600>
				{formatDateWithTime(new Date(schedule.startAt))}
			</Text>
		);
	}
	return (
		<Text type="small" neutral600>
			{schedule.cron}
		</Text>
	);
}

export function NextExecution({ schedule }: { schedule: ExperimentScheduleSummaryVO }): ReactElement | null {
	if ((!schedule.nextExecution && !schedule.startAt) || !schedule.enabled) {
		return null;
	}
	const date = schedule.nextExecution || schedule.startAt || '';
	return (
		<Text type="small" neutral600 style={{ fontVariantNumeric: 'tabular-nums' }}>
			{`${formatDateWithTime(new Date(date))}`}
		</Text>
	);
}

export function ActivateToggle({ schedule }: { schedule: ExperimentScheduleSummaryVO }): ReactElement | null {
	const isEditPermitted: boolean = schedule._actions?.includes('edit') ?? false;
	const [optimisticEnabled, setOptimisticEnabled] = useState(schedule.enabled);
	const [isSaving, setIsSaving] = useState(false);

	// reoccuring schedules schedules (cron) can be always activated
	const canBeActivated =
		isEditPermitted && (!!schedule.cron || (schedule.startAt && compare(new Date(), asDate(schedule.startAt)) >= 0));

	useEffect(() => {
		setOptimisticEnabled(schedule.enabled);
	}, [schedule.enabled]);

	return (
		<Flex direction="horizontal" justify="center" style={{ width: '100%' }}>
			<Tooltip
				content={
					!isEditPermitted
						? `You don't have permissions to edit the schedule${''}`
						: !canBeActivated
							? `Experiment schedule can't be activated as it is scheduled in the past${''}`
							: 'Activate or deactivate the schedule'
				}
			>
				<Toggle
					type="radio"
					checked={optimisticEnabled}
					disabled={!canBeActivated}
					onChange={async (e) => {
						if (isSaving) {
							return;
						}
						setIsSaving(true);

						setOptimisticEnabled(e.target.checked);
						schedule.enabled = e.target.checked;
						const enabled = e.target.checked;
						const scheduleToSave = { ...schedule, enabled };

						try {
							await Services.schedulesApi.scheduleExperiment(scheduleToSave);
						} catch (e) {
							Snackbar.error(aggregateErrorInformation(e));
						}

						setIsSaving(false);
					}}
				/>
			</Tooltip>
		</Flex>
	);
}
