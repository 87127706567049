/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ExperimentRunCard from 'pages/experiments/components/ExperimentRunCard';
import { ReactElement } from 'react';

export default function UnknownWidget(): ReactElement {
	return (
		<>
			<ExperimentRunCard title="Warning">
				One of the actions is reporting an unknown widget type. Please check the widget configuration.
			</ExperimentRunCard>
		</>
	);
}
