/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import {
	Pagination,
	RouterLink,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSort,
} from 'components';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import { ExperimentScheduleSummaryVO, PagedModel } from 'ui-api';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { IconCalendar, IconTarget } from 'components/icons';
import ListHeader from 'components/List/presets/ListHeader';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import ViewWrapper from 'pages/components/ViewWrapper';
import { useUrlState } from 'url/useUrlState';

import SchedulesFilter, { FilterType } from '../components/Schedule/EditSchedulesModal/SchedulesFilter';
import EditSchedulesModal from '../components/Schedule/EditSchedulesModal/EditSchedulesModal';
import DeleteScheduleExperimentModal from '../components/Schedule/DeleteScheduleModal';
import { compareEnabled, compareNextExecution, sortByExperimentKey } from './utils';
import { experimentKeyParam, selectedScheduleIdParam, UrlState } from './urlParams';
import Notifications from '../../../components/Notifications/Notifications';
import SchedulesRow from './SchedulesRow';
import ScheduleRow from './ScheduleRow';

interface SchedulesProps {
	schedulesResult: DataStreamResult<PagedModel<ExperimentScheduleSummaryVO>>;
}

interface ConfigToEdit {
	experimentKey: string;
	scheduleId: string | null;
}

export default function Schedules({ schedulesResult }: SchedulesProps): ReactElement {
	const [{ experimentKey, scheduleId }, , updateUrlState] = useUrlState<UrlState>([
		selectedScheduleIdParam,
		experimentKeyParam,
	]);
	const configToEdit: ConfigToEdit | undefined = experimentKey ? { experimentKey, scheduleId } : undefined;

	const [scheduleIdToDelete, setScheduleIdToDelete] = useState<string | undefined>(undefined);
	const [sortKey, setSortKey] = useState<'experimentKey' | 'nextRun' | 'enabled'>('experimentKey');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	const [activeFilter, setActiveFilter] = useState<FilterType>(null);

	const pageSize = 15;
	const [page, setPage] = useState(0);
	useEffect(() => {
		setPage(0);
	}, [schedulesResult.value, activeFilter]);

	if (schedulesResult.value && schedulesResult.value.page.totalElements === 0) {
		return <EmptyPageContent />;
	}

	const schedules = schedulesResult.value?.content || [];
	const groupedByExperimentKey = schedules
		.filter((s) => (activeFilter ? (activeFilter === 'once' ? !s.cron : !!s.cron) : true))
		.reduce((acc, schedule) => {
			const schedules = acc.get(schedule.experimentKey) || [];
			schedules.push(schedule);
			acc.set(schedule.experimentKey, schedules);
			return acc;
		}, new Map<string, ExperimentScheduleSummaryVO[]>());

	return (
		<>
			{configToEdit && (
				<EditSchedulesModal
					experimentName={groupedByExperimentKey.get(configToEdit.experimentKey)?.[0].experimentName}
					initSelectedScheduleId={configToEdit.scheduleId}
					experimentKey={configToEdit.experimentKey}
					onClose={() => updateUrlState({ scheduleId: null, experimentKey: null })}
				/>
			)}
			{scheduleIdToDelete && (
				<DeleteScheduleExperimentModal id={scheduleIdToDelete} onClose={() => setScheduleIdToDelete(undefined)} />
			)}
			<ViewWrapper>
				<Flex spacing="medium" style={{ width: '100%' }}>
					<ListHeader left={<ListHeaderTitle title="Schedules" Icon={IconCalendar} />} />

					<Flex direction="horizontal" align="center" spacing="small">
						<Text type="mediumStrong" style={{ whiteSpace: 'nowrap' }}>
							Filter by:
						</Text>
						<SchedulesFilter
							numberOfRecurrentSchedules={
								schedulesResult.loading ? undefined : schedules.filter((s) => !!s.cron).length
							}
							numberOfOnceSchedules={schedulesResult.loading ? undefined : schedules.filter((s) => !s.cron).length}
							activeFilter={activeFilter}
							setActiveFilter={setActiveFilter}
						/>
					</Flex>
					<Notifications types={['LICENSE_HARD_LIMIT_REACHED_EXPERIMENT_EXECUTION']} />

					<Table width="100%">
						<TableHead>
							<TableRow>
								<TableHeadCell>
									<TableSort
										sort={sortKey === 'experimentKey' ? sortDirection : undefined}
										onClick={() => {
											if (sortKey !== 'experimentKey') {
												setSortKey('experimentKey');
												setSortDirection('asc');
											} else {
												setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
											}
										}}
									>
										Name
									</TableSort>
								</TableHeadCell>

								<TableHeadCell>
									<TableSort
										sort={sortKey === 'nextRun' ? sortDirection : undefined}
										onClick={() => {
											if (sortKey !== 'nextRun') {
												setSortKey('nextRun');
												setSortDirection('desc');
											} else {
												setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
											}
										}}
									>
										Next Run
									</TableSort>
								</TableHeadCell>

								<TableHeadCell>Cron / Scheduled Date</TableHeadCell>

								<TableHeadCell width={136}>
									<TableSort
										sort={sortKey === 'enabled' ? sortDirection : undefined}
										onClick={() => {
											if (sortKey !== 'enabled') {
												setSortKey('enabled');
												setSortDirection('desc');
											} else {
												setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
											}
										}}
									>
										Activate Schedule
									</TableSort>
								</TableHeadCell>

								<TableHeadCell width={48} />
								<TableHeadCell width={48} />
								<TableHeadCell width={48} />
							</TableRow>
						</TableHead>

						<TableBody>
							{schedulesResult.loading && (
								<>
									<TableLoadingRow numColumns={6} />
									<TableLoadingRow numColumns={6} />
									<TableLoadingRow numColumns={6} />
								</>
							)}
							{groupedByExperimentKey.size === 0 && !schedulesResult.loading && <NoDataRow />}
							{Array.from(groupedByExperimentKey.entries())
								.slice(page * pageSize, (page + 1) * pageSize)
								.sort((a, b) => {
									if (sortKey === 'nextRun') {
										return compareNextExecution(a[1][0], b[1][0], sortDirection);
									}
									if (sortDirection === 'asc') {
										const c = a;
										a = b;
										b = c;
									}
									if (sortKey === 'experimentKey') {
										return sortByExperimentKey(a[0], b[0]);
									}
									if (sortKey === 'enabled') {
										return compareEnabled(a[1][0].enabled, b[1][0].enabled);
									}
									return 0;
								})
								.map(([experimentKey, schedules]) =>
									schedules.length > 1 ? (
										<SchedulesRow
											key={experimentKey}
											experimentKey={experimentKey}
											schedules={schedules}
											setScheduleIdToDelete={setScheduleIdToDelete}
											setConfigToEdit={({ experimentKey, scheduleId }) => updateUrlState({ scheduleId, experimentKey })}
										/>
									) : (
										<ScheduleRow
											key={experimentKey}
											schedule={schedules[0]}
											setScheduleIdToDelete={setScheduleIdToDelete}
											setConfigToEdit={({ experimentKey, scheduleId }) => updateUrlState({ scheduleId, experimentKey })}
										/>
									),
								)}
						</TableBody>
					</Table>
					{groupedByExperimentKey.size > pageSize && (
						<Flex style={{ width: '100%', alignItems: 'center' }}>
							<Pagination
								activePage={page}
								totalPages={Math.ceil(groupedByExperimentKey.size / pageSize)}
								onClick={setPage}
							/>
						</Flex>
					)}
				</Flex>
			</ViewWrapper>
		</>
	);
}

function NoDataRow(): ReactElement {
	return (
		<TableRow>
			<TableDataCell colSpan={6}>
				<Text type="small" neutral600>
					No Schedules matching your filter.
				</Text>
			</TableDataCell>
		</TableRow>
	);
}

function EmptyPageContent(): ReactElement {
	return (
		<Flex align="center" justify="center" style={{ width: '100%' }}>
			<Flex style={{ marginTop: -160 }}>
				<AstroidScreen
					title={
						<Text type="xLargeStrong" slate>
							There are no Experiment scheduled
						</Text>
					}
					icon={<IconTarget variant="xxLarge" color="slate" />}
					description={
						<Text type="large" neutral600 style={{ textAlign: 'center' }}>
							You can schedule an Experiment directly within the Experiment Designer or using the “Create New Schedule”
							option in the{' '}
							<RouterLink to="/experiments" sx={{ textDecoration: 'underline' }}>
								<Text type="largeStrong">Experiment List</Text>
							</RouterLink>
							.
						</Text>
					}
				/>
			</Flex>
		</Flex>
	);
}
