/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Table, TableBody, TableDataCell, TableHead, TableHeadCell, TableRow } from 'components';
import { collectStepIds } from 'pages/templates/UseTemplateModal/pages/TemplatePlaceholder';
import { Colors, Flex, Icon, Text, TextInput } from '@steadybit/ui-components-lib';
import ExperimentPreview from 'pages/templates/components/ExperimentPreview';
import { useStableErrorMap } from 'pages/experimentsV2/useFieldErrors';
import { ExperimentVO, OccuranceVO, VariableVO } from 'ui-api';
import { usePromise } from 'utils/hooks/usePromise';
import { VariableWithScope } from 'utils/envVars';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';

interface ExecutionOverrideTableProps {
	occurances: Record<string, OccuranceVO[]>;
	usedVariables: VariableWithScope[];
	experiment: ExperimentVO;
	variables: VariableVO[];
	disabled: boolean;
	setVariables: (v: VariableVO[] | undefined) => void;
}

export default function ExecutionOverrideTable({
	usedVariables,
	experiment,
	occurances,
	variables,
	disabled,
	setVariables,
}: ExecutionOverrideTableProps): ReactElement {
	const [focusedKey, setFocusedKey] = useState<string | undefined>(undefined);
	const selectedStepIds = collectStepIds(
		Object.entries(occurances)
			.filter(([key]) => (focusedKey ? key === focusedKey : true))
			.map(([, occurances]) => occurances)
			.flat() || [],
	);

	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const stepIdToError = useStableErrorMap();

	return (
		<>
			<Flex
				spacing="medium"
				style={{
					width: '100%',
					p: 'small',
					backgroundColor: Colors.neutral000,
					borderRadius: 'xSmall',
				}}
			>
				<Table width="100%">
					<TableHead>
						<TableRow>
							<TableHeadCell width="50%">Variables in use</TableHeadCell>
							<TableHeadCell width="50%">Value</TableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{usedVariables.map(({ key, value, scope }) => {
							const matchingScheduleVariable = variables?.find((v) => v.key === key);
							return (
								<TableRow key={key}>
									<TableDataCell>
										<Flex direction="horizontal" align="center" spacing="xxSmall">
											<Icon type={scope} size="small" />
											<Text type="medium" textEllipsis>
												{key}
											</Text>
										</Flex>
									</TableDataCell>
									<TableDataCell>
										<TextInput
											value={matchingScheduleVariable?.value || ''}
											placeholder={value}
											disabled={disabled}
											onClear={() => setVariables(variables.filter((v) => v.key !== key))}
											onChange={(newValue) => {
												const newVariables = [...variables.filter((v) => v.key !== key)];
												if (newValue) {
													newVariables.push({ key, value: newValue });
												}
												setVariables(newVariables);
											}}
											onFocus={() => setFocusedKey(key)}
											onBlur={() => setFocusedKey(undefined)}
										/>
									</TableDataCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>

				{experiment.lanes.length > 0 && (
					<Flex spacing="xSmall" style={{ width: '100%' }}>
						<Text type="small">Preview of your experiment with overrides</Text>
						<div style={{ width: '100%' }}>
							<ExperimentPreview
								actions={actionsResult.value || []}
								selectedStepIds={selectedStepIds}
								stepIdToError={stepIdToError}
								lanes={experiment.lanes}
							/>
						</div>
					</Flex>
				)}
			</Flex>
		</>
	);
}
