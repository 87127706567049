/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

import { Options } from './Common';
import { Input } from './Integer';

interface StressNgWorkerProps {
	value: string | number;
	disabled: boolean;
	min?: number;
	max?: number;
	setValue: (value: string | number | null) => void;
}

export default function StressNgWorker({
	max = Number.MAX_SAFE_INTEGER,
	disabled,
	min = 0,
	value,
	setValue,
}: StressNgWorkerProps): ReactElement {
	const selectedOption = typeof value === 'string' ? 'cores' : value == null || value > 0 ? 'cores' : 'all';

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{selectedOption === 'cores' && (
				<Input min={min} max={max} value={value} disabled={disabled} withUnit onChange={setValue} />
			)}
			<Options
				width={selectedOption === 'all' ? '100%' : 'fit-content'}
				value={selectedOption}
				options={[
					{ id: 'cores', label: 'Cores' },
					{ id: 'all', label: 'All cores' },
				]}
				onChange={(v) => {
					if (v === selectedOption) {
						return;
					}
					setValue(v === 'cores' ? 1 : 0);
				}}
				disabled={disabled}
			/>
		</div>
	);
}
