/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Tooltip } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import './styles.css';

interface LaneMarkerProps {
	hasErrors?: boolean;
	children: string;
}

export default function LaneMarker({ hasErrors, children }: LaneMarkerProps): ReactElement {
	return (
		<Tooltip content={hasErrors ? 'This lane contains errors' : undefined}>
			{({ setRefElement }) => (
				<div ref={setRefElement} className={`lane-index-marker${hasErrors ? ' lane-index-marker-error' : ''}`}>
					<span className={`lane-index-marker-text${hasErrors ? ' lane-index-marker-text-error' : ''}`}>
						{hasErrors ? '!' : children}
					</span>
				</div>
			)}
		</Tooltip>
	);
}
