/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ModalContentV2, ModalFooterV2, ModalHeaderV2, ModalV2, Snackbar } from 'components';
import DataStore, { Errors, useStore, useValidations } from 'DataStore/DataStore';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { Button, Tooltip } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { debounce, set } from 'lodash';

import { InviteUserForm, InviteUserFormValues } from './components/inviteUserForm';

function InviteUser(): ReactElement {
	return (
		<DataStore<InviteUserFormValues> initialData={{ invitations: [] }}>
			<ValidationHandler />
			<ModalContent />
		</DataStore>
	);
}

export default InviteUser;

function ModalContent(): ReactElement {
	const [isSubmitting, setSubmitting] = useState(false);
	const history = useHistory();

	const { data, touched } = useStore<InviteUserFormValues>();
	const { errors, isValidating, setValidations } = useValidations();

	const handleSubmit = async (): Promise<void> => {
		try {
			setSubmitting(true);
			await Services.users.inviteUsers(data);
			Snackbar.dark('Invitation has been sent.', { toastId: 'user-invited' });
			history.replace('/settings/users');
		} catch (error) {
			if (error instanceof ValidationError) {
				const errors = {};
				error.violations.forEach((violation) => set(errors, violation.field, violation.message));
				setValidations({ isValidating: false, errors });
			} else {
				Snackbar.error(error.toString(), { toastId: 'user-invited' });
			}
			setSubmitting(false);
		}
	};

	const dirty = Object.keys(touched).length > 0;
	const hasErrors = Object.keys(errors).length > 0;
	const isEmpty = data.invitations.length === 0;

	return (
		<ModalV2 withFooter centered>
			<ModalHeaderV2
				title="Invite Users"
				onClose={() => {
					history.goBack();
				}}
			/>
			<ModalContentV2>
				<InviteUserForm />
			</ModalContentV2>
			<ModalFooterV2>
				<Tooltip content={isValidating ? 'Validating...' : hasErrors ? 'There are validation errors' : undefined}>
					{({ setRefElement }) => (
						<Button
							ref={setRefElement}
							id="invite-user"
							disabled={!dirty || isValidating || hasErrors || isEmpty || isSubmitting}
							onClick={handleSubmit}
							data-cy="send-invitation"
						>
							{isSubmitting ? 'Inviting users…' : 'Send Invitation'}
						</Button>
					)}
				</Tooltip>
			</ModalFooterV2>
		</ModalV2>
	);
}

function ValidationHandler(): null {
	const { data } = useStore<InviteUserFormValues>();
	const { setValidations } = useValidations();

	const [debouncedValidate] = useState(() =>
		debounce(
			async (v) => {
				setValidations({ isValidating: false, errors: await validate(v) });
			},
			500,
			{ leading: true },
		),
	);

	const [stableId, stableObject] = useStableInstance(data);

	useEffect(() => {
		setValidations({ isValidating: true });
		debouncedValidate(stableObject);
	}, [stableId, debouncedValidate]);

	return null;
}

export async function validate(values: InviteUserFormValues): Promise<Errors> {
	const errors = {};
	if (values.invitations.length === 0) {
		return errors;
	}
	const violations = await Services.users.validateInviteUsersRequest(values);
	violations.forEach(({ field, message }) => set(errors, field, message));
	return errors;
}
