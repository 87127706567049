/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { TrackingExecutionSourceVO, VariableVO } from 'ui-api';
import { Snackbar, userConfirm } from 'components';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { useCallback } from 'react';

import { useExperimentExecutionSystemStatusCheck } from './useExperimentExecutionSystemStatusCheck';

type RunExperiment = (experimentKey: string, options: RunOptions) => Promise<void>;

interface RunOptions {
	executionSource: TrackingExecutionSourceVO;
	redirectToEditorOnRunFailure: boolean;
	executionVariables: VariableVO[];
}

export function useExperimentExecutionTrigger(): RunExperiment {
	const history = useHistory();
	const checkSystemStatus = useExperimentExecutionSystemStatusCheck();
	return useCallback(run, [checkSystemStatus]);

	async function run(experimentKey: string, options: RunOptions): Promise<void> {
		const systemStatusCheckResult = await checkSystemStatus();
		if (systemStatusCheckResult === 'abort-execution') {
			return;
		}

		try {
			const experimentExecutionId = await Services.experiments.runExperiment(
				experimentKey,
				options.executionSource,
				options.executionVariables,
			);
			history.push(`/experiments/edit/${experimentKey}/executions/${experimentExecutionId}`);
		} catch (error) {
			if (error.type !== 'https://steadybit.com/problems/another-experiment-running-exception') {
				onError(error);
			} else if (
				(await userConfirm({
					title: 'Running experiments in parallel',
					message: `There is already an experiment running. Do you want to start ${experimentKey} in parallel?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{ value: 'confirm', label: `Start ${experimentKey}`, variant: 'primary' },
					],
					dataCy: 'running-parallel',
				})) === 'confirm'
			) {
				try {
					const experimentExecutionId = await Services.experiments.runExperiment(
						experimentKey,
						options.executionSource,
						options.executionVariables,
						true,
					);
					history.push(`/experiments/edit/${experimentKey}/executions/${experimentExecutionId}`);
				} catch (e) {
					onError(e);
				}
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function onError(error: any): void {
			let errorMessage = '';
			if (error.type === 'https://steadybit.com/problems/experiment-invalid-exception') {
				errorMessage = 'There are validation errors in your experiment.';
			} else {
				errorMessage = error.toString();
			}
			Snackbar.error('Experiment not started! ' + errorMessage, { toastId: 'experiment-run' });

			if (options.redirectToEditorOnRunFailure) {
				history.push(`/experiments/edit/${experimentKey}`);
			}
		}
	}
}
