/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useCallback, useEffect } from 'react';
import { useStore } from 'DataStore/DataStore';
import { Services } from 'services/services';
import { debounce } from 'lodash';

import { TemplateFormValues } from './types';

/**
 * We want to have the backend the only source of truth for extracting variables and placeholders.
 * Since some places rely on the metadata, I put this into a separate component and enrich the form with it.
 */
export default function MetadataEnrichment(): null {
	const { data, setValue } = useStore<TemplateFormValues>();

	const extractMetadata = useCallback(
		debounce(
			async (v: TemplateFormValues) => {
				const metadata = await Services.templatesApi.getTemplateMetadata({
					id: '',
					...v,
					created: v.created ? new Date(v.created) : new Date(),
					edited: v.edited ? new Date(v.edited) : new Date(),
				});
				setValue('metadata', metadata, false);
			},
			500,
			{ leading: true },
		),
		[],
	);

	const [stableId, stableData] = useStableInstance<TemplateFormValues>({
		...data,
		variables: [],
		placeholders: [],
		metadata: undefined,
	});
	useEffect(() => {
		extractMetadata(stableData);
	}, [stableId, extractMetadata]);

	return null;
}
