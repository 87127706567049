/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	RouterLink,
	RouterPagination,
	Snackbar,
	Spinner,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	userConfirm,
} from 'components';
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { PreflightActionIntegrationSummaryVO, TeamSummaryVO } from 'ui-api';
import TeamSelection from 'pages/settings/teams/components/TeamSelection';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import { Order, PageLocation, usePage } from 'utils/hooks/usePage';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { Button, Flex } from '@steadybit/ui-components-lib';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { IconDelete, IconEdit } from 'components/icons';
import { Services } from 'services/services';
import { useDebounce } from 'react-use';
import { useHistory } from 'url/hooks';
import { debounce } from 'lodash';

import { PreflightActionIntegrationScope } from '../../../../services/preflightActionIntegrationApi';
import { PreflightActionIcon } from '../../../../hocs/PreflightActionIcon';
import invokePromise from '../../../../utils/ignorePromise';
import { ampli } from '../../../../ampli';

interface PreflightActionIntegrationListProps {
	type?: string;
	pathSegment: string;
	onEdit: (id: string) => string;
	onCreate: () => string;
	labelCreatePreflightActionIntegration?: string;
	labelNoData?: string;
}

export default function PreflightActionIntegrationList({
	labelCreatePreflightActionIntegration,
	pathSegment,
	labelNoData,
	type,
	onCreate,
	onEdit,
}: PreflightActionIntegrationListProps): ReactElement {
	const page = usePage(pathSegment, { size: 15, sort: [['name', 'asc', 'ignoreCase']] });
	const [preflightActionIntegrations, fetch] = useAsyncState(
		() => Services.preflightActionIntegrationsApi.findPreflightActionIntegrations(page.criteria, page.pageParams),
		[type, page],
	);
	const [teams] = useAsyncState(
		async () =>
			preflightActionIntegrations.value
				? Services.teams.findTeamsById(preflightActionIntegrations.value.content.map((w) => w.teamId || ''))
				: new Map(),
		[preflightActionIntegrations.value?.content],
		new Map(),
	);
	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if (
					'preflight.action.integration.created' === event.type ||
					preflightActionIntegrations.value?.content.some(
						(preflightActionIntegration) => preflightActionIntegration.id === event.preflightActionIntegrationId,
					)
				) {
					debouncedFetch();
				}
			},
			[debouncedFetch, preflightActionIntegrations],
		),
		[],
		debouncedFetch.cancel,
		[preflightActionIntegrations],
	);

	useEffect(() => {
		if (type) {
			ampli.preflightActionIntegrationListViewed({
				url: window.location.href,
			});
		}
	}, [type]);

	return (
		<Stack minHeight={'100%'} size={'large'} m={'xLarge'}>
			<PreflightActionIntegrationFilters
				page={page}
				allowCreate={preflightActionIntegrations.value?._actions.includes('create')}
				onCreate={onCreate}
				labelCreatePreflightActionIntegration={labelCreatePreflightActionIntegration}
			/>

			{preflightActionIntegrations.error && (
				<Text>Error loading PreflightActionIntegrations: {preflightActionIntegrations.error.message}</Text>
			)}
			{preflightActionIntegrations.loading && !preflightActionIntegrations.value ? (
				<Spinner variant="large" color={'neutral500'} mr={'auto'} />
			) : null}
			{preflightActionIntegrations.value ? (
				<>
					<PreflightActionIntegrationTable page={page}>
						{preflightActionIntegrations.value.content.map((preflightActionIntegration) => (
							<PreflightActionIntegrationRow
								key={preflightActionIntegration.id}
								value={preflightActionIntegration}
								onClick={onEdit}
								team={teams.value.get(preflightActionIntegration.teamId)}
							/>
						))}
						{!preflightActionIntegrations.value.content.length && (
							<TableRow>
								<TableDataCell colSpan={6}>
									<Text muted>{labelNoData ? labelNoData : 'No PreflightActionIntegrations found.'}</Text>
								</TableDataCell>
							</TableRow>
						)}
					</PreflightActionIntegrationTable>
				</>
			) : null}
			<RouterPagination
				activePage={page.pageParams.page}
				totalPages={preflightActionIntegrations.value?.totalPages}
				to={(i) => page.withPage(i).toString()}
			/>
		</Stack>
	);
}

interface PreflightActionIntegrationFiltersProps {
	allowCreate?: boolean | false;
	labelCreatePreflightActionIntegration?: string;
	page: PageLocation;
	onCreate: () => string;
}

function PreflightActionIntegrationFilters({
	page,
	allowCreate,
	onCreate,
	labelCreatePreflightActionIntegration,
}: PreflightActionIntegrationFiltersProps): ReactElement {
	const history = useHistory();
	const [name, setName] = React.useState<string | null>(page.criteria.get('name'));
	useDebounce(
		() => {
			trackPreflightActionIntegrationFilter(name, page.criteria.get('teamId'));
			history.replace(page.withCriterion('name', name).toString());
		},
		350,
		[name],
	);

	return (
		<Stack direction="horizontal" size="medium" justifyContent="flex-start">
			<ListHeaderSearch
				title="Search preflight action integration"
				value={name ?? ''}
				setValue={(v) => {
					setName(v);
					// reset pagination information when filtering
					history.replace(page.withPage(0).toString());
				}}
			/>
			<Container width="200px">
				<TeamSelection
					value={page.criteria.get('teamId') || undefined}
					includeAny
					onChange={(teamId) => {
						trackPreflightActionIntegrationFilter(name, page.criteria.get('teamId'));
						history.replace(page.withCriterion('teamId', teamId || null).toString());
					}}
				/>
			</Container>
			<Container flex="1 1 300px" sx={{ textAlign: 'right' }}>
				{allowCreate ? (
					<RouterLink color={'primaryLarge'} to={onCreate()} style={{ textDecoration: 'none' }}>
						<Button withLeftIcon="plus" onClick={() => {}}>
							{labelCreatePreflightActionIntegration
								? labelCreatePreflightActionIntegration
								: 'Add Preflight Action Integration'}
						</Button>
					</RouterLink>
				) : null}
			</Container>
		</Stack>
	);
}

const trackPreflightActionIntegrationFilter = (name: string | null, teamId: string | null): void => {
	ampli.preflightActionIntegrationListFiltered({
		filter_text: name || undefined,
		filter_team: teamId || undefined,
	});
};

const SORT_SCOPE_ASC: Order[] = [['scope', 'asc']];
const SORT_SCOPE_DESC: Order[] = [['scope', 'desc']];
const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];

const PreflightActionIntegrationTable: React.FC<{ page: PageLocation; children: ReactNode }> = ({ page, children }) => {
	return (
		<Table width={'100%'}>
			<TableHead>
				<TableRow>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
							to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							onClick={() => {
								ampli.preflightActionIntegrationListSorted({ sorted_by: 'Name' });
							}}
						>
							Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_SCOPE_ASC, SORT_SCOPE_DESC)}
							to={page.toggleSort(SORT_SCOPE_ASC, SORT_SCOPE_DESC).toString()}
							onClick={() => {
								ampli.preflightActionIntegrationListSorted({ sorted_by: 'Scope' });
							}}
						>
							Scope
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>Team</TableHeadCell>
					<TableHeadCell />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

interface PreflightActionIntegrationRowProps {
	value: PreflightActionIntegrationSummaryVO;
	team?: TeamSummaryVO;
	onClick: (id: string) => string;
}

const handleDeletePreflightActionIntegrationClick =
	(preflightActionIntegrationVO: PreflightActionIntegrationSummaryVO) => () => {
		invokePromise(async () => {
			if (
				(await userConfirm({
					title: 'Delete PreflightActionIntegration',
					message: `Do you really want to delete ${preflightActionIntegrationVO.name}?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{ value: 'confirm', label: `Delete ${preflightActionIntegrationVO.name}`, variant: 'primary' },
					],
				})) === 'confirm'
			) {
				try {
					await Services.preflightActionIntegrationsApi.deletePreflightActionIntegration(
						preflightActionIntegrationVO.id,
					);
					Snackbar.dark(`'${preflightActionIntegrationVO.name}'  deleted.`, {
						toastId: 'preflightActionIntegration-deleted',
					});
				} catch (err) {
					Snackbar.error(`'${preflightActionIntegrationVO.name}' not deleted: ${err.toString()}`, {
						toastId: 'preflightActionIntegration-deleted',
					});
				}
			}
		});
	};

function PreflightActionIntegrationRow({ value, team, onClick }: PreflightActionIntegrationRowProps): ReactElement {
	const { push, createHref } = useHistory();
	const preflightActionIntegrationScope = PreflightActionIntegrationScope[value.scope];
	return (
		<TableRow hoverable>
			<TableDataCell width={'10%'} sx={{ whiteSpace: 'nowrap' }}>
				<Flex direction="horizontal" spacing="xSmall" align="center">
					<PreflightActionIcon id={value.preflightActionId} />
					{value.name}
				</Flex>
			</TableDataCell>
			<TableDataCell width={'10%'} sx={{ whiteSpace: 'nowrap' }}>
				{preflightActionIntegrationScope?.label}
			</TableDataCell>
			<TableDataCell width={'10%'} sx={{ whiteSpace: 'nowrap' }}>
				{team ? `${team.key} ${team.name}` : value.teamId || '-'}
			</TableDataCell>
			<TableDataCell width={'100'}>
				{value._actions.includes('edit') ? (
					<ButtonIcon
						tooltip={'Edit PreflightActionIntegration'}
						onClick={() => {
							push(
								createHref((location) => {
									location.pathname = onClick(value.id);
								}),
							);
						}}
					>
						<IconEdit />
					</ButtonIcon>
				) : null}
				{value._actions.includes('delete') ? (
					<ButtonIcon
						onClick={handleDeletePreflightActionIntegrationClick(value)}
						tooltip={'Delete PreflightActionIntegration'}
					>
						<IconDelete />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
}
