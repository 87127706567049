/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import {
	CreatePreflightActionIntegrationRequest,
	GetPreflightActionIntegrationPageResponse,
	OptionVO,
	PreflightActionIntegrationVO,
	UpdatePreflightActionIntegrationRequest,
} from 'ui-api';
import { Criteria, PageParams } from 'utils/hooks/usePage';
import axios from 'axios';

export const PreflightActionIntegrationScope: Record<string, OptionVO> = {
	GLOBAL: { value: 'GLOBAL', label: 'Any Experiment' },
	TEAM: { value: 'TEAM', label: 'Team' },
};

export interface PreflightActionIntegrationInfoVO {
	type: string;
	label: string;
}

export class PreflightActionIntegrationsApi {
	async findPreflightActionIntegrations(
		criteria: Criteria,
		page: PageParams,
	): Promise<GetPreflightActionIntegrationPageResponse> {
		const params = page.appendTo(new URLSearchParams(criteria));
		return (await axios.get<GetPreflightActionIntegrationPageResponse>('/ui/preflight-action-integration', { params }))
			.data;
	}

	async deletePreflightActionIntegration(id: string): Promise<void> {
		await axios.delete(`/ui/preflight-action-integration/${id}`);
	}

	async createPreflightActionIntegration(body: CreatePreflightActionIntegrationRequest): Promise<void> {
		await axios.post('/ui/preflight-action-integration', body);
	}

	async updatePreflightActionIntegration(id: string, body: UpdatePreflightActionIntegrationRequest): Promise<void> {
		await axios.post('/ui/preflight-action-integration/' + id, body);
	}

	async fetchPreflightActionIntegration(id: string): Promise<PreflightActionIntegrationVO> {
		return (await axios.get<PreflightActionIntegrationVO>(`/ui/preflight-action-integration/${id}`)).data;
	}
}
