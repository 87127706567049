/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Colors, CustomIcon, Icon, PositiveSpacings } from '@steadybit/ui-components-lib';
import { useTargetDefinition } from 'targets/useTargetDefinition';
import { ReactElement } from 'react';

interface IconTargetTypeProps {
	size?: keyof typeof PositiveSpacings | number;
	color?: keyof typeof Colors;
	targetType: string;
}

export function IconTargetType({ targetType, ...props }: IconTargetTypeProps): ReactElement {
	const targetDefinition = useTargetDefinition(targetType);
	if (targetDefinition.value?.icon) {
		const color = props.color ? Colors[props.color] : undefined;
		return <CustomIcon svgDataUri={targetDefinition.value.icon} {...props} color={color} />;
	}
	return <Icon type="target" {...props} />;
}
