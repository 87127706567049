/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { StateBadge } from 'components';
import { ReactElement } from 'react';

interface ErroredBadgeProps {
	numberErrored: number;
}

export default function ErroredBadge({ numberErrored }: ErroredBadgeProps): ReactElement | null {
	if (numberErrored === 0) {
		return null;
	}
	return <StateBadge as="tag" value="ERRORED" />;
}
