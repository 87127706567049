/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, Grid, presets, metrics as m, PieChartMetric, LineChartTimeMetric } from '@steadybit/ui-components-lib';
import { GroupMatcherKeyEqualsValue, GroupMatcherNotEmpty, LineChartWidgetVO } from 'ui-api';
import ExperimentRunCard from 'pages/experiments/components/ExperimentRunCard';
import { Checkbox, Container, Label, Stack, Text, Tooltip } from 'components';
import { ExperimentPlayerTimeStamp } from 'components/ExperimentPlayer/types';
import { useExecutionMetrics } from 'services/executionMetricsStreams';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { countBy } from 'lodash';

import LoadingContent from '../../metrics/LoadingContent';
import EmptyContent from '../../metrics/EmptyContent';
import { WidgetProps } from '../types';

export default function LineChartWidget({
	experimentExecution,
	position,
	duration,
	widget,
	start,
	onPositionSelect,
}: WidgetProps): ReactElement {
	const lineChartWidget = widget as LineChartWidgetVO;
	const liveUpdate = !experimentExecution.ended;

	const [selectedValue, setSelectedValue] = useState<string | null>(null);

	const identityValues = useExecutionMetricsDistinctValues(
		lineChartWidget.identity.metricName,
		lineChartWidget.identity.from,
	);

	useEffect(() => {
		if (selectedValue == null && identityValues.value && identityValues.value.length > 0) {
			setSelectedValue(identityValues.value[0]);
		}
	}, [selectedValue, setSelectedValue, identityValues.value]);

	if (lineChartWidget.identity.mode === 'com.steadybit.widget.line_chart.identity_mode.select') {
		return (
			<>
				<ExperimentRunCard
					title={lineChartWidget.title}
					additionalHeaderItems={
						<Switcher values={identityValues.value} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
					}
				>
					{!identityValues.value?.length || !selectedValue ? (
						<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
							{liveUpdate || identityValues.loading ? (
								<LoadingContent loading={identityValues.loading}>Waiting for metrics...</LoadingContent>
							) : (
								<EmptyContent>No metrics recorded.</EmptyContent>
							)}
						</Stack>
					) : (
						<WidgetContent
							widget={lineChartWidget}
							value={selectedValue}
							position={position}
							duration={duration}
							start={start}
							onPositionSelect={onPositionSelect}
						/>
					)}
				</ExperimentRunCard>
			</>
		);
	} else if (lineChartWidget.identity.mode === 'com.steadybit.widget.line_chart.identity_mode.widget-per-value') {
		return (
			<>
				{identityValues.value?.map((value) => (
					<ExperimentRunCard key={value} title={lineChartWidget.title + ' for ' + value}>
						<WidgetContent
							widget={lineChartWidget}
							position={position}
							duration={duration}
							value={value}
							start={start}
							onPositionSelect={onPositionSelect}
						/>
					</ExperimentRunCard>
				))}
			</>
		);
	} else {
		return <ExperimentRunCard title={lineChartWidget.title}>LineChartWidget: Unknown identity.mode</ExperimentRunCard>;
	}
}

interface SwitcherProps {
	values: string[] | undefined;
	selectedValue: string | null;
	setSelectedValue: (target: string | null) => void;
}

function Switcher({ selectedValue, values, setSelectedValue }: SwitcherProps): ReactElement {
	if (!values || values.length <= 1) {
		return <></>;
	}

	return (
		<presets.dropdown.SingleChoiceButton
			selectedId={selectedValue || undefined}
			items={values.map((id) => ({ id, label: id }))}
			size="small"
			placement="bottom-end"
			maxContentHeight="300px"
			onSelect={setSelectedValue}
			style={{
				maxWidth: '220px',
			}}
		>
			{selectedValue || 'Select'}
		</presets.dropdown.SingleChoiceButton>
	);
}

const COLORS: Record<string, string> = {
	['danger']: theme.colors.feedbackErrorDark,
	['warn']: theme.colors.experimentWarning,
	['info']: 'neutral400',
	['success']: theme.colors.cyanDark,
};

function getColor(colorName?: string): string {
	if (colorName) {
		return COLORS[colorName] || theme.colors.neutral400;
	}
	return theme.colors.neutral400;
}

function WidgetContent({
	position,
	duration,
	widget,
	start,
	value,
	onPositionSelect,
}: {
	position: ExperimentPlayerTimeStamp | null;
	widget: LineChartWidgetVO;
	duration: number;
	start: number;
	value: string;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
}): ReactElement {
	const metrics = useFilteredExecutionMetrics(widget.identity.metricName, widget.identity.from, value);

	const data = useMemo(() => {
		const bars: Stat[] = metrics.value?.map((m) => metricToStat(m, widget)) || [];
		const results = Object.entries(countBy(bars, (metric) => metric.group)).map(([group, value]) => ({
			group: group,
			count: value,
			color: getColor(widget.grouping?.groups?.find((g) => g.title === group)?.color),
		}));
		return { bars, results };
	}, [metrics.value]);
	const [hidden, setHidden] = useState<Record<string, boolean>>({});
	const filteredData = useMemo(() => data.bars.filter((entry) => !hidden[entry.group]), [data.bars, hidden]);

	const showSummary = widget.grouping?.showSummary && widget.grouping?.groups?.length > 0;

	return (
		<Flex style={{ width: '100%', padding: '6px 6px 0 0' }}>
			<m.LineChart
				height={200}
				data={
					filteredData.length === 0
						? []
						: [
								filteredData.map(({ group, ts, metricValue, tooltipValues, groupColor }) => ({
									id: group,
									group: group,
									ts,
									value: metricValue,
									tooltipValues: tooltipValues,
									color: groupColor,
									data: {
										group,
										renderTooltipContent: () => {
											return (
												<>
													{tooltipValues?.map((content) => (
														<KeyValue
															key={content.k}
															k={content.k}
															v={content.v + (content.unit ? ' ' + content.unit : '')}
														/>
													))}
												</>
											);
										},
									},
								})),
							]
				}
				start={start}
				end={start + duration}
				timeMarkers={position ? [position] : []}
				onPositionSelect={onPositionSelect}
				formatX={(v) => formatTime(new Date(v))}
				formatY={(v) => `${v} ${widget.tooltip?.metricValueUnit || ''}`}
				renderTooltip={renderTooltip}
			/>

			{showSummary && (
				<Grid
					cols="auto auto"
					spacing="small"
					align="start"
					style={{ width: '100%', py: 'xxSmall', pl: 'xxLarge', pr: 'small' }}
				>
					<Flex
						wrap
						spacing="xxSmall"
						style={{ maxHeight: '64px', height: '100%', maxWidth: '100%', overflowX: 'auto' }}
					>
						{data.results.map((result) => (
							<Flex key={result.group} direction="horizontal" align="center" spacing="xxSmall">
								<Checkbox
									id={result.group}
									checked={!hidden[result.group]}
									color={result.color}
									onChange={(e) => setHidden((c) => ({ ...c, [result.group]: !e.target.checked }))}
								/>
								<Label htmlFor={result.group}>
									<Tooltip content={result.group} onlyShowOnEllipsis>
										<Container
											sx={{
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
											}}
										>
											<Text as="span" variant="small" color="neutral400">
												{result.count}x
											</Text>
											<Text as="span" variant="small" color="neutral600">
												{result.group}
											</Text>
										</Container>
									</Tooltip>
								</Label>
							</Flex>
						))}
					</Flex>

					<m.PieChart
						size={48}
						data={data.results.map((entry) => ({
							value: entry.count,
							color: entry.color,
							label: entry.group,
						}))}
						renderTooltip={renderPieChartTooltip}
					/>
				</Grid>
			)}
		</Flex>
	);
}

interface TooltipData extends LineChartTimeMetric {
	x: Date;
	y: number;
	color: string;
	data: {
		renderTooltipContent: () => ReactElement;
		group: string;
	};
}

function renderTooltip(dataPoints: TooltipData[]): ReactElement {
	const firstDataPoint = dataPoints[0];

	return (
		<Container
			sx={{
				py: 'xSmall',
				px: 'small',
				backgroundColor: 'neutral000',
				boxShadow: 'applicationMedium',
				border: '1px solid ' + theme.colors.neutral300,
				borderRadius: 4,
				maxWidth: 400,
			}}
		>
			<Text color={firstDataPoint.color} variant={'smallMedium'} mb={'xSmall'}>
				{firstDataPoint.data.group}
			</Text>

			<Stack size="xxSmall">
				<KeyValue k="Timestamp" v={formatTime(new Date(firstDataPoint.ts))} />
				{firstDataPoint.data.renderTooltipContent()}
			</Stack>
		</Container>
	);
}

interface KeyValueProps {
	k: string;
	v: string;
}

function KeyValue({ k, v }: KeyValueProps): ReactElement {
	return (
		<Stack size="none">
			<Text variant="small" color="neutral600">
				{k}:
			</Text>
			<Text variant="smallStrong" color="neutral700" sx={{ wordBreak: 'break-word' }}>
				{v}
			</Text>
		</Stack>
	);
}

type TooltipValue = {
	k: string;
	v: string;
	unit?: string;
};

type Stat = {
	ts: number;
	group: string;
	groupColor: string;
	metricValue: number;
	tooltipValues?: TooltipValue[];
};

function renderPieChartTooltip({ color, label, value }: PieChartMetric): ReactElement {
	return (
		<presets.dropdown.DropdownContentFrame>
			<Flex
				spacing="xxSmall"
				style={{
					p: 'xSmall',
				}}
			>
				<Text color={color ?? theme.colors.neutral400} variant="smallMedium">
					{label}
				</Text>
				<Text color="neutral600" variant={'smallMedium'}>
					{value}
				</Text>
			</Flex>
		</presets.dropdown.DropdownContentFrame>
	);
}

type MetricVO = { ts: number; value: number; labels: Record<string, string> };

function metricToStat(metric: MetricVO, widget: LineChartWidgetVO): Stat {
	let group = '';
	let groupColor = undefined;

	const fallbackGroup = widget.grouping?.groups?.find(
		(g) => g.matcher?.type === 'com.steadybit.widget.line_chart.group_matcher_fallback',
	);
	const otherGroups = widget.grouping?.groups?.filter(
		(g) => g.matcher.type !== 'com.steadybit.widget.line_chart.group_matcher_fallback',
	);

	for (const g of otherGroups || []) {
		if (g.matcher.type === 'com.steadybit.widget.line_chart.group_matcher_not_empty') {
			if (metric.labels[(g.matcher as GroupMatcherNotEmpty).key]) {
				group = g.title;
				groupColor = g.color;
				break;
			}
		} else if (g.matcher.type === 'com.steadybit.widget.line_chart.group_matcher_key_equals_value') {
			const keyEqualsValueMatcher = g.matcher as GroupMatcherKeyEqualsValue;
			if (metric.labels[keyEqualsValueMatcher.key] === keyEqualsValueMatcher.value) {
				group = g.title;
				groupColor = g.color;
				break;
			}
		}
	}
	if (fallbackGroup && group === '') {
		group = fallbackGroup.title;
		groupColor = fallbackGroup.color;
	}
	const tooltipValues: TooltipValue[] =
		(widget.tooltip?.additionalContent
			?.map((tooltip) => {
				const value = metric.labels[tooltip.from];
				if (value !== undefined) {
					return {
						k: tooltip.title,
						v: value,
						unit: tooltip.unit,
					};
				}
				return undefined;
			})
			.filter((v) => v !== undefined) as TooltipValue[]) || [];

	tooltipValues.unshift({
		k: widget.tooltip?.metricValueTitle || 'Value',
		v: metric.value.toString(),
		unit: widget.tooltip?.metricValueUnit,
	});

	return {
		ts: metric.ts,
		group,
		groupColor: getColor(groupColor),
		metricValue: metric.value,
		tooltipValues,
	};
}

function useExecutionMetricsDistinctValues(name: string, label: string): DataStreamResult<string[]> {
	return useExecutionMetrics({
		dependencies: [name, label],
		filterMetric: (_metric) => _metric.labels.name === 'response_time',
		transformResponse: (_metrics) => [
			...new Set(
				_metrics
					.filter((m) => m.labels.name === name && m.labels[label])
					.map((m) => m.labels[label])
					.sort(),
			),
		],
	});
}

function useFilteredExecutionMetrics(name: string, label: string, labelValue: string): DataStreamResult<MetricVO[]> {
	return useExecutionMetrics({
		dependencies: [name, label, labelValue],
		filterMetric: (_metric) => _metric.labels.name === 'response_time',
		transformResponse: (_metrics) =>
			_metrics
				.filter((m) => m.labels.name === name && m.labels[label] === labelValue)
				.map((m) => ({ ts: m.ts, value: m.value, labels: m.labels })),
	});
}
