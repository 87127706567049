/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { PositiveSpacings } from '@steadybit/ui-components-lib';
import { Container } from 'components';
import { ReactElement } from 'react';

interface ModalFooterProps {
	children: ReactElement | ReactElement[];
	px?: keyof typeof PositiveSpacings;
	withShadow?: boolean;
}

export default function ModalFooter({ px = 'xxxLarge', withShadow, children }: ModalFooterProps): ReactElement {
	return (
		<Container
			className="modalFooter"
			display="flex"
			alignItems="center"
			justifyContent="flex-end"
			py="medium"
			px={px}
			mt="auto"
			sx={{
				boxShadow: withShadow ? '0px -1px 14px #0000001a' : 'none',
			}}
		>
			{children}
		</Container>
	);
}
