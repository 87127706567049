/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DeploymentReplicaCountMetrics from 'pages/experiments/components/metrics/DeploymentReplicaCountMetrics';
import ExperimentRunCard from 'pages/experiments/components/ExperimentRunCard';
import { PredefinedWidgetVO } from 'ui-api';
import { ReactElement } from 'react';

import { WidgetProps } from '../types';

export default function PredefinedWidget({
	experimentExecution,
	widget,
	position,
	start,
	duration,
	progress,
}: WidgetProps): ReactElement {
	const predefinedWidget = widget as PredefinedWidgetVO;

	if (predefinedWidget.predefinedWidgetId === 'com.steadybit.widget.predefined.DeploymentReadinessWidget') {
		return (
			<ExperimentRunCard title="Deployment Readiness">
				<DeploymentReplicaCountMetrics
					experimentExecution={experimentExecution}
					duration={duration}
					position={position}
					progress={progress}
					start={start}
				/>
			</ExperimentRunCard>
		);
	}

	return (
		<ExperimentRunCard title={predefinedWidget.predefinedWidgetId}>
			Unknown Widget Type ${predefinedWidget.predefinedWidgetId}
		</ExperimentRunCard>
	);
}
