/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import DataStore from 'DataStore/DataStore';

import PlaceholderExtractionAndCleanupJob from './PlaceholderExtractionAndCleanupJob';
import { PreparedFormData } from './TemplateEditorLoader';
import MetadataEnrichment from './MetadataEnrichment';
import ValidationHandler from './ValidationHandler';
import { TemplateFormValues } from './types';

interface TemplateEditorFormProps {
	formData: PreparedFormData;
	children: ReactNode;
}

export default function TemplateEditorForm({ formData, children }: TemplateEditorFormProps): ReactElement {
	return (
		<DataStore<TemplateFormValues> initialData={{ ...formData, ...formData.initialValues }} initialErrors={{}}>
			<MetadataEnrichment />
			<ValidationHandler />
			<PlaceholderExtractionAndCleanupJob />
			{children}
		</DataStore>
	);
}
