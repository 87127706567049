/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { StateBadge } from 'components';
import { ReactElement } from 'react';

interface FailedBadgeProps {
	numberFailed: number;
}

export default function FailedBadge({ numberFailed }: FailedBadgeProps): ReactElement | null {
	if (numberFailed === 0) {
		return null;
	}
	return <StateBadge as="tag" value="FAILED" />;
}
