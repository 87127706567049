/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage, Flex, Grid, Text, TextInput } from '@steadybit/ui-components-lib';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import { wrapWithEnvironmentVariableMarkers } from 'pages/templates/utils';
import { Form, Formik, FormikErrors } from 'formik';
import { ReactElement, useEffect } from 'react';
import { EnvVarRegex } from 'utils/envVars';
import { theme } from 'styles.v2/theme';
import { VariableVO } from 'ui-api';

interface VariableInputsFormProps {
	existingVariables: VariableVO[];
	variableValue: string;
	variableKey: string;
	setHasErrors: (hasErrors: boolean) => void;
}

interface FormValues {
	existingVariables: VariableVO[];
	inititalValue: string;
	inititalKey: string;
	value: string;
	key: string;
}

export default function VariableInputsForm({
	existingVariables,
	variableValue,
	variableKey,
	setHasErrors,
}: VariableInputsFormProps): ReactElement {
	const initialValues: FormValues = {
		inititalValue: variableValue,
		inititalKey: variableKey,
		key: variableKey,
		value: variableValue,
		existingVariables,
	};

	useEffect(() => {
		setHasErrors(Object.keys(getErrors(initialValues)).length > 0);
	}, []);

	return (
		<Formik<FormValues>
			initialValues={initialValues}
			validate={(values) => {
				const errors = getErrors(values);
				setHasErrors(Object.keys(errors).length > 0);
				return errors;
			}}
			onSubmit={() => {}}
		>
			{({ values, errors, touched, setFieldValue, setFieldTouched }) => (
				<Form style={{ width: '100%' }}>
					<Grid
						cols="2fr 3fr"
						align="start"
						spacing="medium"
						style={{
							padding: '20px',
							width: '100%',
							backgroundColor: theme.colors.neutral100,
							borderRadius: 'xSmall',
						}}
					>
						<Flex spacing="xxSmall">
							<Text type="mediumStrong" neutral800>
								Key
							</Text>

							<Flex direction="horizontal" style={{ width: '100%' }}>
								<PlaceholderMarker marker="{{" left />
								<TextInput
									id="variable-key"
									placeholder="Key"
									value={values.key}
									onChange={(v) => {
										setFieldValue('key', v, true);
										setFieldTouched('key', true, false);
									}}
									style={{
										borderRadius: 'none',
										zIndex: 1,
									}}
								/>
								<PlaceholderMarker marker="}}" />
							</Flex>
							{errors.key && touched.key && <ErrorMessage type="small">{errors.key}</ErrorMessage>}
						</Flex>
						<Flex spacing="xxSmall">
							<Text type="mediumStrong" neutral800>
								Value
							</Text>
							<TextInput
								id="variable-value"
								placeholder="Value"
								value={values.value}
								onChange={(v) => {
									setFieldValue('value', v);
									setFieldTouched('value', true, false);
								}}
							/>
							{errors.value && touched.value && <ErrorMessage type="small">{errors.value}</ErrorMessage>}
						</Flex>
					</Grid>
				</Form>
			)}
		</Formik>
	);
}

function getErrors({ key, value, inititalKey, existingVariables }: FormValues): FormikErrors<FormValues> {
	const errors: FormikErrors<FormValues> = {};
	if (!key || key.trim() === '') {
		errors.key = 'A key is required';
	} else {
		const isUnique = key === inititalKey || !existingVariables.some((v) => v.key === key);
		if (!isUnique) {
			errors.key = 'This key already exists';
		} else if (wrapWithEnvironmentVariableMarkers(key).match(EnvVarRegex) === null) {
			errors.key = 'Only alphanumeric characters, underscores, hyphens and periods are allowed.';
		}
	}
	if (!value || value.trim() === '') {
		errors.value = 'A value is required';
	}
	return errors;
}
