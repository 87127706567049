/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Snackbar, Stack, Text, Tooltip } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { LandscapeViewColorConfigVO } from 'ui-api';
import { ampli } from 'ampli';

import ConfigureColorsOverrideModal from './ConfigureColorsOverrideModal';
import { theme } from '../../../styles.v2/theme';
import DropDownLabel from './DropDownLabel';
import { emit } from '../ServiceLocator';
import { ColorMapping } from '../types';
import { colors } from '../utils';

interface ColorListProps {
	setColorOverrides: (overrides: { [index: string]: string }) => void;
	colorConfig: LandscapeViewColorConfigVO;
	colorMapping: ColorMapping;
	colorBy: string;
	readOnly?: boolean;
}

export function ColorList({
	setColorOverrides,
	colorConfig,
	colorMapping,
	colorBy,
	readOnly,
}: ColorListProps): ReactElement {
	const [showColorConfigModal, setShowColorConfigModal] = useState(false);
	const colorList = colorMapping.colorList;

	// avoid to render a "Show 3 more" label
	const colorsToShowWhenCollapsed = colorList.length > 16 ? 11 : 16;

	const [showAll, setShowAll] = useState(false);
	useEffect(() => {
		setShowAll(colorsToShowWhenCollapsed >= colorList.length);
	}, [colorsToShowWhenCollapsed]);

	useEffect(() => {
		// -1 because grey is not rendered
		if (colorMapping.colorList.length > Object.keys(colors).length - 1) {
			ampli.landscapeExplorerColorReused({
				url: window.location.href,
				number_color_assignments: colorMapping.colorList.length,
			});
			Snackbar.darkForever(
				'The property you have picked will result in colors being reused for different values. Use advanced configuration or a filter if you are unhappy with the result',
				{
					toastId: 'explore-too-many-colors',
					containerId: 'explore',
				},
			);
		} else {
			Snackbar.dismiss('explore-too-many-colors');
		}

		return () => {
			Snackbar.dismiss('explore-too-many-colors');
		};
	}, [colorMapping.colorList.length]);

	return (
		<>
			{showColorConfigModal && (
				<ConfigureColorsOverrideModal
					colorMapping={colorMapping}
					onClose={() => setShowColorConfigModal(false)}
					onSave={(newOverrides) => {
						setColorOverrides(newOverrides);
						setShowColorConfigModal(false);
					}}
				/>
			)}

			<Stack size="xSmall" pt="medium" sx={{ borderTop: '1px solid ' + theme.colors.neutral300 }}>
				{readOnly ? (
					<Text variant="smallStrong">Color Legend</Text>
				) : (
					<DropDownLabel
						label="Color Legend"
						configLabel="Edit Colors"
						canEdit
						onConfigureClick={() => setShowColorConfigModal(true)}
					/>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
					onMouseOut={() => {
						emit({ type: 'colorLegendHovered' });
					}}
				>
					{colorList.slice(0, showAll ? undefined : colorsToShowWhenCollapsed).map(({ label, color }, i) => {
						return (
							<Stack
								key={i}
								direction="horizontal"
								size="xSmall"
								alignItems="center"
								onMouseEnter={() => {
									let attributes = [label];
									if (colorConfig && colorConfig.mappings.some((mapping) => mapping.groupName === label)) {
										attributes = colorConfig.mappings
											.filter((mapping) => mapping.groupName === label)
											.map((mapping) => mapping.attributeValues)[0];
									}

									emit({ type: 'colorLegendHovered', colorAttributeKey: colorBy, colorAttributeValues: attributes });
								}}
								sx={{
									cursor: 'pointer',
									'&:hover': {
										fontWeight: '700',
									},
								}}
							>
								<Container
									sx={{
										minWidth: '16px',
										minHeight: '16px',
										maxWidth: '16px',
										maxHeight: '16px',
										borderRadius: 4,
										bg: toColorString(color),
									}}
								/>
								<Tooltip content={label}>
									<Container sx={{ color: 'neutral600', fontSize: 13, ...textEllipsis }}>{label}</Container>
								</Tooltip>
							</Stack>
						);
					})}
					{!showAll && (
						<Text
							variant="smallStrong"
							color="neutral600"
							onClick={() => setShowAll(true)}
							sx={{
								cursor: 'pointer',
								'&:hover': {
									textDecoration: 'underline',
								},
							}}
						>
							Show {colorList.length - colorsToShowWhenCollapsed} more
						</Text>
					)}
				</div>
			</Stack>
		</>
	);
}

export function toColorString(color?: [number, number, number]): string {
	if (!color) {
		return 'undefined';
	}
	return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}
