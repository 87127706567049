/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, ExperimentStepActionVO, FieldTypeVO, FieldVO } from 'ui-api';
import { KeyValuePair } from 'components/KeyValueListInput/KeyValueListInput';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { ExperimentError } from 'pages/experimentsV2/types';
import { useStoreField } from 'DataStore/DataStore';
import React, { ReactElement } from 'react';
import { Separator } from 'hocs/Separator';
import { Header } from 'hocs/Header';

import ActionTargetSelection from '../ActionTargetSelection';
import StressNgWorker from './Controls/StressNgWorker';
import StringOptions from './Controls/StringOptions';
import MultiSelect from './Controls/MultiSelect';
import Percentage from './Controls/Percentage';
import TextArray from './Controls/TextArray';
import Duration from './Controls/Duration';
import KeyValue from './Controls/KeyValue';
import TextArea from './Controls/TextArea';
import FieldWrapper from './FieldWrapper';
import Bitrate from './Controls/Bitrate';
import Boolean from './Controls/Boolean';
import Integer from './Controls/Integer';
import File from './Controls/File';
import Text from './Controls/Text';
import Uri from './Controls/Uri';

export interface CommonFieldProps {
	expanded?: boolean;
	action?: ActionVO;
	stepPath: string;
	setExpanded?: (expanded: boolean) => void;
}

interface FieldValueHandlerProps extends CommonFieldProps {
	disabled: boolean;
	field: FieldVO;
	path: string;
}

export default function FieldValueHandler({
	disabled,
	field,
	path,
	...rest
}: FieldValueHandlerProps): ReactElement | null {
	const { mode } = useEditorSettings();

	const { value, errors, setValue } = useStoreField<unknown>(path);

	const error: ExperimentError | undefined = errors as ExperimentError | undefined;
	const stepErrors = error ? [error] : [];

	return (
		<Field
			stepErrors={stepErrors}
			disabled={disabled}
			field={field}
			value={value}
			path={path}
			mode={mode}
			key={path}
			{...rest}
			setValue={setValue}
		/>
	);
}

interface FieldProps extends CommonFieldProps {
	stepErrors: ExperimentError[];
	disabled: boolean;
	value: unknown;
	field: FieldVO;
	mode?: string;
	path: string;
	setValue: (value: unknown) => void;
}

class Field extends React.Component<FieldProps> {
	constructor(props: FieldProps) {
		super(props);
	}

	shouldComponentUpdate(nextProps: Readonly<FieldProps>): boolean {
		return (
			this.props.value !== nextProps.value ||
			this.props.disabled !== nextProps.disabled ||
			!areErrorsEqual(this.props.stepErrors, nextProps.stepErrors)
		);
	}

	render(): ReactElement | null {
		const { stepErrors, mode, field, value, setValue } = this.props;

		if (field.deprecated && !value) {
			return null;
		}

		if (field.readonly) {
			return <FieldWrapper field={field}>{readOnlyContent(field, value as string)}</FieldWrapper>;
		}

		if (field.type === 'header') {
			return <Header variant="small" mb="small" label={field.label} />;
		}

		if (field.type === 'separator') {
			return <Separator />;
		}

		const errors: ExperimentError[] = stepErrors.map((error) => ({
			...error,
			level: mode === 'templateUsage' ? 'warning' : 'error',
		}));
		return (
			<FieldWrapper field={field} errors={errors}>
				<FieldInput
					{...this.props}
					hasErrors={errors.length > 0}
					hasWarnings={false}
					setValue={setValue}
					required={field.required}
				/>
			</FieldWrapper>
		);
	}
}

interface FieldInputProps extends CommonFieldProps {
	hasWarnings: boolean;
	hasErrors: boolean;
	required?: boolean;
	disabled: boolean;
	field: FieldVO;
	value: unknown;
	setValue: (value: unknown) => void;
}

export function FieldInput({
	hasWarnings,
	hasErrors,
	expanded,
	stepPath,
	disabled,
	required,
	action,
	value,
	field,
	setExpanded,
	setValue,
}: FieldInputProps): ReactElement {
	const { type, min, max, acceptedFileTypes, optionsOnly, durationUnits } = field;
	let options = field.options;

	if (options) {
		options = options.slice().sort((a, b) => a.label.localeCompare(b.label));
	}

	const common = {
		hasWarnings,
		hasErrors,
		disabled,
		setValue,
	};

	if (type === 'bitrate') {
		return <Bitrate {...common} value={value as string} />;
	}
	if (type === 'boolean') {
		return <Boolean {...common} value={value as boolean} />;
	}
	if (type === 'duration') {
		return <Duration {...common} value={value as string} units={durationUnits} />;
	}
	if (type === 'file') {
		return <File {...common} accept={acceptedFileTypes?.join(',') || ''} value={value as string} />;
	}
	if (type === 'integer') {
		return <Integer {...common} min={min} max={max} value={value as number} />;
	}
	if (type === 'key-value') {
		return <KeyValue {...common} value={value as KeyValuePair[]} />;
	}
	if (type === 'percentage') {
		return <Percentage {...common} min={min} max={max} value={value as string} />;
	}
	if (type === 'stressng-workers') {
		return <StressNgWorker {...common} min={min} max={max} value={value as string} />;
	}
	if (type === 'string') {
		if (options?.length) {
			return (
				<StringOptions
					{...common}
					optionsOnly={optionsOnly === undefined ? true : optionsOnly}
					value={value as string}
					required={required}
					options={options}
				/>
			);
		}
		return <Text {...common} value={value as string} />;
	}
	if (type === 'string[]') {
		if (options?.length) {
			return <MultiSelect {...common} options={options} value={value as string[]} />;
		}
		return <TextArray {...common} value={value as string[]} />;
	}
	if (type === 'textarea') {
		return <TextArea {...common} value={value as string} />;
	}
	if (type === 'regex') {
		return <Text {...common} value={value as string} />;
	}
	if (type === 'url') {
		return <Uri {...common} placeholder="https://example.com" value={value as string} />;
	}

	if (options?.length) {
		return (
			<StringOptions
				{...common}
				options={options}
				value={value as string}
				optionsOnly={optionsOnly === undefined ? true : optionsOnly}
			/>
		);
	}

	if (type === 'target-selection') {
		return <TargetSelectionField expanded={expanded} stepPath={stepPath} action={action} setExpanded={setExpanded} />;
	}

	return <Text {...common} value={value as string} />;
}

function readOnlyContent(field: FieldVO, value: string): string {
	if (field.type === 'boolean') {
		return value ? 'true' : 'false';
	}

	if (field.type === 'string[]' && field.options?.length) {
		const selectedOptions = field.options
			.filter((option) => value.includes(option.value))
			.map((option) => option.label)
			.join(', ');
		return selectedOptions || value;
	}

	if (field.type === 'string' && field.options?.length) {
		return field.options.find((option) => value === option.value)?.label || value;
	}

	return value;
}

export function getDefaultValue(type: FieldTypeVO): unknown {
	if (
		type === 'bitrate' ||
		type === 'duration' ||
		type === 'percentage' ||
		type === 'integer' ||
		type === 'stressng-workers'
	) {
		return 0;
	}
	if (type === 'boolean') {
		return false;
	}
	return '';
}

function areErrorsEqual(a: ExperimentError[], b: ExperimentError[]): boolean {
	if (a.length !== b.length) {
		return false;
	}
	for (let i = 0; i < a.length; i++) {
		if (a[i].message !== b[i].message || a[i].level !== b[i].level) {
			return false;
		}
	}
	return true;
}

function TargetSelectionField({ expanded, stepPath, action, setExpanded }: CommonFieldProps): ReactElement {
	const { value: actionStep } = useStoreField<ExperimentStepActionVO | undefined>(stepPath);
	if (actionStep && action && setExpanded && expanded !== undefined) {
		return (
			<ActionTargetSelection
				actionStep={actionStep}
				expanded={expanded}
				stepPath={stepPath}
				autoFocus={false}
				action={action}
				setExpanded={setExpanded}
			/>
		);
	}
	console.error('Field target-selection requires additional props which are not present.');
	return <></>;
}
