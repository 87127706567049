/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { EnvironmentSummaryVO, OccuranceVO, TemplatePlaceholderVO } from 'ui-api';
import StepIndicators, { Step } from 'components/StepIndicator/StepIndicator';
import ChunksWithPlaceholders from 'pages/templates/ChunksWithPlaceholders';
import { IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { useStoreField } from 'DataStore/DataStore';
import { Pill, Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';

import { UseTemplateFormData } from '../UseTemplateFormLoadingHandler';
import { Placeholder } from './TemplatePlaceholder';
import { ampli } from '../../../../ampli';
import Footer from './Footer';
import Pages from './Pages';

interface NavigationWithContentProps {
	selectedEnvironment: EnvironmentSummaryVO | undefined;
	environments: EnvironmentSummaryVO[];
	submitLabel: string;
	onSubmit: (values: UseTemplateFormData) => void;
}

export default function NavigationWithContent({
	selectedEnvironment,
	environments,
	submitLabel,
	onSubmit,
}: NavigationWithContentProps): ReactElement {
	const { value: placeholderValuesMap } = useStoreField<Record<string, string>>('placeholderValuesMap');
	const { value: variablesMap } = useStoreField<Record<string, OccuranceVO[]>>('variablesMap');
	const placeholdersField = useStoreField<TemplatePlaceholderVO[]>('placeholders');
	const { value: templateTitle } = useStoreField<string>('templateTitle');
	const { value: environmentId } = useStoreField<string>('environmentId');

	const placeholders: Placeholder[] = placeholdersField.value.map(({ key, name, description }) => {
		const value = placeholderValuesMap[key] || '';
		return {
			description,
			value,
			name,
			key,
		};
	});

	const hasEnvironmentPage = environments.length > 1 || Object.keys(variablesMap).length > 0;
	const totalPages = hasEnvironmentPage ? placeholders.length + 1 : placeholders.length;

	const [page, setPage] = useState(0);

	useEffect(() => {
		ampli.experimentTemplateUsedWizardStepStarted({
			experiment_template_name: templateTitle,
			experiment_template_step: page + 1,
			experiment_template_last_step: page + 1 === totalPages,
			experiment_template_step_placeholder: page >= 1 ? placeholders[page - 1]?.name : undefined,
			experiment_template_total_steps: totalPages,
		});
		if (page > 0) {
			const completedPage = page - 1;
			ampli.experimentTemplateUsedWizardStepCompleted({
				experiment_template_name: templateTitle,
				experiment_template_step: completedPage + 1,
				experiment_template_last_step: completedPage === totalPages,
				experiment_template_step_placeholder: completedPage >= 1 ? placeholders[completedPage - 1]?.name : undefined,
				experiment_template_total_steps: totalPages,
			});
		}
	}, [page, totalPages]);

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '278px 1fr',

				border: '1px solid ' + theme.colors.neutral300,
				marginBottom: '32px',
			}}
		>
			<div
				style={{
					padding: '24px 16px 32px',
					backgroundColor: theme.colors.neutral100,
				}}
			>
				<StepIndicators
					currentPage={page}
					steps={
						[
							hasEnvironmentPage
								? {
										label: 'Select Environment',
										description: (currentPage: number, stepIndex: number) => {
											if (currentPage > stepIndex) {
												return <Environment environment={environments.find((e) => e.id === environmentId)} />;
											}
											return '';
										},
										onClick: () => setPage(0),
									}
								: null,
							...placeholders.map(({ key, name, value }, index) => {
								return {
									label: name || key,
									description: () => (
										<Text variant="smallCode" color="neutral700" pl={7}>
											<ChunksWithPlaceholders value={value || ''} />
										</Text>
									),
									onClick: () => setPage(hasEnvironmentPage ? 1 + index : index),
								};
							}),
						].filter((step) => step !== null) as Step[]
					}
				/>
			</div>
			<Stack size="none" sx={{ flexDirection: 'column-reverse' }}>
				<Footer submitLabel={submitLabel} pages={totalPages} page={page} setPage={setPage} onSubmit={onSubmit} />
				<Stack sx={{ flexGrow: 1, p: 'medium', minHeight: '500px' }}>
					<Pages
						selectedEnvironment={selectedEnvironment}
						hasEnvironmentPage={hasEnvironmentPage}
						placeholder={placeholders[hasEnvironmentPage ? page - 1 : page]}
						environments={environments}
						page={page}
					/>
				</Stack>
			</Stack>
		</div>
	);
}

function Environment({ environment }: { environment: EnvironmentSummaryVO | undefined }): ReactElement {
	return (
		<Pill
			backgroundColorOnHover="neutral300"
			backgroundColor="neutral200"
			color="neutral800"
			sx={{
				height: '28px',
				fontSize: '12px',
				borderRadius: '14px',
				pr: 'small',
				overflowX: 'hidden',
				...textEllipsis,
			}}
		>
			{environment?.global ? (
				<IconEnvironmentGlobal variant="small" mr="xxSmall" />
			) : (
				<IconEnvironment variant="small" mr="xxSmall" />
			)}
			<span style={{ ...textEllipsis }}>{environment?.name || ''}</span>
		</Pill>
	);
}
