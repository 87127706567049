/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

interface ProgressIndicatorProps {
	inverted?: boolean;
	progress: number;
}

export default function ProgressIndicator({ progress = 0, inverted }: ProgressIndicatorProps): ReactElement | null {
	progress = Math.max(Math.min(progress, 1.0), 0.0);
	if (inverted) {
		progress = 1 - progress;
	}

	const strokeWidth = 2;
	const pixelSize = 30 - strokeWidth;

	const viewBox = `0 0 ${pixelSize} ${pixelSize}`;
	const radius = (pixelSize - strokeWidth) / 2;
	const circumference = radius * Math.PI * 2;
	const dash = progress * circumference;

	return (
		<svg
			width={pixelSize}
			height={pixelSize}
			viewBox={viewBox}
			style={{
				position: 'absolute',
				top: strokeWidth,
				bottom: strokeWidth,
				left: strokeWidth,
				right: strokeWidth,

				pointerEvents: 'none', // important to prevent mouse events from being captured by the SVG
			}}
		>
			<circle
				style={{
					transition: 'all 1s linear',
				}}
				fill="none"
				stroke={'white'}
				cx={pixelSize / 2}
				cy={pixelSize / 2}
				r={radius}
				strokeWidth={strokeWidth}
				transform={`rotate(-90 ${pixelSize / 2} ${pixelSize / 2})`}
				strokeDasharray={`${dash} ${circumference - dash}`}
				strokeLinecap="round"
			/>
		</svg>
	);
}
