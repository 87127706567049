/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStoreField } from 'DataStore/DataStore';
import { ReactElement } from 'react';

export default function MissingEnvironmentBlurOverlay(): ReactElement | null {
	const { value: environmentId } = useStoreField<string>('environmentId');
	if (environmentId) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				top: '128px',
				left: '0',
				right: '0',
				bottom: '0',
				backgroundColor: '#e8edf4cc',
				backdropFilter: 'blur(2px)',
				zIndex: 4,
			}}
		/>
	);
}
