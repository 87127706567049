/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { IconLightbulb } from 'components/icons';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { Link } from 'components';

export default function CronExamples(): ReactElement {
	return (
		<Flex
			direction="horizontal"
			spacing="xSmall"
			style={{
				width: '100%',
				p: 'small',
				borderRadius: 'xSmall',
				alignItems: 'flex-start',
				border: '1px solid ' + theme.colors.neutral300,
			}}
		>
			<IconLightbulb variant="large" color="neutral700" />
			<Flex spacing="xSmall">
				<Text type="mediumStrong" neutral700>
					Expression examples:
				</Text>
				<CronTabExample syntax="0 15 10 ? * *" description="= 10:15am every day" />
				<CronTabExample syntax="0 15 10 * * ? 2025" description="= 10:15am every day during the year 2025" />
				<CronTabExample
					syntax="0 15 10 ? * MON-FRI"
					description="= 10:15am every Monday, Tuesday, Wednesday, Thursday and Friday"
				/>
				<Flex direction="horizontal" spacing="xxSmall" align="center" wrap>
					<Text type="smallMedium" neutral600>
						If you need other examples or you have doubt on cron quartz syntax, you can check our
						<Link
							href="https://docs.steadybit.com/use-steadybit/experiments/schedule#recurrent-schedule"
							external
							style={{ margin: '0 4px' }}
						>
							documentation
						</Link>
						on this regard.
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
}

interface CronTabExampleProps {
	syntax: string;
	description: string;
}

function CronTabExample({ syntax, description }: CronTabExampleProps): ReactElement {
	return (
		<Flex direction="horizontal" spacing="xSmall" align="center">
			<Text
				type="code"
				style={{
					backgroundColor: Colors.neutral100,
					color: Colors.neutral600,
					px: 'xSmall',
					py: 'xxSmall',
					borderRadius: 'xxSmall',
				}}
			>
				{syntax}
			</Text>
			<Text type="code" neutral600>
				{description}
			</Text>
		</Flex>
	);
}
