/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import SplashScreen from 'pages/splashScreen/splashScreen';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { lazy, ReactElement, Suspense } from 'react';
import { UnauthorizedError } from 'services/authApi';
import { UserContext } from 'services/usersApi';
import { Services } from 'services/services';
import { Container } from 'components';

const MobileTenantCreationNotification = lazy(() => import('./mobileTenantCreationNotification'));
const WaitForTenantPreparation = lazy(() => import('./waitForTenantPreparationContent'));

export default function WaitForTenantPreparationLazyLoader(): ReactElement {
	const [user] = useAsyncState(() => Services.users.getCurrentUser());

	const error = user.error;
	const loading = user.loading;

	if (loading || error || !user.value) {
		return (
			<SplashScreen>
				{error && !(error instanceof UnauthorizedError) && <div>{error.message ?? error.toString()}</div>}
			</SplashScreen>
		);
	}

	if (isMobile()) {
		return (
			<UserContext.Provider value={{ user: user.value }}>
				<Suspense
					fallback={
						<Container display="flex" alignItems="center" justifyContent="center" height="100vh">
							Loading
						</Container>
					}
				>
					<MobileTenantCreationNotification />
				</Suspense>
			</UserContext.Provider>
		);
	}

	return (
		<UserContext.Provider value={{ user: user.value }}>
			<Suspense fallback={<></>}>
				<WaitForTenantPreparation />
			</Suspense>
		</UserContext.Provider>
	);
}
function isMobile(): boolean {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
