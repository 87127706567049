/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, ErrorMessage, Flex, Grid, Icon, Spacings, Text, Tooltip } from '@steadybit/ui-components-lib';
import { ExperimentScheduleSummaryVO, ExperimentScheduleVO, UpsertExperimentScheduleVO } from 'ui-api';
import { ModalHeaderV2, ModalOverlay, ModalV2, Snackbar, userConfirm } from 'components';
import { compareNextExecution } from 'pages/experiments/SchedulesList/utils';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { aggregateErrorInformation } from 'utils/error';
import Skeletons from 'components/Skeleton/Skeletons';
import { Services } from 'services/services';

import SchedulesSidebarItem from './SchedulesSidebarItem';
import SchedulesSidebar from './SchedulesSidebar';
import { FilterType } from './SchedulesFilter';
import EditSchedule from './EditSchedule';

export interface ScheduleWithNextRun extends UpsertExperimentScheduleVO {
	nextExecution?: Date;
	id: string;
}

export interface Schedule extends ScheduleWithNextRun {
	__isTouched: boolean;
	__type: 'once' | 'cron';
}

interface EditSchedulesLoader {
	initSelectedScheduleId?: string | null;
	experimentName: string | undefined;
	experimentKey: string;
	onClose: () => void;
}

type OnCloseCallbackCheck = () => Promise<boolean>;

export default function EditSchedulesLoader(props: EditSchedulesLoader): ReactElement {
	const registeredOnCloseCheck = useRef<OnCloseCallbackCheck | null>(null);
	const schedulesResult = Services.schedulesApi.useSchedules$(props.experimentKey);

	const isLoading = schedulesResult.loading || !schedulesResult.value;
	const errorMessage = schedulesResult.error?.message;

	return (
		<ModalOverlay
			open
			centerContent
			onClose={async () => {
				if (registeredOnCloseCheck.current == null || (await registeredOnCloseCheck.current())) {
					props.onClose();
				}
			}}
		>
			{({ close }) => (
				<ModalV2 slick withFooter width={1200} minHeight={600}>
					<ModalHeaderV2 title="Schedule experiment" onClose={close} />
					{errorMessage ? (
						<ErrorModalContent>{errorMessage}</ErrorModalContent>
					) : isLoading ? (
						<LoadingModalContent />
					) : (
						<EditSchedulesModalContent
							{...props}
							schedules={schedulesResult.value.content.sort((a, b) => compareNextExecution(a, b, 'desc'))}
							setRegisteredOnCloseCheck={(check) => (registeredOnCloseCheck.current = check)}
						/>
					)}
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function LoadingModalContent(): ReactElement {
	return (
		<Grid cols="360px 1fr" spacing="small" style={{ height: '600px', margin: Spacings.small }}>
			<SchedulesSidebar>
				<Flex spacing="small" style={{ px: 'small', width: '100%' }}>
					<Skeletons height={60} widths={[304]} />
					<Skeletons height={60} widths={[304]} />
					<Skeletons height={60} widths={[304]} />
				</Flex>
			</SchedulesSidebar>

			<Flex spacing="small" style={{ px: 'small', width: '100%' }}>
				<Skeletons height={24} widths={[100, 60, 20, 40, 140]} />
			</Flex>
		</Grid>
	);
}

function ErrorModalContent({ children }: { children: string }): ReactElement {
	return (
		<Grid cols="360px 1fr" spacing="small" style={{ height: '600px', margin: Spacings.small }}>
			<SchedulesSidebar schedules={[]}>
				<Flex spacing="small" style={{ px: 'small', width: '100%' }}>
					<ErrorMessage withIcon>{children}</ErrorMessage>
				</Flex>
			</SchedulesSidebar>

			<Flex align="center" justify="center" style={{ width: '100%', height: '100%' }}>
				<Icon type="warning" size="xxxLarge" color="coral300" />
			</Flex>
		</Grid>
	);
}

interface EditSchedulesModalContentProps extends EditSchedulesLoader {
	setRegisteredOnCloseCheck: (check: OnCloseCallbackCheck | null) => void;
	schedules: ExperimentScheduleSummaryVO[];
}

function EditSchedulesModalContent({
	initSelectedScheduleId,
	experimentName,
	experimentKey,
	schedules,
	setRegisteredOnCloseCheck,
	onClose,
}: EditSchedulesModalContentProps): ReactElement {
	const permissions = useGlobalPermissions();
	const disabled = !permissions.schedules.canEdit.allowed;

	const [scheduleToEdit, setScheduleToEdit] = useState<Schedule | null>(() => {
		if (schedules.length === 0) {
			return newSchedule(experimentKey);
		}

		const scheduleToSet = schedules.find((s) => s.id === initSelectedScheduleId) || schedules[0];
		if (!scheduleToSet) {
			return null;
		}
		return { ...scheduleToSet, __isTouched: false, __type: scheduleToSet.cron ? 'cron' : 'once' };
	});

	const [activeFilter, setActiveFilter] = useState<FilterType>(null);
	const filteredSchedules = schedules.filter((s) =>
		activeFilter ? (activeFilter === 'once' ? !s.cron : !!s.cron) : true,
	);

	useEffect(() => {
		if (!scheduleToEdit || !scheduleToEdit.__isTouched) {
			setRegisteredOnCloseCheck(null);
			return;
		}
		setRegisteredOnCloseCheck(async () => await checkUnsavedChanges(scheduleToEdit));
	}, [scheduleToEdit]);

	return (
		<Grid cols="360px 1fr" spacing="small" style={{ overflow: 'hidden', margin: Spacings.small }}>
			<SchedulesSidebar schedules={schedules} activeFilter={activeFilter} setActiveFilter={setActiveFilter}>
				{scheduleToEdit?.id !== '<new>' && (
					<Tooltip content={permissions.schedules.canEdit.tooltipContent}>
						{({ setRefElement }) => (
							<Button
								ref={setRefElement}
								type="chromeless"
								withLeftIcon="plus"
								disabled={disabled}
								onClick={async () => {
									if (await checkUnsavedChanges(scheduleToEdit)) {
										setScheduleToEdit(newSchedule(experimentKey));
									}
								}}
							>
								New schedule
							</Button>
						)}
					</Tooltip>
				)}

				<Flex spacing="small" style={{ paddingTop: 1, px: 'small', pb: 'small', width: '100%', overflowY: 'auto' }}>
					{scheduleToEdit?.id === '<new>' && (
						<SchedulesSidebarItem key={scheduleToEdit.id} selected schedule={scheduleToEdit} onClick={() => {}} />
					)}

					{filteredSchedules.map((s, i) => (
						<SchedulesSidebarItem
							key={s.id}
							selected={s.id === scheduleToEdit?.id}
							addDivider={i > 0 && !s.enabled && filteredSchedules[i - 1].enabled}
							schedule={s}
							onClick={async () => {
								if (await checkUnsavedChanges(scheduleToEdit)) {
									setScheduleToEdit({ ...s, __isTouched: false, __type: s.cron ? 'cron' : 'once' });
								}
							}}
						/>
					))}
				</Flex>
			</SchedulesSidebar>

			<div style={{ padding: `0 ${Spacings.small}`, overflow: 'hidden', height: '100%' }}>
				{scheduleToEdit ? (
					<EditSchedule
						experimentName={experimentName}
						experimentKey={experimentKey}
						schedule={scheduleToEdit}
						setScheduleToEdit={setScheduleToEdit}
						onClose={onClose}
						onScheduleDeleted={() => {
							const firstSchedule = schedules.find((s) => s.id !== scheduleToEdit.id);
							setScheduleToEdit(
								firstSchedule
									? { ...firstSchedule, __isTouched: false, __type: firstSchedule.cron ? 'cron' : 'once' }
									: null,
							);
						}}
					/>
				) : (
					<Flex align="center" justify="center" style={{ width: '100%', padding: '10px 50px 100px' }}>
						<AstroidScreen
							title={
								<Text type="xLargeStrong" slate>
									There are no run scheduled
								</Text>
							}
							icon={<Icon type="calendar" size="xxLarge" style={{ color: Colors.slate }} />}
							description={
								<>
									<Text
										type="medium"
										style={{
											color: Colors.neutral600,
											display: 'flex',
											alignItems: 'center',
										}}
									>
										You can set up a new schedule for this experiment and choose whether it runs once or on a recurring
										basis using a Quartz expression.
									</Text>
									{!disabled && (
										<Button withLeftIcon="plus" onClick={() => setScheduleToEdit(newSchedule(experimentKey))}>
											Create schedule
										</Button>
									)}
								</>
							}
						/>
					</Flex>
				)}
			</div>
		</Grid>
	);
}

export function ScheduleContentBlock({
	description,
	children,
	setting,
	title,
}: {
	children?: ReactNode;
	description: string;
	setting: ReactNode;
	title: string;
}): ReactElement {
	return (
		<Flex
			spacing="xSmall"
			align="center"
			style={{
				width: '100%',
				p: 'small',
				backgroundColor: Colors.neutral100,
				borderRadius: 'xSmall',
			}}
		>
			<Flex direction="horizontal" spacing="medium" align="start" justify="spread" style={{ width: '100%' }}>
				<Flex spacing="xxSmall">
					<Text type="mediumStrong">{title}</Text>
					<Text type="small" neutral600>
						{description}
					</Text>
				</Flex>

				{setting}
			</Flex>

			{children}
		</Flex>
	);
}

export async function saveSchedule(schedule: Schedule): Promise<ExperimentScheduleVO | null> {
	try {
		const scheduleToSave: UpsertExperimentScheduleVO = { ...schedule };
		if (schedule.__type === 'once') {
			delete scheduleToSave.cron;
			delete scheduleToSave.timezone;
		} else {
			delete scheduleToSave.startAt;
		}
		if (schedule.id === '<new>') {
			delete scheduleToSave.id;
		}
		const savedSchedule = await Services.schedulesApi.scheduleExperiment(scheduleToSave);
		Snackbar.dark('Schedule saved');
		return savedSchedule;
	} catch (e) {
		Snackbar.error(aggregateErrorInformation(e));
	}
	return null;
}

async function checkUnsavedChanges(scheduleConfig: Schedule | null): Promise<boolean> {
	if (!scheduleConfig || !scheduleConfig.__isTouched) {
		return true;
	}

	const userSelection = await userConfirm({
		title: 'You have unsaved changes',
		message: 'You applied some changed to this schedule. What do you want to do?',
		actions: [
			{ value: 'discard', label: 'Discard changes' },
			{
				value: 'save',
				label: 'Save changes',
				variant: 'primary',
			},
		],
	});

	if (userSelection === 'cancel') {
		return false;
	}

	if (userSelection === 'save') {
		await saveSchedule(scheduleConfig);
	}
	return true;
}

function newSchedule(experimentKey: string): Schedule {
	const startAt = new Date();
	startAt.setMinutes(startAt.getMinutes() + 60);
	const schedule: Schedule = {
		id: '<new>',
		experimentKey,
		allowParallel: true,
		enabled: true,
		startAt,
		__isTouched: true,
		__type: 'once',
	};
	return schedule;
}
