/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { createErrorResult, createResult, DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { setGlobal, getGlobal } from 'utils/localStorage';
import { useEffect, useMemo, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { addDebuggingLine } from 'utils/debugging';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { TenantVO } from 'ui-api';

import { useTabActive } from './useTabActive';
import { tenantKeyParam } from './urlParams';

const localStorageKey = 'tenant-key';

export function useActiveTenant(): DataStreamResult<TenantVO> {
	const activeTabTS = useTabActive();
	const [tenantInitialized, setTenantInitialized] = useState<boolean>(false);
	const result = usePromise(() => Services.tenants.fetchTenants(), []);
	addDebuggingLine(`useActiveTenant;1;${result.value?.length || 0}`);
	const history = useHistory();
	const [{ tenant: tenantKey }, getUrl] = useUrlState([tenantKeyParam]);
	const keyInLocalStorage = getGlobal(localStorageKey);

	let tenant = result.value?.find((t) => t.key === (tenantKey ?? keyInLocalStorage));
	if (tenant == null && result.value && result.value?.length > 0) {
		tenant = result.value[0];
	}

	useEffect(() => {
		if (tenant && tenantKey !== tenant.key) {
			addDebuggingLine('useActiveTenant;2');
			history.replace(getUrl({ tenant: tenant.key }));
		}
	}, [tenantKey, tenant]);

	useEffect(() => {
		if (tenant) {
			setGlobal(localStorageKey, tenant.key);
		}
	}, [tenant?.key, activeTabTS]);

	useEffect(() => {
		if (tenant) {
			addDebuggingLine('useActiveTenant;3');
			Services.auth.setTenant(tenant.key);
			setTenantInitialized(true);
		}
	}, [tenant?.key]);

	useEffect(() => {
		if (tenant) {
			addDebuggingLine('useActiveTenant;4');
			Services.events.start(tenant.key);
		}
	}, [tenant?.key]);

	// We require useMemo because several parts of the UI rely on identity comparison of the returned result.
	return useMemo(() => {
		if (tenant && !tenantInitialized) {
			return loadingResult;
		}
		if (tenant) {
			return createResult(tenant);
		} else if (result.error) {
			return result;
		} else if (result.loading) {
			return result;
		} else {
			return createErrorResult('Failed to find a tenant', 404);
		}
	}, [tenantInitialized, tenant, result]);
}
