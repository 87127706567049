/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import EditableLabel from 'components/EditableLabel/EditableLabel';
import { Container, Stack, TutorialTooltip } from 'components';
import { useStoreField } from 'DataStore/DataStore';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface SidebarNameModuleProps {
	disabled?: boolean;
}

export default function SidebarNameModule({ disabled = false }: SidebarNameModuleProps): ReactElement {
	const { value, setValue } = useStoreField<string>('name');

	return (
		<Stack direction="horizontal" alignItems="center" width="100%">
			<Container sx={{ borderBottom: disabled ? undefined : '2px solid ' + theme.colors.neutral300, width: '100%' }}>
				<EditableLabel disabled={disabled} value={value} onChange={setValue} />
			</Container>
			<TutorialTooltip showAlways light hideIcon hideSkip id="please_name_your_environment">
				Name your Environment in order to create one
			</TutorialTooltip>
		</Stack>
	);
}
