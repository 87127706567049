/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import ExperimentRunCard from 'pages/experiments/components/ExperimentRunCard';
import { Flex, TextInput } from '@steadybit/ui-components-lib';
import { withBaseHref } from 'utils/getBaseHref';
import { IconSaveFile } from 'components/icons';
import { ReactElement, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { LogWidgetVO } from 'ui-api';
import { Link } from 'components';

import useSelectedLogLevel from './useSelectedLogLevel';
import EventLogLevelFilter from './EventLogLevelFilter';
import LogCardPresenter from './LogCardPresenter';
import { ampli } from '../../../../../ampli';
import { WidgetProps } from '../types';

export default function LogCard({
	experimentExecution,
	position,
	duration,
	widget,
	start,
	onPositionSelect,
}: WidgetProps): ReactElement {
	const [filter, setFilter] = useState<string>();
	const logWidget = widget as LogWidgetVO;
	const tenant = useTenant();
	const params = new URLSearchParams({ tenantKey: tenant.key || '' });
	const selectedLogLevelProps = useSelectedLogLevel([]);

	return (
		<ExperimentRunCard
			title={logWidget.title}
			help={<>Your {logWidget.logType} Events will be displayed here. You can also download the logs as a CSV file.</>}
			additionalHeaderItems={
				<Flex direction="horizontal" align="center" spacing="xSmall">
					<TextInput placeholder="Search" value={filter || ''} onChange={setFilter} size="small" />

					<EventLogLevelFilter {...selectedLogLevelProps} />

					<Link
						href={withBaseHref(
							`/ui/executions/${experimentExecution.id}/log/${logWidget.logType}/csv?${params.toString()}`,
						)}
						onClick={() => {
							ampli.experimentExecutionLogDownloaded({
								experiment_execution_id: experimentExecution.id,
								experiment_key: experimentExecution.experimentKey,
								environment_id: experimentExecution.environmentId,
								experiment_execution_log_type: logWidget.logType,
							});
						}}
						variant={'primary'}
						display={'inline'}
					>
						<IconSaveFile
							color={'neutral100'}
							mr="xSmall"
							mt="xxSmall"
							variant={'small'}
							sx={{ verticalAlign: 'baseline' }}
						/>
					</Link>
				</Flex>
			}
		>
			<LogCardPresenter
				widget={widget as LogWidgetVO}
				position={position}
				start={start}
				duration={duration}
				experimentExecutionId={experimentExecution.id}
				experimentExecution={experimentExecution}
				onPositionSelect={onPositionSelect}
				filter={filter}
				{...selectedLogLevelProps}
			/>
		</ExperimentRunCard>
	);
}
