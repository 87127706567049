/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { KeyError, ValuesError } from 'components/PredicateEditor/PredicateEditor';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useStoreField } from 'DataStore/DataStore';
import { useEffect, useState } from 'react';
import { ExperimentLaneVO } from 'ui-api';
import { get } from 'lodash';

import { ExperimentError } from './types';

/**
 * Validation errors are returned only on an index based object e.g. lanes[0].steps[0].param = "not valid".
 * When we now drag in a new step, the indices of the steps change, but the error object is still the same until validation is done again.
 * As a result, steps have the wrong error messages.
 * Therefore, this hook returns a stable map of errors which resolve a stepId to the error messages in the error object.
 */
export function useStableErrorMap(): Map<string, ExperimentError[]> {
	const [map, setMap] = useState(() => new Map<string, ExperimentError[]>());
	const { value: lanes, errors } = useStoreField<ExperimentLaneVO[]>('lanes');

	const [stableId] = useStableInstance(errors);
	useEffect(() => {
		const newMap = new Map<string, ExperimentError[]>();
		if (!errors) {
			setMap(newMap);
			return;
		}
		for (let iL = 0; iL < lanes.length; iL++) {
			const lane = lanes[iL];
			for (let iS = 0; iS < lane.steps.length; iS++) {
				const step = lane.steps[iS];
				const stepErrors = get(errors, `[${iL}].steps[${iS}]`);
				if (stepErrors) {
					if (typeof stepErrors === 'string') {
						newMap.set(step.id, [{ message: stepErrors, level: 'error' }]);
					} else {
						newMap.set(step.id, getErrorMessages(stepErrors));
					}
				}
			}
		}

		setMap(newMap);
	}, [stableId]);

	return map;
}

export function getErrorMessages(errors?: { [index: string]: unknown } | undefined | string): ExperimentError[] {
	if (!errors) {
		return [];
	}

	if (typeof errors === 'string') {
		return [{ message: errors, level: 'error' }];
	}

	if ('message' in errors && 'level' in errors) {
		const err = errors as ExperimentError;
		return [err as ExperimentError];
	}

	const messages: ExperimentError[] = Object.keys(errors || {})
		.map((key: string) => {
			if (key === 'predicateparts') {
				const summary = getSummarizedPredicateErrorMessage(errors[key]);
				if (summary) {
					return [{ message: summary, level: 'error' }] as ExperimentError[] as ExperimentError[];
				}
				return [] as ExperimentError[];
			}

			const value = errors[key];
			if (value && typeof value === 'string') {
				return [{ message: key + ': ' + value, level: 'error' }] as ExperimentError[];
			}
			if (typeof value === 'object') {
				if (value && 'message' in value && 'level' in value) {
					return [
						{ message: key + ': ' + (value as ExperimentError).message, level: (value as ExperimentError).level },
					] as ExperimentError[];
				}
				return getErrorMessages(value as { [index: string]: unknown }) as ExperimentError[];
			}
			return [] as ExperimentError[];
		})
		.flat()
		.filter(Boolean);

	return messages;
}

export function getSummarizedPredicateErrorMessage(errors: unknown): string | undefined {
	if (!errors) {
		return undefined;
	}
	const violations = errors as Array<KeyError | ValuesError>;
	const containsKeyViolation = violations.some((violation) => containsProperty(violation, 'key'));
	const containsValuesViolation = violations.some((violation) => containsProperty(violation, 'values'));
	if (containsKeyViolation && containsValuesViolation) {
		return 'The query contains invalid keys and values';
	}
	if (containsKeyViolation) {
		return 'The query contains invalid keys';
	}
	if (containsValuesViolation) {
		return 'The query contains invalid values';
	}
	return undefined;
}

function containsProperty(obj: unknown, property: string): boolean {
	try {
		return typeof obj === 'object' && obj !== null && property in obj;
	} catch {
		return false;
	}
}
