/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconClose, IconDelete, IconDuplicate } from 'components/icons';
import { ButtonIcon, Container, Skeletons, Stack } from 'components';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { Flex, Grid } from '@steadybit/ui-components-lib';
import { ActionVO, BaseExperimentStepVO } from 'ui-api';
import { useStoreField } from 'DataStore/DataStore';
import React, { ReactElement } from 'react';

import StepTypeSwitcher from './StepTypeSwitcher';

interface StepEditHeaderProps {
	step: BaseExperimentStepVO;
	stepPath: string | null;
	backgroundColor: string;
	icon?: React.ReactNode;
	disabled: boolean;
	action?: ActionVO;
	caption: string;
	color?: string;
	onReplaceStep?: (attackId: string) => void;
	onDuplicate?: () => void;
	onDelete: () => void;
	onClose: () => void;
}

export default function StepEditHeader({
	color = 'neutral000',
	backgroundColor,
	disabled,
	stepPath,
	caption,
	action,
	icon,
	step,
	onReplaceStep,
	onDuplicate,
	onDelete,
	onClose,
}: StepEditHeaderProps): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				position: 'sticky',
				top: 0,

				bg: 'neutral000',
				px: 'small',
				py: 'small',
				borderBottom: '1px solid',
				borderBottomColor: 'neutral200',
				zIndex: 1,
			}}
		>
			<Grid cols="40px 1fr" spacing="small" style={{ width: '100%' }}>
				<Container
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '16px',
						minWidth: 40,
						maxWidth: 40,
						minHeight: 40,
						maxHeight: 40,
						borderRadius: '6px',
						backgroundColor,
						color,
					}}
				>
					{icon}
				</Container>

				<Flex style={{ overflow: 'hidden' }}>
					<Grid cols="1fr 120px" align="end" style={{ width: '100%' }}>
						{action && onReplaceStep ? (
							<StepTypeSwitcher
								actionId={action.id}
								label={action.name}
								disabled={disabled}
								onActionClick={onReplaceStep}
							/>
						) : (
							<div />
						)}

						<Container display="flex" justifyContent="flex-end">
							{disabled ? null : (
								<ButtonIcon
									onClick={(e) => {
										e.stopPropagation();
										onDelete();
									}}
									tooltip={'Delete Step'}
								>
									<IconDelete />
								</ButtonIcon>
							)}
							{disabled || !onDuplicate ? null : (
								<ButtonIcon
									onClick={(e) => {
										e.stopPropagation();
										onDuplicate();
									}}
									tooltip={'Duplicate Step'}
								>
									<IconDuplicate />
								</ButtonIcon>
							)}
							<ButtonIcon
								onClick={(e) => {
									e.stopPropagation();
									onClose();
								}}
								tooltip={'Close'}
							>
								<IconClose />
							</ButtonIcon>
						</Container>
					</Grid>

					<CustomLabel disabled={disabled} caption={caption} customLabel={step.customLabel} stepPath={stepPath} />
				</Flex>
			</Grid>
		</Stack>
	);
}

interface CustomLabelProps {
	customLabel: string | undefined;
	stepPath: string | null;
	disabled: boolean;
	caption: string;
}

function CustomLabel({ disabled, caption, stepPath, customLabel }: CustomLabelProps): ReactElement {
	const { setValue } = useStoreField<string | null>(`${stepPath}.customLabel`);

	return (
		<Stack sx={{ borderBottom: '2px solid', borderColor: 'neutral300', width: '100%' }}>
			<EditableLabel
				value={customLabel ?? caption}
				disabled={disabled}
				width="100%"
				onChange={(label: string): void => {
					setValue(label === caption ? null : label);
				}}
			/>
		</Stack>
	);
}

export function LoadingStepActionHeader({ onClose }: { onClose: () => void }): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				position: 'sticky',
				top: 0,

				bg: 'neutral000',
				px: 'small',
				py: 'small',
				borderBottom: '1px solid',
				borderBottomColor: 'neutral200',
				zIndex: 1,
			}}
		>
			<Grid cols="40px 1fr 120px" spacing="small">
				<Container
					sx={{
						marginTop: '16px',
						borderRadius: '6px',
						overflow: 'hidden',
					}}
				>
					<Skeletons height={40} widths={[40]} />
				</Container>

				<Flex spacing="small">
					<Skeletons height={18} widths={[180]} />
					<Skeletons height={32} widths={[240]} />
				</Flex>

				<Flex direction="horizontal" justify="end">
					<ButtonIcon onClick={onClose}>
						<IconClose />
					</ButtonIcon>
				</Flex>
			</Grid>
		</Stack>
	);
}

interface FallbackStepActionHeaderProps {
	disabled: boolean;
	onDelete: () => void;
	onClose: () => void;
}

export function FallbackStepActionHeader({ disabled, onClose, onDelete }: FallbackStepActionHeaderProps): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				position: 'sticky',
				top: 0,

				bg: 'neutral000',
				px: 'small',
				py: 'small',
				borderBottom: '1px solid',
				borderBottomColor: 'neutral200',
				zIndex: 1,
				width: '100%',
			}}
		>
			<Flex direction="horizontal" justify="end">
				{!disabled && (
					<ButtonIcon onClick={onDelete} tooltip={'Delete Step'}>
						<IconDelete />
					</ButtonIcon>
				)}
				<ButtonIcon onClick={onClose}>
					<IconClose />
				</ButtonIcon>
			</Flex>
		</Stack>
	);
}
