/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { formatDateWithTime } from 'utils/dateFns';
import 'eventsource/example/eventsource-polyfill';
import { withBaseHref } from 'utils/getBaseHref';
import { Observable, Subject } from 'rxjs';
import { SteadybitEventVO } from 'ui-api';
import { track } from 'tracking/sentry';
import { share } from 'rxjs/operators';

import { redirectToLogin } from './authApi';

declare global {
	interface Window {
		EventSourcePolyfill: new (url: string | URL, eventSourceInitDict?: EventSourceInit) => EventSource;
		SET_EVENTSOURCE_DATA_CONSOLE_TRACING: (v: boolean) => void;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const revive = (key: string, value: any): any => {
	if (key === 'time' && value) {
		return new Date(value);
	}
	return value;
};

export class EventsApi {
	private eventSource?: EventSource;
	private subject = new Subject<SteadybitEventVO>();
	private published = this.subject.pipe(share());
	private timeoutHandler: NodeJS.Timeout | undefined;
	public hasBeenDisconnected = false;

	private consoleTracingEnabled: boolean = false;
	constructor() {
		window.SET_EVENTSOURCE_DATA_CONSOLE_TRACING = (v: boolean) => {
			this.consoleTracingEnabled = v;
		};
	}

	async start(tenantKey: string): Promise<void> {
		this.stop();

		this.eventSource = new window.EventSourcePolyfill(withBaseHref(`/ui/events?tenantKey=${tenantKey}`)) as EventSource;
		this.eventSource.onopen = () => {
			if (this.hasBeenDisconnected) {
				this.subject.next({
					type: 'events_reconnected',
					time: new Date(),
					tenantKey,
				});
			}
			this.hasBeenDisconnected = true;
		};
		this.eventSource.onmessage = (message) => {
			const event = JSON.parse(message.data, revive);
			this.subject.next(event);
			if (this.consoleTracingEnabled) {
				console.debug(formatDateWithTime(new Date()), event);
			}
		};
		this.eventSource.onerror = (error) => {
			this.stop();

			track('Disconnected from eventstream', { error });

			// Status is added by EventSourcePolyfill
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (error.status === 401 || error.status === 403) {
				redirectToLogin();
				return;
			}

			this.timeoutHandler = setTimeout(() => {
				this.start(tenantKey);
				this.timeoutHandler = undefined;
			}, 5000);
		};
	}

	get events$(): Observable<SteadybitEventVO> {
		return this.published;
	}

	stop(): void {
		if (this.timeoutHandler) {
			clearTimeout(this.timeoutHandler);
			this.timeoutHandler = undefined;
		}
		if (this.eventSource) {
			this.eventSource.close();
			this.eventSource = undefined;
		}
	}
}
