/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentSaveResult } from 'services/experimentApi';
import { userInput } from 'components/Dialog/UserInput';
import DataStore, { Errors } from 'DataStore/DataStore';
import { Flex } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';
import { Services } from 'services/services';
import { toMillis } from 'utils/dateFns';
import { Snackbar } from 'components';

import { PreparedFormData } from './ExperimentEditorLoader';
import MetadataEnrichment from './MetadataEnrichment';
import ValidationHandler from './ValidationHandler';
import { ExperimentFormValues } from './types';

interface ExperimentEditorFormHandlerProps {
	formData: PreparedFormData;
	children: ReactNode;
}

export default function ExperimentEditorFormHandler({
	formData,
	children,
}: ExperimentEditorFormHandlerProps): ReactElement | null {
	return (
		<DataStore<ExperimentFormValues>
			initialData={mapInitialData({ ...formData, ...formData.initialValues })}
			initialErrors={formData.initialErrors as Errors}
		>
			<MetadataEnrichment />
			<ValidationHandler />
			{children}
		</DataStore>
	);
}

function mapInitialData(data: ExperimentFormValues): ExperimentFormValues {
	data = {
		...data,
		created: toMillis(data.created),
		edited: toMillis(data.edited),
	};
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	delete data.initialValues;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	delete data.initialErrors;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	delete data.experiment;
	return data;
}

export async function saveExperiment(
	values: ExperimentFormValues,
): Promise<[ExperimentFormValues, ExperimentSaveResult | null] | null> {
	const newValuesOrNull = await provideNameIfNotExist(values);

	if (newValuesOrNull === null) {
		return null;
	}

	values = newValuesOrNull;
	try {
		const result = await Services.experiments.createOrUpdateExperiment({
			...values,
			created: values.created ? new Date(values.created) : new Date(),
			edited: values.edited ? new Date(values.edited) : new Date(),
		});
		Snackbar.dark(`Experiment ${result.experimentKey} saved.`, {
			toastId: 'experiment-saved',
		});
		return [values, result];
	} catch (e) {
		Snackbar.error(
			<Flex>
				<span>Failed to save experiment!</span>
				{e.title && <span>{e.title}</span>}
			</Flex>,
			{
				toastId: 'experiment-saved',
			},
		);
		console.error('Could not save experiment', { experimentKey: values.experimentKey, e });
		return [values, null];
	}
}

export async function provideNameIfNotExist(values: ExperimentFormValues): Promise<ExperimentFormValues | null> {
	if (!values.name) {
		const inputResult = await userInput({
			title: 'Name your experiment',
			placeholder: 'Untitled Experiment',
			buttonOkCaption: 'Save Experiment',
		});
		if (inputResult.action === 'cancel') {
			return null;
		}
		values = {
			...values,
			name: inputResult.input || '',
		};
	}

	return values;
}
