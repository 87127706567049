/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Heading, Stack, Text } from 'components';
import { ReactElement, ReactNode } from 'react';

interface EmptyStateWrapperProps {
	description: string;
	children: ReactNode;
	icon: ReactNode;
	title: string;
}

export default function EmptyStateWrapper({
	description,
	children,
	title,
	icon,
}: EmptyStateWrapperProps): ReactElement {
	return (
		<Stack direction="vertical" size="xSmall" alignItems="center" px="large">
			{icon}
			<Heading variant="small">{title}</Heading>
			<Text variant="smallMedium" textAlign="center" color="neutral600" mb="xSmall">
				{description}
			</Text>
			{children}
		</Stack>
	);
}
