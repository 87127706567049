/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	RouterLink,
	ShortenedText,
	Spinner,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableRow,
	Text,
	Tooltip,
} from 'components';
import DashboardWidget from 'components/DashboardWidget/DashboardWidget';
import { ExperimentSummaryVO, GetExperimentsRequestVO } from 'ui-api';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { formatDateWithTime } from 'utils/dateFns';
import { ReactElement, ReactNode } from 'react';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';

export function LastEditetExperimentsWidget(): ReactElement {
	const team = useTeam();

	const [experimentsVO] = useAsyncState(async () => {
		const body: GetExperimentsRequestVO = {
			teamId: team.id,
			freeTextPhrases: [],
			environmentIds: [],
			targetTypes: [],
			actions: [],
			kinds: [],
			tags: [],
			pageable: {
				number: 0,
				size: 5,
				order: [
					{
						property: 'edited',
						direction: 'DESC',
					},
				],
			},
		};

		return Services.experiments.fetchExperiments(body);
	}, [team]);

	return (
		<DashboardWidget
			title={'Last edited Experiments'}
			icon="experiment"
			actionsRight={
				<Stack direction={'horizontal'} size={'small'}>
					<RouterLink to={'/experiments'}>
						<Text variant="mediumStrong">Show all Experiments</Text>
					</RouterLink>
				</Stack>
			}
		>
			<ExperimentTable>
				{experimentsVO.loading ? (
					<TableRow>
						<TableDataCell colSpan={7}>
							<Spinner variant="large" color={'neutral500'} />
						</TableDataCell>
					</TableRow>
				) : experimentsVO.value ? (
					experimentsVO.value.content.map((experiment, i) => (
						<ExperimentRow
							key={experiment.key}
							value={experiment}
							hideBorder={i === (experimentsVO.value?.content.length || 0) - 1}
						/>
					))
				) : null}
				{experimentsVO.value && !experimentsVO.loading && experimentsVO.value.content.length === 0 && (
					<TableRow sx={{ td: { borderBottomColor: 'neutral000' } }}>
						<TableDataCell colSpan={7}>
							<Text muted>No Experiments found.</Text>
						</TableDataCell>
					</TableRow>
				)}
			</ExperimentTable>
		</DashboardWidget>
	);
}

function ExperimentTable({ children }: { children: ReactNode }): ReactElement {
	return (
		<Table width={'100%'} sx={{ '& td > div': { minHeight: 43 } }}>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

function ExperimentRow({ value, hideBorder }: { value: ExperimentSummaryVO; hideBorder?: boolean }): ReactElement {
	return (
		<TableRow hoverable={true} sx={{ ...(hideBorder ? { td: { borderBottomColor: 'neutral000' } } : {}) }} height={52}>
			<TableDataCell maxWidth={1} overflow={'hidden'}>
				<Tooltip onlyShowOnEllipsis content={`${value.key} ${value.name}`}>
					<RouterLink
						to={`/experiments/edit/${value.key}`}
						sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
					>
						<ShortenedText noWrap>
							<Text variant={'smallMedium'} as={'span'} mr={'xxSmall'}>
								{value.key}
							</Text>
							<Text variant={'small'} color={'neutral700'} as={'span'}>
								{value.name}
							</Text>
						</ShortenedText>
						<ShortenedText noWrap sx={{ marginTop: -4 }}>
							<Text variant={'xSmall'} color={'neutral600'} as={'span'} data-private>
								{formatDateWithTime(value.edited)} by {value?.editedBy?.name}
							</Text>
						</ShortenedText>
					</RouterLink>
				</Tooltip>
			</TableDataCell>
		</TableRow>
	);
}
