/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	Divider,
	Label,
	LoadingIndicator,
	ModalOverlay,
	Stack,
} from 'components';
import { Button, Checkbox, Flex, presets, Text } from '@steadybit/ui-components-lib';
import TeamSelection from 'pages/settings/teams/components/TeamSelection';
import DataStore, { useStore, useStoreField } from 'DataStore/DataStore';
import { Notification } from 'components/Notifications/Notifications';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { EnvironmentSummaryVO, ExperimentVO } from 'ui-api';
import StoreTextInput from 'DataStore/StoreTextInput';
import { ReactElement, useEffect } from 'react';
import { useObservable } from 'react-use';
import { toMillis } from 'utils/dateFns';
import { ensure } from 'utils/ensure';
import { ReplaySubject } from 'rxjs';

type ConfirmOptions = {
	originalExperiment: ExperimentVO;
};

type DuplicateExperimentFormValues = {
	environmentId?: string;
	clearTargets?: boolean;
	hypothesis?: string;
	teamId?: string;
	name?: string;
};

export type DuplicateExperimentResult = DuplicateExperimentFormValues & {
	result: string;
};
type OutstandingConfirmation = ConfirmOptions & {
	resolve: (result: DuplicateExperimentResult) => void;
};

const outstandingConfirmation$ = new ReplaySubject<OutstandingConfirmation | null>(1);

export function DuplicateExperimentConfirm(): ReactElement {
	const confirmation = useObservable(outstandingConfirmation$);
	const { environments } = useEnvironments();

	const teamId = confirmation?.originalExperiment.teamId;
	const environmentId = confirmation?.originalExperiment.environmentId || environments[0]?.id || '';

	const isLoading = !teamId || !environmentId || !confirmation || environments.length === 0;

	return (
		<ModalOverlay
			open={Boolean(confirmation)}
			onClose={() => confirmation?.resolve?.({ result: 'cancel' })}
			zIndex={40}
		>
			{({ close }) => {
				const { originalExperiment } = { ...ensure(confirmation) };
				const initialTeamId: string = originalExperiment.teamId;
				const environmentsOfSelectedTeam = environments.filter(
					(environment) => !!environment.teams.find((ts) => ts.id === teamId),
				);
				const initialEnvironmentId: string =
					environmentsOfSelectedTeam.find((environment) => environment.id === environmentId)?.id ??
					environmentsOfSelectedTeam[0]?.id ??
					environmentId;

				return (
					<DataStore
						initialData={
							{
								...originalExperiment,
								name: `Copy of ${originalExperiment.name}`,
								teamId: initialTeamId,
								environmentId: initialEnvironmentId,
								created: toMillis(originalExperiment.created),
								edited: toMillis(originalExperiment.edited),
							} as DuplicateExperimentFormValues
						}
					>
						<Dialog>
							<DialogHeader title={'Duplicate Experiment'} onClose={close} />
							<Divider my={'small'} />
							<DialogContent>
								{isLoading ? (
									<LoadingContent />
								) : (
									<DuplicateExperimentForm
										originalEnvironmentId={originalExperiment.environmentId}
										environments={environments}
									/>
								)}
							</DialogContent>
							<Divider mt={'small'} />
							<DialogFooter my={'small'}>
								<FooterContent
									isLoading={isLoading}
									saveDate={(_data) => {
										confirmation?.resolve?.({ result: 'duplicate', ..._data });
									}}
									cancel={(_data) => {
										confirmation?.resolve({ result: 'cancel', ..._data });
									}}
								/>
							</DialogFooter>
						</Dialog>
					</DataStore>
				);
			}}
		</ModalOverlay>
	);
}

interface FooterContentProps {
	isLoading: boolean;
	saveDate: (data: DuplicateExperimentFormValues) => void;
	cancel: (data: DuplicateExperimentFormValues) => void;
}

function FooterContent({ isLoading, saveDate, cancel }: FooterContentProps): ReactElement {
	const store = useStore<DuplicateExperimentFormValues>();

	return (
		<Flex direction="horizontal" justify="spread" style={{ width: '100%' }}>
			<Button type="secondary" onClick={() => cancel(store.data)}>
				Cancel
			</Button>

			<Button type="primary" onClick={() => saveDate(store.data)} disabled={isLoading} data-cy="duplicateExperiment">
				Duplicate
			</Button>
		</Flex>
	);
}

function LoadingContent(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				py: 128,
			}}
		>
			<LoadingIndicator variant="xxLarge" color="slate" />
		</Container>
	);
}

interface DuplicateExperimentFormProps {
	environments: EnvironmentSummaryVO[];
	originalEnvironmentId: string;
}
function DuplicateExperimentForm({ originalEnvironmentId, environments }: DuplicateExperimentFormProps): ReactElement {
	const store = useStore<DuplicateExperimentFormValues>();
	const { clearTargets, teamId, environmentId } = store.data;

	const showWarning = environmentId && environmentId !== originalEnvironmentId && !clearTargets;

	return (
		<Stack>
			<StoreTextInput
				placeholder="Descriptive label to identify this query"
				fieldName="name"
				label="Name"
				maxLength={255}
			/>
			<StoreTextInput as="textarea" label="Description (optional)" fieldName="hypothesis" maxLength={20_000} />

			<Flex spacing="xxSmall">
				<Label>Team</Label>
				<TeamSelection value={teamId} myTeamsOnly useFallback onChange={(teamId) => store.setValue('teamId', teamId)} />
			</Flex>

			<Flex spacing="xxSmall">
				<Label>Environment</Label>
				<EnvironmentSelection environments={environments} teamId={teamId} />
				{showWarning && (
					<Notification
						content={{
							message:
								'Changing the environment may prevent the experiment from running, because inconsistent targets would not be reachable.',
							level: 'warning',
						}}
					/>
				)}
			</Flex>

			<Flex spacing="xxSmall">
				<Label>Clear Targets</Label>
				<Flex direction="horizontal" align="center" spacing="xSmall">
					<Checkbox
						checked={Boolean(store.data.clearTargets)}
						onChange={() => store.setValue('clearTargets', !clearTargets)}
					/>
					<Text neutral700>Remove all target information when duplicating</Text>
				</Flex>
			</Flex>
		</Stack>
	);
}

export function duplicateExperimentConfirm(originalExperiment: ExperimentVO): Promise<DuplicateExperimentResult> {
	return new Promise<DuplicateExperimentResult>((resolve) => {
		outstandingConfirmation$.next({
			resolve: (result: DuplicateExperimentResult) => {
				outstandingConfirmation$.next(null);
				resolve(result);
			},
			originalExperiment,
		});
	});
}

interface EnvironmentSelectionProps {
	environments: EnvironmentSummaryVO[];
	teamId: string | undefined;
}

function EnvironmentSelection({ environments, teamId }: EnvironmentSelectionProps): ReactElement {
	const { value: environmentId, setValue } = useStoreField<string>('environmentId');

	const teamEnvironments = environments.filter((environment) => !!environment.teams.find((ts) => ts.id === teamId));

	const selectedEnvironment = teamEnvironments.find((environment) => environment.id === environmentId);
	useEffect(() => {
		if (!selectedEnvironment) {
			// If the selected environment is not in the selected team, take the first environment of the team
			setValue(teamEnvironments[0]?.id);
		}
	}, [teamId]);

	const environmentOptions = teamEnvironments.map((environment) => ({
		id: environment.id,
		label: environment.name,
	}));

	return (
		<presets.dropdown.SingleChoiceButton
			items={environmentOptions}
			placement="bottom-start"
			maxContentHeight="400px"
			withKeyboardArrowSupport
			withLeftIcon={selectedEnvironment?.global ? 'environment-global' : 'environment'}
			selectedId={environmentId}
			style={{ width: '100%' }}
			onSelect={setValue}
		>
			{selectedEnvironment?.name || environmentId}
		</presets.dropdown.SingleChoiceButton>
	);
}
