/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { createContext, ReactElement, ReactNode, useContext, useState } from 'react';
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { get, set } from 'lodash';

interface DataSliceActions<T> {
	setValue(params: { fieldName: string; value: T; touched: boolean }): void;
	setValidation(params: { isValidating: boolean; errors?: Errors }): void;
	replaceValues(values: Record<string, unknown>): void;
	setTouched(fieldName: string): void;
	resetValues<T>(values: T): void;
}

interface StoreActions {
	setValue<T>(params: { fieldName: string; value: T; touched?: boolean }): void;
}

interface SetValueParams<STORE_TYPE, T> {
	fieldName: keyof STORE_TYPE;
	touched: boolean;
	value: T;
}

interface Data<T> {
	initialErrors?: Errors;
	isValidating: boolean;
	touched: Touched;
	errors: Errors;
	data: T;
}

interface DataWrapper<T> {
	data: Data<T>;
}

const ActionsContext = createContext<DataSliceActions<unknown>>({
	setValue: function (): void {},
	setTouched: function (): void {},
	resetValues: function (): void {},
	setValidation: function (): void {},
	replaceValues: function (): void {},
});

interface DataStoreProps<STORE_TYPE> {
	initialData: STORE_TYPE;
	initialErrors?: Errors;
	children: ReactNode;
}

export type Touched = Record<string, boolean>;
export type Errors = Record<string, string | Record<string, string>>;

export default function DataStore<STORE_TYPE>({
	initialErrors,
	initialData,
	children,
}: DataStoreProps<STORE_TYPE>): ReactElement {
	const [{ store, actions }] = useState(() => {
		const dataSlice = createSlice({
			name: 'data',
			initialState: {
				initialErrors,
				data: initialData,
				touched: {},
				errors: {},
				isValidating: false,
			},
			reducers: {
				setValue<T>(state: Data<T>, action: PayloadAction<SetValueParams<STORE_TYPE, T>>): void {
					const { fieldName, value, touched } = action.payload;

					set(state.data as object, fieldName, value);
					if (touched) {
						set(state.touched, fieldName, true);
					}
				},
				replaceValues<STORE_TYPE>(state: Data<STORE_TYPE>, action: PayloadAction<STORE_TYPE>): void {
					state.data = action.payload as STORE_TYPE;
				},
				setTouched(state, action: PayloadAction<string>): void {
					const fieldName = action.payload;
					set(state.touched, fieldName, true);
				},
				setValidation(state, action: PayloadAction<ValidationResult>): void {
					state.isValidating = action.payload.isValidating;
					if (action.payload.errors) {
						state.errors = action.payload.errors;
					}
				},
				resetValues<STORE_TYPE>(state: Data<STORE_TYPE>, action: PayloadAction<STORE_TYPE>): void {
					state.data = action.payload;
					state.touched = {};
				},
			},
		});

		return {
			store: configureStore({
				reducer: {
					data: dataSlice.reducer,
				},
			}),
			actions: dataSlice.actions,
		};
	});

	return (
		<Provider store={store}>
			<ActionsContext value={actions as DataSliceActions<STORE_TYPE>}>{children}</ActionsContext>
		</Provider>
	);
}

export interface StateField<T> {
	touched: boolean;
	errors: Errors;
	value: T;
	setTouched: () => void;
	setValue: (value: T) => void;
}

export function useStoreField<T>(fieldName: string): StateField<T> {
	const actions = useContext(ActionsContext);
	const value = useSelector((state: DataWrapper<T>) => get(state.data.data, fieldName)) as T;
	const errors = useSelector((state: DataWrapper<T>) => get(state.data.errors, fieldName)) as Errors;
	const touched = useSelector((state: DataWrapper<T>) => get(state.data.touched, fieldName)) === true ? true : false;
	const dispatch = useDispatch();

	return {
		touched,
		errors,
		value,
		setValue: (value: T) => {
			dispatch(actions.setValue({ fieldName, value, touched: true }));
		},
		setTouched: () => {
			dispatch(actions.setTouched(fieldName));
		},
	};
}

export function useStore<T>(): {
	initialErrors?: Errors;
	touched: Touched;
	errors: Errors;
	data: T;
	setValue<T2>(fieldName: string, value: T2, touched?: boolean): void;
	replaceValues(values: Partial<T>): void;
	resetValues(values: Partial<T>): void;
} {
	const actions = useContext(ActionsContext);
	const dispatch = useDispatch();

	const { data, initialErrors, errors, touched } = useSelector((state: DataWrapper<T>) => state.data);
	return {
		initialErrors,
		touched,
		errors,
		data,
		setValue<T2>(fieldName: string, value: T2, touched = true) {
			dispatch(actions.setValue({ fieldName, value, touched }));
		},
		replaceValues(values: Partial<T>) {
			dispatch(actions.replaceValues(values));
		},
		resetValues(values: Partial<T>) {
			dispatch(actions.resetValues(values));
		},
	};
}

interface ValidationResult {
	isValidating: boolean;
	errors: Errors;
	setValidations: (params: { isValidating: boolean; errors?: Errors }) => void;
}

export function useValidations(): ValidationResult {
	const actions = useContext(ActionsContext);
	const dispatch = useDispatch();

	const errors = useSelector((state: DataWrapper<unknown>) => state.data.errors);
	const isValidating = useSelector((state: DataWrapper<unknown>) => state.data.isValidating);
	return {
		isValidating,
		errors: errors,
		setValidations: (p) => dispatch(actions.setValidation(p)),
	};
}

export function useTouched(): { touched: Touched } {
	const touched = useSelector((state: DataWrapper<unknown>) => state.data.touched);
	return { touched };
}

export function useStoreActions(): StoreActions {
	const actions = useContext(ActionsContext);
	const dispatch = useDispatch();

	return {
		setValue: (params) => {
			dispatch(actions.setValue({ ...params, touched: params.touched || true }));
		},
	};
}
