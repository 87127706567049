/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Icon, Tooltip } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

export default function ExperimentVariableOverrideIndicator(): ReactElement | null {
	return (
		<Tooltip content="There are variable overrides configured">
			{({ setRefElement }) => (
				<div
					ref={setRefElement}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '50%',
						backgroundColor: Colors.purple200,
						minWidth: '24px',
						minHeight: '24px',
						maxWidth: '24px',
						maxHeight: '24px',
						marginLeft: 'auto',
					}}
				>
					<Icon type="function" size={14} />
				</div>
			)}
		</Tooltip>
	);
}
