/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { isTargetAttributeKeyValuePredicateVO } from 'components/PredicateEditor/utils';
import { getGlobal, removeGlobal, setGlobal } from 'utils/localStorage';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { LandscapeViewVO, TargetTypeDescriptionVO } from 'ui-api';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { Container, SnackbarContainer, Stack } from 'components';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { PREVIEW_DIMENSIONS } from 'pages/customViews/View';
import { usePromise } from 'utils/hooks/usePromise';
import { Formik, useFormikContext } from 'formik';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { isBlank } from 'utils/string';
import { useHistory } from 'url/hooks';
import { Subject } from 'rxjs';
import { ampli } from 'ampli';

import {
	UrlState,
	adviceParam,
	colorConfigParam,
	colorOverridesParam,
	coloringParam,
	descriptionParam,
	environmentParam,
	filterQueryParam,
	groupingConfigsParam,
	groupingParam,
	nameParam,
	sizingParam,
} from './urlParams';
import { ColorListEntry, LandscapeConfig, LandscapeGroup, NumberOfItems, Landscape as LandscapeType } from './types';
import ExploreTooManyTargetsSoftcapView from './ExploreResultView/ExploreTooManyTargetsSoftcapView';
import { ExploreNoEnvironmentsView } from './ExploreResultView/ExploreNoEnvironmentsView';
import ExploreTooManyTargetsView from './ExploreResultView/ExploreTooManyTargetsView';
import { hardGroupsLimit, hardTargetsLimit, softTargetsLimit } from './config';
import { ExploreLoadingView } from './ExploreResultView/ExploreLoadingView';
import ExploreNotFoundView from './ExploreResultView/ExploreNotFoundView';
import ExploreResultView from './ExploreResultView/ExploreResultView';
import { getGroupingLabelFromUrl, getIconMap } from './utils';
import ConfigSidebar from './ConfigSidebar/ConfigSidebar';
import DidNotSavePromptView from './DidNotSavePromptView';
import { useIconTextures } from './hooks/useIconTextures';
import ConfigHeader from './ConfigSidebar/ConfigHeader';
import { provideEvent$ } from './ServiceLocator';
import ArcadeOverlay from './ArcadeOverlay';
import TargetModal from './TargetModal';

interface LandscapeViewEnvironmentPreloaderProps {
	viewId?: string;
}
export default function LandscapeViewEnvironmentPreloader({
	viewId,
}: LandscapeViewEnvironmentPreloaderProps): ReactElement {
	const { defaultSelectedEnvironment } = useEnvironments();

	return (
		<Container
			as="main"
			sx={{
				display: 'flex',
				height: '100%',
				width: '100%',
				overflow: 'hidden',
			}}
		>
			{defaultSelectedEnvironment ? (
				<LandscapeViewPreloader viewId={viewId} defaultEnvironmentId={defaultSelectedEnvironment.id} />
			) : defaultSelectedEnvironment === null ? (
				<ExploreNoEnvironmentsView />
			) : (
				<ExploreLoadingView />
			)}
		</Container>
	);
}

interface LandscapeViewPreloaderProps {
	defaultEnvironmentId: string;
	viewId?: string;
}
function LandscapeViewPreloader({ viewId, defaultEnvironmentId }: LandscapeViewPreloaderProps): ReactElement {
	const team = useTeam();
	const [currentView, setCurrentView] = useState<LandscapeViewVO | null>(null);
	const viewResponse = usePromise(() => {
		setCurrentView(null);
		return Services.landscapeV2.getView(viewId, team.id, defaultEnvironmentId);
	}, [viewId]);

	const targetDefinitions = useTargetDefinitions();

	const isNotFound = viewResponse.error?.statusCode === 404;
	const view = viewResponse.value;

	useEffect(() => {
		if (view) {
			setCurrentView(view);
		}
	}, [view]);

	if (isNotFound) {
		return <ExploreNotFoundView />;
	}
	if (!currentView || targetDefinitions.loading) {
		return <ExploreLoadingView />;
	}
	return (
		<LandscapeStateHandler
			view={currentView}
			teamId={team.id}
			viewId={viewId}
			setAsCurrentView={setCurrentView}
			targetDefinitions={targetDefinitions.value || []}
		/>
	);
}

interface LandscapeStateHandlerProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	view: LandscapeViewVO;
	viewId?: string;
	teamId: string;
	setAsCurrentView: (view: LandscapeViewVO) => void;
}
function LandscapeStateHandler({
	targetDefinitions,
	viewId,
	teamId,
	view,
	setAsCurrentView,
}: LandscapeStateHandlerProps): ReactElement {
	useEffect(() => {
		provideEvent$(new Subject());
	}, []);

	const [urlvalues] = useUrlState<UrlState>([
		groupingConfigsParam,
		colorOverridesParam,
		colorConfigParam,
		environmentParam,
		filterQueryParam,
		descriptionParam,
		coloringParam,
		groupingParam,
		adviceParam,
		sizingParam,
		nameParam,
	]);

	const showAdvice = urlvalues.showAdvice ?? view.showAdvice;
	const environmentId = urlvalues.environment ?? (view.environmentId || '');
	const groupConfigs = urlvalues.groupConfigs ?? (view.groupConfigs || []);
	const colorOverrides = urlvalues.colorOverrides ?? view.colorOverrides;
	const description = urlvalues.description ?? (view.description || '');
	const filterQuery = urlvalues.query ?? (view.filterQuery || '');
	const colorConfig = urlvalues.colorConfig ?? view.colorConfig;
	const coloring = urlvalues.coloring ?? (view.colorBy || '');
	const sizing = urlvalues.sizing ?? (view.sizeBy || '');
	const name = urlvalues.name ?? (view.name || '');
	let grouping = urlvalues.grouping;
	grouping = grouping ? grouping : view.groupBy || [];
	grouping = grouping.map(getGroupingLabelFromUrl);
	const { createHref, push } = useHistory();

	const canvasRef = useRef<HTMLCanvasElement>(null);

	const [showArcadeOverlay, setShowArcadeOverlay] = useState(() => {
		const state = getGlobal('showArcadeOverlay');
		return state !== 'false' && state !== 'never';
	});

	const team = useTeam();
	const saveAsNewView = teamId !== team.id || view.template;

	useEffect(() => {
		if (viewId !== undefined) {
			ampli.landscapeExplorerViewLoaded({
				url: window.location.href,
				environment_id: environmentId,
				landscape_explorer_colored_by: coloring,
				landscape_explorer_filter_query: filterQuery,
				landscape_explorer_grouped_by: grouping,
				landscape_explorer_sized_by: sizing,
				landscape_explorer_view_name: view.name,
				landscape_explorer_view_source: 'navigation',
				landscape_explorer_show_advice: showAdvice,
			});
		} else {
			ampli.landscapeExplorerViewed({
				url: window.location.href,
				environment_id: environmentId,
			});
		}
	}, [view.id]);

	return (
		<Formik<LandscapeConfig>
			initialValues={{
				...view,
				name,
				showAdvice,
				description,
				filterQuery,
				colorConfig,
				groupConfigs,
				environmentId,
				colorOverrides,
				sizeBy: sizing,
				latestView: view,
				colorBy: coloring,
				groupBy: grouping,
				colorMapping: null,
				isNew: viewId === undefined,
				reloadSignal: 0,
			}}
			onSubmit={async (values, helper) => {
				const actionToSubmit = values.action;

				const savedView = await saveView(teamId, actionToSubmit === 'create', values, canvasRef.current);

				if (savedView) {
					setAsCurrentView(savedView);

					push(
						createHref((location) => {
							location.pathname = `/landscape/explore/${encodeURIComponent(savedView.id)}`;
							location.matrix = {};
						}),
					);

					helper.setValues({
						...values,
						...savedView,
						isNew: false,
						latestView: savedView,
					});
					helper.setFieldValue('action', null);
				}
			}}
			validate={(values) => {
				if (isBlank(values.name)) {
					return { name: 'Name is required' };
				}
			}}
			validateOnBlur={false}
			validateOnChange={false}
		>
			<>
				<TargetModal environmentId={environmentId} />
				<DidNotSavePromptView
					onSave={async (view, saveAsNewView) => {
						await saveView(teamId, saveAsNewView, view, canvasRef.current);
					}}
					saveAsNewView={saveAsNewView}
				/>
				{showArcadeOverlay && (
					<ArcadeOverlay
						onClose={() => {
							ampli.landscapeExplorerHelpTutorialDismissed({ url: window.location.href });
							setGlobal('showArcadeOverlay', 'false');
							setShowArcadeOverlay(false);
						}}
						onCloseAndDontShowAgain={() => {
							ampli.landscapeExplorerHelpTutorialDismissedPermanently({ url: window.location.href });
							setGlobal('showArcadeOverlay', 'never');
							setShowArcadeOverlay(false);
						}}
					/>
				)}

				<>
					<Stack size="none" width="100%">
						<ConfigHeader
							onNeedHelpClick={() => {
								ampli.landscapeExplorerHelpNeeded({ url: window.location.href });
								removeGlobal('showArcadeOverlay');
								setShowArcadeOverlay(true);
							}}
						/>
						<Container display="flex" width="100%" height="calc(100% - 56px)">
							<ConfigSidebar />
							<TargetsCountLoader canvasRef={canvasRef} targetDefinitions={targetDefinitions} />
						</Container>
					</Stack>

					<SnackbarContainer containerId="explore" />
				</>
			</>
		</Formik>
	);
}

interface TargetsCountLoaderProps {
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	targetDefinitions: TargetTypeDescriptionVO[];
}
function TargetsCountLoader({ targetDefinitions, canvasRef }: TargetsCountLoaderProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, environmentId } = formik.values;

	const countResult = usePromise(
		async () => Services.targets.countTargets(environmentId, { query: filterQuery }),
		[environmentId, filterQuery],
	);

	const [softLimitToLoadTargets, setSoftLimitToLoadTargets] = useState(softTargetsLimit);
	const isLoading = !countResult.value;

	if (isLoading) {
		return <ExploreLoadingView />;
	}

	const totalNumberOfUniqueTargets = countResult.value.total;
	if (totalNumberOfUniqueTargets > hardTargetsLimit) {
		return <ExploreTooManyTargetsView numberOfTargets={totalNumberOfUniqueTargets} />;
	}

	if (totalNumberOfUniqueTargets > softLimitToLoadTargets) {
		return (
			<ExploreTooManyTargetsSoftcapView
				numberOfTargets={totalNumberOfUniqueTargets}
				onLoadClick={() => setSoftLimitToLoadTargets(totalNumberOfUniqueTargets)}
			/>
		);
	}

	return <LandscapeTargetsLoader canvasRef={canvasRef} targetDefinitions={targetDefinitions} />;
}

interface LandscapeTargetsLoaderProps {
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	targetDefinitions: TargetTypeDescriptionVO[];
}
function LandscapeTargetsLoader({ targetDefinitions, canvasRef }: LandscapeTargetsLoaderProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, environmentId, reloadSignal } = formik.values;

	const landscapeResult = usePromise<LandscapeType>(() => {
		let predicate = toTargetPredicate(filterQuery);
		if (isTargetAttributeKeyValuePredicateVO(predicate)) {
			predicate = {
				predicates: [predicate],
				operator: 'AND',
			};
		}

		return Services.landscapeV2.getLandscape(predicate, environmentId, true);
	}, [filterQuery, environmentId, reloadSignal]);
	const landscape = landscapeResult.value;
	const isLoading = landscapeResult.loading || !landscape;

	if (isLoading) {
		return <ExploreLoadingView />;
	}

	return <Landscape canvasRef={canvasRef} targetDefinitions={targetDefinitions} landscape={landscape} />;
}

interface WorkerResultException {
	tooManyTargets: boolean;
	tooManyGroups: boolean;
}

interface WorkerResult {
	numberOfItems: NumberOfItems | null;
	groups: LandscapeGroup[];
}

interface LandscapeProps {
	canvasRef: MutableRefObject<HTMLCanvasElement | null>;
	targetDefinitions: TargetTypeDescriptionVO[];
	landscape: LandscapeType;
}
function Landscape({ landscape, targetDefinitions, canvasRef }: LandscapeProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { colorBy, sizeBy, groupConfigs, colorConfig, colorOverrides } = formik.values;
	const groupBy = formik.values.groupBy.map(getGroupingLabelFromUrl);

	const [duplicationSignals] = useState<boolean[]>([]);

	const [workerResult, setWorkerResult] = useState<WorkerResult | WorkerResultException>({
		numberOfItems: null,
		groups: [],
	});

	const [isCalculating, setIsCalculating] = useState(true);

	const landscapeTargets = landscape?.targets ?? [];
	const landscapeIcons = landscape?.icons;
	const icons = useMemo(() => getIconMap(landscapeIcons), [landscapeIcons]);

	useEffect(() => {
		if (
			!isExceptionResult(workerResult) &&
			(duplicationSignals[0] || duplicationSignals[1]) &&
			workerResult.numberOfItems
		) {
			ampli.landscapeExplorerTargetDuplication({
				url: window.location.href,
				explore_method:
					duplicationSignals[0] && duplicationSignals[1]
						? ['color_by', 'group_by']
						: [duplicationSignals[0] ? 'group_by' : 'color_by'],
				number_of_targets: landscapeTargets.length,
				number_of_visible_targets: workerResult.numberOfItems.targets,
			});
		}
	}, duplicationSignals);

	const [stableColorsId, stableColorOverrides] = useStableInstance(colorOverrides);
	const [stableConfigId, stableColorConfig] = useStableInstance(colorConfig);
	const [stableGroupId, stableConfigs] = useStableInstance(groupConfigs);
	useEffect(() => {
		if (!isCalculating) {
			setIsCalculating(true);
		}
		if (landscapeTargets.length === 0) {
			if (!isExceptionResult(workerResult)) {
				if (workerResult.groups === null || workerResult.groups.length > 0) {
					setWorkerResult({ ...(workerResult || {}), groups: [] });
				} else if (workerResult.numberOfItems == null) {
					setWorkerResult({ ...(workerResult || {}), numberOfItems: { targets: 0, groups: 0 } });
				}
			} else {
				setWorkerResult({ ...(workerResult || {}), numberOfItems: { targets: 0, groups: 0 } });
			}
			return;
		}

		const worker = new Worker(new URL('./util.worker.ts', import.meta.url), {
			type: 'module',
		});
		worker.postMessage({
			type: 'processTargets',
			targets: landscapeTargets,
			colorConfig: stableColorConfig,
			colorOverrides: stableColorOverrides,
			groupConfigs: stableConfigs,
			targetDefinitions,
			colorBy,
			groupBy,
			sizeBy,
			maxTargets: hardTargetsLimit,
			maxGroups: hardGroupsLimit,
		});
		worker.addEventListener('message', (event: MessageEvent): void => {
			const tooManyTargets = event.data.tooManyTargets;
			const tooManyGroups = event.data.tooManyGroups;
			if (tooManyTargets || tooManyGroups) {
				setWorkerResult({
					tooManyTargets,
					tooManyGroups,
				});
				setIsCalculating(false);
				return;
			}

			const { groups, numberOfTargets, numberOfGroups } = event.data;
			const colorList: Array<ColorListEntry> = event.data.colorList;
			const attributeValueToBucketName: { [index: string]: string } = event.data.attributeValueToBucketName;
			const colorAsMap: { [index: string]: [number, number, number] } = event.data.colorAsMap;

			const anyTargetWasDuplicatedBecauseOfGrouping: boolean = event.data.anyTargetWasDuplicatedBecauseOfGrouping;
			const anyTargetWasDuplicatedBecauseOfColoring: boolean = event.data.anyTargetWasDuplicatedBecauseOfColoring;
			duplicationSignals[0] = anyTargetWasDuplicatedBecauseOfGrouping;
			duplicationSignals[1] = anyTargetWasDuplicatedBecauseOfColoring;
			// no rerendering triggered!

			formik.setFieldValue('colorMapping', {
				colorList,
				colorAsMap,
				attributeValueToBucketName,
			});

			setWorkerResult({
				groups,
				numberOfItems: { targets: numberOfTargets, groups: numberOfGroups },
			});
			setIsCalculating(false);
		});

		return (): void => {
			worker.terminate();
		};
	}, [colorBy, groupBy.join(','), sizeBy, landscapeTargets, stableColorsId, stableConfigId, stableGroupId]);

	const textures = useIconTextures(icons);

	if (isExceptionResult(workerResult)) {
		return <ExploreTooManyTargetsView />;
	}

	const isLoading = workerResult.numberOfItems == null;
	const { numberOfItems, groups } = workerResult;

	return (
		<ExploreResultView
			numberOfItems={numberOfItems}
			isCalculating={isCalculating}
			isLoading={isLoading}
			canvasRef={canvasRef}
			textures={textures}
			groups={groups}
			icons={icons}
		/>
	);
}

async function saveView(
	teamId: string,
	isNewView: boolean,
	viewToSave: LandscapeViewVO,
	canvas: HTMLCanvasElement | null,
): Promise<LandscapeViewVO | null> {
	viewToSave.teamId = teamId;
	viewToSave.screenshot = getScreenshot(canvas);

	try {
		const savedView = await (isNewView
			? Services.landscapeV2.createView(viewToSave)
			: Services.landscapeV2.updateView(viewToSave));

		ampli.landscapeExplorerViewSaved({
			environment_id: savedView.environmentId,
			landscape_explorer_view_name: savedView.name,
			landscape_explorer_filter_query: savedView.filterQuery,
			landscape_explorer_grouped_by: savedView.groupBy,
			landscape_explorer_colored_by: savedView.colorBy,
			landscape_explorer_sized_by: savedView.sizeBy,
			landscape_explorer_save_new: isNewView,
			landscape_explorer_show_advice: savedView.showAdvice,
			url: window.location.href,
		});

		return savedView;
	} catch (error) {
		console.error(error);
	}
	return null;
}

function getScreenshot(canvas: HTMLCanvasElement | null): string {
	let screenshotUrl = '';
	if (!canvas) {
		return screenshotUrl;
	}
	try {
		const resizedCanvas = document.createElement('canvas');
		const resizedContext = resizedCanvas.getContext('2d');

		const ratio = PREVIEW_DIMENSIONS.width / canvas.width;
		resizedCanvas.width = PREVIEW_DIMENSIONS.width;
		resizedCanvas.height = canvas.height * ratio;

		if (resizedContext) {
			resizedContext.imageSmoothingQuality = 'high';
			resizedContext.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
			screenshotUrl = resizedCanvas.toDataURL('image/jpeg');
		}
	} catch (error) {
		console.warn('Could not create screenshot', error);
	}
	return screenshotUrl;
}

function isExceptionResult(result: WorkerResult | WorkerResultException): result is WorkerResultException {
	return (
		(result as WorkerResultException).tooManyTargets !== undefined ||
		(result as WorkerResultException).tooManyGroups !== undefined
	);
}
