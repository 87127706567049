/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import {
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
} from 'components';
import { Colors, Flex, Pill, Text, Tooltip, presets } from '@steadybit/ui-components-lib';
import { ExperimentExecutionVariableVO, ExperimentExecutionVO } from 'ui-api';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { formatDateWithTime } from 'utils/dateFns';
import { ReactElement } from 'react';

interface ExecutionUsedVariablesModalProps {
	experimentExecution: ExperimentExecutionVO;
	onClose: () => void;
}

interface Variable extends ExperimentExecutionVariableVO {
	key: string;
}

export default function ExecutionUsedVariablesModal({
	experimentExecution,
	onClose,
}: ExecutionUsedVariablesModalProps): ReactElement {
	const { selectedEnvironment } = useEnvironments(experimentExecution.environmentId);
	const thStyle = {
		minHeight: '36px !important',
	};

	const variables: Variable[] = Object.keys(experimentExecution.variables).map((key) => ({
		...experimentExecution.variables[key],
		key,
	}));

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2>
					<ModalHeaderV2 title="" px="large" onClose={close} />
					<ModalContentV2 px="large">
						<Flex spacing="small" style={{ width: '100%' }}>
							<Text type="xLargeStrong">Used variables for this run</Text>

							<Flex spacing="xSmall">
								<Flex direction="horizontal" align="center" spacing="xSmall">
									<Text type="smallStrong" neutral700>
										#{experimentExecution.id}
									</Text>

									{selectedEnvironment && (
										<presets.pill.EnvironmentTag global={selectedEnvironment.global} small>
											{selectedEnvironment.name}
										</presets.pill.EnvironmentTag>
									)}
								</Flex>

								<Text type="small">{formatDateWithTime(experimentExecution.requested)}</Text>
							</Flex>

							<Table width="100%">
								<TableHead>
									<TableRow>
										<TableHeadCell sx={thStyle}>Variable</TableHeadCell>
										<TableHeadCell sx={thStyle}>Used value</TableHeadCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{variables.map((variable: Variable) => (
										<TableRow key={variable.key}>
											<TableDataCell sx={thStyle}>
												<Tooltip
													content={
														variable.origin === 'ENVIRONMENT'
															? 'Environment variable'
															: variable.origin === 'EXPERIMENT'
																? 'Experiment variable'
																: 'Variable override'
													}
												>
													{({ setRefElement }) => (
														<div ref={setRefElement}>
															<Pill
																withLeftIcon={
																	variable.origin === 'ENVIRONMENT'
																		? 'environment'
																		: variable.origin === 'EXPERIMENT'
																			? 'experiment'
																			: 'variable-override'
																}
																style={{
																	height: '24px',
																	backgroundColor: Colors.purple200,
																	color: Colors.purple800,
																}}
															>
																{variable.key}
															</Pill>
														</div>
													)}
												</Tooltip>
											</TableDataCell>
											<TableDataCell sx={thStyle}>{variable.value}</TableDataCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Flex>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
