/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { getActionsAndTargets } from 'pages/templates/FromTemplateModal/TemplateDetails/TemplateDetails';
import { BaseExperimentStepVOUnion, ExperimentStepActionVO, TargetTypeDescriptionVO } from 'ui-api';
import ExperimentPreview from 'templates/components/TemplateCard/ExperimentPreview';
import Actions from 'pages/templates/FromTemplateModal/TemplateDetails/Actions';
import Targets from 'pages/templates/FromTemplateModal/TemplateDetails/Targets';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { Flex, presets, Text, utils } from '@steadybit/ui-components-lib';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { IconClose, IconNewTab } from 'components/icons';
import Markdown from 'components/Markdown/Markdown';
import { usePromise } from 'utils/hooks/usePromise';
import { ReactElement, useEffect } from 'react';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { Link } from 'components';
import { ampli } from 'ampli';

import ActionIconWithBorder from './ActionIconWithBorder';
import { ActionCategoryItem } from './types';
import useActions from '../useActions';

interface SelectedEntity {
	action?: ActionCategoryItem;
	templateId?: string;
}

interface SelectedEntityPopupProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	selectedEntity: SelectedEntity;
	onClose: () => void;
}

export default function SelectedEntityPopup({
	targetDefinitions,
	selectedEntity,
	onClose,
}: SelectedEntityPopupProps): ReactElement | null {
	if (!selectedEntity.action && !selectedEntity.templateId) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				top: '160px',
				bottom: '24px',
				left: 'calc(100% - 8px)',
				pointerEvents: 'none',
				zIndex: 3,
			}}
		>
			<Flex
				spacing="small"
				style={{
					backgroundColor: theme.colors.neutral000,
					boxShadow: '0px 0px 7px 5px rgba(0, 0, 0, 0.1)',
					minWidth: '330px',
					maxWidth: '330px',
					maxHeight: '100%',
					pointerEvents: 'auto',
				}}
			>
				<IconClose
					variant="medium"
					sx={{
						position: 'absolute',
						top: '6px',
						right: '6px',
						cursor: 'pointer',
						':hover': { color: theme.colors.neutral500 },
					}}
					onClick={onClose}
				/>
				<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
					{selectedEntity.action?.id && (
						<ActionDetails action={selectedEntity.action} targetDefinitions={targetDefinitions} />
					)}
					{selectedEntity.templateId && (
						<TemplateDetails templateId={selectedEntity.templateId} targetDefinitions={targetDefinitions} />
					)}
				</ErrorBoundary>
			</Flex>
		</div>
	);
}

interface TemplateDetailsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	templateId: string;
}

function TemplateDetails({ targetDefinitions, templateId }: TemplateDetailsProps): ReactElement {
	const { allActions } = useActions();
	const templateResult = usePromise(() => Services.templatesApi.getTemplate(templateId), [templateId]);

	useEffect(() => {
		if (templateResult.value) {
			ampli.experimentSidebarStepDocumentationOpened({
				experiment_step_id: templateResult.value.id,
				experiment_step_kind: 'template',
			});
		}
	}, [templateResult.value]);

	if (!templateResult.value) {
		return (
			<Flex align="center" justify="center" style={{ width: '100%', padding: '32px 24px 24px' }}>
				<LoadingIndicator color="slate" />
			</Flex>
		);
	}

	const template = templateResult.value;
	const [containingActions, containingTargets] = getActionsAndTargets(template);

	return (
		<Flex spacing="small" style={{ padding: '36px 24px 24px 24px', overflow: 'hidden' }}>
			<ExperimentPreview
				width={270}
				lanes={template.lanes.map((lane) => ({
					...lane,
					steps: lane.steps.map((step) => ({
						...step,
						actionId: isExperimentStepActionVO(step) ? step.actionId : 'wait',
						durationInSeconds: utils.convertDurationToSeconds(step.parameters.duration) || 0,
					})),
				}))}
				actions={allActions}
				disabled={false}
			/>

			<div>
				<presets.pill.Tags appearance="template" tags={template.tags} />
			</div>

			{/* To get the scrollbar on the right place */}
			<Flex spacing="small" style={{ width: '326px', overflowY: 'auto', margin: '0 -20px', padding: '0 24px' }}>
				<Text type="mediumStrong">{template.templateTitle}</Text>
				<Markdown content={template.templateDescription} small />
				<Flex spacing="xSmall">
					<Targets targets={containingTargets} targetDefinitions={targetDefinitions} />
					<Actions actions={containingActions} actionDefinitions={allActions} />
				</Flex>
			</Flex>

			{template.hubLink && <DetailLink href={`${template.hubLink}/templateRedirect/${template.id}`} />}
		</Flex>
	);
}

function isExperimentStepActionVO(step: BaseExperimentStepVOUnion): step is ExperimentStepActionVO {
	return (step as ExperimentStepActionVO).actionId !== undefined;
}

interface ActionDetailsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	action: ActionCategoryItem;
}

function ActionDetails({ action, targetDefinitions }: ActionDetailsProps): ReactElement {
	const actionResult = usePromise(() => Services.actions.findAction(action.id), [action.id]);

	useEffect(() => {
		if (actionResult.value) {
			ampli.experimentSidebarStepDocumentationOpened({
				experiment_step_id: actionResult.value.id,
				experiment_step_action_kind: actionResult.value.kind.toLowerCase() as
					| 'check'
					| 'attack'
					| 'load_test'
					| 'other'
					| 'basic',
				experiment_step_kind: 'action',
			});
		}
	}, [actionResult.value]);

	if (actionResult.loading) {
		return (
			<Flex align="center" justify="center" style={{ width: '100%', padding: '32px 24px 24px' }}>
				<LoadingIndicator color="slate" />
			</Flex>
		);
	}

	const actionVO = actionResult.value;
	if (!actionVO || !actionVO.hubSummary) {
		return (
			<Flex spacing="small" style={{ width: '100%', padding: '24px' }}>
				<Flex direction="horizontal" spacing="xSmall">
					<ActionIconWithBorder action={actionVO} />
				</Flex>

				<Text type="mediumStrong">{action.label}</Text>

				<Text type="medium">{actionVO ? actionVO.description : 'No description available'}</Text>
			</Flex>
		);
	}

	return (
		<Flex spacing="small" style={{ padding: '24px', overflow: 'hidden' }}>
			<Flex direction="horizontal" align="center" spacing="xSmall">
				<Text type="small">Action kind:</Text>
				<presets.pill.KindTag kind={actionVO.kind} />
			</Flex>

			<Flex direction="horizontal" align="center" spacing="xSmall">
				<ActionIconWithBorder action={actionVO} />
				<presets.pill.Tags appearance="template" tags={actionVO.hubTags} />
			</Flex>

			{/* To get the scrollbar on the right place */}
			<Flex spacing="small" style={{ width: '330px', overflowY: 'auto', margin: '0 -24px', padding: '0 24px ' }}>
				<Text type="mediumStrong">{action.label}</Text>

				<Markdown content={actionVO.hubSummary} small />

				{'target' in actionVO && actionVO.target.type && (
					<Flex direction="horizontal" align="center" spacing="xSmall">
						<Text type="smallStrong" neutral600>
							Target:
						</Text>
						<presets.pill.TargetTypeTag icon={targetDefinitions.find((def) => def.id === actionVO.target.type)?.icon}>
							{targetDefinitions.find((def) => def.id === actionVO.target.type)?.label.one || actionVO.target.type}
						</presets.pill.TargetTypeTag>
					</Flex>
				)}
			</Flex>

			{actionVO.hubLink && <DetailLink href={actionVO.hubLink} />}
		</Flex>
	);
}

function DetailLink({ href }: { href: string }): ReactElement {
	return (
		<Link href={href} external>
			<Text type="smallStrong">More details</Text>
			<IconNewTab variant="small" ml="xxSmall" />
		</Link>
	);
}
