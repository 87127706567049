/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEffect } from 'react';

export default function useDisabledBodyOverflow(): void {
	useEffect(() => {
		const current = document.body.style.overflow;
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = current;
		};
	}, []);
}
