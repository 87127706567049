/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionKindVO, ExperimentStepRadiusVO, MissingQuerySelectionVO, QuantityRestrictionVO } from 'ui-api';
import { Box, Colors, Flex, Spacings } from '@steadybit/ui-components-lib';
import { Container, LoadingIndicator, Text, Tooltip } from 'components';
import { useTargetDefinition } from 'targets/useTargetDefinition';
import UnitField from 'components/UnitField/UnitField';
import { useStoreField } from 'DataStore/DataStore';
import { Target } from 'pages/components/Target';
import Slider from 'components/Slider2/Slider';
import { IconTarget } from 'components/icons';
import StoreError from 'DataStore/StoreError';
import { toTitleCase } from 'utils/string';
import { hasError } from 'DataStore/utils';
import { toString } from 'utils/numberFn';
import { ReactElement } from 'react';

import BlastCanvas from './BlastCanvas';

const BLAST_UNIT_PERCENTAGE = { label: '%', id: 'percentage' };
const BLAST_UNIT_MAXIMUM = { label: '#', id: 'maximum' };
const BLAST_UNITS = [BLAST_UNIT_PERCENTAGE, BLAST_UNIT_MAXIMUM];

interface BlastRadiusCount {
	impacted?: number;
	loading: boolean;
	total?: number;
}

interface StepTargetsBlastRadiusProps {
	missingQuerySelection: MissingQuerySelectionVO;
	quantityRestriction?: QuantityRestrictionVO;
	blastRadius: ExperimentStepRadiusVO;
	blastRadiusCount: BlastRadiusCount;
	cannotDeterminCount: boolean;
	actionKind?: ActionKindVO;
	stepPath: string | null;
	targetType: string;
	disabled?: boolean;
}

export default function StepTargetsBlastRadius({
	missingQuerySelection,
	quantityRestriction,
	cannotDeterminCount,
	blastRadiusCount,
	blastRadius,
	targetType,
	actionKind,
	disabled,
	stepPath,
}: StepTargetsBlastRadiusProps): ReactElement {
	const targetDefinition = useTargetDefinition(targetType);
	const predicateRequired = missingQuerySelection === 'INCLUDE_NONE';

	const mode = Number.isInteger(blastRadius.maximum) ? 'maximum' : 'percentage';

	const hasValidPredicate = !!blastRadius.predicate || !predicateRequired;
	const renderBlastRadiusSlider = quantityRestriction !== 'EXACTLY_ONE' && quantityRestriction !== 'ALL';

	const impactedTargets = blastRadiusCount.impacted;
	const totalTargets = blastRadiusCount.total;

	const targetTypeLabel = targetDefinition.value ? targetDefinition.value.label.other : toTitleCase(targetType) + 's';

	const isLoading = blastRadiusCount.loading;

	return (
		<Box style={{ pt: 'small', px: 'small', backgroundColor: Colors.neutral100 }}>
			<Flex
				spacing="small"
				style={{
					position: 'relative',
					p: 'small',
					borderRadius: 'small',
					backgroundColor: Colors.neutral000,
				}}
			>
				<Flex direction="horizontal" align="center" style={{ minHeight: '44px' }}>
					<IconTarget variant="large" color="slate" mr="xxSmall" />

					{isLoading ? (
						<LoadingIndicator />
					) : cannotDeterminCount || impactedTargets === undefined || isNaN(impactedTargets) ? (
						<Text variant="xxLargeStrong" color="neutral800">
							--
						</Text>
					) : (
						<>
							<Text variant="xxLargeStrong" color="neutral800">
								{impactedTargets}
							</Text>

							{totalTargets !== undefined && !isNaN(totalTargets) && totalTargets > 0 && (
								<Text variant="xxLarge" color="neutral600">
									{` /${totalTargets}`}
								</Text>
							)}

							{targetDefinition.value ? (
								<Container ml={'xSmall'}>
									<Target targetDefinitions={[targetDefinition.value]} targetId={targetDefinition.value.id} />
								</Container>
							) : null}
						</>
					)}
				</Flex>

				{renderBlastRadiusSlider && (
					<>
						{/* The blast radius animation is absolut positioned because it's right in the middle of everything */}
						<div
							style={{
								position: 'absolute',
								top: '8px',
								right: '16px',
								height: 'auto',
								width: '120px',
							}}
						>
							<BlastCanvas
								total={mode === 'percentage' ? 100 : blastRadiusCount.total || 0}
								impacted={
									mode === 'percentage'
										? blastRadius.percentage || 0
										: !hasValidPredicate
											? 0
											: blastRadiusCount.impacted || blastRadius.maximum || 0
								}
							/>
						</div>

						<Flex spacing="none" style={{ width: '100%', marginTop: '14px' }}>
							<Text variant="xSmallStrong" color="neutral800" mb="-xxSmall">
								Limit {actionKind === 'ATTACK' ? 'Blast Radius' : ''}
							</Text>
							<EditBlastRadius
								disabled={disabled || blastRadiusCount.loading}
								totalTargetCount={totalTargets}
								blastRadius={blastRadius}
								stepPath={stepPath}
								mode={mode}
								targetTypeLabel={targetTypeLabel}
								actionKind={actionKind}
							/>
						</Flex>

						<StoreError name={`${stepPath}.blastRadius.percentage`} />
						<StoreError name={`${stepPath}.blastRadius.maximum`} />
					</>
				)}
			</Flex>
		</Box>
	);
}

interface EditBlastRadiusProps {
	actionKind: ActionKindVO | undefined;
	blastRadius: ExperimentStepRadiusVO;
	mode: 'percentage' | 'maximum';
	totalTargetCount?: number;
	stepPath: string | null;
	targetTypeLabel: string;
	disabled?: boolean;
}

function EditBlastRadius({
	totalTargetCount,
	targetTypeLabel,
	blastRadius,
	actionKind,
	disabled,
	stepPath,
	mode,
}: EditBlastRadiusProps): ReactElement {
	const blastRadiusField = useStoreField<ExperimentStepRadiusVO>(`${stepPath}.blastRadius`);
	const percentageField = useStoreField<number | null>(`${stepPath}.blastRadius.percentage`);
	const maximumField = useStoreField<number | null>(`${stepPath}.blastRadius.maximum`);

	const handleUnitChange = (unit: string): void => {
		const newBlastRadius: ExperimentStepRadiusVO = {};
		newBlastRadius.targetType = blastRadius.targetType;
		newBlastRadius.predicate = blastRadius.predicate;
		if (unit === 'percentage') {
			newBlastRadius.percentage = 100;
		} else if (unit === 'maximum') {
			newBlastRadius.maximum = 1;
		}
		blastRadiusField.setValue(newBlastRadius);
	};
	const handleValueChange = (value: number | null): void => {
		if (mode === 'percentage') {
			percentageField.setValue(value == null ? null : Math.min(100, value));
		} else {
			maximumField.setValue(value);
		}
	};

	const value: number = (mode === 'percentage' ? blastRadius.percentage : blastRadius.maximum) || 0;
	const stringValue = toString(value);
	const maxValue = mode === 'percentage' ? 100 : Number.MAX_SAFE_INTEGER;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row-reverse',
				alignItems: 'center',
				width: '100%',
				gap: Spacings.small,
			}}
		>
			<UnitField
				unit={mode === 'percentage' ? BLAST_UNIT_PERCENTAGE : BLAST_UNIT_MAXIMUM}
				disabled={disabled}
				units={BLAST_UNITS}
				value={stringValue}
				max={maxValue}
				width="100px"
				min={0}
				hasError={mode === 'percentage' ? hasError(percentageField) : hasError(maximumField)}
				onUnitChange={handleUnitChange}
				onChange={handleValueChange}
			/>

			{mode === 'percentage' ? (
				<Tooltip
					content={
						value === 100 ? (
							<>
								All of your {targetTypeLabel} selected by your <br />
								query will be {actionKind === 'ATTACK' ? 'attacked' : 'picked'}.
							</>
						) : (
							<>
								The {actionKind === 'ATTACK' ? 'attack' : 'action'} is limited to {stringValue} % of the
								<br /> {targetTypeLabel} selected by your query.
							</>
						)
					}
				>
					<div style={{ width: '100%' }}>
						<Slider
							value={value}
							min={0}
							max={mode === 'percentage' ? 100 : totalTargetCount}
							disabled={disabled}
							onChange={(value) => {
								const rounded = Math.round(value);
								if (mode === 'percentage') {
									percentageField.setValue(rounded);
								} else {
									maximumField.setValue(rounded);
								}
							}}
						/>
					</div>
				</Tooltip>
			) : (
				<Text variant="xSmallMedium" color="neutral600" width="100%">
					Maximum number of randomly {actionKind === 'ATTACK' ? 'attacked' : 'picked'} {targetTypeLabel}:
				</Text>
			)}
		</div>
	);
}
