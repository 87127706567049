/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ButtonIcon, Heading, Container, Stack, Text } from 'components';
import { PositiveSpacings } from '@steadybit/ui-components-lib';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, ReactNode } from 'react';
import { IconClose } from 'components/icons';

interface ModalHeaderProps {
	px?: keyof typeof PositiveSpacings;
	subTitle?: string | ReactNode;
	title: string | ReactNode;
	withShadow?: boolean;
	renderActions?: () => ReactElement;
	onClose?: () => void;
}

export default function ModalHeader({
	px = 'xxxLarge',
	withShadow,
	subTitle,
	title,
	renderActions,
	onClose,
}: ModalHeaderProps): ReactElement {
	return (
		<>
			<Container className="modalCloseLine" display="flex" alignItems="center" justifyContent="flex-end">
				{onClose && (
					<ButtonIcon
						variant="medium"
						onClick={(e) => {
							e.stopPropagation();
							onClose();
						}}
					>
						<IconClose />
					</ButtonIcon>
				)}
			</Container>

			{title && (
				<Stack
					className="title"
					px={px}
					pb="large"
					sx={{
						boxShadow: withShadow ? '0px 6px 8px #0000000a' : 'none',
					}}
				>
					<Container display="flex" alignItems="flex-start" justifyContent="space-between">
						<Heading variant="large" color="neutral800" sx={{ ...textEllipsis }}>
							{title}
						</Heading>
						{renderActions && (
							<Stack alignItems="flex-start" direction="horizontal">
								{renderActions()}
							</Stack>
						)}
					</Container>
					{subTitle && (
						<Text variant="medium" color="neutral600">
							{subTitle}
						</Text>
					)}
				</Stack>
			)}
		</>
	);
}
