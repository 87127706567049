/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Grid, metrics, presets, SingleChoiceListItem, Text } from '@steadybit/ui-components-lib';
import DashboardWidget from 'components/DashboardWidget/DashboardWidget';
import { IconTrendDown, IconTrendUp } from 'components/icons';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { forIn, groupBy, orderBy, sum } from 'lodash';
import { daysAgo, formatDate } from 'utils/dateFns';
import { ReactElement, useState } from 'react';
import { ExecutionStatsGrouped } from 'ui-api';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { RouterLink } from 'components';

import { ampli } from '../../../ampli';

const mapTimeRangeOptionsToAmplitude = (id: string): '7_days' | '30_days' | 'all_time' => {
	if (id === '7') {
		return '7_days';
	} else if (id === '30') {
		return '30_days';
	} else {
		return 'all_time';
	}
};

export function TeamActivitiesWidget(): ReactElement {
	const [timeRange, setTimeRange] = useState('7');
	const timeRangeOptions: SingleChoiceListItem[] = [
		{
			id: '7',
			label: 'last 7 Days',
		},
		{
			id: '30',
			label: 'last 30 Days',
		},
		{
			id: 'all',
			label: 'all time',
		},
	];

	const selectedTimeRange = timeRangeOptions.find((option) => option.id === timeRange);

	return (
		<DashboardWidget
			title="Activities for your Team"
			icon="results"
			actionsLeft={
				<presets.dropdown.SingleChoiceButton
					placement="bottom-start"
					selectedId={timeRange}
					items={timeRangeOptions}
					size="small"
					onSelect={(id) => {
						setTimeRange(id);
						ampli.dashboardTeamActivityFiltered({ team_activity_time_range: mapTimeRangeOptionsToAmplitude(id) });
					}}
					maxContentHeight="250px"
				>
					{selectedTimeRange?.label || ''}
				</presets.dropdown.SingleChoiceButton>
			}
			actionsRight={<ReportLink timeRange={timeRange} />}
		>
			<LatestStats lastNoOfDays={timeRange === 'all' ? null : parseInt(timeRange)} />
		</DashboardWidget>
	);
}

function ReportLink({ timeRange }: { timeRange: string }): ReactElement {
	const team = useTeam();

	return (
		<RouterLink
			to={`/reporting;teamIds=!*${team.id};type=EXECUTION*_STATE;time=${timeRange === 'all' ? 'allTime' : timeRange === '30' ? 'last30Days' : 'last7Days'}~`}
			onClick={() => {
				ampli.dashboardTeamActivityExported({ team_activity_time_range: mapTimeRangeOptionsToAmplitude(timeRange) });
			}}
			variant={'primary'}
			display={'inline'}
		>
			<Text type="mediumStrong">See detailed report</Text>
		</RouterLink>
	);
}

const groupStats = (stats: ExecutionStatsGrouped[]): ExecutionStatsGrouped[] => {
	const groupedByDate = groupBy(stats, 'date');
	const result: ExecutionStatsGrouped[] = [];
	forIn(groupedByDate, (value, key) => {
		result.push({
			date: key,
			count: sum(value.map((v) => v.count)),
		});
	});
	if (result.length === 1) {
		result.push(result[0]);
	}
	return result;
};

function LatestStats({ lastNoOfDays }: { lastNoOfDays: number | null }): ReactElement {
	const team = useTeam();
	const [stats] = useAsyncState(async () => {
		const [currentExperiments, beforeExperiments] = await Promise.all([
			Services.experiments.fetchRunStats(team.id, lastNoOfDays !== null ? daysAgo(lastNoOfDays) : undefined),
			lastNoOfDays !== null
				? Services.experiments.fetchRunStats(team.id, daysAgo(2 * lastNoOfDays), daysAgo(lastNoOfDays))
				: undefined,
		]);
		const current = {
			executed: currentExperiments?.executed || 0,
			completed: currentExperiments?.completed || 0,
			failed: currentExperiments?.failed || 0,
			executedStats: orderBy(groupStats(currentExperiments?.executedStats), ['date'], ['asc']),
			completedStats: orderBy(groupStats(currentExperiments?.completedStats), ['date'], ['asc']),
			failedStats: orderBy(groupStats(currentExperiments?.failedStats), ['date'], ['asc']),
		};
		const before = {
			executed: beforeExperiments?.executed || 0,
			completed: beforeExperiments?.completed || 0,
			failed: beforeExperiments?.failed || 0,
		};
		return { current, before };
	}, [team.id, lastNoOfDays]);

	const hasNoOfDays: boolean =
		lastNoOfDays && lastNoOfDays > 0 && stats.value?.before?.executed && stats.value?.before?.executed > 0
			? true
			: false;

	return (
		<Flex justify="spread" spacing="small" style={{ width: '100%' }}>
			<Grid cols="1fr 1fr 1fr" spacing="small" align="stretch" style={{ width: '100%' }}>
				<TrendItem
					name="Total Experiment Runs"
					stats={stats.value?.current?.executedStats}
					current={stats.value?.current?.executed}
					before={stats.value?.before?.executed}
					color="slate"
				/>
				<TrendItem
					name="Completed Runs"
					stats={stats.value?.current?.completedStats}
					current={stats.value?.current?.completed}
					before={stats.value?.before?.completed}
					color="feedbackSuccess"
				/>
				<TrendItem
					name="Failed Runs"
					stats={stats.value?.current?.failedStats}
					current={stats.value?.current?.failed}
					before={stats.value?.before?.failed}
					color="experimentWarning"
				/>
			</Grid>
			{hasNoOfDays && (
				<Text type="xSmall" neutral600>
					Percentage values reflect development compared to previous {lastNoOfDays} days.
				</Text>
			)}
		</Flex>
	);
}

interface TrendItemProps {
	stats?: ExecutionStatsGrouped[];
	color: keyof typeof Colors;
	current?: number;
	before?: number;
	name: string;
}
function TrendItem({ color, current, before, stats, name }: TrendItemProps): ReactElement {
	return (
		<Flex spacing="small" style={{ width: '100%', flexGrow: 1 }}>
			<Text type="smallMedium" neutral600>
				{name}
			</Text>

			<Flex direction="horizontal" spacing="xxSmall" style={{ width: '100%' }}>
				<Flex spacing="xxSmall">
					<Text type="xxLargeStrong" style={{ lineHeight: '30px' }}>
						{current}
					</Text>
					<TrendIcon current={current} before={before} color={color} />
				</Flex>

				{stats && stats.length > 0 && (
					<metrics.SparkChart
						data={stats.map((d) => ({
							ts: new Date(d.date).getTime(),
							value: d.count,
						}))}
						color={color}
						height={35}
						formatTime={(v) => formatDate(new Date(v))}
					/>
				)}
			</Flex>
		</Flex>
	);
}

interface TrendIconProps {
	color: keyof typeof Colors;
	current?: number;
	before?: number;
}
function TrendIcon({ color, current, before }: TrendIconProps): ReactElement {
	if (typeof current === 'undefined' || typeof before === 'undefined' || before === 0) {
		return <Text type="smallStrong">&nbsp;</Text>;
	}
	const resultNum = Math.abs(Number(100 - (current / before) * 100));
	const result = resultNum.toFixed(1);
	const Icon = resultNum === 0 || current === before ? undefined : current > before ? IconTrendUp : IconTrendDown;
	return (
		<Flex direction="horizontal" align="center" style={{ color: Colors[color] }}>
			{Icon && <Icon height={16} width={16} />}
			<Text type="smallStrong">{result}%</Text>
		</Flex>
	);
}
