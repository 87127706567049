/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { CapabilityTypeVO, CapabilityVO, ExperimentSummaryVO, SteadybitEventVO, TeamVO } from 'ui-api';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable } from 'rxjs';
import cached from 'utils/cached';
import { get } from 'lodash';

import { EnvironmentsApi } from './environmentsApi';
import { ExperimentApi } from './experimentApi';
import { TemplatesApi } from './templatesApi';
import { SchedulesApi } from './schedulesApi';
import { EventsApi } from './eventsApi';
import { AgentsApi } from './agentsApi';
import { TeamsApi } from './teamsApi';
import { HubsApi } from './hubsApi';

export interface Permissions {
	environments: EnvironmentsPermissions;
	experiments: ExperimentsPermissions;
	schedules: SchedulesPermissions;
	templates: TemplatesPermissions;
	agents: AgentsPermissions;
	teams: TeamsPermissions;
	hubs: HubsPermissions;
}

interface Capability extends CapabilityVO {
	tooltipContent?: string;
}

interface AgentsPermissions {
	canRegister: Capability;
}

interface EnvironmentsPermissions {
	canCreate: Capability;
}

interface SchedulesPermissions {
	canEdit: Capability;
	canDelete: Capability;
}

interface ExperimentsPermissions {
	canCreate: Capability;
}

interface TemplatesPermissions {
	canCreate: Capability;
	canUse: Capability;
}

interface HubsPermissions {
	canCreate: Capability;
}

interface TeamsPermissions {
	canCreate: Capability;
	canAddMember: Capability;
}

interface TeamPermissions {
	canManageMembers: boolean;
	canDelete: boolean;
	canEdit: boolean;
}

interface ExperimentPermissions {
	canDeleteSchedule: boolean;
	canRunByLicense: boolean;
	canRunByTeam: boolean;
	canSchedule: boolean;
	canDelete: boolean;
	canEdit: boolean;
}

export class PermissionsApi {
	getEnvironmentsPermissions = cached(this.getEnvironmentsPermissionsInternal.bind(this));
	getExperimentsPermissions = cached(this.getExperimentsPermissionsInternal.bind(this));
	getTemplatesPermissions = cached(this.getTemplatesPermissionsInternal.bind(this));
	getSchedulesPermissions = cached(this.getSchedulesPermissionsInternal.bind(this));
	getAgentsPermissions = cached(this.getAgentsPermissionsInternal.bind(this));
	getTeamsPermissions = cached(this.getTeamsPermissionsInternal.bind(this));
	getHubsPermissions = cached(this.getHubsPermissionsInternal.bind(this));
	events$: BehaviorSubject<SteadybitEventVO | null>;
	environmentsApi: EnvironmentsApi;
	experimentsApi: ExperimentApi;
	templatesApi: TemplatesApi;
	schedulesApi: SchedulesApi;
	agentsApi: AgentsApi;
	teamApi: TeamsApi;
	hubApi: HubsApi;

	constructor(
		events: EventsApi,
		environmentsApi: EnvironmentsApi,
		experimentApi: ExperimentApi,
		schedulesApi: SchedulesApi,
		templatesApi: TemplatesApi,
		agentsApi: AgentsApi,
		teamApi: TeamsApi,
		hubApi: HubsApi,
	) {
		this.environmentsApi = environmentsApi;
		this.experimentsApi = experimentApi;
		this.schedulesApi = schedulesApi;
		this.templatesApi = templatesApi;
		this.agentsApi = agentsApi;
		this.teamApi = teamApi;
		this.hubApi = hubApi;
		this.events$ = new BehaviorSubject<SteadybitEventVO | null>(null);

		events.events$
			.pipe(
				map((event) => {
					if (['team.updated', 'team.deleted', 'team.created', 'license.updated'].includes(event.type)) {
						this.getEnvironmentsPermissions.invalidateCaches();
						this.getExperimentsPermissions.invalidateCaches();
						this.getSchedulesPermissions.invalidateCaches();
						this.getTemplatesPermissions.invalidateCaches();
						this.getAgentsPermissions.invalidateCaches();
						this.getTeamsPermissions.invalidateCaches();
						this.getHubsPermissions.invalidateCaches();
						return true;
					}
					return false;
				}),
			)
			.pipe(filter(Boolean))
			.pipe(debounceTime(200))
			.subscribe(() => {
				this.events$.next(null);
			});
	}

	getGlobalPermissions$(teamId: string): Observable<Permissions> {
		return this.events$.pipe(switchMap(() => from(this.getGlobalPermissions(teamId))));
	}

	getExperimentPermissions(experiment: ExperimentSummaryVO): ExperimentPermissions {
		return {
			canDeleteSchedule: experiment._actions.includes('delete-schedule'),
			canRunByLicense: experiment._actions.includes('run-by-license'),
			canRunByTeam: experiment._actions.includes('run-by-team'),
			canSchedule: experiment._actions.includes('schedule'),
			canDelete: experiment._actions.includes('delete'),
			canEdit: experiment._actions.includes('edit'),
		};
	}

	getTeamPermissions(team: TeamVO): TeamPermissions {
		return {
			canManageMembers: team._actions.includes('manage-members'),
			canDelete: team._actions.includes('delete'),
			canEdit: team._actions.includes('edit'),
		};
	}

	private async getGlobalPermissions(teamId: string): Promise<Permissions> {
		const [environments, agents, experiments, templates, schedules, teams, hubs] = await Promise.all([
			this.getEnvironmentsPermissions(),
			this.getAgentsPermissions(),
			this.getExperimentsPermissions(teamId),
			this.getTemplatesPermissions(teamId),
			this.getSchedulesPermissions(teamId),
			this.getTeamsPermissions(teamId),
			this.getHubsPermissions(teamId),
		]);

		return {
			environments,
			experiments,
			schedules,
			templates,
			agents,
			teams,
			hubs,
		};
	}

	private async getAgentsPermissionsInternal(): Promise<AgentsPermissions> {
		try {
			const capabilities: CapabilityVO[] = await this.agentsApi.getCapabilities();
			return {
				canRegister: createCapability(capabilities, this.defaultPermissions.agents.canRegister, 'CREATE', 'AGENT'),
			};
		} catch {
			return this.defaultPermissions.agents;
		}
	}

	private async getEnvironmentsPermissionsInternal(): Promise<EnvironmentsPermissions> {
		try {
			const capabilities: CapabilityVO[] = await this.environmentsApi.getCapabilities();
			return {
				canCreate: createCapability(
					capabilities,
					this.defaultPermissions.environments.canCreate,
					'CREATE',
					'ENVIRONMENT',
				),
			};
		} catch {
			return this.defaultPermissions.environments;
		}
	}

	private async getExperimentsPermissionsInternal(teamId: string): Promise<ExperimentsPermissions> {
		try {
			const capabilities: CapabilityVO[] = await this.experimentsApi.getCapabilities(teamId);
			return {
				canCreate: createCapability(
					capabilities,
					this.defaultPermissions.experiments.canCreate,
					'CREATE',
					'EXPERIMENT',
				),
			};
		} catch {
			return this.defaultPermissions.experiments;
		}
	}

	private async getTemplatesPermissionsInternal(teamId: string): Promise<TemplatesPermissions> {
		try {
			const capabilities: CapabilityVO[] = await this.templatesApi.getCapabilities(teamId);
			return {
				canCreate: createCapability(capabilities, this.defaultPermissions.templates.canCreate, 'CREATE', 'TEMPLATE'),
				canUse: createCapability(capabilities, this.defaultPermissions.templates.canUse, 'USE', 'TEMPLATE'),
			};
		} catch {
			return this.defaultPermissions.templates;
		}
	}

	private async getHubsPermissionsInternal(): Promise<HubsPermissions> {
		try {
			const capabilities: CapabilityVO[] = await this.hubApi.getCapabilities();
			return {
				canCreate: createCapability(capabilities, this.defaultPermissions.hubs.canCreate, 'CREATE', 'HUB'),
			};
		} catch {
			return this.defaultPermissions.hubs;
		}
	}

	private async getTeamsPermissionsInternal(): Promise<TeamsPermissions> {
		try {
			const capabilities: CapabilityVO[] = await this.teamApi.getCapabilities();
			return {
				canCreate: createCapability(capabilities, this.defaultPermissions.teams.canCreate, 'CREATE', 'TEAM'),
				canAddMember: createCapability(capabilities, this.defaultPermissions.teams.canCreate, 'ADD', 'TEAM'),
			};
		} catch {
			return this.defaultPermissions.teams;
		}
	}

	private async getSchedulesPermissionsInternal(teamId: string): Promise<SchedulesPermissions> {
		try {
			const capabilities: CapabilityVO[] = await this.schedulesApi.getCapabilities(teamId);
			return {
				canEdit: createCapability(capabilities, this.defaultPermissions.schedules.canEdit, 'EDIT', 'SCHEDULE'),
				canDelete: createCapability(capabilities, this.defaultPermissions.schedules.canDelete, 'DELETE', 'SCHEDULE'),
			};
		} catch {
			return this.defaultPermissions.schedules;
		}
	}

	defaultPermissions: Permissions = {
		agents: {
			canRegister: forbidden('CREATE'),
		},
		environments: {
			canCreate: forbidden('CREATE'),
		},
		experiments: {
			canCreate: forbidden('CREATE'),
		},
		templates: {
			canCreate: forbidden('CREATE'),
			canUse: forbidden('USE'),
		},
		hubs: {
			canCreate: forbidden('CREATE'),
		},
		teams: {
			canCreate: forbidden('CREATE'),
			canAddMember: forbidden('ADD'),
		},
		schedules: {
			canEdit: forbidden('EDIT'),
			canDelete: forbidden('DELETE'),
		},
	};
}

type PermissionType = 'ENVIRONMENT' | 'EXPERIMENT' | 'AGENT' | 'TEMPLATE' | 'SCHEDULE' | 'TEAM' | 'HUB';
const TOOLTIPS = {
	AGENT: {
		CREATE: {
			NOT_ADMIN: 'You do not have the necessary permissions to add agents.',
			LICENSE: 'You cannot add agents because your Steadybit license has expired.',
			LICENSE_HARD_LIMIT_EXCEEDED: 'You reached your maximum number of agents.',
		},
	},
	ENVIRONMENT: {
		CREATE: {
			NOT_ADMIN: 'You do not have the necessary permissions to create environments.',
			LICENSE: 'You cannot create environments because your Steadybit license has expired.',
			LICENSE_HARD_LIMIT_EXCEEDED: 'You reached your maximum number of environments.',
		},
	},
	EXPERIMENT: {
		CREATE: {
			NOT_IN_TEAM: 'You need to be a member of the team to create new experiments.',
		},
	},
	TEMPLATE: {
		CREATE: {
			NOT_ADMIN: 'You do not have the necessary permissions to create templates.',
			LICENSE: 'Experiment creation using templates is not included in your current plan.',
		},
		USE: {
			NOT_ADMIN: 'You need to be a member of a team to use templates.',
			LICENSE: 'This feature is not included in your current plan.',
		},
	},
	HUB: {
		CREATE: {
			NOT_ADMIN: 'You do not have the necessary permissions to connect hubs.',
		},
	},
	TEAM: {
		CREATE: {
			NOT_ADMIN: 'You do not have the necessary permissions to create teams.',
			NOT_BATCH: 'You cannot manually add users because LDAP integration is enabled for this tenant.',
			LICENSE: 'You cannot create teams because your Steadybit license has expired.',
			LICENSE_HARD_LIMIT_EXCEEDED: 'You reached your maximum number of teams.',
		},
	},
	SCHEDULE: {
		EDIT: { NOT_IN_TEAM: 'You do not have permissions to schedule this experiment.' },
		DELETE: { NOT_IN_TEAM: 'You need to be a member of the Team to delete schedules.' },
	},
};

function createCapability(
	capabilities: CapabilityVO[],
	defaultCap: CapabilityVO,
	type: CapabilityTypeVO,
	permission: PermissionType,
): Capability {
	const capability: Capability = capabilities.find((capability) => capability.type === type) || defaultCap;
	capability.tooltipContent = capability.restriction
		? get(TOOLTIPS, `${permission}.${type}.${capability.restriction}`)
		: undefined;
	return capability;
}

function forbidden(type: CapabilityTypeVO): Capability {
	return {
		type,
		allowed: false,
	};
}
