/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useIsExperimentDisabled from 'pages/experimentsV2/useIsExperimentDisabled';
import { ReactElement } from 'react';
import { getHash } from 'utils/hash';
import { FieldVO } from 'ui-api';

import Field, { CommonFieldProps } from './Field';

interface FieldsProps extends CommonFieldProps {
	fields: FieldVO[];
}

export default function Fields({ fields, stepPath, ...rest }: FieldsProps): ReactElement | null {
	const disabled = useIsExperimentDisabled();

	return (
		<>
			{fields.map((field, __index) => {
				const hash = getHash({
					field,
					__additionalProps: {
						__index,
					},
				});

				return (
					<Field
						key={hash}
						path={`${stepPath}.parameters['${field.name}']`}
						disabled={disabled}
						stepPath={stepPath}
						field={field}
						{...rest}
					/>
				);
			})}
		</>
	);
}
