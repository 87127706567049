/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { StyleProp, UnstyledLink } from 'components';
import { Container } from 'components/Container';
import { Tooltip } from 'components/Tooltip';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { DataPoint } from './StripChart';

interface StripValuesProps {
	getTooltipContent: (dataPoint: DataPoint) => ReactElement;
	getColor?: (dataPoint: DataPoint) => string;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	dataPoints: DataPoint[];
	isHovered: boolean;
	muted?: boolean;
	from: number;
	to: number;
}

export default function StripValues({
	getTooltipContent,
	muted = false,
	onMouseEnter,
	onMouseLeave,
	dataPoints,
	isHovered,
	getColor,
	from,
	to,
}: StripValuesProps): ReactElement {
	const totalTime = to - from;
	return (
		<Container
			sx={{
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				backgroundColor: muted ? 'neutral100' : 'white',
				border: muted ? '1px dashed ' + theme.colors.neutral300 : '1px solid transparent',
				height: 24,
				width: '100%',
				opacity: isHovered && !muted ? 1 : 0.5,
			}}
			onMouseEnter={muted ? undefined : onMouseEnter}
			onMouseLeave={muted ? undefined : onMouseLeave}
		>
			{dataPoints
				.filter((dataPoint) => dataPoint.timestamp < to)
				.map((dataPoint, i) => {
					return (
						<Strip
							key={i}
							getTooltipContent={getTooltipContent}
							dataPoints={dataPoints}
							dataPoint={dataPoint}
							totalTime={totalTime}
							getColor={getColor}
							from={from}
							index={i}
							to={to}
						/>
					);
				})}
		</Container>
	);
}

interface StripProps {
	getTooltipContent: (dataPoint: DataPoint) => ReactElement;
	getColor?: (dataPoint: DataPoint) => string;
	dataPoints: DataPoint[];
	dataPoint: DataPoint;
	totalTime: number;
	index: number;
	from: number;
	to: number;
}

function Strip({
	dataPoints,
	totalTime,
	dataPoint,
	index,
	from,
	to,
	getTooltipContent,
	getColor,
}: StripProps): ReactElement {
	// clamp from timestamp to avoid rendering of values outside of the chart
	const dpFrom = Math.max(from, dataPoint.timestamp);
	const dpTo = Math.min(
		to,
		dataPoint.endTimestamp ?? (index === dataPoints.length - 1 ? to : dataPoints[index + 1].timestamp),
	);
	const scale = (dpTo - dpFrom) / totalTime;
	const color = getColor?.(dataPoint) ?? dataPoint.color;

	const height = 24;
	const width = `calc(${Math.max(scale * 100, 1)}% - 1px)`;

	const sx: StyleProp = {
		position: 'absolute',
		left: `${((dpFrom - from) / totalTime) * 100}%`,
		background: color,
		width,
		height,

		'&:hover': {
			border: '1px solid white',
			outline: '1px solid ' + theme.colors.slate,
			borderRadius: 2,

			'&::after': {
				content: '""',
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.2)',
			},
		},
	};

	return (
		<Tooltip content={getTooltipContent(dataPoint)}>
			{dataPoint.url ? <UnstyledLink href={dataPoint.url} external sx={sx} /> : <Container sx={sx} />}
		</Tooltip>
	);
}
