/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { FormikTextField, Label, OptionTypeBase, Stack, Text } from 'components';
import TeamSelection from 'pages/settings/teams/components/TeamSelection';
import { Flex, Grid, presets } from '@steadybit/ui-components-lib';
import { WebhookScope, WebhookTypes } from 'services/webhooksApi';
import { useTenant } from 'tenancy/useTenant';
import { CreateWebhookRequest } from 'ui-api';
import { useFormikContext } from 'formik';
import { isUserAdmin } from 'utils/user';
import { ReactElement } from 'react';

import { WebhookEventsInput } from './webhooksEventsInput';
import { WebhookParameters } from './webhookParameters';

export type WebhookFormValues = Omit<CreateWebhookRequest, 'type'>;

interface WebhookFormProps {
	disabled?: string[] | boolean;
	type: string;
}

export function WebhookForm({ type, disabled }: WebhookFormProps): ReactElement {
	const isDisabled = (name: string): boolean => (Array.isArray(disabled) ? disabled.includes(name) : Boolean(disabled));

	return (
		<Stack size={'medium'}>
			<FormWebhookScope disabled={isDisabled('scope')} />
			<FormikTextField
				label={'Name'}
				name={'name'}
				maxLength={255}
				autoFocus
				autoComplete={'off'}
				disabled={isDisabled('name')}
			/>
			<FormikTextField
				label={'Webhook URL'}
				name={'url'}
				maxLength={1024}
				autoComplete={'off'}
				disabled={isDisabled('url')}
			/>
			<FormWebhookParameters disabled={isDisabled('parameters')} type={type} />
			<FormWebhookEventsInput type={type} disabled={isDisabled('events')} />
		</Stack>
	);
}

function FormWebhookScope({ disabled }: { disabled?: boolean }): ReactElement {
	const formik = useFormikContext<WebhookFormValues>();
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const handleScopeChange = (option?: OptionTypeBase | null): void => {
		if (option && 'value' in option && option.value !== formik.values.scope) {
			formik.setFieldValue('scope', option?.value);
			formik.setFieldTouched('scope');
		}
		if (option?.value !== WebhookScope.TEAM.value) {
			formik.setFieldValue('team', '');
			formik.setFieldTouched('team');
		}
	};

	const hasError = !!formik.errors.team;

	return (
		<Grid cols="1fr 2fr" spacing="small">
			<Flex spacing="xSmall">
				<Label>Scope</Label>
				<presets.dropdown.SingleChoiceButton
					selectedId={formik.values.scope}
					withKeyboardArrowSupport
					maxContentHeight="250px"
					items={(isAdmin ? [WebhookScope.GLOBAL, WebhookScope.TEAM] : [WebhookScope.TEAM]).map((v) => ({
						...v,
						id: v.value,
					}))}
					disabled={disabled}
					onSelect={(id) => handleScopeChange(WebhookScope[id])}
					style={{ width: '100%' }}
				>
					{WebhookScope[formik.values.scope].label}
				</presets.dropdown.SingleChoiceButton>
			</Flex>

			{formik.values.scope === 'TEAM' && (
				<Flex spacing="xSmall">
					<Label>Team</Label>

					<TeamSelection
						value={formik.values.team}
						myTeamsOnly={!isAdmin}
						includeAny={isAdmin}
						disabled={disabled}
						onChange={(teamId) => {
							formik.setFieldValue('team', teamId);
							formik.setFieldTouched('team');
						}}
					/>
					{hasError ? (
						<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
							{formik.errors.team}
						</Text>
					) : null}
				</Flex>
			)}
		</Grid>
	);
}

function FormWebhookEventsInput({ type, disabled }: { type: string; disabled?: boolean }): ReactElement {
	const formik = useFormikContext<WebhookFormValues>();
	return (
		<WebhookEventsInput
			value={formik.values.events}
			name={'events'}
			onChange={(v) => formik.setFieldValue('events', v)}
			type={type}
			disabled={disabled}
		/>
	);
}

function FormWebhookParameters({ disabled, type }: { disabled?: boolean; type: string }): ReactElement | null {
	const selectedType = WebhookTypes.find((w) => w.type === type);
	if (!selectedType) {
		return null;
	}
	return <WebhookParameters type={selectedType} disabled={disabled} />;
}
