/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	IconActionKit,
	IconAdviceGeneral,
	IconExtension,
	IconPreflightActionGeneral,
	IconTags,
	IconTarget,
	IconTargetEnrichmentRule,
} from 'components/icons';
import { Link, LinkButton, Stack, Tab, TabList, TabPanel, Tabs, Tooltip } from 'components';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import ListHeader from 'components/List/presets/ListHeader';
import Actions from 'pages/settings/ExtensionList/Actions';
import { useLicenseFeature } from 'services/licenseApi';
import { ReactElement, useEffect } from 'react';
import { useUrlState } from 'url/useUrlState';
import { ampli } from 'ampli';

import PreflightActionDefinitions from './PreflightActionDefinitions';
import TargetEnrichmentRules from './TargetEnrichmentRules';
import ContentWrapper from '../components/ContentWrapper';
import AttributeDefinitions from './AttributeDefinitions';
import AdviceDefinitions from './AdviceDefinitions';
import HelpText from '../components/HelpText';
import TargetTypes from './TargetTypes';

type Tab = 'targets' | 'attributes' | 'actions';

export default function ExtensionList(): ReactElement {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ tab }, _, updateUrlWithState] = useUrlState([
		{
			pathSegment: '/extensions',
			name: 'tab',
			defaultValue: 'actions' as Tab,
		},
	]);

	const adviceEnabled = useLicenseFeature('RELIABILITY_ADVICE');
	const preflightEnabled = useLicenseFeature('PREFLIGHT_ACTIONS');

	return (
		<ContentWrapper>
			<ListHeader left={<ListHeaderTitle title="Extensions" Icon={IconExtension} />} />

			<Stack direction="horizontal" justifyContent="space-between" alignItems="center">
				<HelpText>
					Extensions leveraging any of our kits, e.g.,{' '}
					<Link href="https://github.com/steadybit/action-kit" external>
						ActionKit
					</Link>{' '}
					or{' '}
					<Link href="https://github.com/steadybit/discovery-kit" external>
						DiscoveryKit
					</Link>
					, report a variety of meta-data. Extension authors and users may sometimes find it helpful to inspect the
					meta-data provided through extensions. The following sections present the meta-data.
				</HelpText>

				<LinkButton
					href="https://hub.steadybit.com/?utm_campaign=extensions&utm_source=platform-extensions&utm_medium=button"
					external
					target="_blank"
					data-track="get-extensions-from-extension-list"
					flexShrink={0}
				>
					Get Extensions
				</LinkButton>
			</Stack>
			<Tabs onChange={(tab) => updateUrlWithState({ tab })} value={tab as string}>
				<TabList>
					<Tab value="actions">
						<IconActionKit size={'small'} mr={'xSmall'} ml={-8} />
						Actions
					</Tab>
					<Tab value="targets">
						<IconTarget size={'small'} mr={'xSmall'} ml={-8} />
						Target Types
					</Tab>
					<Tab value="attributes">
						<IconTags size={'small'} mr={'xSmall'} ml={-8} />
						Target Attributes
					</Tab>
					<Tab value="rules">
						<IconTargetEnrichmentRule size={'small'} mr={'xSmall'} ml={-8} />
						Target Enrichment Rules
					</Tab>
					<Tooltip content={<LicenseTooltipContent />} disabled={!!adviceEnabled}>
						<Tab value="adviceDefinitions" disabled={!adviceEnabled}>
							<IconAdviceGeneral size={'small'} mr={'xSmall'} ml={-8} />
							Advice
						</Tab>
					</Tooltip>
					<Tooltip content={<LicenseTooltipContent />} disabled={!!preflightEnabled}>
						<Tab value="preflightDefinitions" disabled={!preflightEnabled}>
							<IconPreflightActionGeneral size={'small'} mr={'xSmall'} ml={-8} />
							Preflight Actions
						</Tab>
					</Tooltip>
				</TabList>
				<TabPanel value="actions" sx={{ overflowX: 'auto' }}>
					<Actions />
				</TabPanel>
				<TabPanel value="targets" sx={{ overflowX: 'auto' }}>
					<TargetTypes />
				</TabPanel>
				<TabPanel value="rules" sx={{ overflowX: 'auto' }}>
					<TargetEnrichmentRules />
				</TabPanel>
				<TabPanel value="attributes" sx={{ overflowX: 'auto' }}>
					<AttributeDefinitions />
				</TabPanel>
				<TabPanel value="adviceDefinitions" sx={{ overflowX: 'auto' }}>
					<AdviceDefinitions />
				</TabPanel>
				<TabPanel value="preflightDefinitions" sx={{ overflowX: 'auto' }}>
					<PreflightActionDefinitions />
				</TabPanel>
			</Tabs>
		</ContentWrapper>
	);
}

export const useTrackExtensionsListViewed = (
	extensionType:
		| 'actions'
		| 'target_types'
		| 'target_attributes'
		| 'target_enrichment_rules'
		| 'advice_definitions'
		| 'preflight_actions_definitions'
		| undefined,
	page: number,
	totalElements: number,
	totalPages: number,
	typeIdsUnavailable?: string[],
	typeIdsWithMultipleVersions?: string[],
	typeIdsWithUnknownVersion?: string[],
): void => {
	useEffect(() => {
		if (extensionType) {
			const extension_warnings: ('unavailable' | 'multiple_versions' | 'unknown_versions')[] = [];
			if (typeIdsUnavailable && typeIdsUnavailable.length > 0) {
				extension_warnings.push('unavailable');
			}
			if (typeIdsWithMultipleVersions && typeIdsWithMultipleVersions.length > 0) {
				extension_warnings.push('multiple_versions');
			}
			if (typeIdsWithUnknownVersion && typeIdsWithUnknownVersion.length > 0) {
				extension_warnings.push('unknown_versions');
			}
			ampli.extensionsListViewed({
				current_page: page,
				total_elements: totalElements,
				total_pages: totalPages,
				url: window.location.href,
				extension_type: extensionType,
				extension_warnings,
				type_ids_unavailable: typeIdsUnavailable,
				type_ids_multiple_versions: typeIdsWithMultipleVersions,
				type_ids_unknown_versions: typeIdsWithUnknownVersion,
			});
		}
	}, [extensionType, typeIdsUnavailable, typeIdsWithMultipleVersions, typeIdsWithUnknownVersion]);
};
