/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import SidebarPredicateModule from 'pages/settings/environments/config/SidebarPredicateModule';
import SidebarNameModule from 'pages/settings/environments/config/SidebarNameModule';
import SidebarWrapper from 'pages/settings/environments/config/SidebarWrapper';
import { EnvironmentConfig } from 'pages/settings/environments/config/types';
import ResultView from 'pages/settings/environments/config/ResultView';
import { Button, Snackbar, Stack, Text } from 'components';
import DataStore, { useStore } from 'DataStore/DataStore';
import { userInput } from 'components/Dialog/UserInput';
import { Flex } from '@steadybit/ui-components-lib';
import { IconSaveDisc } from 'components/icons';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { EnvironmentVO } from 'ui-api';

interface CreateNewEnvironmentInsideTeamProps {
	onCreated: (config: EnvironmentVO) => void;
}

export default function CreateNewEnvironmentInsideTeam({
	onCreated,
}: CreateNewEnvironmentInsideTeamProps): ReactElement {
	return (
		<DataStore<EnvironmentConfig>
			initialData={{
				name: '',
				predicate: {
					predicates: [],
					operator: 'AND',
				},
				teamIds: [],
			}}
		>
			<>
				<Header onCreated={onCreated} />
				<Flex
					direction="horizontal"
					style={{
						overflow: 'hidden',
						width: '100%',
						height: '100%',
					}}
				>
					<SidebarWrapper>
						<SidebarNameModule />
						<SidebarPredicateModule />
					</SidebarWrapper>

					<ResultView />
				</Flex>
			</>
		</DataStore>
	);
}

interface HeaderProps {
	onCreated: (config: EnvironmentVO) => void;
}

function Header({ onCreated }: HeaderProps): ReactElement {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { data } = useStore<EnvironmentConfig>();

	return (
		<Stack direction="horizontal" alignItems="center" justifyContent="space-between" pb="medium">
			<Text variant="xLargeStrong">Create new Environment</Text>
			<Button
				onClick={async () => {
					setIsSubmitting(true);
					const savedConfig = await save(data);
					if (savedConfig) {
						onCreated(savedConfig);
					}
					setIsSubmitting(false);
				}}
				disabled={isSubmitting || !data.name}
				width="210px"
			>
				<IconSaveDisc variant="small" mr="xSmall" /> {isSubmitting ? 'Saving...' : 'Create Environment'}
			</Button>
		</Stack>
	);
}

async function save(configToSave: EnvironmentConfig): Promise<EnvironmentVO | undefined> {
	if (!configToSave.name) {
		const inputResult = await userInput({
			title: 'Name your Environment',
			placeholder: 'Untitled Environment',
			buttonOkCaption: 'Create Environment',
		});
		if (inputResult.action === 'cancel') {
			return;
		}
		configToSave = { ...configToSave, name: inputResult.input || '' };
	}

	try {
		return await Services.environments.createEnvironment(configToSave);
	} catch (error) {
		console.error('error', error);
		Snackbar.error(error.title);
	}
}
