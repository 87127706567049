/*
 * Copyright 2019 steadybit GmbH. All rights reserved.
 */

import { track } from '../tracking/sentry';

const invokePromise = <T>(promiseFn: () => Promise<T>): void => {
	(async () => {
		try {
			await promiseFn();
		} catch (error) {
			track('Promised returned unhandled Error', { error });
		}
	})();
};

export default invokePromise;
