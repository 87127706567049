/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Errors, useStore, useValidations } from 'DataStore/DataStore';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { Services } from 'services/services';
import { useEffect, useState } from 'react';
import { debounce, set } from 'lodash';
import { ViolationVO } from 'ui-api';

import { useValidationSignal$ } from './useSignals$';
import { ExperimentFormValues } from './types';

export default function ValidationHandler(): null {
	const signal = useValidationSignal$();

	const { data, initialErrors = {} } = useStore<ExperimentFormValues>();
	const { errors, setValidations } = useValidations();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { actions, ...experiment } = data;
	const [debouncedValidate] = useState(() =>
		debounce(
			async (v) => {
				setValidations({ isValidating: false, errors: await validate(v) });
			},
			500,
			{ leading: true },
		),
	);

	const [stableId, stableExperiment] = useStableInstance(experiment);
	const reloadSignalBecauseOrErrors = Object.keys(errors).length === 0 && Object.keys(initialErrors).length > 0;

	useEffect(() => {
		setValidations({ isValidating: true });
		debouncedValidate(stableExperiment);
	}, [stableId, reloadSignalBecauseOrErrors, signal, debouncedValidate]);

	return null;
}

export async function validate(values: ExperimentFormValues): Promise<Errors> {
	try {
		const violations = await Services.experiments.validateExperiment({
			...values,
			created: values.created ? new Date(values.created) : undefined,
			edited: values.edited ? new Date(values.edited) : undefined,
		});
		return mapViolations(violations);
	} catch (error) {
		console.error('Could not validate experiment', { experimentKey: values.experimentKey, error });
	}
	return {};
}

export function mapViolations(violations: ViolationVO[]): Errors {
	const _errors = {};
	violations.forEach(({ field, message }) => {
		if (field === 'experimentVariables') {
			set(_errors, 'overallExperimentVariables', { message, level: 'error' });
		} else if (message === 'There are no targets matching your query.') {
			set(_errors, field, { message, level: 'info' });
		} else {
			set(_errors, field, { message, level: 'error' });
		}
	});
	return _errors;
}
