/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useGlobalNotificationWithFilter } from 'services/useGlobalNotifications';
import { utils } from '@steadybit/ui-components-lib';
import { Banner, Stack, Text } from 'components';
import { get, set } from 'utils/localStorage';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { NotificationVO } from 'ui-api';
import { ReactElement } from 'react';
import { days } from 'utils/time';

import getContent, { Content } from './notificationContentMap';

interface EnrichedNotification extends NotificationVO {
	content: Content;
}

export default function GlobalNotificationBanner(): ReactElement | null {
	const tenant = useTenant();
	const notifications = useGlobalNotificationWithFilter(
		(notification) => notification.global && isIncreasedUsage(tenant.key, notification),
	);

	if (!notifications || notifications.length === 0) {
		return null;
	}

	function getSnoozeTime(snoozeTime: string): Date {
		const durationInMs = utils.convertDurationToMilliseconds(snoozeTime) ?? days(1).getMillis();
		return new Date(Date.now() + durationInMs);
	}

	const order = {
		info: 1,
		warning: 2,
		error: 3,
	};

	const orderedNotifications = notifications
		.map((notification: NotificationVO): EnrichedNotification => {
			return { ...notification, content: getContent(notification) };
		})
		.sort((a, b) => {
			return order[b.content.level] - order[a.content.level];
		});

	return (
		<div id="notification-banner">
			{orderedNotifications.map((notification) => {
				const { id, snoozeTime, notifyOnDismiss, content } = notification;
				return (
					<Banner
						key={id}
						id={id}
						variant={content.level === 'error' ? 'danger' : content.level === 'warning' ? 'warning' : 'info'}
						title={content.title || ''}
						dismissible={!!snoozeTime || notifyOnDismiss}
						description={
							<Stack size="none">
								<Text as="span" variant="small">
									{content.message}
								</Text>
							</Stack>
						}
						snooze={
							snoozeTime
								? {
										id: id,
										end: getSnoozeTime(snoozeTime),
									}
								: undefined
						}
						onSnoozeCallback={() => {
							saveSnoozedUsage(tenant.key, notification);
							if (notifyOnDismiss) {
								Services.notificationsApi.dismiss(notification).then(); // don't wait
							}
						}}
						sx={{
							py: '6px',
						}}
					/>
				);
			})}
		</div>
	);
}

// Global notifications containing a "usage", like the ones for license limits,
// should be displayed on their initial occurrence and, if snoozed, only on
// subsequent increases.
// For this, the "usage" of snoozed notifications is stored in the local storage and used
// to filter later notifications with increased "usage".

const notificationUsagePrefix = 'notification-usage-';

function isIncreasedUsage(tenant: string, notification: NotificationVO): boolean {
	if (!notification.data?.['usage']) {
		return true;
	}
	const notificationUsage = notification.data['usage'];
	const snoozedUsage = get(tenant, notificationUsagePrefix + notification.id);
	return notificationUsage > Number(snoozedUsage);
}

function saveSnoozedUsage(tenant: string, notification: NotificationVO): void {
	if (notification.data?.['usage'] && isIncreasedUsage(tenant, notification)) {
		const notificationUsage = notification.data['usage'] || undefined;
		set(tenant, notificationUsagePrefix + notification.id, notificationUsage);
	}
}
