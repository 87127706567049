/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { VariableVO } from 'ui-api';

export const EnvVarRegex = /\{\{([A-Za-z0-9-_.]+?)\}\}/g;
export const EnvVarRegexKeepingBrackets = /(\{\{([A-Za-z0-9-_.]+?)\}\})/g;

export function quoteForVariables(value: string): string {
	return value.replaceAll(EnvVarRegex, '\\$&');
}

export type Scope = 'experiment' | 'environment';
export interface VariableWithScope extends VariableVO {
	scope: Scope;
}

export function mergeAndSort(envVars: VariableVO[], expVars: VariableVO[]): VariableWithScope[] {
	const merged = new Map<string, VariableWithScope>();
	envVars.forEach((v) => merged.set(v.key, { ...v, scope: 'environment' }));
	expVars.forEach((v) => merged.set(v.key, { ...v, scope: 'experiment' }));
	return Array.from(merged.values()).sort((a, b) => a.key.localeCompare(b.key));
}
