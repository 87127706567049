/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import EnvironmentSelector from 'components/EnvironmentSelector/EnvironmentSelector';
import { Colors, Dropdown, Pill, presets } from '@steadybit/ui-components-lib';
import { useValidations, useStoreField } from 'DataStore/DataStore';
import { Stack, TutorialTooltip } from 'components';
import { ReactElement, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { hasError } from 'DataStore/utils';
import { VariableVO } from 'ui-api';

import ExperimentAndEnvironmentVariables from './ExperimentAndEnvironmentVariables';

export function ExperimentSubHeaderEnvironments({ disabled }: { disabled: boolean }): ReactElement {
	const { errors } = useValidations();

	const { value: experimentVariables = [] } = useStoreField<VariableVO[] | undefined>('experimentVariables');
	const { value: variables = [] } = useStoreField<VariableVO[] | undefined>('variables');
	const { value: teamId } = useStoreField<string>('teamId');

	const environmentIdField = useStoreField<string>('environmentId');
	const { value: environmentId, setValue: setEnvironmentId } = environmentIdField;
	const hasEnvironmentError = hasError(environmentIdField);

	// when teamId changes reset environmentId
	useUpdateEffect(() => {
		setEnvironmentId('');
	}, [teamId]);

	const hasVariablesError: boolean =
		Boolean(errors.overallExperimentVariables) ||
		Boolean(errors.experimentVariables) ||
		experimentVariables.some((variable) => !variable.value);

	const [isDeletingVariable, setIsDeletingVariable] = useState(false);
	const totalNumberOfVariables = variables.length + experimentVariables.length;

	return (
		<Stack flexShrink={0} direction={'horizontal'}>
			<Stack direction="horizontal" size="none">
				<EnvironmentSelector
					selectedEnvironmentId={environmentId}
					hasError={hasEnvironmentError}
					disabled={disabled}
					teamId={teamId}
					selectEnvironmentId={setEnvironmentId}
				/>

				{!environmentId && !disabled && (
					<TutorialTooltip id="experiments-noEnvironmentSelected" light hideIcon hideSkip showAlways>
						<>
							Select an environment to design
							<br /> an Experiment.
						</>
					</TutorialTooltip>
				)}
			</Stack>

			<Dropdown
				disabledCloseOnClickAway={isDeletingVariable}
				placement="bottom-start"
				renderDropdownContent={({ close }) => (
					<presets.dropdown.DropdownContentFrame maxHeight="500px" style={{ borderRadius: 'xxSmall' }}>
						<ExperimentAndEnvironmentVariables
							environmentVariables={variables}
							setIsDeletingVariable={setIsDeletingVariable}
							close={close}
						/>
					</presets.dropdown.DropdownContentFrame>
				)}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<Pill
							ref={setRefElement}
							withLeftIcon="function"
							withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
							type={hasVariablesError && !hasEnvironmentError ? 'error' : 'default'}
							disabled={disabled || hasEnvironmentError}
							style={
								totalNumberOfVariables > 0 && !hasVariablesError
									? {
											backgroundColor: isOpen ? Colors.purple500 : Colors.purple300,
											color: isOpen ? Colors.neutral000 : Colors.neutral800,
											onHover: {
												backgroundColor: Colors.purple500,
												color: Colors.neutral000,
											},
										}
									: undefined
							}
							onClick={disabled ? undefined : () => setOpen(!isOpen)}
						>
							{totalNumberOfVariables === 0 ? 'No variables' : `Variables (${totalNumberOfVariables})`}
						</Pill>
					);
				}}
			</Dropdown>
		</Stack>
	);
}
