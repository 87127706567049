/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, Flex, presets, Text } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO, OptionVO } from 'ui-api';
import { ReactElement } from 'react';

interface EnvironmentsProps {
	environments: EnvironmentSummaryVO[];
	historicalEnvironments?: OptionVO[];
	selectedEnvironmentIds: string[];
	selectIds: (envIds: string[]) => void;
}

interface ReducedEnvironment {
	id: string;
	label: string;
}

export default function Environments({
	historicalEnvironments = [],
	selectedEnvironmentIds,
	environments,
	selectIds,
}: EnvironmentsProps): ReactElement {
	const allCurrentEnvIds = new Set(environments.map((t) => t.id));
	const deletedEnvs = historicalEnvironments.filter((t) => !allCurrentEnvIds.has(t.value));

	const mappedEnvs = environments.map((e) => ({
		id: e.id,
		label: e.name,
	}));
	const mappedDeletedEnvs = deletedEnvs.map((e) => ({
		id: e.value,
		label: e.label,
	}));
	const mergedEnvironments: ReducedEnvironment[] = mappedEnvs.concat(mappedDeletedEnvs);

	return (
		<Dropdown
			renderDropdownContent={({ width }) => {
				return (
					<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="400px">
						<EnvironmentList
							selectedEnvironmentIds={selectedEnvironmentIds}
							environments={mappedEnvs}
							title="ENVIRONMENTS"
							selectIds={selectIds}
						/>
						<EnvironmentList
							selectedEnvironmentIds={selectedEnvironmentIds}
							environments={mappedDeletedEnvs}
							title="DELETED ENVIRONMENTS"
							selectIds={selectIds}
						/>
					</presets.dropdown.DropdownContentFrame>
				);
			}}
		>
			{({ setRefElement, isOpen, setOpen }) => (
				<presets.dropdown.ChoiceButton
					ref={setRefElement}
					placeholder="Any environment"
					isOpen={isOpen}
					style={{ width: '100%' }}
					onClick={() => setOpen(!isOpen)}
				>
					{mergedEnvironments
						.filter((env) => selectedEnvironmentIds.includes(env.id))
						.map((env) => env.label)
						.join(', ')}
				</presets.dropdown.ChoiceButton>
			)}
		</Dropdown>
	);
}

interface EnvironmentListProps {
	environments: ReducedEnvironment[];
	selectedEnvironmentIds: string[];
	title: string;
	selectIds: (envIds: string[]) => void;
}

function EnvironmentList({
	selectedEnvironmentIds,
	environments,
	title,
	selectIds,
}: EnvironmentListProps): ReactElement | null {
	if (environments.length === 0) {
		return null;
	}
	const allSelected = environments.every((e) => selectedEnvironmentIds.includes(e.id));

	const onSelectAll = (): void =>
		selectIds(Array.from(new Set([...selectedEnvironmentIds, ...environments.map((t) => t.id)])));
	const onClearAll = (): void =>
		selectIds(selectedEnvironmentIds.filter((v) => !environments.map((t) => t.id).includes(v)));

	return (
		<>
			<Flex direction="horizontal" align="center" justify="spread" style={{ pt: 'xSmall', pl: 'small' }}>
				<Text type="small" neutral600>
					{title}
				</Text>
				<Button
					type="chromeless"
					size="small"
					style={{
						color: Colors.slate,
						onHover: {
							textDecoration: 'underline',
						},
					}}
					onClick={() => (allSelected ? onClearAll() : onSelectAll())}
				>
					{allSelected ? 'Clear all' : 'Select all'}
				</Button>
			</Flex>
			<presets.dropdown.MultipleChoiceList
				items={environments.map((e) => ({
					...e,
					isSelected: selectedEnvironmentIds.includes(e.id),
				}))}
				onUncheck={(id) => selectIds(selectedEnvironmentIds.filter((i) => i !== id))}
				onCheck={(id) => selectIds([...selectedEnvironmentIds, id])}
			/>
		</>
	);
}
