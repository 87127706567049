/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { TemplatePlaceholderVO, TemplateVO } from 'ui-api';
import { ReactElement, useState } from 'react';
import DataStore from 'DataStore/DataStore';
import { cloneDeep } from 'lodash';

import { UseTemplateFormData } from './UseTemplateFormLoadingHandler';
import UseTemplateEmptyChecker from './UseTemplateEmptyChecker';
import PlaceholderSyncJob from './PlaceholderSyncJob';
import ValidationHandler from './ValidationHandler';
import MetadataSyncJob from './MetadataSyncJob';

interface UseTemplateFormProps {
	withExperimentHypothesisExtraction?: boolean;
	withExperimentNameExtraction?: boolean;
	placeholders: TemplatePlaceholderVO[];
	newExperimentTags?: string[];
	isCreatedByAdvice: boolean;
	children: ReactElement;
	environmentId: string;
	template: TemplateVO;
	onIsEmpty: (values: UseTemplateFormData) => void;
	onClose: () => void;
}

export default function UseTemplateForm({
	withExperimentHypothesisExtraction,
	withExperimentNameExtraction,
	newExperimentTags,
	isCreatedByAdvice,
	environmentId,
	placeholders,
	template,
	children,
	onIsEmpty,
	onClose,
}: UseTemplateFormProps): ReactElement {
	const [__originalLanes] = useState(() => cloneDeep(template.lanes));
	const [__originalExperimentName] = useState(() => template.experimentName);
	const [__originalHypothesis] = useState(() => template.hypothesis);

	const placeholderValuesMap: Record<string, string> = {};
	template.placeholders.forEach(({ key }) => {
		placeholderValuesMap[key] = '';
	});

	const initialData: UseTemplateFormData = {
		...template,
		placeholderValuesMap,
		variableValuesMap: {},
		placeholdersMap: {},
		variablesMap: {},
		additionalPlaceholders: placeholders,
		initialMetadataLoaded: false,
		newExperimentTags,
		variables: [],
		environmentId,
		__originalLanes,
		__originalExperimentName,
		__originalHypothesis,
	};

	return (
		<DataStore<UseTemplateFormData> initialData={initialData}>
			<MetadataSyncJob
				withExperimentHyphotesisExtraction={withExperimentHypothesisExtraction}
				withExperimentNameExtraction={withExperimentNameExtraction}
			/>
			<UseTemplateEmptyChecker
				isCreatedByAdvice={isCreatedByAdvice}
				template={template}
				onIsEmpty={() => onIsEmpty(initialData)}
				onClose={onClose}
			>
				<>
					<PlaceholderSyncJob />
					<ValidationHandler />
					{children}
				</>
			</UseTemplateEmptyChecker>
		</DataStore>
	);
}
