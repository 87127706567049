/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Link,
	RouterLink,
	RouterPagination,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableErrorRow,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	Tooltip,
} from 'components';
import { IconEdit, IconHub, IconTemplateAdd, IconTrash } from 'components/icons';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { Order, PageLocation, usePage } from 'utils/hooks/usePage';
import useGlobalPermissions from 'services/useGlobalPermissions';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { debounce } from 'lodash';
import { HubVO } from 'ui-api';

import { formatDateWithTime } from '../../../utils/dateFns';
import ImportTemplatesModal from './ImportTemplatesModal';
import { EditHubModal } from './ConnectHubModal';
import DeleteHubModal from './DeleteHubModal';

const SORT_NAME_ASC: Order[] = [['hubName', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['hubName', 'desc', 'ignoreCase']];

interface HubsTableProps {
	selectedHubId?: string;
}

export default function HubsTable({ selectedHubId }: HubsTableProps): ReactElement {
	const page = usePage('/hubs', {
		page: 0,
		size: 10,
		sort: [
			['isProtected', 'desc', 'ignoreCase'],
			['hubName', 'asc', 'ignoreCase'],
		],
	});

	const history = useHistory();

	const [hubsResult, fetch] = useAsyncState(() => {
		return Services.hubApi.getHubs(page.pageParams);
	}, [page.pageParams.page, page.pageParams.sort.join(',')]);
	const debouncedFetch = useMemo(() => debounce(fetch, 500), [fetch]);
	useEventEffect(debouncedFetch, ['hub.deleted', 'hub.created', 'hub.updated', 'hub.synced'], () => {}, [
		debouncedFetch,
	]);

	const [editableHub, setEditableHub] = useState<HubVO | null>(null);
	const [deletableHub, setDeletableHub] = useState<HubVO | null>(null);

	const hubToImport = selectedHubId ? hubsResult.value?.content.find((hub) => hub.id === selectedHubId) : null;

	let content;
	if (hubsResult.error) {
		content = (
			<TableWrapper page={page}>
				<TableErrorRow error="Error loading hubs" />
			</TableWrapper>
		);
	} else if (hubsResult.loading || !hubsResult.value) {
		content = (
			<TableWrapper page={page}>
				<TableLoadingRow numColumns={4} />
				<TableLoadingRow numColumns={4} />
				<TableLoadingRow numColumns={4} />
			</TableWrapper>
		);
	} else {
		content = (
			<Stack size="large">
				<TableWrapper page={page}>
					{hubsResult.value.content.map((hub) => (
						<HubRow key={hub.id} hub={hub} editHub={setEditableHub} deleteHub={setDeletableHub} />
					))}
					{!hubsResult.value.content.length && (
						<TableRow>
							<TableDataCell colSpan={3}>
								<Text muted>No hubs found.</Text>
							</TableDataCell>
						</TableRow>
					)}
				</TableWrapper>
				<RouterPagination
					activePage={page.pageParams.page}
					totalPages={hubsResult.value?.totalPages}
					to={(i) => page.withPage(i).toString()}
				/>
			</Stack>
		);
	}

	return (
		<>
			{editableHub && (
				<EditHubModal
					readonly={!editableHub._actions.includes('edit')}
					hubId={editableHub.id}
					onHubUpdated={() => setEditableHub(null)}
					onClose={() => setEditableHub(null)}
				/>
			)}
			{deletableHub && <DeleteHubModal hub={deletableHub} onClose={() => setDeletableHub(null)} />}
			{hubToImport && (
				<ImportTemplatesModal
					title="Import Templates"
					cancelText="Cancel"
					hub={hubToImport}
					pathname={'/' + hubToImport.id}
					onClose={() => history.push('/settings/hubs')}
				/>
			)}
			{content}
		</>
	);
}

function TableWrapper({ page, children }: { page: PageLocation; children: ReactNode }): ReactElement {
	return (
		<Table width="100%">
			<TableHead>
				<TableRow>
					<TableHeadCell>
						<TableSortLink
							sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
							to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
						>
							Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>Hub Link</TableHeadCell>
					<TableHeadCell width="160px">Last Sync</TableHeadCell>
					<TableHeadCell width="140px" />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

interface TemplateRowProps {
	hub: HubVO;
	deleteHub: (hub: HubVO) => void;
	editHub: (hub: HubVO) => void;
}

function HubRow({ hub, editHub, deleteHub }: TemplateRowProps): ReactElement {
	const permissions = useGlobalPermissions();

	return (
		<TableRow
			hoverable={true}
			height={54}
			sx={{
				...(hub.protected
					? {
							borderTop: '2px solid',
							borderTopColor: 'neutral400',
							borderBottom: '2px solid',
							borderBottomColor: 'neutral400',
						}
					: {}),
			}}
		>
			<TableDataCell>
				<Stack direction="horizontal" size="xSmall" alignItems="center">
					<IconHub />
					<Tooltip content={hub.hubName} onlyShowOnEllipsis>
						<Text variant="mediumStrong" as="span">
							{hub.hubName}
						</Text>
					</Tooltip>
				</Stack>
			</TableDataCell>

			<TableDataCell>
				<Link href={hub.hubLink} external>
					<Text as="span" variant="small" color="slate">
						{hub.hubLink}
					</Text>
				</Link>
			</TableDataCell>

			<TableDataCell>
				<Tooltip content={hub.syncError ? 'Sync error: ' + hub.syncError : ''}>
					<Text
						variant="small"
						sx={{ color: hub.syncError ? 'feedbackErrorDark' : 'neutral600', fontVariantNumeric: 'tabular-nums' }}
					>
						{hub.lastSync ? formatDateWithTime(new Date(hub.lastSync)) : ''}
					</Text>
				</Tooltip>
			</TableDataCell>

			<TableDataCell>
				<Stack direction="horizontal" size="xxSmall" justifyContent="flex-end">
					{permissions.hubs.canCreate.allowed && (
						<RouterLink to={`/settings/hubs/${hub.id}`}>
							<ButtonIcon tooltip="Import templates from hub">
								<IconTemplateAdd
									style={{
										color: theme.colors.neutral600,
									}}
								/>
							</ButtonIcon>
						</RouterLink>
					)}

					{permissions.hubs.canCreate.allowed && (
						<ButtonIcon onClick={() => editHub(hub)} tooltip="Edit hub connection">
							<IconEdit />
						</ButtonIcon>
					)}

					{permissions.hubs.canCreate.allowed && hub._actions.includes('delete') && (
						<ButtonIcon tooltip="Delete hub" data-cy={'remove-hub' + hub.repositoryUrl} onClick={() => deleteHub(hub)}>
							<IconTrash />
						</ButtonIcon>
					)}
				</Stack>
			</TableDataCell>
		</TableRow>
	);
}
