/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ModalContentV2, ModalFooterV2, ModalHeaderV2, ModalOverlay, ModalV2, Tooltip } from 'components';
import DataStore, { useValidations, useStore } from 'DataStore/DataStore';
import { mergeAndSort, VariableWithScope } from 'utils/envVars';
import { Button, Flex } from '@steadybit/ui-components-lib';
import { ExperimentVO, VariableVO } from 'ui-api';
import { ReactElement } from 'react';

import ExecutionOverrideTable from './ExecutionOverrideTable';
import ValidationHandler from './ValidationHandler';
import { ExperimentFormValues } from './types';

interface ExecuteWithOverridesModalProps {
	onExecute: (executionVariables: VariableVO[]) => Promise<void>;
	onClose: () => void;
}

export default function ExecuteWithOverridesModal({
	onExecute,
	onClose,
}: ExecuteWithOverridesModalProps): ReactElement {
	const { data } = useStore<ExperimentFormValues>();

	const used = mergeAndSort(data.variables, data.experimentVariables).filter(
		({ key }) => data.metadata?.variables[key],
	);

	return (
		<DataStore<ExperimentFormValues>
			initialData={{
				...data,
				variables: used,
				// use experimentVariables to store overrides since they over-apply variables
				experimentVariables: [],
			}}
		>
			<ValidationHandler />

			<ModalOverlay open centerContent onClose={onClose}>
				<ModalV2 minHeight="64px" width="90vw" maxWidth="800px" withFooter>
					<ModalHeaderV2
						title="Run with Variable Overrides"
						subTitle="Override variable's values in this experiment, and run it with overriden variables immediately."
						onClose={onClose}
						px="large"
					/>

					<ModalContentV2 px="large">
						<Table used={used} />
					</ModalContentV2>
					<ModalFooterV2 px="large">
						<Footer onClose={onClose} onExecute={onExecute} />
					</ModalFooterV2>
				</ModalV2>
			</ModalOverlay>
		</DataStore>
	);
}

function Table({ used }: { used: VariableWithScope[] }): ReactElement {
	const { data, setValue } = useStore<ExperimentFormValues>();
	return (
		<ExecutionOverrideTable
			occurances={data.metadata?.variables || {}}
			variables={data.experimentVariables}
			experiment={toExperiment(data)}
			usedVariables={used}
			disabled={false}
			setVariables={(v) => setValue('experimentVariables', v || [])}
		/>
	);
}

function toExperiment(value: ExperimentFormValues): ExperimentVO {
	return {
		...value,
		// fill up with values which are not used
		created: new Date(),
		createdBy: {
			username: '',
			name: '',
		},
		edited: new Date(),
		editedBy: {
			username: '',
			name: '',
		},
		_actions: value.actions,
		key: value.experimentKey,
		version: 0,
	};
}

interface FooterProps {
	onExecute: (variables: VariableVO[]) => void;
	onClose: () => void;
}

function Footer({ onClose, onExecute }: FooterProps): ReactElement {
	const { data } = useStore<ExperimentFormValues>();
	const { errors, isValidating } = useValidations();
	const hasErrors = Object.keys(errors).length > 0;

	return (
		<Flex direction="horizontal" justify="end" spacing="medium" style={{ width: '100%' }}>
			<Button type="secondary" onClick={onClose}>
				Cancel
			</Button>
			<Tooltip content={hasErrors ? 'There are validation errors in your experiment.' : undefined}>
				<Button disabled={hasErrors || isValidating} onClick={() => onExecute(data.experimentVariables)}>
					Run Experiment with Overrides
				</Button>
			</Tooltip>
		</Flex>
	);
}
