/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, StyleProp } from 'components';
import { ReactElement, ReactNode } from 'react';

interface ModalProps {
	children: ReactNode | ReactNode[];
	minHeight?: string | number;
	maxWidth?: string | number;
	width?: string | number;
	withFooter?: boolean;
	frameless?: boolean;
	centered?: boolean;
	slick?: boolean;
	sx?: StyleProp;
}

export default function Modal({
	children,
	centered,
	width = 960,
	minHeight = 320,
	withFooter = false,
	frameless = false,
	slick = false,
	maxWidth,
	sx = {},
}: ModalProps): ReactElement {
	return (
		<Container
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				width,
				minHeight,
				maxWidth: maxWidth || 'calc(100vw - 4rem)',
				maxHeight: 'calc(100vh - 4rem)',
				my: centered ? 'auto' : '2rem',
				px: frameless ? 0 : slick ? 0 : 'xSmall',
				pt: frameless ? 0 : 'xxSmall',
				pb: withFooter ? 0 : frameless ? 0 : 'xxLarge',
				bg: 'neutral000',
				overflowX: 'hidden',
				boxShadow: 'applicationLarge',
				borderRadius: 4,
				...sx,
				'& > div.modalContent': frameless
					? {
							padding: 0,
						}
					: slick
						? {
								padding: '0 16px',
							}
						: {},
				'& > div.modalCloseLine': frameless
					? {
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
						}
					: slick
						? {
								padding: '0 4px 0 32px',
							}
						: {},
				'& > div.title': slick
					? {
							padding: '0 16px 12px 16px',
						}
					: {
							marginLeft: '-8px',
							marginRight: '-8px',
						},

				'& > div.modalFooter': slick
					? {
							padding: '16px',
						}
					: {
							marginLeft: '-8px',
							marginRight: '-8px',
						},
			}}
		>
			{children}
		</Container>
	);
}
