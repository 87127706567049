/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Icon, IconButton, Spacings, Text, Tooltip } from '@steadybit/ui-components-lib';
import { RouterLink, TableDataCell, TableRow } from 'components';
import { ExperimentScheduleSummaryVO } from 'ui-api';
import { ReactElement, useState } from 'react';

import { ActivateToggle, CronOrStart, NextExecution, ScheduledBy } from './ScheduleTableComponents';
import { compareNextExecution, getSchedulesIcon } from './utils';
import { ConfigToEdit } from './types';

interface SchedulesRowProps {
	schedules: ExperimentScheduleSummaryVO[];
	experimentKey: string;
	setConfigToEdit: (config: ConfigToEdit) => void;
	setScheduleIdToDelete: (id: string) => void;
}

export default function SchedulesRow({
	experimentKey,
	schedules,
	setScheduleIdToDelete,
	setConfigToEdit,
}: SchedulesRowProps): ReactElement {
	const [expanded, setExpanded] = useState(false);
	const firstSchedule = schedules[0];

	const tdStyle = { border: expanded ? 'none' : undefined };

	return (
		<>
			<TableRow>
				<TableDataCell maxWidth={450} style={tdStyle}>
					<Flex direction="horizontal" align="center" spacing="small">
						<Icon type={getSchedulesIcon(schedules)} style={{ color: Colors.neutral700 }} />
						<RouterLink
							to={`/experiments/edit/${experimentKey}`}
							sx={{ color: Colors.neutral800, ':hover': { color: Colors.slate } }}
						>
							<Flex direction="horizontal" align="center" spacing="xxSmall">
								<Text type="mediumStrong">{experimentKey}</Text>
								<Text type="medium" textEllipsis>
									{firstSchedule.experimentName}
								</Text>
							</Flex>
						</RouterLink>
					</Flex>
				</TableDataCell>

				<TableDataCell style={tdStyle}></TableDataCell>

				<TableDataCell style={tdStyle}></TableDataCell>

				<TableDataCell style={tdStyle}>
					<Text type="smallMedium" neutral600 style={{ textAlign: 'center', width: '100%' }}>
						Multiple schedules
					</Text>
				</TableDataCell>

				{/* no edit or delete for multiple schedules */}
				<TableDataCell style={tdStyle}></TableDataCell>
				<TableDataCell style={tdStyle}></TableDataCell>

				<TableDataCell justifyContent="flex-end" style={tdStyle}>
					<IconButton
						type="chromelessWithBorderOnHover"
						size="small"
						icon={expanded ? 'chevron-up' : 'chevron-down'}
						onClick={() => setExpanded(!expanded)}
					/>
				</TableDataCell>
			</TableRow>

			{/* sort -> its okay to mutate the original array here */}
			{expanded &&
				schedules
					.sort((a, b) => compareNextExecution(a, b, 'desc'))
					.map((schedule, i) => (
						<ScheduleSubRow
							key={schedule.id}
							schedule={schedule}
							isLast={i === schedules.length - 1}
							setConfigToEdit={setConfigToEdit}
							setScheduleIdToDelete={setScheduleIdToDelete}
						/>
					))}
		</>
	);
}

interface ScheduleSubRowProps {
	schedule: ExperimentScheduleSummaryVO;
	isLast?: boolean;
	setConfigToEdit: (config: ConfigToEdit) => void;
	setScheduleIdToDelete: (id: string) => void;
}

function ScheduleSubRow({
	schedule,
	isLast,
	setConfigToEdit,
	setScheduleIdToDelete,
}: ScheduleSubRowProps): ReactElement {
	const isDeletePermitted: boolean = schedule._actions?.includes('delete-schedule') ?? false;
	const isEditPermitted: boolean = schedule._actions?.includes('edit') ?? false;

	const tdSx = {
		border: 'none',
		minHeight: '40px !important',
	};
	const tdStyle = { border: 'none' };

	return (
		<TableRow key={schedule.id} style={{ borderBottom: isLast ? '1px solid ' + Colors.neutral300 : undefined }}>
			<TableDataCell sx={tdSx} style={tdStyle}>
				<Flex
					direction="horizontal"
					align="center"
					spacing="xxSmall"
					data-private
					style={{ marginLeft: Spacings.large }}
				>
					<Icon
						type={!schedule.enabled ? 'calendar-paused' : schedule.cron ? 'calendar-recurrent' : 'calendar-once'}
						style={{ color: Colors.neutral700 }}
					/>
					<ScheduledBy schedule={schedule} setConfigToEdit={setConfigToEdit} />
				</Flex>
			</TableDataCell>

			<TableDataCell sx={tdSx} style={tdStyle}>
				<NextExecution schedule={schedule} />
			</TableDataCell>

			<TableDataCell sx={tdSx} style={tdStyle}>
				<CronOrStart schedule={schedule} />
			</TableDataCell>

			<TableDataCell sx={tdSx} style={tdStyle}>
				<ActivateToggle schedule={schedule} />
			</TableDataCell>

			<TableDataCell sx={tdSx} style={tdStyle}>
				<Tooltip content={isEditPermitted ? 'Edit schedule' : 'View schedule'}>
					{({ setRefElement }) => (
						<IconButton
							ref={setRefElement}
							icon={isEditPermitted ? 'edit' : 'view'}
							type="chromelessWithBorderOnHover"
							size="small"
							onClick={() => setConfigToEdit({ experimentKey: schedule.experimentKey, scheduleId: schedule.id })}
						/>
					)}
				</Tooltip>
			</TableDataCell>

			<TableDataCell sx={tdSx} style={tdStyle}>
				<Tooltip
					content={isDeletePermitted ? 'Delete schedule' : `You don't have permissions to delete this schedule${''}`}
				>
					{({ setRefElement }) => (
						<IconButton
							ref={setRefElement}
							type="chromelessWithBorderOnHover"
							disabled={!isDeletePermitted}
							size="small"
							icon="delete"
							onClick={() => setScheduleIdToDelete(schedule.id)}
						/>
					)}
				</Tooltip>
			</TableDataCell>

			<TableDataCell sx={tdSx} style={tdStyle}></TableDataCell>
		</TableRow>
	);
}
