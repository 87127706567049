/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Link, ModalFooterV2, ModalHeaderV2, ModalOverlay, ModalV2, Stack, Text } from 'components';
import React, { MouseEventHandler, ReactElement, SyntheticEvent, useEffect } from 'react';
import { IconClock, IconNewTab } from 'components/icons';
import { isOnpremise } from 'utils/onprem';
import { ampli } from 'ampli';

import imgArcadeOnPrem from './images/introduction-arcade-onprem.webp';

interface ArcadeOverlayProps {
	onClose: () => void;
	onCloseAndDontShowAgain: () => void;
}

const ARCADE_DEMO_LINK = 'https://demo.arcade.software/hpiEa9SvZcln3TdxR2bE?embed';

export default function ArcadeOverlay({ onClose, onCloseAndDontShowAgain }: ArcadeOverlayProps): ReactElement {
	const [loadExternalContent, setLoadExternalContent] = React.useState<boolean>(!isOnpremise());
	useEffect(() => {
		ampli.landscapeExplorerHelpTutorialOpened({ url: window.location.href });
	}, []);
	const arcadeUrl = window.INJECTED_AMPLITUDE_API_KEY
		? `${ARCADE_DEMO_LINK}&amplitude_api_key=${window.INJECTED_AMPLITUDE_API_KEY}__eu`
		: ARCADE_DEMO_LINK;

	return (
		// using god mode z index so nobody and nothing will ever stand above this modal
		<ModalOverlay open centerContent onClose={onClose} zIndex={10_000}>
			{() => (
				<ModalV2 width="90vw" slick withFooter sx={{ height: '90vh', overflow: 'auto' }}>
					<ModalHeaderV2 title="Learn How to Use Explorer" onClose={onClose} />

					<Stack size="none" mx="32px">
						<Text variant="small" color="neutral600">
							Quickly learn about the Explorer&apos;s capabilities to visualize your system and identifying reliability
							gaps by leveraging Steadybit&apos;s automatic discovery data.
						</Text>
					</Stack>

					<Container display="flex" justifyContent="center" px="32px" mt="small" sx={{ position: 'relative' }}>
						{loadExternalContent ? (
							<ArcadeEmbedded url={arcadeUrl} />
						) : (
							<ArcadePlaceholderPrivacy onLoadExternalContent={() => setLoadExternalContent(true)} />
						)}
					</Container>

					<ModalFooterV2>
						<Button variant="chromeless" mr="small" mb="xxSmall" onClick={onCloseAndDontShowAgain}>
							Don’t show me this again
						</Button>

						<Button variant="secondary" mr="small" mb="xxSmall" onClick={onClose}>
							<IconClock mr="xSmall" /> Watch it later
						</Button>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function ArcadePlaceholderPrivacy({
	onLoadExternalContent,
}: {
	onLoadExternalContent: MouseEventHandler;
}): ReactElement {
	return (
		<Container
			sx={{
				width: '1200px',
				height: '756px',

				borderRadius: '0.5em 0.5em 0 0',
				backgroundImage: `url(${imgArcadeOnPrem})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: '100%',

				animation: 'fade-in-arcade-window 1s',
				'@keyframes fade-in-arcade-window': {
					'0%': {
						opacity: '0',
					},
					'100%': {
						opacity: '100%',
					},
				},
			}}
		>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					width: '1200px',
					height: '756px',
					backgroundColor: 'neutral000',
					borderRadius: '0.5em 0.5em 0 0',
					opacity: 0.95,
				}}
			>
				<Text mb={'small'} variant={'mediumStrong'}>
					The interactive demo hosted at Arcade. Software isn&apos;t loaded by default for privacy reasons.
				</Text>
				<Button onClick={onLoadExternalContent} variant="primary">
					Load External Content
				</Button>
			</Container>
		</Container>
	);
}

function ArcadeEmbedded({ url }: { url: string }): ReactElement {
	return (
		<>
			<iframe
				title="Steadybit Explore Interactive Demo"
				src={url}
				style={{
					width: '1200px',
					height: '756px',
					border: 'none',
					overflow: 'hidden',
				}}
				onLoad={(event: SyntheticEvent<HTMLIFrameElement>) => {
					//For some reason, sending the postMessage immediately when the iframe has loaded is too early and Arcade isn't able to handle this message
					//(resulting in no identify call to Amplitude). So, let's just wait a few milliseconds.
					setTimeout(async () => {
						const contentWindow = (event.target as HTMLIFrameElement).contentWindow;
						const userId = ampli.client.getUserId();
						if (contentWindow && userId) {
							contentWindow.postMessage(
								{
									event: 'identify-user',
									user: { id: userId },
								},
								'*',
							);
						}
					}, 100);
				}}
			/>
			<Container
				sx={{
					position: 'absolute',

					display: 'flex',
					flexDirection: 'row-reverse',
					alignItems: 'center',
					maxWidth: '1200px',
					width: 'calc(100% - 64px)',
					height: '41px',

					backgroundColor: 'neutral700',
					borderRadius: '0.5em 0.5em 0 0',
					px: 'small',

					animation: 'fade-in-arcade-window 1s',
					'@keyframes fade-in-arcade-window': {
						'0%': {
							opacity: '0',
						},
						'100%': {
							opacity: '100%',
						},
					},
				}}
			>
				<Link href={url} external>
					<IconNewTab
						variant="small"
						color="neutral500"
						sx={{
							cursor: 'pointer',
							':hover': {
								color: 'neutral000',
							},
						}}
					/>
				</Link>
			</Container>
		</>
	);
}
