/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import textEllipsis2Lines from 'utils/styleSnippets/textEllipsis2Lines';
import { Text, Tooltip } from 'components';
import { ReactElement } from 'react';

interface TitleProps {
	title: string;
}

export default function Title({ title }: TitleProps): ReactElement {
	return (
		<Tooltip content={title} onlyShowOnEllipsis>
			<Text
				variant="mediumStrong"
				sx={{
					minHeight: 'fit-content',
					color: 'neutral800',
					...textEllipsis2Lines,
				}}
			>
				{title}
			</Text>
		</Tooltip>
	);
}
