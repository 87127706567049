/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';

export default function useLastExecutionId(experimentKey: string): number | undefined {
	const [updateSignal, setUpdateSignal] = useState(0);
	const executionIdsResult = usePromise(() => {
		const pageParams = new PageParams(0, 1).withSort([['requested', 'desc']]);
		return Services.experiments.fetchExperimentRunIds(experimentKey, pageParams);
	}, [experimentKey, updateSignal]);

	const debouncedUpdate = useMemo(() => debounce(setUpdateSignal, 100), [setUpdateSignal]);
	useEventEffect((e) => {
		if (e.experimentKey === experimentKey && e.type.startsWith('experiment.execution.')) {
			debouncedUpdate((prev) => prev + 1);
		}
	});
	return executionIdsResult.value?.[0];
}
