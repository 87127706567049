/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { isSteadybitOrPartnerUser } from 'tracking/ignoredEmailAddresses';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import Notifications from 'components/Notifications/Notifications';
import useGlobalPermissions from 'services/useGlobalPermissions';
import ListHeader from 'components/List/presets/ListHeader';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { RouterLink, Snackbar, Tooltip } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import useAgentReport from 'utils/hooks/useAgentReport';
import { Button } from '@steadybit/ui-components-lib';
import invokePromise from 'utils/ignorePromise';
import { usePage } from 'utils/hooks/usePage';
import { IconAgent } from 'components/icons';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';
import { ensure } from 'utils/ensure';
import { debounce } from 'lodash';
import { ampli } from 'ampli';

import ContentWrapper from '../components/ContentWrapper';
import AgentList, { AgentFilters } from './agentList';
import HelpText from '../components/HelpText';
import AgentLogLevel from './agentLogLevel';

export default function Agents(): ReactElement {
	const { someAgentHasReportedInThePast } = useAgentReport();
	const [isDeregistering, setIsDeregistering] = useState<boolean>(false);
	const user = useUser();
	const showDeregister = isSteadybitOrPartnerUser(user) || process.env.NODE_ENV === 'development';
	const permissions = useGlobalPermissions();

	const page = usePage('/installed', { sort: [['hostname', 'desc']] });
	const debounced = useMemo(
		() => debounce((query, params) => Services.agents.findAgents(query, params), 200, { leading: true }),
		[],
	);
	const [agents, fetch] = useAsyncState(
		() => ensure(debounced(page.criteria.get('query') ?? '', page.pageParams)),
		[page],
	);

	const deregisterAllAgents = (): void => {
		invokePromise(async () => {
			try {
				setIsDeregistering(true);
				await Services.agents.deregisterAllAgents();
				Snackbar.dark('Triggered Deregister of all Agents');
				setIsDeregistering(false);
			} catch {
				Snackbar.error('Failed to trigger Deregister of all Agents');
			}
		});
	};
	useEventEffect(
		useCallback(
			(event) => {
				if ('agent.registered' === event.type) {
					fetch();
				} else if (agents.value?.content.some((agent) => agent.id === event.agentId)) {
					fetch();
				}
			},
			[fetch, agents],
		),
	);

	useEffect(() => {
		ampli.agentsListViewed({ agents_ever_reported: someAgentHasReportedInThePast, url: window.location.href });
	}, []);

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Agents" Icon={IconAgent} />}
				description={
					<>
						<HelpText>
							To enable Chaos Engineering in your system, Steadybit uses an agent to establish a connection to your
							system and extensions to discover your system. So, the first step to start with experimenting is to
							install an agent, including extensions in your system.
						</HelpText>
						<Notifications types={['LICENSE_HARD_LIMIT_REACHED_AGENT_SIZE']} />
					</>
				}
				right={
					<>
						<AgentFilters page={page} />
						<AgentLogLevel agents={agents} />
						{someAgentHasReportedInThePast && (
							<Tooltip content={permissions.agents.canRegister.tooltipContent}>
								<RouterLink
									color="neutral000"
									to="/settings/agents/setup"
									disabled={!permissions.agents.canRegister.allowed}
									style={{ textDecoration: 'none' }}
								>
									<Button withLeftIcon="plus" disabled={!permissions.agents.canRegister.allowed} onClick={() => {}}>
										Add Agent
									</Button>
								</RouterLink>
							</Tooltip>
						)}
						{showDeregister ? (
							<Tooltip content={'Steadybit-Internal Only!!!'}>
								<Button type="primaryRunning" disabled={isDeregistering} onClick={() => deregisterAllAgents()}>
									Deregister All Agents
								</Button>
							</Tooltip>
						) : null}
					</>
				}
			/>

			<AgentList agents={agents} page={page} />
		</ContentWrapper>
	);
}
