/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Spinner,
	Stack,
	Text,
} from 'components';
import { Button, Dropdown, Flex, Grid, presets, TextInput } from '@steadybit/ui-components-lib';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import DataStore, { useStore } from 'DataStore/DataStore';
import React, { ReactElement, useState } from 'react';
import { ChangeAgentLogLevelRequest } from 'ui-api';
import { Services } from 'services/services';
import { includes } from 'utils/string';

interface ChangeAgentLogLevelProps {
	agentId?: string;
	close: () => void;
}
export default function ChangeAgentLogLevel({ agentId, close }: ChangeAgentLogLevelProps): ReactElement {
	return (
		<DataStore<DataStoreData>
			initialData={{
				loggerName: 'com.steadybit',
				level: 'INFO',
			}}
		>
			<ModalContent agentId={agentId} close={close} />
		</DataStore>
	);
}

interface DataStoreData {
	loggerName: string;
	level: string;
}

function ModalContent({ agentId, close }: ChangeAgentLogLevelProps): ReactElement {
	const { setValue, data } = useStore<DataStoreData>();

	const [agent, fetch] = useAsyncState(
		async () => (agentId ? Services.agents.fetchAgent(agentId) : undefined),
		[agentId],
	);
	const [submitError, setSubmitError] = React.useState<Error | null>();

	const [options, setOptions] = useState([
		{ label: 'com.steadybit', id: 'com.steadybit' },
		{ label: 'com.steadybit.communication', id: 'com.steadybit.communication' },
		{ label: 'com.steadybit.actions', id: 'com.steadybit.actions' },
		{ label: 'com.steadybit.attacks', id: 'com.steadybit.attacks' },
		{ label: 'com.steadybit.discovery', id: 'com.steadybit.discovery' },
	]);

	useEventEffect(
		React.useCallback(
			(event) => {
				if (agentId === event.agentId) {
					fetch();
				}
			},
			[fetch, agentId],
		),
	);

	const handleSubmit = async (values: ChangeAgentLogLevelRequest): Promise<void> => {
		try {
			if (agentId) {
				await Services.agents.setLogLevel(agentId, values);
			} else {
				await Services.agents.setLogLevelAll(values);
			}
			Snackbar.dark('Log Level Change requested.', { toastId: 'level-saved' });
			close();
		} catch (error) {
			setSubmitError(error);
		}
	};

	const [loggerQuery, setLoggerQuery] = useState('');

	return (
		<ModalV2 withFooter slick>
			<ModalHeaderV2 title={`Change Agent Log Level ${agentId ? agent.value?.hostname : ''}`} onClose={close} />
			<ModalContentV2>
				{agentId && agent.loading ? (
					<Stack>
						<Spinner variant={'xxLarge'} color={'neutral200'} />
					</Stack>
				) : (
					<Stack size={'large'} pb="2px">
						{agent.value?.logLevel ? (
							<Container>
								<Text variant={'mediumStrong'}>Current Settings</Text>
								{agent.value?.logLevel?.split('\n').map((value, index) => (
									<React.Fragment key={index}>
										{value}
										<br />
									</React.Fragment>
								))}
							</Container>
						) : null}

						<Text muted>
							Log-Level-Changes are requested from the agent and processed asynchronous. Every Change will be
							automatically reverted after 10 minutes.
						</Text>

						<Grid cols="1fr 212px" spacing="small">
							<Dropdown
								renderDropdownContent={({ width, close }) => {
									const items = options
										.filter((option) => includes(option.label, loggerQuery))
										.map((option) => ({
											...option,
											isSelected: data.loggerName === option.id,
										}));

									return (
										<presets.dropdown.DropdownContentFrame width={width} maxHeight="400px">
											<presets.dropdown.SingleChoiceList
												withKeyboardArrowSupport
												items={items}
												onSelect={(id) => {
													setValue('loggerName', id);
													close();
												}}
											/>
											{loggerQuery && !items.some((item) => item.label === loggerQuery) && (
												<Button
													type="chromeless"
													withLeftIcon="plus"
													onClick={() => {
														setOptions([...options, { label: loggerQuery, id: loggerQuery }]);
														setValue('loggerName', loggerQuery);
														setLoggerQuery('');
														close();
													}}
													style={{ maxWidth: '100%' }}
												>
													{`Add custom logger "${loggerQuery}"`}
												</Button>
											)}
										</presets.dropdown.DropdownContentFrame>
									);
								}}
							>
								{({ setRefElement, isOpen, setOpen }) => {
									return (
										<TextInput
											ref={setRefElement}
											withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
											value={loggerQuery}
											placeholder={data.loggerName}
											onClick={() => setOpen(!isOpen)}
											onRightIconClick={() => setOpen(!isOpen)}
											onChange={setLoggerQuery}
										/>
									);
								}}
							</Dropdown>

							<presets.dropdown.SingleChoiceButton
								selectedId={data.level}
								withKeyboardArrowSupport
								items={[
									{ label: 'INFO', id: 'INFO' },
									{ label: 'DEBUG', id: 'DEBUG' },
									{ label: 'TRACE', id: 'TRACE' },
								]}
								onSelect={(id) => setValue('level', id)}
							>
								{data.level}
							</presets.dropdown.SingleChoiceButton>
						</Grid>
					</Stack>
				)}
				{agent.error && <div>Error loading Agent: {agent.error.message}</div>}
			</ModalContentV2>
			<ModalFooterV2>
				<>
					{submitError ? <Container flex={'1 0 auto'}> {submitError.toString()}</Container> : null}
					<Flex direction="horizontal" spacing="small">
						<Button
							type="secondary"
							onClick={async () => {
								setValue('loggerName', 'com.steadybit');
								setValue('level', '');
								await handleSubmit(data);
							}}
						>
							Request Log-Level Reset
						</Button>
						<Button onClick={async () => await handleSubmit(data)}>Request Log-Level Change</Button>
					</Flex>
				</>
			</ModalFooterV2>
		</ModalV2>
	);
}
