/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { GetTenantsResponse, TenantVO } from 'ui-api';
import axios from 'axios';

export class TenantsApi {
	async getCurrentTenantWithAgentKey(): Promise<TenantVO | undefined> {
		return (await axios.get<TenantVO>('/ui/tenants/current?showAgentKey=true')).data;
	}

	async fetchTenants(): Promise<TenantVO[]> {
		return (await axios.get<GetTenantsResponse>('/ui/tenants')).data.content;
	}

	async isTenantAvailable(): Promise<boolean> {
		try {
			const response = await axios.get('/ui/tenants/refresh');
			return response.status === 200;
		} catch {
			return false;
		}
	}
}
