/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DidNotSavePrompt } from 'pages/experiments/wizard/components/DidNotSavePrompt';
import { useStore, useTouched } from 'DataStore/DataStore';
import { matchPath } from 'url/router';
import { ReactElement } from 'react';

import { saveExperiment } from './ExperimentEditorFormHandler';
import { ExperimentFormValues } from './types';

export default function UnsavedChangesPrompt(): ReactElement {
	const { touched } = useTouched();
	const { data } = useStore<ExperimentFormValues>();
	const { environmentId, experimentKey } = data;
	const isNewExperiment = !experimentKey;

	const dirty = Object.keys(touched).length > 0;

	return (
		<DidNotSavePrompt
			entityTitle="experiment"
			when={dirty || isNewExperiment}
			block={({ pathname, state }) => {
				if (
					(state === 'redirect_after_initial_save' && isNewExperiment) ||
					state === 'redirect_after_delete' ||
					state === 'redirect_after_duplicate'
				) {
					return false;
				}
				// This checks is somebody did a change. The environmentId is required to do anything in the editor.
				// If none is set, the user has not done anything.
				if (!environmentId) {
					return false;
				}
				return !matchPath(pathname, { path: `/experiments/edit/${experimentKey || '<new>'}/design` });
			}}
			onSaveCallback={async () => {
				return await saveExperiment(data);
			}}
		/>
	);
}
