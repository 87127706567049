/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Errors, useValidations, useStore } from 'DataStore/DataStore';
import { Services } from 'services/services';
import { useEffect, useState } from 'react';
import { debounce, set } from 'lodash';

import { TemplateFormValues } from './types';

export default function ValidationHandler(): null {
	const { data } = useStore<TemplateFormValues>();
	const { setValidations } = useValidations();

	const [debouncedValidate] = useState(() =>
		debounce(
			async (v) => {
				setValidations({ isValidating: false, errors: await validate(v) });
			},
			500,
			{ leading: true },
		),
	);

	useEffect(() => {
		setValidations({ isValidating: true });
		debouncedValidate(data);
	}, [data, debouncedValidate]);

	return null;
}

export async function validate(values: TemplateFormValues): Promise<Errors> {
	const errors = {};
	try {
		const violations = await Services.templatesApi.validateTemplate({
			...values,
			id: 'not needed for validation',
			created: values.created ? new Date(values.created) : new Date(),
			edited: values.edited ? new Date(values.edited) : new Date(),
		});
		violations.forEach(({ field, message }) => {
			if (message === 'There are no targets matching your query.') {
				set(errors, field, { message, level: 'info' });
			} else {
				set(errors, field, { message, level: 'error' });
			}
		});
	} catch {
		console.error('Could not validate template');
	}
	return errors;
}
