/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import StepEditHeader from 'pages/experiments/components/experimentStepEditHeader';
import { useStoreField } from 'DataStore/DataStore';
import { ExperimentStepWaitVO } from 'ui-api';
import { IconDelay } from 'components/icons';
import { ReactElement } from 'react';
import { Stack } from 'components';

import Duration from './Fields/Controls/Duration';
import FieldWrapper from './Fields/FieldWrapper';
import { ExperimentError } from '../types';

interface WaitProps {
	waitStep: ExperimentStepWaitVO;
	withErrors: boolean;
	disabled: boolean;
	stepPath: string;
	onDuplicate: () => void;
	onDelete: () => void;
	onClose: () => void;
}

export default function Wait({
	withErrors,
	waitStep,
	stepPath,
	disabled,
	onDuplicate,
	onDelete,
	onClose,
}: WaitProps): ReactElement {
	const { value, errors, setValue } = useStoreField<string | null>(`${stepPath}.parameters.duration`);

	const error = errors as ExperimentError | undefined;
	const stepErrors = error && withErrors ? [error] : [];

	return (
		<Stack size="small" width="100%">
			<StepEditHeader
				backgroundColor="steelBlueMid"
				icon={<IconDelay />}
				stepPath={stepPath}
				disabled={disabled}
				step={waitStep}
				caption="Wait"
				onDuplicate={onDuplicate}
				onDelete={onDelete}
				onClose={onClose}
			/>

			<Stack size="small" width="100%" pt="6px" px="small">
				<FieldWrapper
					field={{
						name: 'duration',
						type: 'duration',
						label: 'Duration',
						required: true,
						description: 'How long should the step wait?',
					}}
					errors={stepErrors}
				>
					<Duration hasErrors={Boolean(stepErrors.length)} disabled={disabled} value={value} setValue={setValue} />
				</FieldWrapper>
			</Stack>
		</Stack>
	);
}
