/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import axios from 'axios';

import {
	ListResponse,
	PreflightActionDefinitionSummaryVO,
	PreflightActionOnAgentInfoVO,
	PreflightActionVO,
} from '../ui-api';

export class PreflightActionDefinitionsApi {
	async deletePreflightAction(id: string): Promise<void> {
		await axios.delete(`/ui/preflight/actions/${id}`);
	}

	async fetchPreflightActionOnAgentInfo(id: string): Promise<ListResponse<PreflightActionOnAgentInfoVO>> {
		return (await axios.get<ListResponse<PreflightActionOnAgentInfoVO>>(`/ui/preflight/actions/${id}/agents`)).data;
	}

	async fetchPreflightAction(id: string): Promise<PreflightActionVO> {
		return (await axios.get<PreflightActionVO>(`/ui/preflight/actions/${id}`)).data;
	}

	async fetchPreflightActionSummaries(): Promise<ListResponse<PreflightActionDefinitionSummaryVO>> {
		return (await axios.get<ListResponse<PreflightActionDefinitionSummaryVO>>('/ui/preflight/actions/summaries')).data;
	}
}
