/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Grid, hooks, Spacings } from '@steadybit/ui-components-lib';
import AgentInstallBanner from 'components/AgentInstallBanner/AgentInstallBanner';
import { ReactElement, useEffect } from 'react';
import { Heading } from 'components';

import { LastEditetExperimentsWidget } from './components/LastEditedExperimentsWidget';
import { LastExecutionsWidget } from './components/LastExecutionsWidget';
import { TeamActivitiesWidget } from './components/TeamActivitiesWidget';
import { GetStartedWidget } from './components/GetStartedWidget';
import { DiscoveryWidget } from './components/DiscoveryWidget';
import { ampli } from '../../ampli';

interface DashboardProps {
	renderAgentInstallBanner?: boolean;
}

export default function Dashboard({ renderAgentInstallBanner }: DashboardProps): ReactElement {
	useEffect(() => {
		ampli.dashboardViewed({ resume_agent_setup_shown: renderAgentInstallBanner ?? false });
	}, []);

	const [ref, [width]] = hooks.useDimensions();
	const isSmall = width < 1200;

	return (
		<Flex
			align="stretch"
			spacing="medium"
			style={{
				maxWidth: 1440,
				margin: `${Spacings.medium} auto`,
				px: 'xxLarge',

				backgroundColor: Colors.neutral050,
				boxShadow: '0px 0px 10000px 10000px #FAFBFD', // set background color of parent: neutral050
			}}
		>
			{renderAgentInstallBanner && <AgentInstallBanner sx={{ width: '100%' }} />}
			<GetStartedWidget />

			<Heading variant="medium" color="neutral800">
				Dashboard
			</Heading>
			<Grid ref={ref} cols={isSmall ? '1fr' : '1fr 1fr'} spacing="small" align="stretch">
				<DiscoveryWidget />
				<TeamActivitiesWidget />
				<LastExecutionsWidget />
				<LastEditetExperimentsWidget />
			</Grid>
		</Flex>
	);
}
