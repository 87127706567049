/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentExecutionWithSideList } from 'pages/experiments/components/experimentExecutionWithSideList';
import { ExperimentExecutionAgentLogs } from 'pages/experiments/experimentExecutionAgentLogs';
import ExperimentExecutionTelemetry from 'pages/experiments/ExperimentExecutionTelemetry';
import { ExperimentExecution } from 'pages/experiments/experimentExecution';
import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { ExperimentExecutionVO } from 'ui-api';
import { ReactElement } from 'react';
import { Stack } from 'components';

import ExperimentHeader from './ExperimentHeader';

interface ExperimentExecutionsProps {
	experimentExecution: DataStreamResult<ExperimentExecutionVO | undefined>;
	experimentExecutionSection: string;
	experimentExecutionId: number;
	experimentKey: string;
}

export default function ExperimentExecutions({
	experimentExecutionSection,
	experimentExecutionId,
	experimentExecution,
	experimentKey,
}: ExperimentExecutionsProps): ReactElement {
	const applicationHeaderHeight = useApplicationHeaderHeight();
	const height = `calc(100vh - ${applicationHeaderHeight + 80}px)`;

	return (
		<Stack size="none">
			<ExperimentHeader section="executions" lastExperimentExecution={experimentExecution.value} />

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height,
					overflowY: 'auto',
				}}
			>
				{/* as agreed, we use the old run view which will be refactored somewhen */}
				<ExperimentExecutionWithSideList
					experimentExecutionSection={experimentExecutionSection}
					experimentExecutionId={experimentExecutionId}
					experimentExecution={experimentExecution}
					experimentKey={experimentKey}
				>
					{experimentExecutionSection === 'agent-log' && <ExperimentExecutionAgentLogs {...experimentExecution} />}
					{experimentExecutionSection === 'telemetry' && <ExperimentExecutionTelemetry {...experimentExecution} />}
					{experimentExecutionSection === 'attack' && <ExperimentExecution {...experimentExecution} />}
				</ExperimentExecutionWithSideList>
			</div>
		</Stack>
	);
}
