/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage, Flex, Text, TextArea, TextInput } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import { getErrorMessage, hasError } from './utils';
import { useStoreField } from './DataStore';

interface StoreTextInputProps {
	size?: 'small' | 'medium';
	as?: 'input' | 'textarea';
	placeholder?: string;
	disabled?: boolean;
	maxLength?: number;
	fieldName: string;
	label: string;
}

export default function StoreTextInput({
	as = 'input',
	placeholder,
	fieldName,
	maxLength,
	disabled,
	label,
	size,
}: StoreTextInputProps): ReactElement {
	const field = useStoreField<string>(fieldName);
	const { value, setValue } = field;

	const errorneuous = hasError(field);

	return (
		<Flex spacing="xxSmall">
			<Text type={size === 'small' ? 'smallMedium' : 'mediumStrong'}>{label}</Text>
			{as === 'input' ? (
				<TextInput
					placeholder={placeholder}
					maxLength={maxLength}
					errored={errorneuous}
					disabled={disabled}
					value={value}
					size={size}
					onChange={setValue}
				/>
			) : (
				<TextArea
					placeholder={placeholder}
					maxLength={maxLength}
					disabled={disabled}
					value={value}
					onChange={setValue}
					style={{ width: '100%', minHeight: 60, maxHeight: 400 }}
				/>
			)}
			{errorneuous && (
				<ErrorMessage type="medium" level="error" withIcon>
					{getErrorMessage(field.errors)}
				</ErrorMessage>
			)}
		</Flex>
	);
}
