/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Icon, Text } from '@steadybit/ui-components-lib';
import { formatDateWithTime } from 'utils/dateFns';
import { ReactElement } from 'react';

import ExperimentVariableOverrideIndicator from '../../ExperimentVariableOverrideIndicator';
import { ScheduleWithNextRun } from './EditSchedulesModal';

interface SchedulesSidebarItemProps {
	schedule: ScheduleWithNextRun;
	addDivider?: boolean;
	selected: boolean;
	onClick: () => void;
}

export default function SchedulesSidebarItem({
	addDivider,
	schedule,
	selected,
	onClick,
}: SchedulesSidebarItemProps): ReactElement {
	const { cron, nextExecution, startAt, enabled } = schedule;
	const isOnce = !cron;
	const isNew = schedule.id === '<new>';
	const item = (
		<Flex
			direction="horizontal"
			align="center"
			spacing="xSmall"
			style={{
				width: '100%',
				minHeight: '60px',
				px: 'small',

				backgroundColor: selected ? Colors.neutral100 : Colors.neutral000,
				border: selected || schedule.enabled ? '1px solid' : '1px dashed',
				borderColor: selected ? Colors.slate : Colors.neutral300,
				borderRadius: 'xxSmall',
				color: Colors.neutral700,
				outline: selected ? '1px solid ' + Colors.slate : undefined,

				onHover: {
					backgroundColor: Colors.neutral200,
					borderColor: Colors.slate,
					border: '1px solid',
					outline: '1px solid' + Colors.slate,
				},
			}}
			onClick={onClick}
		>
			<Icon type={!enabled ? 'calendar-paused' : isOnce ? 'calendar-once' : 'calendar-recurrent'} />

			<Flex spacing="xxxSmall">
				{isNew && <Text type="smallStrong">New schedule*</Text>}

				{!enabled && !isNew && <Text type="smallStrong">[Deactivated]</Text>}

				{enabled ? (
					<>
						{nextExecution ? (
							<Flex direction="horizontal" align="center" spacing="xxSmall">
								<Text type="smallStrong">Next run:</Text>
								<Text type="small" neutral600>
									{formatDateWithTime(new Date(nextExecution))}
								</Text>
							</Flex>
						) : startAt && !cron ? (
							<Flex direction="horizontal" align="center" spacing="xxSmall">
								<Text type="smallStrong">Date:</Text>
								<Text type="small" neutral600>
									{formatDateWithTime(new Date(startAt))}
								</Text>
							</Flex>
						) : null}
					</>
				) : startAt && !cron ? (
					<Flex direction="horizontal" align="center" spacing="xxSmall">
						<Text type="smallStrong">Date:</Text>
						<Text type="small" neutral600>
							{formatDateWithTime(new Date(startAt))}
						</Text>
					</Flex>
				) : null}

				{cron && (
					<Flex direction="horizontal" align="center" spacing="xxSmall">
						<Text
							type={enabled ? 'smallStrong' : 'small'}
							style={{ color: enabled ? Colors.neutral700 : Colors.neutral600 }}
						>
							Cron:
						</Text>
						<Text type="small" neutral600>
							{cron}
						</Text>
					</Flex>
				)}
			</Flex>

			{schedule.variables && schedule.variables.length > 0 && <ExperimentVariableOverrideIndicator />}
		</Flex>
	);

	if (!addDivider) {
		return item;
	}

	return (
		<>
			<div style={{ width: '100%', height: '1px', backgroundColor: Colors.neutral300 }}></div>
			{item}
		</>
	);
}
