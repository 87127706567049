/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useEffect, useState } from 'react';
import { IconButton } from '@steadybit/ui-components-lib';
import { useNowObservable } from 'utils/now';

import ProgressIndicator from './ProgressIndicator';

const clickDelay = 1000;

export interface PlayButtonProps {
	start?: number;
	end?: number;
	tag?: string;
	onLongClickReleased?: (fired: boolean, progress: number) => void;
	onClick: () => void;
}

export default function PlayButton({ tag, onLongClickReleased, onClick, ...rest }: PlayButtonProps): ReactElement {
	const [clickingStartTime, setClickingStartTime] = useState<number | undefined>(undefined);
	const start: () => void = () => setClickingStartTime(Date.now());
	const abort: () => void = () => {
		if (onLongClickReleased && clickingStartTime != undefined) {
			const currentPercentage = Math.min(100, Math.round(((Date.now() - clickingStartTime) / clickDelay) * 100));
			onLongClickReleased(false, currentPercentage);
		}
		setClickingStartTime(undefined);
	};

	const isRunning = rest.start || rest.end ? true : false;

	return (
		<div style={{ position: 'relative' }}>
			<IconButton
				type={isRunning ? 'primaryRunning' : clickingStartTime ? 'primary' : 'secondary'}
				icon={isRunning ? 'stop' : 'play'}
				onTouchStart={isRunning ? undefined : start}
				onMouseDown={isRunning ? undefined : start}
				size="small"
				onMouseLeave={abort}
				onTouchEnd={abort}
				onMouseUp={abort}
				onClick={isRunning ? onClick : () => {}}
				style={{ borderRadius: 'large' }}
				data-cy={tag}
			/>
			{clickingStartTime && (
				<ProgressHandler
					onTimeoutEnded={() => {
						onLongClickReleased?.(true, 100);
						setClickingStartTime(undefined);
						onClick?.();
					}}
				/>
			)}
			{rest.start && rest.end && <Progress start={rest.start} end={rest.end} />}
		</div>
	);
}

interface ProgressHandlerProps {
	onTimeoutEnded: (startTime: Date) => void;
}

function ProgressHandler({ onTimeoutEnded }: ProgressHandlerProps): ReactElement {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const timeout: number = window.setTimeout(() => setProgress(1), 1);
		return () => window.clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const timeout: number = window.setTimeout(onTimeoutEnded, clickDelay);
		return () => window.clearTimeout(timeout);
	}, [onTimeoutEnded]);

	return <ProgressIndicator progress={progress} inverted />;
}

interface ProgressProps {
	start: number;
	end: number;
}

function Progress({ start, end }: ProgressProps): ReactElement {
	const now = useNowObservable();
	return <ProgressIndicator progress={getProgress(start, end, now)} />;
}

function getProgress(start: number | undefined, end: number | undefined, now: Date): number {
	if (start && end) {
		const progressed = now.valueOf() - start;
		const duration = end - start;
		return progressed / duration;
	}
	return 0;
}
