/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Checkbox,
	Message,
	RouterLink,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	userConfirmV2,
} from 'components';
import { Button, Colors, Flex, Text, TextInput } from '@steadybit/ui-components-lib';
import VariableInputsForm from 'components/Variables/VariableInputsForm';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import { IconDelete, IconEdit, IconFunction } from 'components/icons';
import ContentWrapper from 'pages/settings/components/ContentWrapper';
import { ReactElement, useEffect, useState } from 'react';
import { includes, localeCompare } from 'utils/string';
import Skeletons from 'components/Skeleton/Skeletons';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { VariableVO } from 'ui-api';
import { ampli } from 'ampli';

interface EditEnvironmentVariablesProps {
	environmentId: string;
}

export default function EditEnvironmentVariables({ environmentId }: EditEnvironmentVariablesProps): ReactElement {
	const [searchQuery, setSearchQuery] = useState('');

	const environmentResult = usePromise(() => Services.environments.fetchEnvironment(environmentId), [environmentId]);
	const environment = environmentResult.value;

	const environmentVariableResult = Services.environments.useEnvironmentVariables$(environmentId);

	const environmentVariables = environmentVariableResult.value?.content;
	const filteredVariables = (environmentVariables || []).filter(
		(variable) => includes(variable.key, searchQuery) || includes(variable.value, searchQuery),
	);

	// when loading, we assume that there are variables
	const hasVariables = !environmentVariables || environmentVariables.length > 0;

	useEffect(() => {
		if (environmentVariableResult.loading) {
			return;
		}
		ampli.environmentVariableListViewed({
			environment_id: environmentId,
			environment_variables: environmentVariables?.map((variable) => variable.key) || [],
		});
	}, [environmentVariableResult]);

	const canConfigureVariables = environmentVariableResult.value?._actions?.includes('edit');

	return (
		<ContentWrapper>
			<Flex spacing="large">
				<RouterLink to="/settings/environments" style={{ textDecoration: 'none' }}>
					<Button
						withLeftIcon="arrow-left"
						type="chromeless"
						size="small"
						onClick={() => {}}
						style={{ color: Colors.neutral600 }}
					>
						Back to Environment list
					</Button>
				</RouterLink>

				{environment && environmentVariables ? (
					<>
						<Flex direction="horizontal" spacing="large" justify="spread" style={{ width: '100%' }}>
							<Text type="xLargeStrong" textEllipsis>
								{environment.name}
							</Text>

							<Flex direction="horizontal" spacing="large" justify="end" style={{ flexGrow: 1 }}>
								<TextInput
									placeholder="Search"
									withLeftIcon="search"
									value={searchQuery}
									onChange={setSearchQuery}
									style={{ width: '300px' }}
								/>

								{hasVariables && canConfigureVariables && (
									<Button
										withLeftIcon="plus"
										onClick={async () => {
											await onAddVariable(environmentVariables, environmentId);
										}}
									>
										Add environment variable
									</Button>
								)}
							</Flex>
						</Flex>

						{!hasVariables ? (
							<Flex align="center" justify="center" style={{ height: '60vh', width: '100%' }}>
								<AstroidScreen
									title={
										<Text
											type="xLargeStrong"
											style={{
												color: theme.colors.slate,
											}}
										>
											There are no environment variables yet
										</Text>
									}
									icon={<IconFunction variant="xxLarge" color="slate" />}
									description={
										<>
											<Text
												type="medium"
												style={{
													color: theme.colors.neutral600,
													display: 'flex',
													alignItems: 'center',
												}}
											>
												You can create a new variable and use it across all experiments within this environment.
											</Text>
											{canConfigureVariables && (
												<Button
													withLeftIcon="plus"
													onClick={async () => {
														await onAddVariable(environmentVariables, environmentId);
													}}
												>
													Create environment variable
												</Button>
											)}
										</>
									}
								/>
							</Flex>
						) : (
							<Table width="100%">
								<TableHead>
									<TableRow>
										<TableHeadCell width="auto">Key</TableHeadCell>
										<TableHeadCell width="auto">Value</TableHeadCell>
										<TableHeadCell width={90} />
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredVariables
										.sort((v1, v2) => localeCompare(v1.key, v2.key))
										.map((variable) => (
											<TableRow key={variable.key} hoverable={true} height={54}>
												<TableDataCell>
													<Text
														textEllipsis
														type="smallStrong"
														style={{
															onHover: canConfigureVariables
																? {
																		color: theme.colors.slate,
																		textDecoration: 'underline',
																		cursor: 'pointer',
																	}
																: undefined,
														}}
														onClick={
															canConfigureVariables
																? async () => {
																		await onEditVariable(environmentVariables, variable, environmentId);
																	}
																: undefined
														}
													>
														{`{{${variable.key}}}`}
													</Text>
												</TableDataCell>
												<TableDataCell>
													<Text textEllipsis>{variable.value}</Text>
												</TableDataCell>
												<TableDataCell>
													{canConfigureVariables && (
														<ButtonIcon
															tooltip="Edit variable"
															onClick={async () => {
																await onEditVariable(environmentVariables, variable, environmentId);
															}}
														>
															<IconEdit />
														</ButtonIcon>
													)}
													{canConfigureVariables && (
														<ButtonIcon
															onClick={async () => {
																await onDeleteVariable(environmentVariables, variable, environmentId);
															}}
															tooltip="Delete variable"
														>
															<IconDelete />
														</ButtonIcon>
													)}
												</TableDataCell>
											</TableRow>
										))}
									{filteredVariables.length === 0 && (
										<TableRow>
											<TableDataCell colSpan={3}>
												<Text neutral600>No variables matching your query.</Text>
											</TableDataCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						)}
					</>
				) : (
					<Skeletons widths={[300]} height={30} />
				)}
			</Flex>
		</ContentWrapper>
	);
}

async function onAddVariable(variables: VariableVO[], environmentId: string): Promise<void> {
	if (
		(await userConfirmV2({
			title: 'Add new environment variable',
			width: '900px',
			message: ({ setDisabled }) => <AddMessage variables={variables} setDisabled={setDisabled} />,
			actions: [{ value: 'confirm', label: 'Add variable', variant: 'primary' }],
		})) === 'confirm'
	) {
		const key = (document.getElementById('variable-key') as HTMLInputElement)?.value;
		const value = (document.getElementById('variable-value') as HTMLInputElement)?.value;

		try {
			if (key && value) {
				await Services.environments.updateEnvironmentVariables(environmentId, {
					variables: variables.concat({ key, value }),
				});
			}
		} catch (err) {
			console.error(err);
		}
	}
}

interface AddMessageProps {
	variables: VariableVO[];
	setDisabled: (disabled: boolean) => void;
}

function AddMessage({ variables, setDisabled }: AddMessageProps): ReactElement {
	return (
		<Flex spacing="medium" style={{ width: '100%' }}>
			<Text type="medium">You can use this variable across all experiments within this environment.</Text>
			<VariableInputsForm existingVariables={variables} variableValue="" variableKey="" setHasErrors={setDisabled} />
		</Flex>
	);
}

async function onEditVariable(variables: VariableVO[], variable: VariableVO, environmentId: string): Promise<void> {
	if (
		(await userConfirmV2({
			title: 'Edit environment variable',
			width: '900px',
			message: ({ setDisabled }) => <EditMessage variables={variables} variable={variable} setDisabled={setDisabled} />,
			actions: [{ value: 'confirm', label: 'Save changes', variant: 'primary' }],
		})) === 'confirm'
	) {
		const key = (document.getElementById('variable-key') as HTMLInputElement)?.value;
		const value = (document.getElementById('variable-value') as HTMLInputElement)?.value;

		try {
			if (key && value) {
				await Services.environments.updateEnvironmentVariables(environmentId, {
					variables: variables.map((v) => (v.key === variable.key ? { key, value } : v)),
				});
			}
		} catch (err) {
			console.error(err);
		}
	}
}

interface EditMessageProps {
	variables: VariableVO[];
	variable: VariableVO;
	setDisabled: (disabled: boolean) => void;
}

function EditMessage({ variables, variable, setDisabled }: EditMessageProps): ReactElement {
	return (
		<Flex spacing="large" style={{ width: '100%' }}>
			<Message variant="warning" title="Be careful!" width="100%">
				Environment variables can be reused across experiments. Changing their value can affect all experiments run in
				this environment.
			</Message>

			<VariableInputsForm
				variableValue={variable.value}
				existingVariables={variables}
				variableKey={variable.key}
				setHasErrors={setDisabled}
			/>
		</Flex>
	);
}

async function onDeleteVariable(variables: VariableVO[], variable: VariableVO, environmentId: string): Promise<void> {
	if (
		(await userConfirmV2({
			title: 'Delete environment variable',
			message: ({ setDisabled }) => <DeleteMessage variable={variable} setDisabled={setDisabled} />,
			actions: [{ value: 'confirm', label: 'Delete environment variable', variant: 'primary' }],
		})) === 'confirm'
	) {
		try {
			await Services.environments.updateEnvironmentVariables(environmentId, {
				variables: variables.filter((v) => v.key !== variable.key),
			});
		} catch (err) {
			console.error(err);
		}
	}
}

interface DeleteMessageProps {
	variable: VariableVO;
	setDisabled: (disabled: boolean) => void;
}

function DeleteMessage({ variable, setDisabled }: DeleteMessageProps): ReactElement {
	const [value, setValue] = useState('');
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setDisabled(value !== variable.key || !checked);
	}, [checked, value, setDisabled]);

	return (
		<Flex spacing="medium">
			<Flex direction="horizontal">
				<Text type="medium">Are you sure you want to delete the environment variable</Text>
				<Text type="mediumStrong" style={{ marginLeft: '4px' }}>{`{{${variable.key}}}`}</Text>
				<Text type="medium">?</Text>
			</Flex>

			<Text type="medium">
				Once you delete it, you will have errors in all the experiments that are using this variable.
			</Text>

			<Flex spacing="xSmall">
				<Text
					type="mediumStrong"
					style={{
						color: theme.colors.neutral800,
					}}
				>
					Type the variable’s key “{variable.key}” to confirm deletion
				</Text>

				<TextInput placeholder="Variable key" value={value} onChange={setValue} />

				<Flex direction="horizontal" spacing="xSmall" style={{ width: '100%', color: theme.colors.neutral800 }}>
					<Checkbox
						data-cy="delete-team-checkbox"
						onChange={(e) => setChecked(e.target.checked)}
						checked={checked}
						minWidth={18}
						minHeight={18}
						mt={2}
						id={'delete-team-checkbox'}
					/>
					<Text type="medium">
						I’m aware that deleting this variable will result in errors in all the experiments that are using this
						variable.
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
}
