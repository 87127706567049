/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ActionKindVO,
	BaseExperimentStepExecutionVOUnion,
	ExperimentExecutionPreflightVO,
	ExperimentExecutionVO,
	ExperimentStepExecutionActionTargetVO,
	ExperimentStepExecutionActionVO,
} from 'ui-api';
import { ExperimentPlayerStepId } from 'components/ExperimentPlayer/types';
import { Fragment, ReactElement, useEffect, useMemo } from 'react';
import { useTargetDefinition } from 'targets/useTargetDefinition';
import { Flex, presets } from '@steadybit/ui-components-lib';
import { Container, Link, Stack, Text } from 'components';
import { getTargetLabel, toTarget } from 'targets/util';
import { getLabel as getI18nLabel } from 'i18n/label';
import { withBaseHref } from 'utils/getBaseHref';
import { IconSaveFile } from 'components/icons';
import { useTenant } from 'tenancy/useTenant';
import { theme } from 'styles.v2/theme';
import { flatMap } from 'lodash';

import ExperimentExecutionLogPreflightsModal from './ExperimentExecutionLogStepModal/ExperimentExecutionLogPreflightsModal';
import ExperimentExecutionLogStepModal from './ExperimentExecutionLogStepModal/ExperimentExecutionLogStepModal';
import ExperimentExecutionLogPreflights from './ExperimentExecutionLogPreflights';
import ExperimentExecutionLogLabel from './ExperimentExecutionLogLabel';
import ExperimentExecutionLogStep from './ExperimentExecutionLogStep';
import { isNotEnded, mapExperimentExecutionState } from './utils';
import { ampli } from '../../../ampli';

interface CommonExperimentExecutionLogLine {
	time: number;
	state: string;
}

export interface ExperimentExecutionLogStepLine extends CommonExperimentExecutionLogLine {
	step: BaseExperimentStepExecutionVOUnion;
}

export interface ExperimentExecutionLogPreflightsLine extends CommonExperimentExecutionLogLine {
	preflights: ExperimentExecutionPreflightVO[];
}

export interface ExperimentExecutionLogLabelLine extends CommonExperimentExecutionLogLine {
	label: string;
}

type ExperimentExecutionLogLine =
	| ExperimentExecutionLogStepLine
	| ExperimentExecutionLogPreflightsLine
	| ExperimentExecutionLogLabelLine;

interface ExperimentExecutionLogProps {
	experimentExecution: ExperimentExecutionVO;
	onStepHover?: (id: ExperimentPlayerStepId | null) => void;
	setSelectedStepId: (selectedStepId: ExperimentPlayerStepId | null) => void;
	selectedStepId: ExperimentPlayerStepId | null;
	hoveredStepId: string | null;
	setHoveredStepId: (id: string | null) => void;
}

function isExperimentExecutionLogStepLine(line: ExperimentExecutionLogLine): line is ExperimentExecutionLogStepLine {
	return (line as ExperimentExecutionLogStepLine).step !== undefined;
}

function isExperimentExecutionLogPreflightsLine(
	line: ExperimentExecutionLogLine,
): line is ExperimentExecutionLogPreflightsLine {
	return (line as ExperimentExecutionLogPreflightsLine).preflights !== undefined;
}

export default function ExperimentExecutionLog({
	experimentExecution,
	selectedStepId,
	setSelectedStepId,
	hoveredStepId,
	setHoveredStepId,
}: ExperimentExecutionLogProps): ReactElement {
	const logEvents = useMemo(() => getExperimentLog(experimentExecution), [experimentExecution]);

	const runningLogEvents = logEvents.filter((event) => isNotEnded(event.state)).sort((a, b) => b.time - a.time);
	const nonRunningLogEvents = logEvents.filter((event) => !isNotEnded(event.state)).sort((a, b) => b.time - a.time);

	const selectedStep: ExperimentExecutionLogStepLine | ExperimentExecutionLogPreflightsLine | null = useMemo(() => {
		if (selectedStepId === null) {
			return null;
		}
		if (selectedStepId === 'preflights') {
			return {
				time: 0,
				state: 'RUNNING',
				preflights: experimentExecution.preflights ?? [],
			};
		}
		return flatMap(experimentExecution.lanes, (lane) => lane.steps.flat())
			.filter((step) => step.id === selectedStepId)
			.map((step) => ({
				time: Math.min(Math.max(step.started?.getTime() || 0, Date.now()), step.ended?.getTime() || Number.MAX_VALUE),
				state: step.state,
				step: step,
			}))
			.sort((a, b) => b.time - a.time)[0];
	}, [selectedStepId, experimentExecution]);

	const getSelectedStepType = (
		selectedStep: ExperimentExecutionLogStepLine | ExperimentExecutionLogPreflightsLine | null,
	): string | undefined => {
		if (!selectedStep) {
			return undefined;
		} else if ('step' in selectedStep && selectedStep.step.type === 'wait') {
			return 'wait';
		} else if ('step' in selectedStep && selectedStep.step.type === 'action') {
			return selectedStep.step.actionId;
		} else if ('preflight' in selectedStep) {
			return 'preflight';
		}
	};
	useEffect(() => {
		if (selectedStep) {
			ampli.experimentExecutionRunModalViewed({
				experiment_key: experimentExecution.experimentKey,
				experiment_execution_id: experimentExecution.id,
				experiment_execution_result: mapExperimentExecutionState(experimentExecution.state),
				experiment_execution_running: isNotEnded(experimentExecution.state),
				environment_id: experimentExecution.environmentId,
				experiment_run_modal_step: getSelectedStepType(selectedStep),
				experiment_run_modal_step_state: selectedStep ? mapExperimentExecutionState(selectedStep.state) : undefined,
			});
		}
	}, [selectedStep]);

	const indexOfSeparator = runningLogEvents.length;

	return (
		<>
			{selectedStep &&
				(isExperimentExecutionLogStepLine(selectedStep) ? (
					<ExperimentExecutionLogStepModal
						stepLog={selectedStep}
						onClose={() => setSelectedStepId(null)}
						experimentExecutionId={experimentExecution.id}
					/>
				) : (
					<ExperimentExecutionLogPreflightsModal
						preflights={selectedStep.preflights ?? []}
						onClose={() => setSelectedStepId(null)}
					/>
				))}

			<Stack size="xSmall" p="8px" overflowY="auto">
				{[...runningLogEvents, ...nonRunningLogEvents].map((event, i) => (
					<Fragment key={i}>
						{i === indexOfSeparator && indexOfSeparator === 0 && (
							<Text color="neutral500" textAlign="center" my={8}>
								There are no steps running.
							</Text>
						)}

						{i === indexOfSeparator && (
							<Container
								sx={{
									mt: 8,
									width: '100%',
									borderBottom: '1px solid ' + theme.colors.neutral300,
								}}
							/>
						)}

						{isExperimentExecutionLogStepLine(event) ? (
							<ExperimentExecutionLogStep
								{...event}
								hoveredStepId={hoveredStepId}
								setHoveredStepId={setHoveredStepId}
								onClick={() => setSelectedStepId(event.step.id)}
							/>
						) : isExperimentExecutionLogPreflightsLine(event) ? (
							<ExperimentExecutionLogPreflights {...event} onClick={() => setSelectedStepId('preflights')} />
						) : (
							<ExperimentExecutionLogLabel {...event} />
						)}
					</Fragment>
				))}
			</Stack>
		</>
	);
}

const getExperimentLog = (run: ExperimentExecutionVO): ExperimentExecutionLogLine[] => {
	const log: ExperimentExecutionLogLine[] = [];

	if (run.ended && run.state !== 'CANCELED') {
		const experimentEndedLog: ExperimentExecutionLogLabelLine = {
			time: run.ended.getTime(),
			state: run.state,
			label:
				run.state === 'COMPLETED'
					? 'Experiment completed.'
					: run.state === 'FAILED' || run.state === 'ERRORED'
						? `Experiment ${run.state === 'FAILED' ? 'failed' : 'ended with errors'}.${
								run.reason ? ' ' + run.reason : ''
							}${run.reasonDetails ? ' ' + run.reasonDetails : ''}`
						: run.state,
		};
		log.push(experimentEndedLog);
	}
	if (run.canceledBy) {
		const experimentCanceledLog: ExperimentExecutionLogLabelLine = {
			time: run.ended?.getTime() || Date.now(),
			state: 'CANCELED',
			label: `Experiment canceled${run.reason ? ': ' + run.reason : ' by ' + run.canceledBy.name + '.'} `,
		};
		log.push(experimentCanceledLog);
	}

	const steps = flatMap(run.lanes, (lane) => lane.steps.flat()).filter((step) => step.started);

	for (let i = 0; i < steps.length; i++) {
		const { started, state } = steps[i];
		const logLine: ExperimentExecutionLogStepLine = {
			// there is always a started date because it's filtered above
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			time: started,
			state,
			step: steps[i],
		};
		log.push(logLine);
	}

	const preflights = run.preflights ?? [];
	const anyPreflightErrored = preflights.some((check) => check.state === 'ERRORED');
	const anyPreflightFailed = preflights.some((check) => check.state === 'FAILED');
	const allPreflightSuccessful = preflights.every((check) => check.state === 'SUCCESSFUL');

	const connectionEstablishedLog: ExperimentExecutionLogLabelLine = {
		time: (run.created ?? run.requested).getTime(),
		state: run.prepared ? 'COMPLETED' : run.ended ? 'FAILED' : 'RUNNING',
		label: 'Establishing connection to agents...',
	};
	if (allPreflightSuccessful || preflights.length === 0) {
		log.push(connectionEstablishedLog);
	}

	if (preflights.length > 0) {
		const preflightChecks: ExperimentExecutionLogPreflightsLine = {
			time: (run.created ?? run.requested).getTime(),
			state: anyPreflightErrored
				? 'ERRORED'
				: anyPreflightFailed
					? 'FAILED'
					: allPreflightSuccessful
						? 'SUCCESSFUL'
						: 'RUNNING',
			preflights,
		};
		log.push(preflightChecks);
	}

	const createdLog: ExperimentExecutionLogLabelLine = {
		time: (run.created ?? run.requested).getTime(),
		state: 'COMPLETED',
		label: `Experiment run requested by ${run.createdBy.name}.`,
	};
	log.push(createdLog);

	return log;
};

interface ArtifactLinkProps {
	experimentExecutionId: number;
	artifactId: string;
	path: string;
}

function ArtifactLink({ experimentExecutionId, artifactId, path }: ArtifactLinkProps): ReactElement {
	const tenant = useTenant();
	const url = `/ui/artifacts/${experimentExecutionId}/${path}/${artifactId}?tenantKey=${tenant.key}`;
	return (
		<Link href={withBaseHref(url)} variant={'primary'} display={'inline'} ml={'xSmall'}>
			{artifactId} <IconSaveFile mr="xSmall" variant={'xSmall'} />
		</Link>
	);
}

interface TargetExecutionProps {
	targetExecution: ExperimentStepExecutionActionTargetVO;
	step: ExperimentStepExecutionActionVO;
	experimentExecutionId: number;
}

export function TargetExecution({ experimentExecutionId, targetExecution, step }: TargetExecutionProps): ReactElement {
	return (
		<>
			<TargetExecutionText step={targetExecution} kind={step.kind} />
			{targetExecution.artifacts.map((artifact) => (
				<ArtifactLink
					key={`${step.id}_${artifact.name}`}
					experimentExecutionId={experimentExecutionId}
					path={artifact.path}
					artifactId={artifact.name}
				/>
			))}
		</>
	);
}

interface TargetTypeLabelProps {
	target: ExperimentStepExecutionActionTargetVO;
}

interface StepExecutionOrStepTargetExecution {
	started?: Date;
	ended?: Date;
	state: string;
	reason?: string;
}

interface AdditionalTextProps {
	step: ExperimentStepExecutionActionTargetVO;
	kind: ActionKindVO;
}

function TargetExecutionText({ step, kind }: AdditionalTextProps): ReactElement | null {
	const showTargetDetails = (step: ExperimentStepExecutionActionTargetVO): boolean => {
		return step.targetType != null && step.targetType != 'agent';
	};

	const renderStepStatus = (step: StepExecutionOrStepTargetExecution): string | null => {
		if (step.state === 'ERRORED') {
			return ' errored.';
		} else if (step.state === 'FAILED') {
			return ' failed.';
		} else if (step.started && !step.ended) {
			return ' started.';
		} else if (step.state === 'COMPLETED' && step.ended) {
			if (kind === 'CHECK' && step.started) {
				return ` succeeded after ${Math.round((step.ended?.getTime() - step.started.getTime()) / 1000)} seconds.`;
			} else {
				return ' completed.';
			}
		} else if (step.state === 'CANCELED') {
			return ' canceled.';
		}
		return null;
	};

	return (
		<>
			{showTargetDetails(step) ? (
				<Flex direction="horizontal" align="center" spacing="xxSmall">
					on
					<TargetTypeLabel target={step} />
					<TargetIndicator target={step} />
					{renderStepStatus(step)}
				</Flex>
			) : (
				`step ${renderStepStatus(step)}`
			)}
		</>
	);
}

function TargetTypeLabel({ target }: TargetTypeLabelProps): ReactElement | null {
	const targetDefinition = useTargetDefinition(target.targetType);
	if (targetDefinition.value == null) {
		return null;
	}
	return <> {getI18nLabel(targetDefinition.value.label, 1)}</>;
}

interface TargetDescriptionProps {
	target: ExperimentStepExecutionActionTargetVO;
}

function TargetIndicator({ target }: TargetDescriptionProps): ReactElement | null {
	const targetDefinition = useTargetDefinition(target.targetType);

	if (!target.targetType) {
		return null;
	}

	const targetInOtherFormat = toTarget(target);
	if (targetInOtherFormat == null) {
		return null;
	}

	return (
		<presets.pill.TargetTypeTag icon={targetDefinition.value?.icon} small>
			{getTargetLabel(targetInOtherFormat, targetDefinition.value)}
		</presets.pill.TargetTypeTag>
	);
}

export function isExperimentStepExecutionActionVO(
	action: BaseExperimentStepExecutionVOUnion,
): action is ExperimentStepExecutionActionVO {
	return (action as ExperimentStepExecutionActionVO)?.kind ? true : false;
}
