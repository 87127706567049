/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, ModalContentV2, ModalHeaderV2, ModalV2, Stack, Tooltip } from 'components';
import { ExperimentFormActions, ExperimentFormValues } from 'pages/experimentsV2/types';
import { FieldVO, MetricQueryVO, TemplatePlaceholderVO, VariableVO } from 'ui-api';
import Fields from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Fields';
import { TemplateFormValues } from 'pages/templates/TemplateEditor/types';
import DataStore, { useValidations, useStore } from 'DataStore/DataStore';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import StoreTextInput from 'DataStore/StoreTextInput';
import { Flex } from '@steadybit/ui-components-lib';
import { IconSaveDisc } from 'components/icons';
import { debounce, partition } from 'lodash';
import { Services } from 'services/services';

export interface MetricQueryEditorFormValues {
	placeholders: TemplatePlaceholderVO[];
	experimentVariables: VariableVO[];
	actions: ExperimentFormActions[];
	variables: VariableVO[];
	query: MetricQueryVO;
}

interface MetricQueryEditorProps {
	metricQueryParameterDefinitions: FieldVO[];
	query: MetricQueryVO;
	disabled: boolean;
	save: (values: MetricQueryEditorFormValues) => void;
	close: () => void;
}

export default function MetricQueryEditor({
	metricQueryParameterDefinitions,
	disabled,
	query,
	close,
	save,
}: MetricQueryEditorProps): ReactElement {
	const experimentStore = useStore<ExperimentFormValues>();
	const templateStore = useStore<TemplateFormValues>();

	const [initialValues] = useState<MetricQueryEditorFormValues>({
		query,
		actions: disabled ? [] : experimentStore.data.actions || [],
		variables: experimentStore.data.variables || [],
		placeholders: templateStore.data.placeholders || [],
		experimentVariables: experimentStore.data.experimentVariables || [],
	});

	return (
		<DataStore initialData={initialValues}>
			<ModalV2 width={860}>
				<ModalHeaderV2 title="Define Metric Query" onClose={close} />
				<ModalContentV2>
					<Content
						metricQueryParameterDefinitions={metricQueryParameterDefinitions}
						disabled={disabled}
						save={(values) => {
							experimentStore.setValue('placeholders', values.placeholders);
							experimentStore.setValue('variables', values.variables);
							save(values);
						}}
					/>
				</ModalContentV2>
			</ModalV2>
		</DataStore>
	);
}

interface ContentProps {
	metricQueryParameterDefinitions: FieldVO[];
	disabled: boolean;
	save: (values: MetricQueryEditorFormValues) => void;
}

function Content({ metricQueryParameterDefinitions, disabled, save }: ContentProps): ReactElement {
	const { errors, isValidating, setValidations } = useValidations();
	const isValid = Object.keys(errors).length === 0;
	const { data } = useStore<MetricQueryEditorFormValues>();

	const debouncedValidate = useCallback(
		debounce(
			async (_query: MetricQueryVO) => {
				const violations = await Services.experiments.validateMetricQuery(_query);
				if (violations.length === 0) {
					return setValidations({ isValidating: false, errors: {} });
				}
				const errors: Record<string, string> = {};
				for (let i = 0; i < violations.length; i++) {
					const { field, message } = violations[i];
					errors[field] = message;
				}
				setValidations({ isValidating: false, errors: { query: errors } });
			},
			500,
			{ leading: true },
		),
		[],
	);
	const [normalFields, advancedFields] = orderAndPartitionFields(metricQueryParameterDefinitions);

	useEffect(() => {
		setValidations({ isValidating: true });
		debouncedValidate(data.query);
	}, [data.query]);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const saveData = (): void => {
		setIsSubmitting(true);
		save(data);
		setIsSubmitting(false);
	};

	return (
		<Stack
			onKeyDown={(e) => {
				if (e.key === 'Enter' && isValid) {
					saveData();
				}
			}}
			p="2px"
		>
			<StoreTextInput
				placeholder="Descriptive label to identify this query"
				fieldName="query.label"
				disabled={disabled}
				label="Label"
				size="small"
			/>

			<Stack size="small" width="100%" pt="6px">
				<Fields fields={normalFields} stepPath="query" />
				{advancedFields.length > 0 && (
					<Collapsible title="Additional Settings" contentPadding="small">
						<Stack size="small" width="100%" pt="6px" px="small">
							<Fields fields={advancedFields} stepPath="query" />
						</Stack>
					</Collapsible>
				)}
			</Stack>
			<Flex direction="horizontal" justify="end" spacing="small">
				<Button variant="secondary" onClick={close}>
					Cancel
				</Button>
				{!disabled && (
					<Tooltip
						content={
							isValidating ? 'Validating your query…' : !isValid ? 'There are validation errors in your query' : ''
						}
					>
						<Button id="save" onClick={saveData} loading={isSubmitting} disabled={!isValid || isValidating}>
							<IconSaveDisc variant="small" mr="xSmall" />
							Save Metric
						</Button>
					</Tooltip>
				)}
			</Flex>
		</Stack>
	);
}

function orderAndPartitionFields(fields: FieldVO[]): [FieldVO[], FieldVO[]] {
	return partition(
		fields.filter((f) => !f.hidden),
		(f) => !f.advanced,
	);
}
