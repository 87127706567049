/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

export const pathSegment = '/schedules';

export interface UrlState {
	scheduleId: string | null;
	experimentKey: string | null;
}

export const selectedScheduleIdParam: UrlParam<string | null> = {
	pathSegment,
	name: 'scheduleId',
	defaultValue: null,
};

export const experimentKeyParam: UrlParam<string | null> = {
	pathSegment,
	name: 'experimentKey',
	defaultValue: null,
};
