/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import {
	Dropdown,
	ErrorMessage,
	presets,
	SingleChoiceListItem,
	Text,
	TextInput,
	hooks,
} from '@steadybit/ui-components-lib';
import { dateAsUserTimezone, getDateAsLocale, getDayPeriod } from 'utils/dateFns';
import { ReactElement, useMemo, useState } from 'react';
import Calendar from 'components/Calendar/Calendar';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';
import { Stack } from 'components';

import { cronOption, onceOption, ScheduleType } from './EditSchedulesModal/types';
import { ScheduleContentBlock } from './EditSchedulesModal/EditSchedulesModal';
import { ConfigureScheduleProps } from './EditSchedulesModal/EditSchedule';

export default function ScheduleOnce({
	schedule,
	disabled,
	switchScheduleType,
	setScheduleUpdate,
	children,
}: ConfigureScheduleProps): ReactElement {
	const pickedDate = schedule.startAt ? dateAsUserTimezone(schedule.startAt) : new Date();
	const dayPeriod = getDayPeriod(pickedDate);

	const userTimezoneNow = dateAsUserTimezone(new Date());
	const isPickedDateValid = pickedDate.getTime() > userTimezoneNow.getTime();

	return (
		<>
			<ScheduleContentBlock
				title="Run this Experiment"
				description="Should the schedule run the experiment only once or recurrently, based on a cron quartz expression?"
				setting={
					<presets.dropdown.SingleChoiceButton
						items={[onceOption, cronOption]}
						selectedId={onceOption.id}
						disabled={disabled}
						size="small"
						style={{ width: '158px' }}
						onSelect={(id) => switchScheduleType(id as ScheduleType)}
					>
						{onceOption.label}
					</presets.dropdown.SingleChoiceButton>
				}
			>
				<Stack size="small" alignItems="center" mt="large" mb="medium">
					<Stack
						justifyContent="space-between"
						sx={{
							py: 'small',
							px: 'medium',
							borderRadius: 8,
							border: '1px solid ' + theme.colors.neutral300,
							alignItems: 'center',
							background: 'neutral000',
							minHeight: '412px',
						}}
					>
						<Calendar
							defaultActiveStartDate={pickedDate}
							defaultValue={pickedDate}
							disabled={disabled}
							value={pickedDate}
							onChange={(e) => {
								if (e instanceof Date) {
									const dateCopy = new Date(pickedDate);
									dateCopy.setHours(Number(dateCopy.getHours()) - 12);
									setScheduleUpdate({ ...schedule, startAt: userDateAsBrowserDate(e) });
								}
							}}
							minDate={userTimezoneNow}
						/>

						<Stack size="xxSmall" alignItems="center">
							<Text type="small" neutral600 style={{ fontWeight: 600 }}>
								Pick a time:
							</Text>

							<TimePicker
								pickedDate={pickedDate}
								dayPeriod={dayPeriod}
								disabled={disabled}
								setPickedDate={(startAt) => setScheduleUpdate({ ...schedule, startAt })}
							/>
						</Stack>
					</Stack>

					{!isPickedDateValid ? (
						<Stack direction="horizontal" size="small" alignItems="center">
							<ErrorMessage type="small" level="error" withIcon>
								You need to select a date in the future
							</ErrorMessage>
						</Stack>
					) : (
						<Stack direction="horizontal" size="small" alignItems="center"></Stack>
					)}
				</Stack>
			</ScheduleContentBlock>

			{children(isPickedDateValid ? undefined : 'Please select a valid date')}
		</>
	);
}

interface TimePickerProps {
	pickedDate: Date;
	dayPeriod: string;
	disabled: boolean;
	setPickedDate: (date: Date) => void;
}

function TimePicker({ pickedDate, dayPeriod, disabled, setPickedDate }: TimePickerProps): ReactElement {
	const [dropdownContext] = useState(() => new presets.dropdown.DropdownCloseManagement());

	return (
		<presets.dropdown.DropdownContext.Provider value={dropdownContext}>
			<Stack direction="horizontal" size="xSmall" alignItems="center">
				<Numbers
					numberOfItems={12}
					startAt={1}
					value={String(
						pickedDate.getHours() > 12
							? pickedDate.getHours() - 12
							: pickedDate.getHours() === 0
								? 12
								: pickedDate.getHours(),
					).padStart(2, '0')}
					setValue={(hours) => {
						const date = new Date(pickedDate);
						// special case, 12AM
						if (hours === '12' && dayPeriod === 'AM') {
							date.setHours(0);
							// special case, 12PM
						} else if (hours === '12' && dayPeriod === 'PM') {
							date.setHours(Number(hours));
						} else {
							date.setHours(Number(hours) + (dayPeriod === 'PM' ? 12 : 0));
						}
						setPickedDate(userDateAsBrowserDate(date));
					}}
					disabled={disabled}
				/>
				<Text type="small" neutral600 style={{ fontWeight: 600 }}>
					:
				</Text>
				<Numbers
					numberOfItems={60}
					value={String(pickedDate.getMinutes()).padStart(2, '0')}
					setValue={(minutes) => {
						const date = new Date(pickedDate);
						date.setMinutes(Number(minutes));
						setPickedDate(userDateAsBrowserDate(date));
					}}
					disabled={disabled}
				/>
				<Text type="small" neutral600 style={{ fontWeight: 600 }}>
					:
				</Text>
				<Numbers
					numberOfItems={60}
					value={String(pickedDate.getSeconds()).padStart(2, '0')}
					setValue={(seconds) => {
						const date = new Date(pickedDate);
						date.setSeconds(Number(seconds));
						setPickedDate(userDateAsBrowserDate(date));
					}}
					disabled={disabled}
				/>
				<Text type="small" neutral600 style={{ fontWeight: 600 }}>
					:
				</Text>

				<presets.dropdown.SingleChoiceButton
					selectedId={dayPeriod}
					items={['AM', 'PM'].map((dp) => ({ id: dp, label: dp }))}
					disabled={disabled}
					size="small"
					onSelect={(id) => {
						const newDayPeriod = id === 'PM' ? 'PM' : 'AM';
						if (dayPeriod === newDayPeriod) {
							return;
						}
						const dateCopy = new Date(pickedDate);
						dateCopy.setHours(Number(dateCopy.getHours()) + (newDayPeriod === 'PM' ? 12 : -12));
						setPickedDate(userDateAsBrowserDate(dateCopy));
					}}
				>
					{dayPeriod}
				</presets.dropdown.SingleChoiceButton>
			</Stack>
		</presets.dropdown.DropdownContext.Provider>
	);
}

interface NumberSelectorProps {
	numberOfItems: number;
	disabled: boolean;
	startAt?: number;
	value: string;
	setValue: (value: string) => void;
}

function Numbers({ startAt = 0, numberOfItems, value, disabled, setValue }: NumberSelectorProps): ReactElement {
	const [filterText, setFilterText] = useState('');

	const options = useMemo(() => {
		const options: SingleChoiceListItem[] = [];
		for (let i = 0; i < numberOfItems; i++) {
			options.push({
				id: String(startAt + i),
				label: String(startAt + i).padStart(2, '0'),
			});
		}
		return options;
	}, [startAt, numberOfItems]);

	return (
		<Dropdown
			renderDropdownContent={({ width, close }) => (
				<DropDownContent
					options={options.filter((o) => includes(o.label, filterText))}
					width={width}
					onClose={() => {
						setFilterText('');
					}}
					setValue={(v) => {
						setValue(v);
						close();
					}}
				/>
			)}
		>
			{({ isOpen, setRefElement, setOpen }) => {
				return (
					<TextInput
						ref={setRefElement}
						value={isOpen ? filterText : value}
						disabled={disabled}
						size="small"
						onClick={() => setOpen(true)}
						onFocus={() => setOpen(true)}
						onChange={setFilterText}
						style={{ width: '52px' }}
					/>
				);
			}}
		</Dropdown>
	);
}

function userDateAsBrowserDate(userDate: Date): Date {
	const dateNow = new Date();
	const now = getDateAsLocale(dateNow);
	const userNow = dateAsUserTimezone(dateNow);
	const diffInMillis = now.getTime() - userNow.getTime();
	return new Date(userDate.getTime() + diffInMillis);
}

interface DropDownContentProps {
	options: SingleChoiceListItem[];
	width: string;
	setValue: (value: string) => void;
	onClose: () => void;
}

function DropDownContent({ width, options, onClose, setValue }: DropDownContentProps): ReactElement | null {
	hooks.useUnmountEffect(onClose);

	if (options.length === 0) {
		return null;
	}

	return (
		<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="300px">
			<presets.dropdown.SingleChoiceList items={options} onSelect={setValue} />
		</presets.dropdown.DropdownContentFrame>
	);
}
