/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	isOccuranceActionPredicateVO,
	isOccuranceStepLabelVO,
	isOccuranceStepParameterVO,
} from 'pages/templates/UseTemplateModal/types';
import FieldValueHandler from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Field';
import { ExperimentStepActionVO, OccuranceStepParameterVO, OccuranceVO } from 'ui-api';
import ChunksWithPlaceholders from 'pages/templates/ChunksWithPlaceholders';
import { toPredicateString } from 'queryLanguage/parser/serializer';
import { ErrorMessage } from '@steadybit/ui-components-lib';
import { ExperimentError } from 'pages/experimentsV2/types';
import { TemplatePlaceholderRegex } from 'utils/templates';
import { useStoreField } from 'DataStore/DataStore';
import Editor from 'components/Editor/Editor';
import { EnvVarRegex } from 'utils/envVars';
import { ReactElement } from 'react';
import { Stack } from 'components';

interface ActionParametersProps {
	actionStep: ExperimentStepActionVO;
	stepOccurances: OccuranceVO[];
	environmentVariable?: string;
	stepPath: string;
}

export default function ActionParameters({
	environmentVariable,
	stepOccurances,
	actionStep,
	stepPath,
}: ActionParametersProps): ReactElement {
	// env and exp variables are not supported in the attacks custom label
	const hasOccuranceInCustomLabel = !environmentVariable && stepOccurances.some(isOccuranceStepLabelVO);

	const occurancesInParameters: OccuranceStepParameterVO[] = stepOccurances.filter(isOccuranceStepParameterVO);
	const hasOccurancesInBlastRadius = stepOccurances.some(isOccuranceActionPredicateVO);

	return (
		<Stack>
			{hasOccuranceInCustomLabel && <ChunksWithPlaceholders value={actionStep.customLabel || ''} />}
			{hasOccurancesInBlastRadius && (
				<PredicateQuery path={`${stepPath}.blastRadius.predicate`} environmentVariable={environmentVariable} />
			)}
			{occurancesInParameters.map((occurance: OccuranceStepParameterVO) => {
				const resolvedField = occurance.field;
				return (
					<FieldValueHandler
						key={resolvedField.name}
						path={`${stepPath}.parameters['${resolvedField.name}']`}
						field={resolvedField}
						stepPath={stepPath}
						disabled
					/>
				);
			})}
		</Stack>
	);
}

function PredicateQuery({ path, environmentVariable }: { environmentVariable?: string; path: string }): ReactElement {
	const { value, errors } = useStoreField<string>(path);
	const query = toPredicateString(value);

	const error: ExperimentError | undefined = errors as ExperimentError | undefined;

	return (
		<Stack size="xSmall">
			<Editor
				// the monaco API is wrong. It claims to accept a string only, but it actually accepts a RegExp as well
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				highlightTokensContainingString={environmentVariable ? EnvVarRegex : TemplatePlaceholderRegex}
				forceUpdateSignal={query}
				initialValue={query}
				disabled
			/>
			{error && (
				<ErrorMessage type="small" level="warning">
					{error.message}
				</ErrorMessage>
			)}
		</Stack>
	);
}
