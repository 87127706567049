/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	BreadcrumbItem,
	Breadcrumbs,
	ButtonRun,
	HeaderBar,
	Heading,
	SegmentedControl,
	Stack,
	Tooltip,
} from 'components';
import { useExperimentExecutionStatus } from 'pages/experiments/useExperimentExecutionSystemStatusCheck';
import { ExperimentExecutionVO, ExperimentLaneVO, ExperimentStepActionVO } from 'ui-api';
import { useStoreField, useValidations } from 'DataStore/DataStore';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { KillSwitchTooltip } from 'hocs/KillSwitchTooltip';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { useObservable } from 'utils/hooks/useObservable';
import { IconExperiment } from 'components/icons';
import { ReactElement, useEffect } from 'react';
import { Services } from 'services/services';
import { hasError } from 'DataStore/utils';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'url/hooks';
import { ampli } from 'ampli';

import useIsExperimentDisabled from './useIsExperimentDisabled';
import RunExperimentButton from './RunExperimentButton';
import useLastExecutionId from './useLastExecutionId';

interface ExperimentHeaderProps {
	lastExperimentExecution?: ExperimentExecutionVO;
	section: 'design' | 'executions';
}

export default function ExperimentHeader({ lastExperimentExecution, section }: ExperimentHeaderProps): ReactElement {
	const { value: experimentKey } = useStoreField<string>('experimentKey');
	const { value: actions } = useStoreField<string[]>('actions');
	const { value: teamId } = useStoreField<string>('teamId');

	const lastExecutionId = useLastExecutionId(experimentKey);
	const hasExperimentRuns = !!lastExecutionId;

	const nameField = useStoreField<string>('name');
	const { value: name, setValue } = nameField;
	const nameIsInvalid = !hasError(nameField);

	const experimentKeyLabel = useDefaultedExperimentKey(experimentKey, teamId);
	const isNewExperiment = !experimentKey;

	const history = useHistory();

	useAmpliTracking(section);

	return (
		<HeaderBar
			icon={IconExperiment}
			tagline={
				<Breadcrumbs>
					<BreadcrumbItem variant="small" label="Experiments" to="/experiments" sx={{ color: 'neutral300' }} />
				</Breadcrumbs>
			}
			center={
				<SegmentedControl
					type={'dark'}
					name="number"
					value={{ value: section, label: '' }}
					options={[
						{
							value: 'design',
							label: 'Design',
						},
						{
							value: 'executions',
							label: 'Runs',
							disabled: isNewExperiment || !hasExperimentRuns,
							tooltip: isNewExperiment || !hasExperimentRuns ? 'This experiment has not yet run' : undefined,
						},
					]}
					onChange={(option): void => {
						if (option.value === 'design') {
							return history.replace(`/experiments/edit/${experimentKey ?? '<new>'}/design${history.location.search}`);
						}
						if (lastExecutionId) {
							return history.replace(
								`/experiments/edit/${experimentKey}/executions/${lastExecutionId}/attack${history.location.search}`,
							);
						}
						return history.replace(
							`/experiments/edit/${experimentKey ?? '<new>'}/executions${history.location.search}`,
						);
					}}
				/>
			}
			title={
				<Stack direction="horizontal" size="xSmall" alignItems="center">
					<Heading variant={'medium'} flexShrink={0}>
						{experimentKeyLabel}
					</Heading>

					<EditableLabel
						value={name}
						placeholder={nameIsInvalid ? 'Name your experiment' : 'Untitled Experiment'}
						onChange={(value) => {
							setValue(value);
						}}
						disabled={!actions.includes('save') || section === 'executions'}
						withTooltip
						width="100%"
						theme="light"
					/>
				</Stack>
			}
		>
			<RunButton lastExperimentExecutionId={lastExecutionId} lastExperimentExecution={lastExperimentExecution} />
		</HeaderBar>
	);
}

function RunButton({
	lastExperimentExecutionId,
	lastExperimentExecution,
}: {
	lastExperimentExecution: ExperimentExecutionVO | undefined;
	lastExperimentExecutionId: number | undefined;
}): ReactElement | null {
	const experimentRunResult = Services.experiments.useExecution$(
		lastExperimentExecution ? undefined : lastExperimentExecutionId,
	);
	const experimentExecution = lastExperimentExecution || experimentRunResult.value;

	const ongoingIncidentMessage = useExperimentExecutionStatus();

	const { errors, isValidating } = useValidations();
	const { value: lanes } = useStoreField<ExperimentLaneVO[]>('lanes');
	const { value: actions } = useStoreField<string[]>('actions');

	const disabled = useIsExperimentDisabled();

	const killswitchActiveResult = useObservable(() => Services.killswitch.killswitchActive$, []);

	const [stop, handleStopButtonClick] = useAsyncFn((executionId: number) =>
		Services.experiments.cancelExperimentRun(executionId),
	);

	if (experimentRunResult.loading) {
		return (
			<Tooltip content="The experiment is currently running.">
				<RunExperimentButton disabled />
			</Tooltip>
		);
	}

	if (experimentExecution && !experimentExecution.ended) {
		return (
			<ButtonRun
				onClick={() => handleStopButtonClick(experimentExecution.id)}
				experimentExecution={experimentExecution}
				loading={stop.loading}
			/>
		);
	}

	if (!lanes || lanes.length === 0 || lanes.every((lane) => lane.steps.length === 0)) {
		return (
			<Tooltip content="No steps to be executed. Please add at least one step.">
				<RunExperimentButton disabled />
			</Tooltip>
		);
	}

	if (killswitchActiveResult.value) {
		return (
			<Tooltip content={<KillSwitchTooltip />}>
				<RunExperimentButton disabled />
			</Tooltip>
		);
	}

	if (ongoingIncidentMessage) {
		return (
			<Tooltip content={ongoingIncidentMessage}>
				<RunExperimentButton disabled />
			</Tooltip>
		);
	}

	const canRunByTeam = actions.includes('run-by-team');
	if (disabled) {
		return (
			<Tooltip
				content={
					!canRunByTeam
						? 'You need to be a member of the Team to run an experiment.'
						: `You've reached your maximum number of experiment runs.${''}`
				}
			>
				<RunExperimentButton disabled />
			</Tooltip>
		);
	}

	if (isValidating) {
		return (
			<Tooltip content="Validating your experiment...">
				<RunExperimentButton disabled />
			</Tooltip>
		);
	}

	// if only the name is invalid, we can still run the experiment
	const hasErrors = Object.keys(errors).length > 1 || (Object.keys(errors).length === 1 && !('name' in errors));
	if (hasErrors) {
		return (
			<Tooltip content="There are validation errors in your experiment.">
				<RunExperimentButton disabled />
			</Tooltip>
		);
	}

	return <RunExperimentButton />;
}

function useDefaultedExperimentKey(experimentKey?: string, teamId?: string): string {
	return (
		useAsyncState(async () => {
			if (experimentKey) {
				return experimentKey;
			}
			const team = teamId ? await Services.teams.findTeamById(teamId) : undefined;
			return team ? `${team.key}-##` : '';
		}, [experimentKey, teamId])[0].value || ''
	);
}

function useAmpliTracking(section: 'design' | 'executions'): void {
	const { value: experimentKey } = useStoreField<string>('experimentKey');
	const { value: environmentId } = useStoreField<string>('environmentId');
	const { value: lanes } = useStoreField<ExperimentLaneVO[]>('lanes');
	const { value: name } = useStoreField<string>('name');

	const isNewExperiment = !experimentKey;

	useEffect(() => {
		if (section === 'design') {
			const experimentSteps = lanes.flatMap((lane) => lane.steps);
			const experimentStepIds = [
				...new Set(
					experimentSteps.map((step) =>
						step.type === 'action' ? (step as ExperimentStepActionVO).actionId : step.type,
					),
				),
			];
			ampli.experimentViewed({
				experiment_key: experimentKey,
				experiment_name: name,
				experiment_saved: !isNewExperiment,
				environment_id: environmentId,
				experiment_steps: experimentStepIds,
				experiment_number_of_steps: 1,
			});
		}
	}, [experimentKey, section]);
}
