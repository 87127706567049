/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

const ApplicationHeaderHeightContent = React.createContext<number>(0);

export function ApplicationHeaderHeight({ children }: { children: ReactNode }): ReactElement {
	const [totalHeaderHeight, setTotalHeaderHeight] = useState<number>(0);

	useEffect(() => {
		const observer = new MutationObserver(() => {
			const userOnProdHeight: number = document.getElementById('steadybit-user-on-prod')?.offsetHeight ?? 0;
			const incidentBannerHeight: number = document.getElementById('incident-banner')?.offsetHeight ?? 0;
			const maintenanceBannerHeight: number = document.getElementById('maintenance-banner')?.offsetHeight ?? 0;
			const licenseBannerHeight: number = document.getElementById('license-banner')?.offsetHeight ?? 0;
			const notificationBannerHeight: number = document.getElementById('notification-banner')?.offsetHeight ?? 0;
			const updateWarning: number = document.getElementById('steadybit-update-warning')?.offsetHeight ?? 0;
			const killswitchBannerHeight: number = document.getElementById('killswitch-banner')?.offsetHeight ?? 0;
			const safariWarningBannerHeight: number = document.getElementById('steadybit-safari-warning')?.offsetHeight ?? 0;

			const _totalHeaderHeight: number =
				userOnProdHeight +
				incidentBannerHeight +
				maintenanceBannerHeight +
				notificationBannerHeight +
				updateWarning +
				licenseBannerHeight +
				killswitchBannerHeight +
				safariWarningBannerHeight;

			if (_totalHeaderHeight !== totalHeaderHeight) {
				setTotalHeaderHeight(_totalHeaderHeight);
			}
		});
		observer.observe(document, { childList: true, subtree: true });
		return () => {
			observer.disconnect();
		};
	}, [totalHeaderHeight]);

	return (
		<ApplicationHeaderHeightContent.Provider value={totalHeaderHeight}>
			{children}
		</ApplicationHeaderHeightContent.Provider>
	);
}

export const useApplicationHeaderHeight = (): number => {
	return React.useContext(ApplicationHeaderHeightContent);
};
