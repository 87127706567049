/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Fragment, ReactElement } from 'react';
import { theme } from 'styles.v2/theme';
import { Stack } from 'components';

import { SidebarTemplateMaker } from '../DragAndDropHandler';
import useWorkspaceSettings from '../useWorkspaceSettings';
import useTemplates from '../useTemplates';

interface GhostStepProps {
	draggedOverWith?: string | null;
}

export default function GhostStep({ draggedOverWith }: GhostStepProps): ReactElement {
	const { pxPerSecond } = useWorkspaceSettings();

	if (draggedOverWith && draggedOverWith.startsWith(SidebarTemplateMaker)) {
		const templateId = draggedOverWith.substring(SidebarTemplateMaker.length);
		return <TemplateGhostSteps templateId={templateId} pxPerSecond={pxPerSecond} />;
	}

	return <Step key={draggedOverWith} width={`${pxPerSecond * 10}px`} />;
}

function Step({ width }: { width: string }): ReactElement {
	return (
		<div
			style={{
				width,
				height: '46px',
				border: '2px dashed ' + theme.colors.purple500,
				backgroundColor: theme.colors.purple100,
				borderRadius: '2px',
			}}
		/>
	);
}

interface TemplateGhostStepsProps {
	pxPerSecond: number;
	templateId: string;
}

function TemplateGhostSteps({ templateId, pxPerSecond }: TemplateGhostStepsProps): ReactElement {
	const template = useTemplates()?.find((t) => t.id === templateId);
	if (!template) {
		return <Step key={templateId} width={`${pxPerSecond * 10}px`} />;
	}

	return (
		<Stack size="14px" style={{ padding: '5px 0' }}>
			{template.lanes.map((lane, lI) => {
				const steps = lane.steps.map((step, sI) => {
					const durationInSeconds = step.durationInSeconds || 10;
					return <Step key={lI + '-' + sI} width={`${durationInSeconds * pxPerSecond}px`} />;
				});
				if (steps.length > 1) {
					return (
						<Stack direction="horizontal" key={'stepWrapper-' + lI} size="14px">
							{steps}
						</Stack>
					);
				}
				return <Fragment key={'stepWrapper-' + lI}>{steps}</Fragment>;
			})}
		</Stack>
	);
}
