/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	RouterLink,
	RouterPagination,
	Snackbar,
	Stack,
	StateBadge,
	Table,
	TableBody,
	TableDataCell,
	TableErrorRow,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	Tooltip,
	userConfirm,
} from 'components';
import {
	BasicListItem,
	Button,
	Colors,
	Dropdown,
	Flex,
	Icon,
	IconButton,
	IconType,
	ItemRenderer,
	presets,
	Ul,
} from '@steadybit/ui-components-lib';
import {
	ActionVO,
	ExperimentScheduleVO,
	ExperimentSummaryVO,
	GetExperimentsPageResponse,
	RunningExperimentVO,
	TargetTypeDescriptionVO,
} from 'ui-api';
import ExperimentBadgeModal from 'pages/experiments/components/header/ExperimentBadgeModal';
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { createFilterParams } from 'pages/templates/FromTemplateModal/urlParams';
import { IconClose, IconExperiment, IconInformation } from 'components/icons';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import useGlobalPermissions from 'services/useGlobalPermissions';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import SearchBar from 'pages/components/SearchBar/SearchBar';
import { DataStreamResult } from 'utils/hooks/stream/result';
import ListHeader from 'components/List/presets/ListHeader';
import { KillSwitchTooltip } from 'hocs/KillSwitchTooltip';
import PlayButton from 'components/PlayButton/PlayButton';
import useObservable from 'react-use/lib/useObservable';
import ViewWrapper from 'pages/components/ViewWrapper';
import { localeCompareIgnoreCase } from 'utils/string';
import Skeletons from 'components/Skeleton/Skeletons';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { usePage } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { useLocation } from 'react-use';
import { ampli } from 'ampli';

import EditSchedulesModal from './components/Schedule/EditSchedulesModal/EditSchedulesModal';
import { useExperimentExecutionStatus } from './useExperimentExecutionSystemStatusCheck';
import { useExperimentExecutionTrigger } from './useExperimentExecutionTrigger';
import Notifications from '../../components/Notifications/Notifications';
import { directionParam, sortParam, UrlState } from './urlParams';
import { getSchedulesIcon } from './SchedulesList/utils';
import invokePromise from '../../utils/ignorePromise';
import ExperimentRunTrend from './ExperimentRunTrend';
import { useUrlState } from '../../url/useUrlState';
import { useTeam } from '../../services/useTeam';

interface ExperimentListProps {
	experiments: DataStreamResult<GetExperimentsPageResponse>;
	targetDefinitions: TargetTypeDescriptionVO[];
	schedules: ExperimentScheduleVO[];
	actions: ActionVO[];
}

export default function ExperimentListWithTrend({
	targetDefinitions,
	experiments,
	schedules,
	actions,
}: ExperimentListProps): ReactElement {
	const { environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, pageParam } = useRef(
		createFilterParams('/experiments'),
	).current;
	const [{ page }, getWithUrlState, updateUrlState] = useUrlState<UrlState>([pageParam]);

	const location = useLocation();
	const [showError, setShowError] = useState<string | undefined>(location.state?.state?.showError);
	const [showBadgeModal, setShowBadgeModal] = useState<string | undefined>(undefined);
	const [keyToCreateSchedule, setKeyToCreateSchedule] = useState<string | undefined>(undefined);

	const running = useObservable(Services.experiments.running$, []);
	const team = useTeam();

	useEffect(() => {
		ampli.experimentListViewed({ url: window.location.href });
	}, []);

	useEffect(() => {
		if (page > 0 && experiments.value?.content.length === 0) {
			updateUrlState({ page: 0 });
		}
	}, [experiments.value?.content.length, page]);

	const searchObjectsResult = usePromise(() => Services.experiments.getSearchObjects(team.id), []);

	const [{ environmentIds, tags, targetTypes, actions: actionIds, freeTextPhrases }] = useUrlState<UrlState>([
		freeTextPhrasesParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		tagsParam,
	]);
	const isAnyFilterDefined =
		environmentIds.length > 0 ||
		tags.length > 0 ||
		targetTypes.length > 0 ||
		actionIds.length > 0 ||
		freeTextPhrases.length > 0;

	const isEmptyResult = experiments.value && !experiments.loading && experiments.value.content.length === 0;
	const isEmpty = !isAnyFilterDefined && isEmptyResult;

	const permissions = useGlobalPermissions();

	return (
		<>
			{showBadgeModal && (
				<ExperimentBadgeModal experimentKey={showBadgeModal} onClose={() => setShowBadgeModal(undefined)} />
			)}
			{keyToCreateSchedule && (
				<EditSchedulesModal
					experimentName={experiments.value?.content.find((e) => e.key === keyToCreateSchedule)?.name}
					experimentKey={keyToCreateSchedule}
					onClose={() => setKeyToCreateSchedule(undefined)}
				/>
			)}
			<ViewWrapper>
				<Stack py="small" flexGrow={1} size="medium">
					<ListHeader
						left={<ListHeaderTitle title="Experiments" Icon={IconExperiment} />}
						right={!isEmpty && <CreateExperimentButton canCreate={permissions.experiments.canCreate.allowed} />}
					/>
					<Notifications types={['LICENSE_HARD_LIMIT_REACHED_EXPERIMENT_EXECUTION']} />
					{!isEmpty && (
						<SearchBar
							mode="experimentList"
							searchObjectsResult={searchObjectsResult}
							targetDefinitions={targetDefinitions}
							actions={actions}
							cypressTag="search-input"
							pathname="/experiments"
						/>
					)}
					{isEmpty && (
						<Container
							data-cy="no-experiments-found"
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								height: '100%',
							}}
						>
							<Container mt={-200}>
								<AstroidScreen
									title={
										<Text variant="xLargeStrong" color="slate">
											Your Chaos Engineering journey starts here
										</Text>
									}
									icon={<IconExperiment variant="xLarge" color="slate" />}
									description={
										<>
											<Text variant="large" color="neutral600" textAlign="center">
												Create your first experiment to uncover weaknesses, improve system reliability, and embrace
												resilience! Simply recreate a past incident or choose one of Steadybit&apos;s templates to
												tackle reliability.
											</Text>
											<CreateExperimentButton canCreate={permissions.experiments.canCreate.allowed} />
										</>
									}
								/>
							</Container>
						</Container>
					)}
					{showError && (
						<Stack
							alignItems={'left'}
							justifyContent={'center'}
							bg={'coral100'}
							height={47}
							sx={{ borderLeft: '4px solid', borderLeftColor: 'coral700', borderRadius: '3px' }}
						>
							<Container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
								<Text mx={'small'} variant={'mediumStrong'} color={'neutral800'}>
									{showError}
								</Text>
								<ButtonIcon onClick={() => setShowError(undefined)}>
									<IconClose />
								</ButtonIcon>
							</Container>
						</Stack>
					)}

					{!isEmpty && (
						<>
							<ExperimentTable>
								{experiments.error && <TableErrorRow error={experiments.error.message} />}
								{experiments.loading && !isEmpty ? (
									<>
										<TableLoadingRow numColumns={5} />
										<TableLoadingRow numColumns={5} />
										<TableLoadingRow numColumns={5} />
									</>
								) : (
									experiments.value &&
									experiments.value.content.map((experiment) => (
										<ExperimentRow
											key={experiment.key}
											experimentSchedules={schedules.filter((s) => s.experimentKey === experiment.key)}
											running={running.find((r) => r.experimentKey === experiment.key)}
											value={experiment}
											editSchedule={() => setKeyToCreateSchedule(experiment.key)}
											showBadgeModal={setShowBadgeModal}
										/>
									))
								)}
								{isEmptyResult && (
									<TableRow>
										<TableDataCell colSpan={6}>
											<Text muted data-cy="no-experiments-found">
												No Experiments matched your search.
											</Text>
										</TableDataCell>
									</TableRow>
								)}
							</ExperimentTable>

							<RouterPagination
								activePage={page}
								totalPages={experiments.value?.totalPages}
								to={(i) => getWithUrlState({ page: i })}
							/>
						</>
					)}
				</Stack>
			</ViewWrapper>
		</>
	);
}

const ExperimentTable: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [{ direction }, getWithUrlState] = useUrlState<UrlState>([sortParam, directionParam]);

	return (
		<Table data-cy="experiment-table" width={'100%'}>
			<TableHead>
				<TableRow>
					<TableHeadCell>
						<TableSortLink
							sort={direction === 'DESC' ? 'asc' : 'desc'}
							to={getWithUrlState({ direction: direction === 'DESC' ? 'ASC' : 'DESC' })}
							onClick={() => {
								ampli.experimentListSorted({ sorted_by: 'Name' });
							}}
						>
							Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell width={160} justifyContent="flex-end">
						Run Trend
						<Tooltip content="See the latest 10 runs; last one is on the right.">
							<div>
								<IconInformation variant="small" ml="xxSmall" />
							</div>
						</Tooltip>
					</TableHeadCell>
					<TableHeadCell width={150}>Last Run</TableHeadCell>
					<TableHeadCell width={40} />
					<TableHeadCell width={40} />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

interface ExperimentRowProps {
	experimentSchedules: ExperimentScheduleVO[];
	running?: RunningExperimentVO;
	value: ExperimentSummaryVO;
	showBadgeModal: (experimentKey: string) => void;
	editSchedule: () => void;
}

function ExperimentRow({
	experimentSchedules,
	running,
	value,
	showBadgeModal,
	editSchedule,
}: ExperimentRowProps): ReactElement {
	const lastExecutionsResult = Services.experiments.useExecutions$(value.key);

	const page = usePage('/design');

	const location = useLocation();
	const highlightedExperiment = location.state?.state?.highlightedExperiment;

	const permissions = useGlobalPermissions();

	const handleDeleteExperimentClick = (value: ExperimentSummaryVO): void => {
		invokePromise(async () => {
			if (
				(await userConfirm({
					title: 'Delete Experiment',
					message: `Do you really want to delete ${value.key} ${value.name}?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{ value: 'confirm', label: `Delete ${value.key}`, variant: 'primary', dataCyTag: 'confirmDelete' },
					],
				})) === 'confirm'
			) {
				try {
					await Services.experiments.deleteExperiment(value.key, 'UI_LIST');
					Snackbar.dark('Experiment deleted.', { toastId: 'experiment-deleted' });
				} catch (err) {
					Snackbar.error('Experiment not deleted: ' + err.toString(), { toastId: 'experiment-deleted' });
				}
			}
		});
	};

	const { canEdit, canDelete } = Services.permissionsApi.getExperimentPermissions(value);

	return (
		<TableRow
			hoverable={true}
			sx={
				value.key === highlightedExperiment
					? {
							transition: 'opacity 500ms ease-out',
							animationName: 'fadeIn,fadeOut',
							animationIterationCount: '1',
							animationTimingFunction: 'ease-in-out',
							animationDuration: '0.35s,0.35s',
							animationDirection: 'alternate',
							animationDelay: '0s,1.5s',
							animationFillMode: 'forwards',

							'@keyframes fadeIn': {
								'0%': {
									opacity: '0',
								},
								'100%': {
									opacity: '1',
									backgroundColor: 'cyanLight',
								},
							},
							'@keyframes fadeOut': {
								'0%': {
									backgroundColor: 'cyanLight',
								},
								'100%': {
									backgroundColor: 'transparent',
								},
							},
						}
					: {}
			}
		>
			<TableDataCell maxWidth={200}>
				<ExperimentRunButtonRound value={value} running={running} />
				<Stack size="none" ml="small">
					<Tooltip content={`${value.key} ${value.name}`} onlyShowOnEllipsis>
						<RouterLink
							to={`/experiments/edit/${value.key}`}
							sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
						>
							<Text variant={'mediumStrong'} as={'span'}>
								{value.key}
							</Text>
							&nbsp;
							<Text variant={'mediumStrong'} as={'span'}>
								{value.name}
							</Text>
						</RouterLink>
					</Tooltip>
					<Flex direction="horizontal" spacing="xSmall">
						{value.editedBy ? (
							<Tooltip content={`${formatDateWithTime(value.edited)} by ${value.editedBy.name}`}>
								<Stack direction="horizontal" alignItems="center" size="xxSmall">
									<Text variant="small" as="span" color="neutral600" sx={{ whiteSpace: 'nowrap', minWidth: '86px' }}>
										Last edited by
									</Text>
									<Text
										variant="smallStrong"
										sx={{ color: 'neutral600', overflow: 'hidden', textOverflow: 'ellipsis' }}
										noWrap
										data-private
									>
										{value.editedBy.name}
									</Text>
								</Stack>
							</Tooltip>
						) : null}
						<presets.pill.Tags
							appearance="experiment"
							tags={value.tags.slice(0, 5).sort(localeCompareIgnoreCase)}
							small
						/>
					</Flex>
				</Stack>
			</TableDataCell>

			<TableDataCell>
				<>
					{lastExecutionsResult.value ? (
						<ExperimentRunTrend lastRuns={lastExecutionsResult.value} experimentKey={value.key} />
					) : (
						<Flex direction="horizontal" justify="end" style={{ width: '100%' }}>
							<Skeletons height={16} widths={[100]} />
						</Flex>
					)}
				</>
			</TableDataCell>
			<TableDataCell>
				<Stack size="none">
					{lastExecutionsResult.value ? (
						lastExecutionsResult.value.length > 0 ? (
							<Container display={'flex'} flexDirection={'column'}>
								<Container maxWidth={150}>
									<StateBadge as="state" value={lastExecutionsResult.value[0].state} />
								</Container>

								<RouterLink mr={'xSmall'} to={`/experiments/edit/${value.key}/executions`}>
									<Text
										variant={'small'}
										sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums', ':hover': { color: 'slate' } }}
									>
										{`${formatDateWithTime(lastExecutionsResult.value[0].requested)} `}
									</Text>
								</RouterLink>
							</Container>
						) : null
					) : (
						<Skeletons height={16} widths={[100]} />
					)}
				</Stack>
			</TableDataCell>
			<TableDataCell>
				{experimentSchedules.length > 0 && (
					<ButtonIcon onClick={editSchedule} tooltip={canEdit ? 'Edit schedules' : 'View schedules'}>
						<Icon type={getSchedulesIcon(experimentSchedules)} />
					</ButtonIcon>
				)}
			</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				<Dropdown
					placement="bottom-end"
					renderDropdownContent={({ close }) => (
						<presets.dropdown.DropdownContentFrame>
							<Ul<ContextMenuItem, AdditionalProps>
								items={
									[
										{
											id: '1',
											label: canEdit ? 'Edit Experiment' : 'View Experiment',
											icon: canEdit ? 'edit' : 'view',
											href: `/experiments/edit/${value.key}`,
										},
										{
											id: '2',
											label: 'Duplicate Experiment',
											icon: 'duplicate',
											href: page.toString('/experiments/edit/<new>/design', {
												copy: value.key,
											}),
											cypressTag: 'experimentActionDuplicate',
										},
										{
											id: '3',
											label: 'Save as Template',
											disabled: !permissions.templates.canCreate.allowed,
											disabledTooltip: permissions.templates.canCreate.tooltipContent,
											icon: 'template',
											href: `/settings/templates/design/fromExperiment/${value.key}`,
										},
										{
											id: '4',
											label:
												experimentSchedules.length > 0
													? permissions.schedules.canEdit.allowed
														? 'Edit Schedules'
														: 'View Schedules'
													: 'Schedule run',
											icon: getSchedulesIcon(experimentSchedules),
											cypressTag: 'experimentActionSchedule',
											disabled: !permissions.schedules.canEdit.allowed && experimentSchedules.length === 0,
											disabledTooltip:
												experimentSchedules.length === 0 ? permissions.schedules.canEdit.tooltipContent : undefined,
											onClick: () => {
												editSchedule();
												close();
											},
										},
										canDelete
											? {
													id: '5',
													label: 'Delete Experiment',
													icon: 'delete',
													cypressTag: 'experimentActionDelete',
													onClick: () => {
														handleDeleteExperimentClick(value);
														close();
													},
												}
											: null,
										{
											id: '6',
											label: 'Create Badge',
											icon: 'badge',
											onClick: () => {
												showBadgeModal(value.key);
												close();
											},
										},
									].filter(Boolean) as ContextMenuItem[]
								}
								ItemRenderer={ContextMenuItemRenderer}
							/>
						</presets.dropdown.DropdownContentFrame>
					)}
				>
					{({ setRefElement, isOpen, setOpen }) => {
						return (
							<IconButton
								ref={setRefElement}
								type="chromelessWithBorderOnHover"
								size="small"
								data-cy="experimentActions"
								icon="dots"
								style={{
									outline: isOpen ? '2px solid ' + Colors.slate : undefined,
								}}
								onClick={() => setOpen(!isOpen)}
							/>
						);
					}}
				</Dropdown>
			</TableDataCell>
		</TableRow>
	);
}

type AdditionalProps = object;

interface ContextMenuItem extends BasicListItem {
	disabledTooltip?: string | ReactNode;
	cypressTag?: string;
	icon: IconType;
	label: string;
	href?: string;
	onClick?: () => void;
}

const ContextMenuItemRenderer: ItemRenderer<ContextMenuItem, AdditionalProps> = ({
	prevItem,
	nextItem,
	isFirst,
	isLast,
	index,
	item,
}) => {
	const { cypressTag, href, disabledTooltip, onClick } = item;

	let renderItem = (
		<presets.dropdown.SingleChoiceListItemRenderer
			prevItem={prevItem}
			nextItem={nextItem}
			isFirst={isFirst}
			isLast={isLast}
			index={index}
			item={item}
			data-cy={cypressTag}
			onClick={disabledTooltip ? undefined : onClick}
		/>
	);

	if (href) {
		renderItem = (
			<RouterLink to={href} dontResolve style={{ textDecoration: 'none' }} disabled={!!disabledTooltip}>
				{renderItem}
			</RouterLink>
		);
	}

	if (disabledTooltip) {
		renderItem = (
			<Tooltip content={disabledTooltip}>
				<div>{renderItem}</div>
			</Tooltip>
		);
	}

	return renderItem;
};

interface ExperimentRunActionsProps {
	running?: RunningExperimentVO;
	value: ExperimentSummaryVO;
}
function ExperimentRunButtonRound({ running, value }: ExperimentRunActionsProps): ReactElement {
	const killswitchActive = useObservable(Services.killswitch.killswitchActive$);

	const ongoingIncidentMessage = useExperimentExecutionStatus();
	const handleExperimentRunClick = useExperimentExecutionTrigger();

	const handleExperimentRunCancelClick = (experimentExecutionId: number): void => {
		invokePromise(async () => {
			try {
				await Services.experiments.cancelExperimentRun(experimentExecutionId);
			} catch (err) {
				Snackbar.error('Experiment not canceled: ' + err.toString(), { toastId: 'experiment-cancel' });
			}
		});
	};

	const { canRunByTeam, canRunByLicense } = Services.permissionsApi.getExperimentPermissions(value);
	const canRun = canRunByTeam && canRunByLicense;

	return (
		<Tooltip
			content={
				killswitchActive ? (
					<KillSwitchTooltip />
				) : (
					ongoingIncidentMessage ||
					(canRun
						? running
							? 'Stop experiment'
							: 'Long press to run experiment'
						: !canRunByTeam
							? 'You need to be a member of the Team to run an experiment'
							: `You've reached your maximum number of experiment runs.${''}`)
				)
			}
		>
			<PlayButton
				start={running?.started?.valueOf()}
				end={running?.estimatedEnd?.valueOf()}
				onLongClickReleased={(fired, progress) => ampli.runExperimentLongClickReleased({ fired, progress })}
				onClick={() => {
					if (running) {
						handleExperimentRunCancelClick(running.executionId);
					} else {
						handleExperimentRunClick(value.key, {
							redirectToEditorOnRunFailure: true,
							executionSource: 'UI_EXPERIMENT_LIST',
							executionVariables: [],
						});
					}
				}}
				tag={`run-${value.name}`}
			/>
		</Tooltip>
	);
}

function CreateExperimentButton({ canCreate }: { canCreate: boolean }): ReactElement {
	return (
		<Tooltip content={!canCreate ? 'You need to be a member of the Team to create new experiments.' : false}>
			<RouterLink
				to="/experiments/start"
				disabled={!canCreate}
				minWidth={180}
				style={{
					textDecoration: 'none',
				}}
			>
				<Button withLeftIcon="plus" disabled={!canCreate} onClick={() => {}} data-cy="experiment-start">
					New Experiment
				</Button>
			</RouterLink>
		</Tooltip>
	);
}
