/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { useLastVisitedView } from 'pages/landscape/hocs/lastVisitedView';
import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect } from 'react';
import { useHistory } from 'url/hooks';
import { Snackbar } from 'components';
import { ampli } from 'ampli';

export default function ExploreNotFoundView(): ReactElement {
	const { deleteLastViewedId } = useLastVisitedView();
	const history = useHistory();

	useEffect(() => {
		const id = 'explore-not-found';

		ampli.landscapeExplorerDeletedViewViewed({ url: window.location.href });

		Snackbar.darkForever(
			() => (
				<Flex spacing="none">
					<Text type="mediumStrong">The view you’re looking for may have been deleted.</Text>
					<Text type="mediumStrong">
						You can create a new view or go to the{' '}
						<Text
							style={{ color: Colors.purple400, textDecoration: 'underline' }}
							onClick={() => {
								Snackbar.dismiss(id);
								history.push('/landscape/views');
							}}
						>
							<Text type="mediumStrong">saved views</Text>
						</Text>
						.
					</Text>
				</Flex>
			),
			{
				toastId: id,
				updateId: id,
			},
		);
		deleteLastViewedId();
		history.replace('/landscape/explore');
	}, []);

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				backgroundColor: Colors.neutral700,
			}}
		></div>
	);
}
