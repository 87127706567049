/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import TeamsSelection from 'pages/settings/teams/components/TeamsSelection';
import Environments from 'pages/settings/teams/components/Environments';
import { Flex, Grid, Text } from '@steadybit/ui-components-lib';
import { withBaseHref } from 'utils/getBaseHref';
import { IconSaveFile } from 'components/icons';
import { ButtonIcon, Link } from 'components';
import { useTenant } from 'tenancy/useTenant';
import { EnvironmentSummaryVO } from 'ui-api';
import { useUrlState } from 'url/useUrlState';
import { ReactElement } from 'react';
import { days } from 'utils/time';

import {
	directionParam,
	environmentIdsParam,
	pageParam,
	sortParam,
	statesParam,
	teamIdsParam,
	timeParam,
	UrlState,
} from './urlParams';
import States from './States';
import Times from './Times';

interface FilterBarProps {
	environments: EnvironmentSummaryVO[];
}

export default function FilterBar({ environments }: FilterBarProps): ReactElement {
	const [{ environmentIds, time, teamIds, states }, , updateUrlState] = useUrlState<UrlState>([
		environmentIdsParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
	]);

	return (
		<Flex spacing="small">
			<Text type="mediumStrong">Filter by:</Text>

			<Flex direction="horizontal" align="center" justify="end" spacing="medium" style={{ width: '100%' }}>
				<Grid cols="repeat(4, minmax(0, 1fr))" spacing="medium" align="end" style={{ width: '100%' }}>
					<TeamsSelection
						placement="bottom-start"
						value={teamIds}
						onChange={(teamIds) => updateUrlState({ teamIds })}
					/>

					<Times selectedTime={time} selectItem={(time) => updateUrlState({ time })} />

					<Environments
						selectedEnvironmentIds={environmentIds}
						environments={environments}
						selectIds={(_envIds) => {
							updateUrlState({
								environmentIds: _envIds,
							});
						}}
					/>

					<States
						states={['CANCELED', 'COMPLETED', 'CREATED', 'ERRORED', 'FAILED', 'PREPARED', 'REQUESTED', 'RUNNING']}
						selectedStates={states}
						selectItems={(_states) => {
							updateUrlState({
								states: _states,
							});
						}}
					/>
				</Grid>

				<DownloadAsCSVButton />
			</Flex>
		</Flex>
	);
}

function DownloadAsCSVButton(): ReactElement {
	const tenant = useTenant();
	const [{ environmentIds, time, teamIds, states }] = useUrlState<UrlState>([
		environmentIdsParam,
		directionParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
		sortParam,
	]);

	const params = new URLSearchParams();
	if (teamIds.length > 0) {
		params.append('teamIds', teamIds.join(','));
	}
	if (environmentIds.length > 0) {
		params.append('environmentIds', environmentIds.join(','));
	}
	if (states.length > 0) {
		params.append('states', states.join(','));
	}

	if (time) {
		let requestedFrom: number | null = null;
		let requestedTo: number | null = null;
		requestedFrom = new Date().getTime();
		requestedTo = new Date().getTime();
		if (Array.isArray(time)) {
			requestedFrom = time[0];
			requestedTo = time[1];
		} else if (time === 'lastDay') {
			requestedFrom = requestedTo - days(1).getMillis();
		} else if (time === 'lastWeek') {
			requestedFrom = requestedTo - days(7).getMillis();
		} else if (time === 'lastMonth') {
			requestedFrom = requestedTo - days(31).getMillis();
		}
		params.append('requestedFrom', String(requestedFrom));
		params.append('requestedTo', String(requestedTo));
	}

	params.append('tenantKey', tenant.key);

	return (
		<Link href={withBaseHref(`/ui/reports/experiments/runs?${params.toString()}`)}>
			<ButtonIcon
				variant="small"
				tooltip="Download as CSV"
				sx={{ borderColor: 'neutral300', height: '40px', width: '40px' }}
			>
				<IconSaveFile />
			</ButtonIcon>
		</Link>
	);
}
