/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Colors, CustomIcon, Icon, PositiveSpacings } from '@steadybit/ui-components-lib';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import { ReactElement } from 'react';

interface ActionIconProps {
	size?: keyof typeof PositiveSpacings | number;
	color?: keyof typeof Colors;
	id: string;
}

export function ActionIcon({ id, ...props }: ActionIconProps): ReactElement {
	const [action] = useAsyncState(() => (id ? Services.actions.findAction(id) : Promise.resolve(undefined)), [id]);
	if (action.value?.icon) {
		const color = props.color ? Colors[props.color] : undefined;
		return <CustomIcon svgDataUri={action.value.icon} {...props} color={color} />;
	}
	return <Icon type="action" {...props} />;
}
