/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { IconArrowRight, IconAttackTime } from 'components/icons';
import { DataPoint } from 'components/StripChart/StripChart';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface StripChartTimeProps {
	dataPoint: DataPoint;
}

export default function StripChartTime({ dataPoint }: StripChartTimeProps): ReactElement {
	return (
		<Flex direction="horizontal" align="center" spacing="xSmall">
			<IconAttackTime variant="small" />
			<Text type="small">{new Date(dataPoint.timestamp).toLocaleTimeString()}</Text>
			<IconArrowRight variant="small" />
			<Text type="small">{dataPoint.endTimestamp ? new Date(dataPoint.endTimestamp).toLocaleTimeString() : '-'}</Text>
		</Flex>
	);
}
