/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Text } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import { Container } from 'components';
import { ReactElement } from 'react';

import { WorkspaceLaneFalloffCatcherMaker } from '../DragAndDropHandler';
import { useEditorSettings } from '../useEditorSettings';
import LaneMarker from './LaneMarker';
import { LANE_STYLE } from './Lane';
import GhostStep from './GhostStep';
import './styles.css';

export default function EmptyLanes(): ReactElement {
	const { mode } = useEditorSettings();

	return (
		<Droppable
			droppableId={WorkspaceLaneFalloffCatcherMaker + 'mid'}
			ignoreContainerClipping
			isCombineEnabled={false}
			isDropDisabled={false}
			direction="horizontal"
			type="steps"
		>
			{(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => {
				const { neutral100, neutral300, purple100 } = theme.colors;
				return (
					<div
						id={WorkspaceLaneFalloffCatcherMaker + 'mid'}
						ref={dropProvided.innerRef}
						style={{
							display: 'flex',
							flexDirection: 'column',
							backgroundColor: neutral100,
							width: '100%',
							flexGrow: 1,
						}}
						{...dropProvided.droppableProps}
					>
						<div
							className="fake-background"
							style={{
								background: `repeating-linear-gradient(${0}deg, transparent, transparent 63px, ${neutral300} 63px, ${neutral300} 64px)`,
							}}
						/>
						<Container
							id="lane"
							className="lane empty"
							sx={{
								...LANE_STYLE,
								minWidth: mode === 'experiment' ? 'calc(100vw  - 77px - 99px)' : 'calc(100vw - 77px - 77px - 102px )',
								backgroundColor: dropSnapshot.isDraggingOver ? purple100 : neutral100,
							}}
						>
							<LaneMarker>+</LaneMarker>
							{dropSnapshot.isDraggingOver && <GhostStep draggedOverWith={dropSnapshot.draggingOverWith} />}
							<Text className="inner-text" type="xSmall" purple500>
								Drag & Drop actions or templates here
							</Text>
						</Container>
						<div
							className="fake-background"
							style={{
								borderTop: '1px solid ' + neutral300,
								background: `repeating-linear-gradient(${180}deg, transparent, transparent 63px, ${neutral300} 63px, ${neutral300} 64px)`,
							}}
						/>
					</div>
				);
			}}
		</Droppable>
	);
}
