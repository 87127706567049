/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import ExecutionOverrideTable from 'pages/experimentsV2/ExecutionOverrideTable';
import ValidationHandler from 'pages/experimentsV2/ValidationHandler';
import { ExperimentFormValues } from 'pages/experimentsV2/types';
import { usePromise } from 'utils/hooks/usePromise';
import { Toggle, Tooltip } from 'components';
import { Services } from 'services/services';
import { mergeAndSort } from 'utils/envVars';
import DataStore from 'DataStore/DataStore';
import { toMillis } from 'utils/dateFns';
import { ReactElement } from 'react';
import { VariableVO } from 'ui-api';

import { ScheduleContentBlock } from './EditSchedulesModal/EditSchedulesModal';

interface ParallelProps {
	allowParallel: boolean;
	disabled: boolean;
	setAllowParallel: (v: boolean) => void;
}

export function Parallel({ allowParallel, disabled, setAllowParallel }: ParallelProps): ReactElement {
	return (
		<ScheduleContentBlock
			title="Run Experiments in parallel"
			description="At the configured time, should this experiment run even when another experiment runs in parallel?"
			setting={
				<Toggle
					type="radio"
					checked={allowParallel}
					disabled={disabled}
					style={{ minWidth: '36px' }}
					onChange={(e) => setAllowParallel(e.target.checked)}
				/>
			}
		/>
	);
}

interface OverrideVariablesProps {
	variables: VariableVO[] | undefined;
	experimentKey: string;
	disabled: boolean;
	setVariables: (v: VariableVO[] | undefined) => void;
}

export function OverrideVariables({
	experimentKey,
	variables,
	disabled,
	setVariables,
}: OverrideVariablesProps): ReactElement {
	const usedVariablesResult = usePromise(async () => {
		const experiment = await Services.experiments.fetchExperiment(experimentKey);
		const environmentVariables = await Services.environments.fetchEnvironmentVariables(experiment.environmentId);
		const metadata = await Services.templatesApi.getTemplateMetadata({
			...experiment,
			id: '',
			templateTitle: '',
			templateDescription: '',
			experimentName: '',
			placeholders: [],
			hidden: false,
		});

		return {
			variables: mergeAndSort(environmentVariables.content, experiment.experimentVariables).filter(
				({ key }) => metadata.variables[key],
			),
			occurances: metadata.variables,
			experiment,
		};
	}, [experimentKey]);

	const isLoading = usedVariablesResult.loading;
	const hasError = !!usedVariablesResult.error;
	const used = usedVariablesResult.value?.variables || [];

	disabled = disabled || used.length === 0;
	const expanded = !!variables && !disabled;
	const mergedVariables = mergeAndSort(used, variables || []);

	return (
		<ScheduleContentBlock
			title="Override variables for this schedule"
			description="Run the experiment with different variable overrrides per experiment schedule."
			setting={
				<Tooltip
					content={
						isLoading
							? 'Loading variables…'
							: hasError
								? 'There was an error loading variables'
								: used.length === 0
									? 'There are no variables used in this experiment'
									: ''
					}
				>
					<Toggle
						type="radio"
						checked={expanded && !disabled}
						disabled={disabled}
						style={{ minWidth: '36px' }}
						onChange={(e) => setVariables(e.target.checked ? [] : undefined)}
					/>
				</Tooltip>
			}
		>
			{expanded && usedVariablesResult.value && (
				<DataStore<ExperimentFormValues>
					initialData={{
						...usedVariablesResult.value.experiment,
						experimentKey,
						variables: [],
						experimentVariables: mergedVariables,
						actions: [],
						isSubmitting: false,

						created: toMillis(usedVariablesResult.value.experiment.created),
						edited: toMillis(usedVariablesResult.value.experiment.edited),
					}}
				>
					<ValidationHandler />
					<ExecutionOverrideTable
						experiment={usedVariablesResult.value.experiment}
						occurances={usedVariablesResult.value.occurances}
						variables={variables || []}
						usedVariables={used}
						disabled={disabled}
						setVariables={setVariables}
					/>
				</DataStore>
			)}
		</ScheduleContentBlock>
	);
}
