/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface RoleSelectProps {
	value?: string;
	onChange: (value: string) => void;
}

export default function RoleSelect({ value, onChange }: RoleSelectProps): ReactElement {
	const admin = { id: 'ADMIN', label: 'Admin' };
	const user = { id: 'USER', label: 'User' };

	return (
		<presets.dropdown.SingleChoiceButton
			withKeyboardArrowSupport
			items={[admin, user]}
			selectedId={value}
			size="small"
			onSelect={onChange}
			style={{
				width: '92px',
			}}
		>
			{value === admin.id ? admin.label : user.label}
		</presets.dropdown.SingleChoiceButton>
	);
}
