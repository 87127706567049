/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useCallback, useEffect } from 'react';
import { useStore } from 'DataStore/DataStore';
import { debounce } from 'lodash';

import { TemplateFormValues } from './types';

export default function PlaceholderExtractionAndCleanupJob(): null {
	const { data, setValue } = useStore<TemplateFormValues>();

	const debouncedExtract = useCallback(
		debounce(
			(v: TemplateFormValues) => {
				const { variables = [], placeholders = [] } = v.metadata || {};

				const currentUsedEnvVars = Object.keys(variables);
				const currentVariableKeys = v.variables.map((v) => v.key);
				if (currentUsedEnvVars.some((usedVariable) => !currentVariableKeys.includes(usedVariable))) {
					setValue(
						'variables',
						currentUsedEnvVars.map((key) => ({ key, value: '' })),
					);
				}

				const currentUsedPlaceholderKeys = Object.keys(placeholders);

				const keysToAdd = currentUsedPlaceholderKeys.filter((used) =>
					v.placeholders.every((placeholder) => placeholder.key !== used),
				);
				const keysToRemove = v.placeholders.filter((p) => !p.protected && !currentUsedPlaceholderKeys.includes(p.key));
				if (keysToAdd.length > 0 || keysToRemove.length > 0) {
					const newPlaceholders = v.placeholders
						.filter((p) => !keysToRemove.includes(p))
						.concat(keysToAdd.map((key) => ({ key, name: '', description: '', protected: false })));

					setValue('placeholders', newPlaceholders);
				}
			},
			200,
			{ leading: false },
		),
		[],
	);

	useEffect(() => {
		debouncedExtract(data);
	}, [data, debouncedExtract]);

	return null;
}
