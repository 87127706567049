/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { Button, Tooltip } from '@steadybit/ui-components-lib';
import ListHeader from 'components/List/presets/ListHeader';
import { IconHub } from 'components/icons';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { Stack } from 'components';

import ContentWrapper from '../components/ContentWrapper';
import ConnectHubModal from './ConnectHubModal';
import HelpText from '../components/HelpText';
import HubsTable from './HubsTable';

interface HubsProps {
	selectedHubId?: string;
	isNewHub?: boolean;
}

export default function Hubs({ selectedHubId, isNewHub }: HubsProps): ReactElement {
	const history = useHistory();
	const permissions = useGlobalPermissions();
	return (
		<>
			{isNewHub && <ConnectHubModal onClose={() => history.push('/settings/hubs')} />}

			<ContentWrapper>
				<ListHeader left={<ListHeaderTitle title="Hubs" Icon={IconHub} />} />
				<Stack direction="horizontal" justifyContent="space-between" alignItems="center" mb="small">
					<HelpText>
						Manage your connected hubs to ease importing templates from your own hub or showing documentation in the
						Steadybit platform.
					</HelpText>

					<Tooltip content={permissions.hubs.canCreate.tooltipContent}>
						{({ setRefElement }) => (
							<div ref={setRefElement}>
								<Button
									disabled={!permissions.hubs.canCreate.allowed}
									withLeftIcon="plus"
									onClick={() => history.push('/settings/hubs/<new>')}
								>
									Add new Hub
								</Button>
							</div>
						)}
					</Tooltip>
				</Stack>

				<Stack size="large">
					<HubsTable selectedHubId={selectedHubId} />
				</Stack>
			</ContentWrapper>
		</>
	);
}
