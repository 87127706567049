/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { createContext, useContext } from 'react';

export type Mode =
	| undefined
	| 'experiment'
	| 'templateEditor'
	| 'templateUsage'
	| 'templatePreview'
	| 'templateInExperiment'
	| 'templateInTemplate';
type EditorSettingsContextType = {
	mode: Mode;
};

export const EditorSettingsContext = createContext<EditorSettingsContextType>({ mode: undefined });
export const useEditorSettings = (): EditorSettingsContextType => {
	return useContext(EditorSettingsContext);
};
